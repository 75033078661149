import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";


export default function TimeComponent() {


  return (
    <Box p={4}>
        <div>
            hi
        </div>

    </Box>
  );
}
