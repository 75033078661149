import React, { useState, useEffect } from "react";
import "./addorder.scss";
import { Link } from "react-router-dom";
import Button from "../../../shared/components/button";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import Toggle from "../../../shared/components/switch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input } from "antd";
import moment from 'moment';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { control } from "leaflet";
import GlobalButton from "../../../shared/globalButton";
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";


const { TextArea } = Input;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#f1f1f1',
    minHeight: '40px',
    height: '40px',
    fontSize: '14px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '0 6px',
    fontSize: '14px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),
};
export default function AddOrder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const isEditMode = window.location.pathname.includes('/add') || false;
  const name = useSelector(state => state.auth.name);
  useEffect(() => {
    // Fetch data when the component mounts
    getData();
    getCustomer();


  }, []);
  const [productOptions, setProductOptions] = useState([]);
  const [customers, setCustomerOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const [newUpdate, setNewUpdate] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [formData, setFormData] = useState({
    orderNo: "",
    orderDate: new Date(),
    customerName: "",
    customerId: "",
    customerAddress: "",
    customerContact: "",
    status: true,
    orderStatus: 'Pending',
    orderproducts: [],
    ordernotification: [],
    ordercreatedby: '',
    orderupdate: '',
    orderupdatedby: '',
    totalAmount: 0,
  });
  const [dispatchFormData, setDispatchFormData] = useState({
    dispatchNo: "",
    dispatchDate: moment(),
    dispatchCode: "",
    invoiceNo: "",
    invoiceDate: moment(),
    transportId: "",
    transportName: "",
    billingAddress: "",
    dispatchSatatus: 'Pending',
    lrNo: '',
    lrDate: moment(),
    orderproducts: [],
    ordernotification: [],
    ordercreatedby: '',
    orderupdatedby: '',
    totalAmount: '',

  });

  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.id,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      // Fetch unit options
      const response = await fetchData(`systemtype`, {
        typeCategory: { in: ["unit"] },
        status: true,
      });
      if (response?.data) {
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === "unit")
            .map((i) => {
              return {
                label: i?.typeName,
                key: "unit",
                value: { id: i?.systemtypeId, name: i?.typeName },
              };
            })
        );
      }

      // Fetch product options
      const responseProducts = await fetchData(`product`);
      if (responseProducts?.data) {
        setProductOptions(
          responseProducts?.data?.map((i) => {
            return {
              label: `[${i?.hsn_code}] ${i?.name}`,
              key: "product",
              value: {
                productId: i?.id,
                productName: i?.name,
                unitPrice: i?.price,
                unitId: i?.unitId,
                unitName: i?.unitName,
                moq: i?.moq,
                tax: i?.tax,
                discount: i?.discount,
                productUrl: i?.productUrl,
                productCode: i?.hsn_code,
              },
            };
          })
        );
      }

      // Fetch and patch order data
      if (id) {
        const response = await fetchsingleData("order", id);
        if (response?.data) {
          const updatedorderproducts = response.data.orderproducts.map((product) => {
            return {
              ...product,
              total: calculatetotal(product), // Recalculate total for each product
            };
          });

          setFormData(response.data);
          setProductsForOrder([...updatedorderproducts]);
        }
      }
      const quotationId = new URLSearchParams(window.location.search).get("quotationId");
      if (quotationId) {
        getQuotationData(quotationId); // Fetch and pre-fill quotation data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getQuotationData = async (quotationId) => {
    try {
      const response = await fetchsingleData("quotation", quotationId);
      if (response?.data) {
        const quotationData = response.data;
        let customerName = quotationData?.linkname || "";
        setFormData((prevState) => ({
          ...prevState,
          orderDate: quotationData?.quotationDate,
          customerName: customerName || "",
          customerId: quotationData?.lead_to_customer_id || quotationData?.link,
          customerAddress: quotationData?.customeraddress,
          customerContact: quotationData?.customercontact,
          status: quotationData?.status,
          orderStatus: "Pending",
          ordernotification: [],
          ordercreatedby: "sales",
          totalAmount: quotationData?.totalamount,
        }));
        const orderproducts = (quotationData?.orderproducts ?? [])?.map((product) => ({
          index: product.index,
          name: product.name ?? '',
          productId: product.productId,
          id: product.productId,
          unitId: product.unitId,
          unitName: product.unitName,
          quantity: product.qty,
          unitPrice: product.unitPrice,
          discount: product.discount,
          tax: product.tax,
          total: product?.subTotal,
          productUrl: product.productUrl,
          remark:product.remark
        }))
        setProductsForOrder([...orderproducts])
        // calculateTotal(quotationData.orderproducts);
      }
    } catch (error) {
      console.error("Error fetching quotation data:", error);
    }
  };


  const getTransportData = async () => {
    try {
      if (id) {
        const response = await fetchData(`trasport`, {
          status: true,
        });
        if (response?.data) {
          setTransportOptions(
            response?.data?.map((i) => {
              return {
                label: i?.name,
                key: "name",
                value: { id: i?.trasportId, name: i?.name },
              };
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSubmit = (e) => {
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    // const updatedNotification = { date: currentDateTime, user: name };
    // setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
    // setNewUpdate(prevNotifications => [...prevNotifications, updatedNotification]);





    e.preventDefault();
    if (validationCheck() && productsForOrder?.length > 0) {
      try {
        if (id) {
          patchData(`order/${id}`, { ...formData, orderproducts: [...productsForOrder], orderupdatedby: name, orderupdate: currentDateTime })
            .then((data) => {
              notify.success('Order updated Successfully!');
              navigate(`/order`);
            })
            .catch((error) => {
              notify.error('Error while saving order');
              console.error("Error fetching data:", error);
            });
        } else {
          postData("order", { ...formData, orderproducts: [...productsForOrder], ordercreatedby: name, })
            .then((data) => {
              notify.success('Order saved Successfully!');
              navigate(`/order`);
            })
            .catch((error) => {
              notify.error('Error while saving order');
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };
  const handleEvent = (e, type) => {
    e.preventDefault();
    if (type) {
      try {
        if (id) {
          patchData(`order/${id}`, { orderId: id, orderStatus: type, dispatchs: [] })
            .then((data) => {
              navigate(-1);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);

      }
    }
  };
  const selectChanged = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id,

    }));
    if (e.key === "customer") {
      setFormData((prevState) => ({
        ...prevState,
        customerAddress: e.value?.address,
        customerContact: e.value?.phone,
      }));

    }
    if (errors.isSubmit) validationCheck();
  };

  const selectChangedDispatch = (e) => {
    setDispatchFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors.isSubmit) validationCheck();
  };

  const handleDateChange = (date) => {
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        orderDate: date.toISOString(), // Converts a valid date to an ISO string
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        orderDate: null, // Explicitly set as null if no valid date is selected
      }));
    }
  };


  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.customerName.trim()) {
      validationErrors.customerName = "Please select customer";
    }
    if (!formData.orderDate) {
      validationErrors.orderDate = "Select Order Date";
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter((i) => i !== "isSubmit")
      .length === 0
      ? true
      : false;
  };

  const [productsForOrder, setProductsForOrder] = useState([]);
  const AddProduct = () => {
    const orderproducts = [...productsForOrder];
    const newProduct = {
      remark:"",
      productName: "",
      productId: "",
      unitId: "",
      unitName: "",
      quantity: 1,
      unitPrice: 0,
      discount: 0,
      tax: 0,
      productUrl: '',
      productStatus: 'dispatchPending',
      productCode: ''

    };

    // Calculate initial total for new product
    newProduct.total = calculatetotal(newProduct);

    orderproducts.push(newProduct);
    setProductsForOrder([...orderproducts]);
  };
  const calculatetotal = (product) => {
    const unitPrice = Number(product.unitPrice) * Number(product.quantity) || 0;
    const discount = (unitPrice * Number(product.discount)) / 100 || 0;
    const tax = ((unitPrice - discount) * Number(product.tax)) / 100 || 0;
    return Number(((unitPrice - discount) + tax).toFixed(2));
  };

  const productSelectChange = (index, event, type) => {
    const orderproducts = [...productsForOrder];
    if (type === "p") {
      const product = orderproducts[index];
      product.productId = event.value.productId;
      product.productName = event.value.productName;
      product.unitName = event.value.unitName;
      product.unitId = event.value.unitId;
      product.quantity = Number(event.value.moq);
      product.unitPrice = Number(Number(parseFloat(event.value.unitPrice)).toFixed(2));
      product.tax = Number(event.value.tax);
      product.discount = Number(parseFloat(event.value.discount));
      product.productUrl = event.value.productUrl;
      product.productCode = event.value.productCode;

      // Recalculate total
      product.total = calculatetotal(product);

      setProductsForOrder([...orderproducts]);
      calculateTotal(orderproducts);
    }
  };


  const handleInputChangeProduct = (e, index) => {
    const { name, value } = e.target;
    const orderproducts = [...productsForOrder];

    // Update the specific field
    orderproducts[index][name] = name === "quantity" || name === "unitPrice" ?
      Number(parseFloat(value).toFixed(2)) : value;

    // Recalculate total for the updated product
    orderproducts[index].total = calculatetotal(orderproducts[index]);

    setProductsForOrder([...orderproducts]);
    calculateTotal(orderproducts);
  };

  const calculateTotal = (orderproducts) => {
    const total = orderproducts.reduce((acc, orderproducts) => acc + (orderproducts.total || 0), 0);
    setFormData({ ...formData, totalAmount: total.toFixed(2) });
  }
  const handleRemoveProduct = (indexToRemove, producttotal) => {
    const orderproducts = [...productsForOrder];
    orderproducts.splice(indexToRemove, 1);
    setProductsForOrder(orderproducts);
    calculateTotal(orderproducts);
  };
  const handleDispatch = (e, type) => {
    if (type === 'Add') {
      setAddDispatch(!addDispatch);
      setFormData({ ...formData, dispatchs: [{ ...dispatchFormData }] });
      getTransportData();
    }
  }
  const handleAddProductClick = () => {
    if (formData.customerId) {
      AddProduct();
    }
  };




  const [addDispatch, setAddDispatch] = useState(false);


  return (
    <div className="add-order-page-design-change">
      {isEditMode && (
        <div className="page-header add-news-alignment">
          <span>Order {`>`} {id ? 'Edit' : 'Create'}</span>
          <div className="two-button-alignment">
            <div onClick={handleSubmit}>
              <GlobalButton text={id ? 'Update' : 'Save'} />
            </div>
            <Link to="/order"><GlobalButton text='Discard' outline /></Link>

          </div>
        </div>
      )}
      {!isEditMode && (
        <div className="page-header add-news-alignment">
          <span>Order</span>
          {formData?.orderStatus === "Pending" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleEvent(e, "open");
                }}
              >
                <GlobalButton text="Open Order" />
              </div>


              <GlobalButton text="Cancel Order" cancel />

            </div>
          )}
          {formData?.orderStatus === "open" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleDispatch(e, "Add");
                }}
              >
                <GlobalButton text="Add" />
              </div>
              <div
                onClick={(e) => {
                  handleEvent(e, "Pending");
                }}
              >
                <GlobalButton text="Pending Order" outline />
              </div>
              <GlobalButton text="Close Order" outline />
              <GlobalButton text="Cancel Order" cancel />
            </div>
          )}
          {formData?.orderStatus === "cancelled" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleEvent(e, "pending");
                }}
              >
                <GlobalButton text="Pending Order" outline />
              </div>
              <GlobalButton text="Close Order" outline />
              <GlobalButton text="Cancel Order" cancel />
            </div>
          )}
        </div>
      )}
      <div>
        <div className='add-news-form-design' style={{ margin: '10px -5px 10px 3px' }}>
          <h2 style={{ marginTop: '-30px', paddingBottom: '20px' }}>Customer Details</h2>
          <div className='grid' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '70%' }}>
            <div className="bottom-alignment">
              <InputCustom
                label="Order No"
                name="orderNo"
                value={formData.orderNo}
                onChange={handleInputChange}
                placeholder="#orderNo"
                type="text"
              />
            </div>
            <div className="bottom-alignment select-change-design-change">
              <SelectBox
                label={
                  <span>
                    Customer<span className="required-star">*</span>
                  </span>}
                styles={customStyles}
                name="customer"
                value={customers?.find(
                  (option) => option.value.id === formData.customerId
                )}
                options={customers}
                onChange={selectChanged}
                placeholder="Customer Name"
                type="text"
                required={true}
                errors={errors.customerName}
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker label={
                  <span>
                    Date<span className="required-star">*</span>
                  </span>}
                  name='orderDate' value={dayjs(formData.orderDate)} onChange={handleDateChange} />
              </LocalizationProvider>
            </div>


          </div>
          {formData?.customerAddress && (<div><div>
            <h2 style={{ marginTop: '15px' }}>Billing Details</h2><hr />
          </div>
            <div>
              <TextArea
                rows={3}
                value={`${formData.customerAddress}\n${formData.customerContact}`} // Use value to reflect updates
                disabled
                style={{ width: '50%' }}
              />
            </div>
          </div>)}
        </div>

        <div className="add-news-form-design">
          <div className="product-details-header-alignment">
            <h2 style={{
              zIndex: 1111,
            }}>Product Details</h2>

          </div>
          <div className="order-table" style={{
            zIndex: -1,
            position: '',
            top: 'auto',
            //bottom: '100%',
          }}>
            <div className="table-design"
              style={{ overflow: "unset" }}
            >
              <table >
                <thead>
                  <tr style={{ border: '1px solid #eee' }}>
                    <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                    <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                    <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {productsForOrder.map((i, INDEX) => {
                    return (
                      <tr key={INDEX}>
                        <td className="width-auto">
                          <SelectBox
                            name="product"
                            value={productOptions.find(
                              (option) => option.value.productId === i.productId
                            )}
                            options={productOptions}
                            onChange={(e) =>
                              productSelectChange(INDEX, e, "p")
                            }
                            placeholder="Product Name"
                            type="text"

                            styles={{
                              overflow: "initial",
                              ...customStyles,
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                position: 'absolute'



                              }),
                              control: (provided) => ({
                                ...provided,
                                width: '100%'



                              })
                            }}
                          />
                          {company_setting?.extra_setting?.remarksInOrderProduct ==="Yes" && (
                                                    <TextArea  name='remark' value={i?.remark} onChange={(e) =>
                                                        handleInputChangeProduct(e, INDEX)
                                                        } placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>
                                                        
                                                )}
                        </td>
                        <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                          <SelectBox
                            name="unit"
                            isDisabled={true}
                            value={unitOptions.find(
                              (option) => option.value.id === i.unitId
                            )}
                            options={unitOptions}
                            onChange={(e) =>
                              productSelectChange(INDEX, e, "u")
                            }
                            placeholder="Unit Name"
                            type="text"

                          />
                        </td>
                        <td className="whitesapce-nowrap width-auto">
                          <InputCustom
                            name="quantity"
                            value={i?.quantity}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                          />
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="unitPrice"
                            value={i?.unitPrice}
                            onChange={(e) => handleInputChangeProduct(e, INDEX)}
                            type="number"
                            disabled={!checkAccess('edit_price')}
                          />
                        </td>

                        <td className="width-auto">
                          <InputCustom
                            name="discount"
                            value={i?.discount}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                            disabled={!checkAccess('edit_discount')}
                          />
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="tax"
                            value={i?.tax}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                            disabled={!checkAccess('edit_tax')}
                          />
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="total"
                            value={i?.total}
                            readOnly={true}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                          />
                        </td>
                        <th className="width-auto vartical-center">
                          <div
                            onClick={() => {
                              const productToRemove = productsForOrder[INDEX]; // Assuming INDEX is the current index of the product
                              handleRemoveProduct(INDEX, productToRemove.total);
                            }}
                            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <i className="fas fa-trash-alt" ></i>
                          </div>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div style={{ paddingTop: '15px' }}>  {isEditMode && (
              <div onClick={handleAddProductClick}>
                <GlobalButton text="Add Product" />
              </div>
            )}</div>
            {/* <div> <table>
                <tr>
                  <th className="width-50">Sub Total</th>
                  <th></th>
                  <th className="width-50">Total</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Megha</td>
                  <td>19</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>Subham</td>
                  <td>25</td>
                  <td>Male</td>
                </tr>
              </table></div> */}
            <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
              marginLeft: '70%',
              maxWidth: '30%',
              background: '#f5f5f5',
              border: '2px #eee'
            }}>
              <tbody>

                <tr></tr>
                <tr>
                  <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                  <td></td>
                  <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.totalAmount}</b></td>
                </tr>
              </tbody>
            </table></div>
          </div>
        </div>
      </div>
      {formData?.orderStatus === "Open" && formData?.dispatchs?.length >= 0 && !addDispatch && (
        <div className="order-table">
          <div className="table-design">
            <table>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Dispatch #</th>
                  <th>Date</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Transport</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                {formData?.dispatchs?.map((i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <EditIcon />
                      </td>
                      <td className="whitesapce-nowrap">{i?.orderNo}</td>
                      <td>
                        <span className="price-group-text">
                          {i?.customerName}
                        </span>
                      </td>
                      <td className="whitesapce-nowrap">
                        {moment(i?.orderDate).format("MMMM Do YYYY, h:mm:ss a")}
                      </td>
                      <td>
                        {i?.orderproducts
                          ?.reduce((accumulator, currentValue) => {
                            return accumulator + Number(currentValue.total);
                          }, 0)
                          .toFixed(2)}
                      </td>
                      <td>SYSTEM</td>
                      <td>
                        <Link to={{ pathname: `view/${i?.orderId}` }}>
                          <button className={i?.orderStatus}>
                            {i?.orderStatus}
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {
        formData?.orderStatus === 'Open' && addDispatch && (
          <div className="add-news-form-design">
            <h2>Basic Details</h2>
            <div className="grid">
              <div className="bottom-alignment">
                <InputCustom
                  label="Dispatch No."
                  name="dispatchNo"
                  value={dispatchFormData.dispatchNo}
                  onChange={handleInputChange}
                  placeholder="#dispatchNo"
                  type="text"
                />
              </div>
              <div className="bottom-alignment grid-column-auto">
                <Form layout="vertical" autoComplete="off">
                  <Form.Item
                    name="dispatchDate"
                    label="Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      label="Order Date"
                      name="dispatchDate"
                      onChange={handleDateChange}
                      defaultValue={dispatchFormData.dispatchDate}
                      errors={errors.dispatchDate}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="bottom-alignment">
                <InputCustom
                  label="Dispatch code"
                  name="dispatchCode"
                  value={dispatchFormData.dispatchCode}
                  onChange={handleInputChange}
                  placeholder="#dispatchCode"
                  type="text"
                />
              </div>
              <div className="bottom-alignment">
                <InputCustom
                  label="Invoice No."
                  name="invoiceNo"
                  value={dispatchFormData.invoiceNo}
                  onChange={handleInputChange}
                  placeholder="#invoiceNo"
                  type="text"
                />
              </div>
              <div className="bottom-alignment grid-column-auto">
                <Form layout="vertical" autoComplete="off">
                  <Form.Item
                    name="invoiceDate"
                    label="Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      label="Invoice Date"
                      name="invoiceDate"
                      onChange={handleDateChange}
                      defaultValue={dispatchFormData.invoiceDate}
                      errors={errors.invoiceDate}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="bottom-alignment select-change-design-change">
                <SelectBox
                  label="Transport"
                  styles={customStyles}
                  name="transport"
                  options={transportOptions}
                  onChange={selectChangedDispatch}
                  type="text"
                  required={true}
                />
              </div>
              {formData?.customerAddress && (
                <div className="bottom-alignment">
                  <Form layout="vertical" autoComplete="off">
                    <Form.Item name="billingAddress" label="Billing Address">
                      <TextArea
                        rows={3}
                        defaultValue={`${formData?.customerAddress}\n${formData?.customerContact}`}
                        disabled
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>
        )
      }
    </div>
  );
}
