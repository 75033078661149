import { useState } from "react";
import "./paymenttype.scss";
import Button from "../../shared/components/button";
import Paymenttypetable from "./paymenttypetable";
import { deleteData, filterAll } from "../../services/apiService";
import { Link } from "react-router-dom/dist";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import { QuestionCircleOutlined } from '@ant-design/icons';

import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { Popconfirm } from "antd";
export default function Paymenttype() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [paymenttypeList, setpaymentTypeList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(
        selectedIds.map((id) => deleteData(`paymenttype/${id}`))
      );
      setpaymentTypeList(
        paymenttypeList.filter((i) => !selectedIds.includes(i.id))
      );
      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleExport = () => {
    const headers = [
      ["Name","Status"]

    ];
 
    filterAll("paymenttype")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
      item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('Payment Type',headers,datas,'paymenttype')
    })
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };



  return (
    <div className="page-24">
      <div className="">
        <div className="pb-3 customer-header-alignment">
          <div>
            <h2>Payment Type</h2>
            <Breadcumbs activePath="Payment Type" pageName="Payment Type" />
          </div>
          <div className="right-contnet-alignment">
          {selectedIds.length > 0 && (
            <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure to delete this record? "
            description="This will lead to delete all records related to this record only!"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
          </Popconfirm>
              
            )}
            <Link to="addEditpaymenttype">
              <GlobalButton text="Add Payment Type" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>
      <Paymenttypetable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        paymenttypeList={paymenttypeList}
        setProductList={setpaymentTypeList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    </div>
  );
}
