import React from 'react'
import './productwisereport.scss';
import Productwisereportdetails from './productwisereportdetails';
export default function Productwisereport() {
  return (
    <div>
      <div className='page-header'>
        <span>Productwise Dispatch Report</span>
      </div>
      <Productwisereportdetails/>
    </div>
  )
}
