import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../services/apiService';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
export default function MyActivitiesTable({
  myactivitiesids = [],
  setSelectedIds,
  setMyActivitiesList,
  myactivitiesList = [],
  setProductList,
  setTotalItems,
  totalItems,
}) {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10; // Numb
  const [activityType, setactivityTypeTerm] = useState(""); // Initialize as an empty string
  const [Value, setValueTerm] = useState("");
  const [Assign, setAssignTerm] = useState("");
  const [Linked, setLinkedTrem] = useState(""); // Initialize as an empty string
  const [LinkedName, setLinkedNameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchData('myactivities', requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []); // API returns current page data in 'data'
        setTotalItems(response?.totalDocuments || 0); // API returns total count of items in 'totalDocuments'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (e) => {
    const pageItems = myactivitiesList;
    setSelectedIds(e.target.checked ? pageItems.map((i) => i.id) : []);
  };

  const paginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    return myactivitiesList.slice(startIndex, startIndex + pageSize);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredMyactivities = myactivitiesList.filter((item) => {
    const matchesDateRange = (selectedDates && selectedDates.length === 2)
    ? dayjs(item.activitydate).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.activitydate).isSameOrBefore(selectedDates[1], 'day')
    : true;
    return (
      (!activityType || item.activityname?.toLowerCase().includes(activityType.trim().toLowerCase())) &&
      (!Value || (item.activityvalue?.toString().trim() || '').toLowerCase().includes(Value.trim().toLowerCase())) &&
      (!Assign || (item.username?.toString().trim() || '').toLowerCase().includes(Assign.trim().toLowerCase())) &&
      (!Linked || item.module?.toString().trim().includes(Linked.trim())) && // Convert price to string for comparison
      (!LinkedName || item.linkname?.toString().trim().includes(LinkedName.trim())) && // Convert stock to string for comparison
      (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) &&
      matchesDateRange 
    );
  

  });

  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Activity Type	" value={activityType	} onChange={(e) => setactivityTypeTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Activity Value" value={Value} onChange={(e) => setValueTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>

              <th><input type="text" placeholder="Assign To" value={Assign } onChange={(e) => setAssignTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Linked To" value={Linked } onChange={(e) => setLinkedTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Linked Name" value={LinkedName} onChange={(e) => setLinkedNameTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
           
            </tr>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    myactivitiesList.length > 0 &&
                    myactivitiesids.length === myactivitiesList.length
                  }
                />
              </th>
              <th align="center">Action</th>
              <th>Activity Type</th>
              <th>Activity Value</th>
              <th>Date</th>
              <th>Assign To</th>
              <th>Linked To</th>
              <th>Linked Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredMyactivities.map((i) => (
              <tr key={i.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={myactivitiesids.includes(i.id)}
                    onChange={() => handleCheckboxChange(i.id)}
                  />
                </td>
                <td>
                  <Link to={`addeditActivity/${i?.id}`}>
                  <EditIcon/>
                  </Link>
                </td>
                <td>{i?.activityname}</td>
                <td>{i?.activityvalue}</td>
                <td>{i?.activitydate ? moment(i.activitydate).format('YYYY-MM-DD') : 'N/A'}</td>
                <td>{i?.username}</td>
                <td>{i?.module}</td>
                <td>{i?.linkname}</td>
                <td>
                {i?.status ? 'Active' : 'Deactive'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} // Total items from API response
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
