import React, { useState, useEffect } from "react";
import "./adddeal.scss";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../shared/components/button";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import Toggle from "../../../shared/components/switch";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Form, Input, DatePicker } from "antd";
import moment from 'moment';
import dayjs from "dayjs";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";
import StatusBox from "../../addcustomers/statusBox";
import { checkAccess } from "../../../services/checkFeature";
import store from "../../../redux/reduxstore";

const { TextArea } = Input;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#f1f1f1',
        minHeight: '40px',
        height: '40px',
        fontSize: '14px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px',
        fontSize: '14px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
};
export default function AddDeal() {
    const navigate = useNavigate();
    const { id } = useParams();
    const company_setting = store.getState()?.auth?.company_setting;
 
    // console.log(id);

    const isEditMode = window.location.pathname.includes('/add') || false;
    useEffect(() => {


        // if (id) {
        //     getData();
        // }
    }, []);

    const location = useLocation();
    const leadFormData = location.state?.formData; // Access leadId from location state
    console.log("lead form data", leadFormData)
    const [usermoduleOptions, setModuleOptions] = useState([]);
    const [linkdropDown, setlinkdropDown] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [customers, setCustomerOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);

    const [formData, setFormData] = useState({
        // leadId : id,
        dealno: "",
        dealfor: "",
        dealdate: dayjs(),
        customerName: "",
        // customerId: "",
        customerAddress: "",
        customerContact: "",
        status: true,
        dealStatus: 'in progress',
        closingdate: dayjs(),
        products: [],
        dealnotification: [],
        totalAmount: '00',
    });
    

    const dealDate = formData.dealdate ? dayjs(formData.dealdate) : null;
    const closingDate = formData.closingdate ? dayjs(formData.closingdate) : null;


    useEffect(() => {

        fetchModule();
        getData();

    }, [formData.dealfor]);

    console.log("formData", formData)
    const [errors, setErrors] = useState({});

    const fetchModule = async () => {
        try {
            const response = await fetchData('module');
            if (response?.data) {
                setModuleOptions(
                    response.data
                        .filter((module) =>
                            module.modulename === 'Lead' || module.modulename === 'Customer'
                        )
                        .map((module) => ({
                            ...module,
                            label: module.modulename,
                            value: module.modulename,
                        }))
                );
            }
        } catch (error) {
            console.error('Error fetching modules:', error);
        }
    };

    const getdowpDownlist = async (type) => {
        try {
            const response = await fetchData(type);
            if (response?.data) {
                const modules = await fetchData('module');
                const keyName = (modules?.data ?? []).find(f => f?.modulename?.toLowerCase() === type)?.moduleKey;
                setlinkdropDown(
                    (response?.data ?? []).map((module) => ({
                        label: module?.[keyName],
                        value: module?.id,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching ' + type, error);
        }
    };
    const handleSelectChange = (event, type) => {
        if (type === 'dealfor') {
            setFormData({
                ...formData,
                [type]: event.value
            });
            getdowpDownlist(event?.value?.toLowerCase())
        }
        else {
            let formPayload = {
                ...formData,
                [type]: event.value
            }
            if (type === 'link') {
                formPayload = { ...formPayload, customerName: linkdropDown?.find(d => d?.value === event?.value)?.label }
            }
            setFormData(formPayload);
        }
    };
    const getData = async () => {
        try {
            const response = await fetchData(`systemtype`, {
                typeCategory: { in: ["unit"] },
                status: true,
            });
            if (response?.data) {
                setUnitOptions(
                    response?.data
                        .filter((i) => i?.typeCategory === "unit")
                        .map((i) => {
                            return {
                                label: i?.typeName,
                                key: "unit",
                                value: { id: i?.id, name: i?.typeName },
                            };
                        })
                );
            }
            const responseProducts = await fetchData(`product`);
            if (responseProducts?.data) {
                setProductOptions(
                    responseProducts?.data?.map((i) => {
                        return {
                            label: `[${i?.hsn_code}] ${i?.name}`,
                            key: "product",
                            value: { id: i?.id, name: i?.name, price: i?.price, unitId: i?.unitId, unitName: i?.unitName, moq: i?.moq, tax: i?.tax, discount: i?.discount },
                        };
                    })
                );
            }
            if (id) {
                const response = await fetchsingleData("deal", id);
                if (response?.data) {
                    setFormData(response?.data); // Update state with fetched data
                }
                setProductsForOrder([...response?.data?.products]);
                getdowpDownlist(response?.data?.dealfor?.toLowerCase())
            }
            if (leadFormData?.id) {
                getdowpDownlist('lead')
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    dealfor: "Lead", // Set 'dealfor' field
                    link: leadFormData.id, // Set 'link' field
                }));
                // setFormData({dealfor:'Lead',link:leadFormData?.id}); // Update state with fetched data
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     debugger
    //     // if (validationCheck(true) && productsForOrder?.length > 0) {
    //         console.log("=====");

    //         try {
    //             const payload = { ...formData, leadId: id, products: [...productsForOrder] };

    //             if (id) {
    //                 patchData(`deal/${id}`, formData)
    //                     .then((data) => {
    //                         navigate(-1);
    //                         console.log("API response:", data);
    //                     })
    //                     .catch((error) => {
    //                         console.error("Error fetching data:", error);
    //                     });
    //             }
    //              else {
    //                 const data = formData
    //                 console.log("====",formData)
    //                 const requestBody={
    //                     totalAmount:formData.totalAmount,customerAddress:formData.customerAddress,customerContact:formData.customerContact,customerName:formData.customerName,dealStatus:formData.dealStatus,dealdate:formData.dealdate,customerId:formData.customerId,dealfor:formData.dealfor,dealno:formData.dealno,dealnotification:formData.dealnotification,link:formData.link,products:formData.products,status:formData.status
    //                 }

    //                 postData("deal", { data})
    //                     .then((data) => {
    //                         navigate(-1);
    //                         console.log("API response:", data);
    //                     })
    //                     .catch((error) => {
    //                         console.error("Error fetching data:", error);
    //                     });
    //             }
    //         } catch (error) {
    //             console.error("Error saving data:", error);
    //             // Handle error
    //         }
    //     // }
    // };
    const handleSubmit = (e) => {
        e.preventDefault();

        // debugger;
        if (validationCheck()) {
            try {
                const payload = { ...formData, leadId: id, products: [...productsForOrder] };

                if (id) {
                    // PATCH existing record
                    patchData(`deal/${id}`, formData)
                        .then((data) => {
                            navigate(-1); // Navigate back to the previous screen
                            console.log("API response:", data);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                } else {
                    // POST new record
                    console.log("====", formData);

                    const requestBody = {
                        totalAmount: formData.totalAmount,
                        customerAddress: formData.customerAddress,
                        customerContact: formData.customerContact,
                        customerName: formData.customerName,
                        dealStatus: formData.dealStatus,
                        dealdate: formData.dealdate,
                        closingdate: formData.closingdate,
                        // customerId: formData.customerId,
                        dealfor: formData.dealfor,
                        dealno: formData.dealno,
                        dealnotification: formData.dealnotification,
                        link: formData.link,
                        products: formData.products,
                        status: formData.status,
                    };

                    // Pass the requestBody directly instead of wrapping it inside another object
                    postData("deal", requestBody)
                        .then((data) => {
                            navigate(-1); // Navigate back to the previous screen
                            console.log("API response:", data);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                }
            } catch (error) {
                console.error("Error saving data:", error);
                // Handle error
            }
        }
    };

    const handleEvent = (e, type) => {
        e.preventDefault();
        if (type) {
            try {
                if (id) {
                    patchData(`deal/${id}`, { dealId: id, dealSatatus: type })
                        .then((data) => {
                            navigate(-1);
                            console.log("API response:", data);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                }
            } catch (error) {
                console.error("Error saving data:", error);
                // Handle error
            }
        }
    };
    const selectChanged = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [`${e.key}Name`]: e.value?.name,
            [`${e.key}Id`]: e.value?.id,
        }));

        if (e.key === "customer" || e.key === "lead") {
            setFormData((prevState) => ({
                ...prevState,
                customerAddress: e.value?.address,
                customerContact: e.value?.contact,
            }));
        }
        if (errors.isSubmit) validationCheck();
    };



    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const handleDateChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            dealdate: new Date(e)
        }));
    };

    const handleDdateChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            closingdate: new Date(e)
        }));
    };


    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData.dealdate) {
            validationErrors.dealdate = "Select Deal Date";
        }
        if (!formData.closingdate) {
            validationErrors.closingdate = "Select Close Deal Date";
        }
        if (!formData.customerName) {
            validationErrors.customerName = "Select Customer Name";
        }
        if (!formData.dealfor) {
            validationErrors.dealfor = "Select Lead/Customer";
        }
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);

        return Object.keys(validationErrors).filter((i) => i !== "isSubmit")
            .length === 0
            ? true
            : false;
    };

    const [productsForOrder, setProductsForOrder] = useState([]);
    const AddProduct = () => {
        const products = [...productsForOrder];
        products.push({
            index: products.length + 1,
            remark:"",
            name: "",
            id: "",
            unitId: "",
            unitName: "",
            quantity: 1,
            price: 0,
            discount: 0,
            tax: 0,
            subTotal: 0,
        });
        setProductsForOrder([...products]);
        setFormData((prevFormData) => ({
            ...prevFormData,
            products
        }));
    };

    const productSelectChange = (index, event, type) => {
        if (type === 'p') {
            const products = [...productsForOrder];
            products[index]['id'] = event.value.id;
            products[index]['name'] = event.value.name;
            products[index]['unitName'] = event.value.unitName;
            products[index]['unitId'] = event.value.unitId;
            products[index]['quantity'] = Number(event.value.moq);
            products[index]['price'] = Number(Number(parseFloat(event.value.price)).toFixed(2));
            products[index]['tax'] = Number(event.value.tax);
            products[index]['discount'] = Number(parseFloat(event.value.discount));
            products[index]['subTotal'] = (Number(Number(event.value.price) * Number(event.value.moq).toFixed(2)));
            setProductsForOrder([...products]);

        }
    }

    const handleInputChangeProduct = (e, index) => {
        const { name, value, checked, type } = e.target;
        const products = [...productsForOrder];
        if (name === 'quantity') {
            products[index][name] = Number(parseFloat(value).toFixed(2));
        }
        if (name === 'price') {
            products[index][name] = Number(parseFloat(value).toFixed(2));;
        }
        if (name === 'discount') {
            products[index][name] = value;
        }
        if (name === 'tax') {
            products[index][name] = value;
        }
        if (name === 'remark') {
            products[index][name] = value;
        }
        const price = Number(products[index]['price']) * Number(products[index]['quantity']) || 0;
        const discount = (price * Number(products[index]['discount']) / 100) || 0;
        const tax = ((price - discount) * Number(products[index]['tax']) / 100) || 0;
        products[index]['subTotal'] = Number(parseFloat(((price - discount) + tax)).toFixed(2));

        setProductsForOrder([...products]);
        calculateTotal(products);

    };
    const calculateTotal = (products) => {
        const total = products.reduce((acc, products) => acc + (products.subTotal || 0), 0);
        setFormData({ ...formData, totalAmount: total.toFixed(2) });
        console.log(formData.totalAmount);
    }
    const handleRemoveProduct = (indexToRemove, productSubTotal) => {
        const products = [...productsForOrder];
        products.splice(indexToRemove, 1);
        setProductsForOrder(products);
        calculateTotal(products);
    };

    const handleAddProductClick = () => {

        AddProduct();

    };

    useEffect(() => {
        // Check if leadFormData has valid values for name and id
        if (leadFormData?.id && leadFormData?.name) {
            console.log("=====")
            // Set the initial values for the SelectBox in formData
            setFormData((prevFormData) => ({
                ...prevFormData,
                customerId: leadFormData.id,
                customerName: leadFormData.name,
            }));
        }
    }, [leadFormData]);



    return (
        <div className="page-24">

            <div className='add-header-design'>
                <div>
                    <h2>Add Deal</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Deal " />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/payment-collection">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>

            <div className="page-main-grid">
                <div>
                    <div className="new-white-box-design">
                        <h2>Basic Details</h2>
                        <div className="two-col-grid">
                            <InputCustom
                                label="Deal No"
                                name="dealno"
                                value={formData.dealno}
                                onChange={handleInputChange}
                                placeholder="#dealNo"
                                type="text"
                            />
                            <SelectBox
                                label={
                                    <span>
                                        Deal for <span className="required-star">*</span>
                                    </span>}
                                name="dealfor"
                                placeholder="Select Deal for"
                                options={usermoduleOptions}
                                value={usermoduleOptions?.find(i => i?.value === formData.dealfor)}
                                onChange={(e) => handleSelectChange(e, 'dealfor')}
                                style={{ width: '100%' }}
                                errors={errors.dealfor}
                            />
                            <SelectBox
                                name="link"
                                label={
                                    <span>
                                     Linked / Customer <span className="required-star">*</span>
                                     </span> }                                 
                                placeholder={"Linked / " + (formData?.module ? formData?.module : 'Customer')}
                                options={linkdropDown}
                                value={linkdropDown?.find(i => i?.value === formData.link)}
                                onChange={(e) => handleSelectChange(e, 'link')}
                                style={{ width: '100%' }}
                                errors={errors.customerName}
                            />
                            <Form layout="vertical" autoComplete="off">
                                <Form.Item
                                    name="dealdate"
                                    label="Deal Date"
                                    rules={[{ required: true }]}
                                >
                                 <DatePicker
                                    label="Deal Date"
                                    name="dealdate"
                                    value={dealDate}
                                    onChange={handleDateChange}
                                    errors={errors.dealdate}
                                />;
                               
                                </Form.Item>
                            </Form>
                            <Form layout="vertical" autoComplete="off">
                                <Form.Item
                                    name="closingdate"
                                    label="Closing Deal Date"
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker
                                        label="Closing Deal Date"
                                        name="closingdate"
                                        value={closingDate}
                                        onChange={handleDdateChange}
                                        // defaultValue={formData.closingdate}
                                        errors={errors.closingdate}
                                    />;
                                </Form.Item>
                            </Form>
                        </div>
                        {formData?.customerAddress && (
                            <div className="bottom-alignment">
                                <Form layout="vertical" autoComplete="off">
                                    <Form.Item name="billingAddress" label="Billing Address">
                                        <TextArea
                                            rows={3}
                                            defaultValue={`${formData?.customerAddress}\n${formData?.customerContact}`}
                                            disabled
                                        />
                                    </Form.Item>
                                </Form>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>




            <div>
                <div>

                    <div className="">
                        <div className="product-details-header-alignment">
                            <h2 className="h2-style">Product Details</h2>

                        </div>
                        <div >
                            <div className="account-table-design"
                                style={{ overflow: 'initial' }}
                            >
                                <table >
                                    <thead>
                                        <tr style={{ border: '1px solid #eee' }}>
                                            <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                                            <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                                            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                                            <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                                            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                                            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                                            <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                                            <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productsForOrder.map((i, INDEX) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="width-auto">
                                                        <SelectBox
                                                            label="Product"
                                                            name="product"
                                                            value={productOptions.find(
                                                                (option) => option.value.id === i.id
                                                            )}
                                                            options={productOptions}
                                                            onChange={(e) =>
                                                                productSelectChange(INDEX, e, "p")
                                                            }
                                                            placeholder="Product Name"
                                                            type="text"

                                                            styles={{
                                                                ...customStyles,
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 99999,
                                                                    position: 'absolute'




                                                                }),
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    width: '100%'



                                                                })
                                                            }}
                                                        />
                                                        {company_setting?.extra_setting?.remarksInDealProduct ==="Yes" && (
                                                    <TextArea  name='remark' value={i?.remark} onChange={(e) =>
                                                        handleInputChangeProduct(e, INDEX)
                                                        } placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>
                                                        
                                                )}
                                                    </td>
                                                    <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                                                        <SelectBox
                                                            label="Unit"
                                                            name="unit"
                                                            isDisabled={true}
                                                            value={unitOptions.find(
                                                                (option) => option.value.id === i.unitId
                                                            )}
                                                            options={unitOptions}
                                                            onChange={(e) =>
                                                                productSelectChange(INDEX, e, "u")
                                                            }
                                                            placeholder="Unit Name"
                                                            type="text"

                                                        />
                                                    </td>
                                                    <td className="whitesapce-nowrap width-auto">
                                                        <InputCustom
                                                            name="quantity"
                                                            value={i?.quantity}
                                                            onChange={(e) =>
                                                                handleInputChangeProduct(e, INDEX)
                                                            }
                                                            type="number"
                                                        />
                                                    </td>
                                                    <td className="width-auto">
                                                        <InputCustom
                                                            name="price"
                                                            value={i?.price}
                                                            onChange={(e) =>
                                                                handleInputChangeProduct(e, INDEX)
                                                            }
                                                            type="number"
                                                            disabled={!checkAccess('edit_deal_price')}
                                                        />
                                                    </td>
                                                    <td className="width-auto">
                                                        <InputCustom
                                                            name="discount"
                                                            value={i?.discount}
                                                            onChange={(e) =>
                                                                handleInputChangeProduct(e, INDEX)
                                                            }
                                                            type="number"
                                                            disabled={!checkAccess('edit_deal_discount')}
                                                        />
                                                    </td>
                                                    <td className="width-auto">
                                                        <InputCustom
                                                            name="tax"
                                                            value={i?.tax}
                                                            onChange={(e) =>
                                                                handleInputChangeProduct(e, INDEX)
                                                            }
                                                            type="number"
                                                        />
                                                    </td>
                                                    <td className="width-auto">
                                                        <InputCustom
                                                            name="subTotal"
                                                            value={i?.subTotal}
                                                            readOnly={true}
                                                            onChange={(e) =>
                                                                handleInputChangeProduct(e, INDEX)
                                                            }
                                                            type="number"
                                                        />
                                                    </td>
                                                    <th className="width-auto">
                                                        <div
                                                            onClick={() => {
                                                                const productToRemove = productsForOrder[INDEX]; // Assuming INDEX is the current index of the product
                                                                handleRemoveProduct(INDEX, productToRemove.subTotal);
                                                            }}
                                                            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                        >
                                                            <i className="fas fa-trash-alt" ></i>
                                                        </div>
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ paddingTop: '15px' }}>  {isEditMode && (
                                <div onClick={handleAddProductClick}>
                                    <GlobalButton text="Add Product" />
                                </div>
                            )}</div>
                            {/* <div> <table>
                <tr>
                  <th className="width-50">Sub Total</th>
                  <th></th>
                  <th className="width-50">Total</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Megha</td>
                  <td>19</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>Subham</td>
                  <td>25</td>
                  <td>Male</td>
                </tr>
              </table></div> */}
                            <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
                                marginLeft: '70%',
                                maxWidth: '30%',
                                background: '#f5f5f5',
                                border: '2px #eee'
                            }}>
                                <tbody>

                                    <tr></tr>
                                    <tr>
                                        <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                                        <td></td>
                                        <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.totalAmount}</b></td>
                                    </tr>
                                </tbody>
                            </table></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    );
}