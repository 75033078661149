import React, { useState, useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom/dist';
import Pagination from '../../../shared/components/pagination';
import './areatable.scss';
import { checkAccess } from '../../../services/checkFeature';
import EditIcon from '../../../shared/icons/editIcon';

export default function Areatable({ selectedIds, setSelectedIds,setAreaList ,areaList, setProductList, setTotalItems, totalItems }) {
  
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [areaList, setProductList] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [status, setStatus] = useState(undefined);
  const [cityName, setCityName] = useState("");
  const [areaName, setAreaName] = useState("");
  
  const pageSize = 10; // Items per page

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
      const response = await fetchData('area',requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []);  // API returns current page data in 'items'
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(areaList.map((i) => i.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredActivityList = areaList.filter((item) => {
    const searchCountryName = item.countryName?.toLowerCase().includes(countryName?.toLowerCase());
    const searchCityName = item.cityName?.toLowerCase().includes(cityName?.toLowerCase());
    const searchStateName = item.stateName?.toLowerCase().includes(stateName?.toLowerCase());
    const searchAreaName = item.areaName?.toLowerCase().includes(areaName?.toLowerCase());
    const searchStatus = status === undefined || item.status === status;

   
    return (
      (!stateName || searchStateName) &&
      (!countryName || searchCountryName) &&
      (!areaName || searchAreaName) &&
      (!cityName || searchCityName) &&
       searchStatus
   
    );
  });
  const paginatedData = areaList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div>
      <div className=''>
      <div className='account-table-design'>
            <table>
                <thead>
            <tr>
              <th><div className="w-100"></div></th>
              
              <th><div  className="w-100"></div></th>

              <th><div><input
                type="text"
                placeholder="Area Name"
                value={areaName}
                onChange={(e) => setAreaName(e.target.value)}
                className="w-100 border-0"
               

              /></div></th>

              <th><div><input
                type="text"
                placeholder="City Name"
                value={cityName}
                onChange={(e) => setCityName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              <th><div><input
                type="text"
                placeholder="State Name"
                value={stateName}
                onChange={(e) => setStateName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

               <th><div><input
                type="text"
                placeholder="Country Name"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

<th>
                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatus(selectedStatus); 
                    }}
                    className="w-100 border-0"
                  >
                    <option value="">All</option>
                    <option value="true">Active</option> 
                    <option value="false">Inactive</option>
                  </select>
                </div>
                </th>
            </tr>
                    <tr>
                        
                        <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === areaList.length} /></th>
                        
                        {checkAccess('edit_area')&&<th>Action</th>}
                        <th>Name</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    (filteredActivityList ?? []).map((i) => {
                        return(
                            <tr>
                             <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                             {checkAccess('edit_area')&& <td><Link to={{ pathname: `addarea/${i?.id}`}}>
                            <EditIcon/>
                            </Link></td>}
                            <td>{i?.areaName}</td>
                            <td>{i?.cityName}</td>
                            <td>{i?.stateName}</td>
                            <td>{i?.countryName}</td>
                            <td>
                            <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                            </td>
                        </tr>
                        )
                    })
                  }
                </tbody>
            </table>
            <div className='pagination-alignment'>
            <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
        </div>
      </div>
    </div>
  )
}
