import React from 'react'
import './orderwisereport.scss';
import Button from '../../shared/components/button';
import Orderwisereportdetails from './orderwisereportdetails';
export default function Orderwisereport() {
  return (
    <div>
      <div className='page-header'>
        <span>Orderwise Dispatch Report</span>
      </div>
      <Orderwisereportdetails/>
    </div>
  )
}
