import React, { useState,useEffect } from 'react'
import Header from '../header';
import {Navigate } from 'react-router-dom';
import './defaultwrapper.scss';
import Sidebar from '../sidebar';
import {currentsession} from '../../../services/cognitoService'
import { message } from 'antd';
import { useSelector } from 'react-redux';
export default function Defaultwrapper({children}) {
  const [isLoggedIn, setLoggedIn] = useState(true);  
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [isLoading, setLoading] = useState(true)
  const login = useSelector(state=>state.auth.isAuthenticated)
  useEffect(() => {
    checkLogin();
  }, [login]);

  const checkLogin = async ()=>{
    try{
     const userStatus =  await currentsession();
     if(userStatus)
     setLoggedIn(true);
    else
    setLoggedIn(false)
    }catch(e){
      message(e);
    }finally{
      setLoading(false)
    }
  }
  const handleSidebarItemClick = (title) => {
    setPageTitle(title);
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return isLoggedIn ?  (
    <div className='defaultwrapper'>
        <div className='sidebar-wrapper'>
            <Sidebar onSidebarItemClick={handleSidebarItemClick}/>
        </div>
        <div className='defaultwrapper-children'>
          <Header pageTitle={pageTitle}/>
          <div className='left-right-alignment'>
          {children}
          </div>
        </div>
    </div>
  ) : (
    <Navigate to="/login" replace />
);
}
