import React, { useState, useEffect } from 'react';
import "./index.scss";
import PenIcon from '../../../assets/icons/pen.svg';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import { fetchData } from '../../../services/apiService';
import { checkAccess } from '../../../services/checkFeature';

export default function Usertable({ selectedIds, setSelectedIds, setActivityList }) {
  const [activityList, setUserRole] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userrole, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [useRrole, setUserRoleTerm] = useState(""); // Initialize as an empty string
  const [NameTerm, setnameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");

  const pageSize = 10;
  const navigate = useNavigate();

  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = { page, limit: pageSize };
      const response = await fetchData("user", requestBody, pagination);

      if (response?.data) {
        setProductList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.userId));
    } else {
      setSelectedIds([]);
    }
  };

  const filteredActivityList = userrole.filter((item) => {
    return (
      (!NameTerm || item.name?.toLowerCase().includes(NameTerm.toLowerCase())) &&
      (!useRrole || item.role?.toLowerCase().includes(useRrole.toLowerCase())) &&
      (!Status || (item.status?.toString() || '').toLowerCase().includes(Status.toLowerCase()))
    );
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigation = (id) => {
    navigate(`/view/${id}`);
  };

  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={NameTerm} onChange={(e) => setnameTerm(e.target.value)} /></th>
              <th><input type="text" placeholder="User" value={useRrole} onChange={(e) => setUserRoleTerm(e.target.value)} /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} /></th>
            </tr>
            <tr>
              <th>
                <input
                  type='checkbox'
                  onChange={handleSelectAll}
                  checked={selectedIds.length === activityList.length}
                />
              </th>
              {checkAccess('modify_user') && <th>Action</th>}
              <th>Name</th>
              <th>User-Role</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredActivityList.map((item, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
                  cursor: checkAccess('read_user') ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (checkAccess('read_user')) {
                    navigate(`/user/view/${item.id}`);
                  }
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={selectedIds.includes(item.userId)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleCheckboxChange(item.userId)}
                  />
                </td>
                {checkAccess('modify_user') && (
                  <td onClick={(e) => e.stopPropagation()}>
                    <Link to={{ pathname: `adduser/${item.id}` }}>
                      <img src={PenIcon} alt="Edit" />
                    </Link>
                  </td>
                )}
                <td>
                  <span className='text-dark' onClick={() => handleNavigation(item?.id)}>{item?.name}</span>
                </td>
                <td>{item.role}</td>
                <td onClick={(e) => e.stopPropagation()}>
                  <button className={item.status === true ? 'Active' : 'Inactive'}>
                    {item.status ? 'Active' : 'Inactive'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination-alignment'>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
