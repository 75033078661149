import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { withWidth } from '@material-ui/core';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
export default function AddEditexpensetype() {
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            getData();
        }
    }, []);
    const [formData, setFormData] = useState({
        name: '',
        status: true,
        allowancetype: '',
        isperkm: 'no',
        amount: '',
        rate: '',
        editable: '',
    });
    const [errors, setErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck(true)) {
            try {

                if (id) {
                    patchData(`expensetype/${id}`, formData).then(data => {
                        if (data) navigate(-1);
                    })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    postData('expensetype', formData)
                        .then(data => {
                            if (data) navigate(-1);
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };
    const getData = async () => {
        try {
            const response = await fetchsingleData("expensetype",id);
            if (response?.data)
                setFormData(response?.data); // Update state with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.name?.trim()) {
            validationErrors.name = 'This field is required';
        }
        if (isSubmit || errors.isSubmit)
            validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return (Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0) ? true : false
    }
    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit)
            validationCheck()
    };
    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: checked && 'true',


        }));
        if (errors.isSubmit) validationCheck();
    };


    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <span> Expense Type {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/expensetype"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}
            <div>

            <div className='add-header-design'>
                <div>
                    <h2>Add Expense Type</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Expense Type" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/expensetype">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        {id ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>

            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='three-col-grid'>
                        <Input label='Name' name='name' value={formData.name} onChange={handleInputChange} placeholder='Enter Expense Type Name..' type='text' errors={errors.name} required />
                        <Box>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Allowance type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name='allowancetype'
                                        value={formData.allowancetype}
                                        label={formData.allowancetype}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="travelling">Travelling</MenuItem>
                                        <MenuItem value="daily">Daily</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {formData.allowancetype === "travelling" && (<Box style={{ width: 250, justifyContent: 'space-between', marginLeft: '2em' }} >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Is per km</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name='isperkm'
                                        value={formData.isperkm}
                                        label={formData.isperkm}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="no">NO</MenuItem>
                                        <MenuItem value="onsystem">On System</MenuItem>
                                        <MenuItem value="oncounter">On Counter</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>)}
                            {(formData.isperkm !== "onsystem" || formData.isperkm !== "oncounter") && (formData.allowancetype === "daily" || formData.isperkm === "no") && (
                                <div className='bottom-alignment' style={{ marginLeft: '1 rem' }}>
                                    <Input
                                        label='Amount'
                                        name='amount'
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        placeholder='Enter Amount'
                                        type='text'
                                        errors={errors.amount}
                                        required
                                    />
                                </div>


                            )}
                     {((formData.isperkm === "onsystem" || formData.isperkm === "oncounter") && formData.allowancetype === "travelling") && (<div className='bottom-alignment' >
                            <Input label='Rate' name='rate' value={formData.rate} onChange={handleInputChange} placeholder='Enter Rate' type='text' errors={errors.rate} required />
                        </div>)}
                        <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                        <div className="d-flex align-items-center ml-3" >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="editable"
                                checked={formData.editable}
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label" htmlFor="editable">
                                is editable
                            </label>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox/>
                </div>
            </div>

                <div className='add-news-form-design' style={{ margin: '10px -5px 10px 20px' }}>
                    <div className='grid' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                        <div>
                        </div>
                        <div style={{ marginTop: '2rem' }} >
                          

                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            
                        </div>
                        <div>
                          
                        </div>
                       
                    </div>
                    <div style={{ marginTop: '3rem' ,display: 'flex',alignItems: 'center' }}>

                       <div>
                     
                       </div>

                       

                    </div>
                    {/* <div style={{ marginTop: '3rem' }}>

                        



                    </div> */}

                </div>
            </div>
        </div>
    )
}
