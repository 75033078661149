import React, { useEffect, useState } from "react";
import Input from "../../../shared/components/input";
import './addsettings.scss';
import GlobalButton from "../../../shared/globalButton";
import Select from "react-select";
import { postData, patchData, fetchsingleData, filterAll } from '../../../services/apiService';
import { notify } from '../../toastr/Toastr';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { company_setting } from "../../../redux/auth";
import store from "../../../redux/reduxstore";
export default function AddSettings() {
  const userData = store.getState().auth.userData

  // const navigate = useNavigate();
  // const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    getState()
  }, []);
  const [EditDetails, setIsEdit] = useState({});
  const [isCloudTelephonySettings, setCloudTelephonySettings] = useState(false);
  const handleCheckboxChangeCloudTelephonySettings = (event) => {
    setCloudTelephonySettings(event.target.checked);
  };
  // State to manage the GeoFencing settings
  const [geoFencingEnabled, setGeoFencingEnabled] = useState(false);
  const [geoFencingForCustomerLocation, setGeoFencingForCustomerLocation] = useState(false);
  const [allowedRadius, setAllowedRadius] = useState('');

  // Handle changes to GeoFencing and related settings
  const handleGeoFencingChange = (event) => {
    setGeoFencingEnabled(event.target.checked);
  };

  const handleGeoFencingForCustomerLocationChange = (event) => {
    setGeoFencingForCustomerLocation(event.target.checked);
  };

  const handleRadiusChange = (event) => {
    setAllowedRadius(event.target.value);
  };
  // Terms & Conditions Configuration
  const options = [
    { id: 'enable_order', label: 'Order' },
    { id: 'enable_order_dispatch', label: 'Order Dispatch' },
    { id: 'enable_payment_collection', label: 'Payment Collection' },
    { id: 'enable_invoice', label: 'Invoice' },
    { id: 'enable_sale_quotation', label: 'Quotation' },
    { id: 'enable_estimate', label: 'Estimate' },
    { id: 'enable_sales_return', label: 'Sales Return' },
    { id: 'enable_deal', label: 'Deal' },
  ];

  const [settingsTerms, setSettingsTerms] = useState(
    options.reduce((acc, option) => {
      acc[option.id] = false; // Set all checkboxes to false initially
      return acc;
    }, {})
  );

  const handleCheckboxChangeTerms = (event) => {
    const { name, checked } = event.target;
    setSettingsTerms((prevSettingsTerms) => ({
      ...prevSettingsTerms,
      [name]: checked,
    }));
  };
  //wp
  const [isWhatsAppEnabled, setWhatsAppEnabled] = useState(false);
  const [whatsappService, setWhatsappService] = useState("");
  const [settingswp, setSettingswp] = useState({
    accessToken: "",
    apiEndpoint: "",
    defaultCountryCode: "",
  });

  const handleCheckboxToggle = (e) => {
    setWhatsAppEnabled(e.target.checked);
  };


  const handleServiceChange = (e) => {
    setWhatsappService(e.target.value);
  };

  const handleInputChangeWhatsApp = (name, value) => {
    // const { name, value } = e.target;  // Destructure name and value from the event object
    setSettingswp((prevState) => ({
      ...prevState,
      [name]: value, // Dynamically update the field based on the name
    }));
  };

  //SMS
  const [isSmsEnabled, setSmsEnabled] = useState(false);
  const [smsGetUrl, setSmsGetUrl] = useState('');
  const [smsToNumberKey, setSmsToNumberKey] = useState('');
  const [smsMessageKey, setSmsMessageKey] = useState('');

  const [useCustomConfig, setUseCustomConfig] = useState(false);
  const [queryParams, setQueryParams] = useState([{ key: "", value: "" }]);
  const [cloudTelephonySettings, setCloudTelephonySettingsList] = useState([{ key: "", value: "" }]);
  const handleAddFieldCloudTelephonySettings = () => {
    setCloudTelephonySettingsList([...cloudTelephonySettings, { key: "", value: "" }]);
  };

  const handleAddField = () => {
    setQueryParams([...queryParams, { key: "", value: "" }]);
  };

  // mail

  const [isMailEnabled, setMailEnabled] = useState(false);
  const [useCustomMailConfig, setCustomMailConfig] = useState(false);

  // State to hold form values
  const [mailSettings, setMailSettings] = useState({
    mailFromName: "",
    smtpHost: "",
    smtpPort: "",
    smtpUsername: "",
    smtpPassword: "",
    smtpEncryption: "",
  });

  // Handler to update state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMailSettings({
      ...mailSettings,
      [name]: value,
    });
  };
  // Handle Change in Key or Value
  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...queryParams];
    updatedFields[index][field] = value;
    setQueryParams(updatedFields);
  };
  // Handle Change in Key or Value
  const handleFieldChangeCloudTelephonySettings = (index, field, value) => {
    const updatedFields = [...cloudTelephonySettings];
    updatedFields[index][field] = value;
    setCloudTelephonySettingsList(updatedFields);
  };
  // Handle Remove Field
  const handleRemoveField = (index) => {
    const updatedFields = queryParams.filter((_, i) => i !== index);
    setQueryParams(updatedFields);
  };

  // Handle Remove Field
  const handleRemoveFieldCloudTelephonySettings = (index) => {
    const updatedFields = cloudTelephonySettings.filter((_, i) => i !== index);
    setCloudTelephonySettingsList(updatedFields);
  };

  const [currency, setCurrency] = useState('INR');

  // // Extra Settings
  const [Extrasetting, setExtrasetting] = useState({
    "customerUniqueKey": "Name",
    "allowMultipleOrders": "No",
    "remarksInSaleQuotation": "No",
    "remarksInInvoice": "No",
    "displayStockInOrderProduct": "No",
    "enableHSNCode": "No",
    "enableMultiCurrency": "No",
    "enableVanSales": "No",
    "enableAreaCode": "No",
    "autoGenerateCustomerCode": "No",
    "remarksInProduct": "No",
    "remarksInEstimateProduct": "No",
    "remarksInDispatchProduct": "No",
    "priorityForCustomers": "No",
    "restrictOrderOnOutstanding": "No",
    "enableMultiAddress": "No",
    "allowProductRepetition": "No",
    "productUniqueKey": "Name",
    "remarksInOrderProduct": "No",
    "remarksInSalesReturnProduct": "No",
    "remarksInDealProduct": "No",
    "enableCharge": "No",
    "enableUserwiseExpenseType": "No",
    "enableSubarea": "No",
    "enableMRP": "No"
  });
  const ExtrasettinghandleChange = (name, value) => {
    setExtrasetting((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };
  const setExtraSettingConfig = [{ label: "Customer Unique Key", name: "customerUniqueKey" ,options: ["Name", "Code"] }, { label: "Allow multiple orders in single dispatch", name: "allowMultipleOrders" }, { label: "Can add remarks in Sale Quotation product", name: "remarksInSaleQuotation" }, { label: "Can add remarks in Invoice product", name: "remarksInInvoice" }, { label: "Display stock in order product", name: "displayStockInOrderProduct" }, { label: "Enable HSN Code", name: "enableHSNCode" }, { label: "Enable Multi Currency", name: "enableMultiCurrency" }, { label: "Enable Van Sales", name: "enableVanSales" }, { label: "Enable Area Code", name: "enableAreaCode" }, { label: "Autogenerate Customer Code", name: "autoGenerateCustomerCode" }, { label: "Can add remarks in Product", name: "remarksInProduct" }, { label: "Can add remarks in Estimate product", name: "remarksInEstimateProduct" }, { label: "Can add remarks in Dispatch product", name: "remarksInDispatchProduct" }, { label: "Can set priority for customers?", name: "priorityForCustomers" }, { label: "Restrict order on outstanding", name: "restrictOrderOnOutstanding" }, { label: "Enable Multi Address", name: "enableMultiAddress" }, { label: "Allow Product Repetition", name: "allowProductRepetition" }, { label: "Product Unique Key", name: "productUniqueKey" ,options: ["Name", "Code"]}, { label: "Can add remarks in Order product", name: "remarksInOrderProduct" }, { label: "Can add remarks in Sales Return product", name: "remarksInSalesReturnProduct" }, { label: "Can add remarks in Deal product", name: "remarksInDealProduct" }, { label: "Enable Charge", name: "enableCharge" }, { label: "Enable Userwise Expense Type", name: "enableUserwiseExpenseType" }, { label: "Enable Subarea", name: "enableSubarea" }, { label: "Enable MRP", name: "enableMRP" }];


  // Modules / System config
  const [settings, setSettings] = useState({
    "enableSaleQuotation": "No",
    "enableEstimate": "No",
    "enableInvoice": "No",
    "enableSalesReturn": "No",
    "targetUser": "No",
    "targetsCustomer": "No",
    "routesUser": "No",
    "profileUser": "No",
    "enableComplaint": "No",
    "enableSegment": "No",
    "callrecording": "No",
    "leadCampaign": "No",
    "accountcategory": "No",
    "enableWorkflow": "No"
  });

  const handleChange = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };




  const settingsConfig = [
    { label: "Enable Sale Quotation", name: "enableSaleQuotation" },
    { label: "Enable Estimate", name: "enableEstimate" },
    { label: "Enable Invoice", name: "enableInvoice" },
    { label: "Enable Sales Return", name: "enableSalesReturn" },
    { label: "Can set targets for User", name: "targetUser" },
    { label: "Can set targets for Customer", name: "targetsCustomer" },
    { label: "Can set routes for User", name: "routesUser" },
    { label: "Can set work profile for User", name: "profileUser" },
    { label: "Enable Complaint", name: "enableComplaint" },
    { label: "Enable Segment", name: "enableSegment" },
    { label: "Enable phone call recording", name: "callrecording" },
    { label: "Enable Meta Lead Campaign", name: "leadCampaign" },
    { label: "Enable account category", name: "accountcategory" },
    { label: "Enable Workflow", name: "enableWorkflow" },
  ];

  // Product Price Configuration
  const [productPrice, setProductPrice] = useState("exclusive");
  const [enableDiscount, setEnableDiscount] = useState("Yes");
  const [enableOfferDiscount, setEnableOfferDiscount] = useState("Yes");
  const [enableAdditionalDiscount, setEnableAdditionalDiscount] = useState("No");
  const [enableCashDiscount, setEnableCashDiscount] = useState("No");
  const [enableAmountAdjustmentFor, setEnableAmountAdjustmentFor] = useState([]);
  const [enableAmountRounding, setEnableAmountRounding] = useState("off");


  const [autocloseOrder, setAutocloseOrder] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState(false);
  // Auto Register Device
  const [autoRegisterSales, setAutoRegisterSales] = useState("first");
  const [autoRegisterCustomer, setAutoRegisterCustomer] = useState("first");




  const radioGroups = [
    {
      label: "Product Price",
      options: [
        { label: "Exclusive Tax", value: "exclusive" },
        { label: "Inclusive Tax", value: "inclusive" },
        { label: "Inclusive/Exclusive Tax", value: "inclusiveExclusive" },
      ],
      state: productPrice,
      setState: setProductPrice,
    },
    {
      label: "Enable Discount",
      options: [
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ],
      state: enableDiscount,
      setState: setEnableDiscount,
    },
    {
      label: "Enable Offer Discount",
      options: [
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ],
      state: enableOfferDiscount,
      setState: setEnableOfferDiscount,
    },
    {
      label: "Enable Additional Discount",
      options: [
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ],
      state: enableAdditionalDiscount,
      setState: setEnableAdditionalDiscount,
    },
    {
      label: "Enable Cash Discount",
      options: [
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
      ],
      state: enableCashDiscount,
      setState: setEnableCashDiscount,
    },
    {
      label: "Enable Amount Rounding",
      options: [
        { label: "Off", value: "off" },
        { label: "Lower", value: "lower" },
        { label: "Upper", value: "upper" },
        { label: "Medium", value: "Medium" },
      ],
      state: enableAmountRounding,
      setState: setEnableAmountRounding,
    },
  ];

  const checkboxOptions = [
    "Order",
    "Order Delivery",
    "Invoice",
    "Quotation",
    "Estimate",
    "Sales Return",
    "Deal",
  ];

  const handleCheckboxChange = (value) => {
    setEnableAmountAdjustmentFor((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };


  const currencyOptions = [
    { label: "USD - United States Dollar", value: "USD" },
    { label: "INR - Indian Rupee", value: "INR" },
  ];

  // GST Configuration

  const [enableGST, setEnableGST] = useState("No");
  const [gstNumber, setGstNumber] = useState("");
  const [placeOfSupply, setPlaceOfSupply] = useState("");
  const [placeOfSupplyOptions, setPlaceOfSupplyOptions] = useState([]);

  const getState = async () => {
    try {
        const response = await filterAll('state');
        if (response?.data) {
          setPlaceOfSupplyOptions(
            response?.data?.map((i) => {
              return {
                label: i?.stateName,
                key: "state",
                value: i?.id
              };
            })
          );
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  const countryOptions = [
    { value: "+1", label: "+1 (USA)" },
    { value: "+91", label: "+91 (India)" },
    { value: "+44", label: "+44 (UK)" },
  ];


  // Lead Configuration

  const Leadoptions = ["Name", "Email", "Contact No."];
  const [selectedKey, setSelectedKey] = useState(Leadoptions[0]);
  const LeadhandleChange = (event) => { setSelectedKey(event.target.value); };

  // Customers assignment to user

  const customeroptions = ["Assign areawise customer to user", "Direct assign customer to user"];
  const [selectedCustomer, setSelectedCustomer] = useState(customeroptions[0]);
  const handleChangeCustomer = (event) => { setSelectedCustomer(event.target.value); };

  // User Hierarchy

  const hierarchyoptions = ["Disable User hierarchy", "Enable user hierarchy"];
  const [selectedhierarchy, setSelectedhierarchy] = useState(hierarchyoptions[0]);
  const handleChangeHierarchy = (event) => { setSelectedhierarchy(event.target.value); };

  // Inventory Configuration

  const stockoptions = ["No", "Yes"];
  const [selectedstock, setSelectedstock] = useState(stockoptions[0]);
  const handleChangeStock = (event) => { setSelectedstock(event.target.value); };


  // Unit Configuration

  const MultiUnitOptions = [
    "Display All Unit Everywhere",
    "Display Price For Every Unit",
    "Can Change Unit",
  ];

  const [enableMultiUnit, setEnableMultiUnit] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleEnableMultiUnitChange = () => {
    setEnableMultiUnit(!enableMultiUnit);
    if (!enableMultiUnit) {
      setSelectedOptions([]);
    }
  };

  const handleMultiUnitChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  // Customer Web Configuration

  const checkboxOptionsCustomer = [
    { label: "Access Customer", name: "accessCustomer" },
    { label: "Modify Customer", name: "modifyCustomer" },
    { label: "Access Product", name: "accessProduct" },
    { label: "Can Edit Sales Order", name: "canEditSalesOrder" },
    { label: "Can Edit Purchase Order", name: "canEditPurchaseOrder" },
    { label: "Enable Amount Adjustment", name: "enableAmountAdjustment" },
  ];
  const [config, setConfig] = useState({
    accessCustomer: false,
    modifyCustomer: false,
    accessProduct: false,
    canEditSalesOrder: true,
    canEditPurchaseOrder: false,
    enableAmountAdjustment: false,
    displayStock: 'No',
  });
  const handleCheckboxCustomer = (e) => {
    const { name, checked } = e.target;
    setConfig((prev) => ({ ...prev, [name]: checked }));
  };
  const handleRadioChange = (e) => {
    const { value } = e.target;
    setConfig((prev) => ({ ...prev, displayStock: value }));
  };
  // Customer App Configuration
  const checkboxOptionsapp = [
    { label: "Can Edit Order", name: "canEditOrder" },
    { label: "Can Edit Sales Return", name: "canEditSalesReturn" },
    { label: "Enable Amount Adjustment", name: "enableAmountAdjustment" },
  ];
  const [configs, setConfigs] = useState({
    canEditOrder: false,
    canEditSalesReturn: false,
    enableAmountAdjustment: false,
    displayStock: 'No',
  });
  const handleCheckboxChangeapp = (e) => {
    const { name, checked } = e.target;
    setConfigs((prev) => ({ ...prev, [name]: checked }));
  };
  const handleRadioChangeapp = (e) => {
    const { value } = e.target;
    setConfigs((prev) => ({ ...prev, displayStock: value }));
  };

  // Secondary Sales

  const [selectedSalesOption, setSelectedSalesOption] = useState('disable');
  const [formData, setFormData] = useState({
    firstLevelName: '',
    secondLevelName: '',
    thirdLevelName: '',
    fourthLevelName: '',
  });

  const handleRadioChangelevel = (event) => {
    setSelectedSalesOption(event.target.value);
  };
  const handleLevelNameChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const renderLevelInputs = (levels) => {
    return levels.map((level, index) => (
      <div key={index}>
        <label className="mr-3">
          {level} Name:{" "}</label>
        <input
          type="text"
          className="w-50"
          name={`${level.toLowerCase()}LevelName`}
          value={formData[`${level.toLowerCase()}LevelName`]}
          onChange={handleLevelNameChange}
          required
        />
        <br />
      </div>
    ));
  };
  // Customer Stock Management
  const [manageStock, setManageStock] = useState(false); // Checkbox state
  const handleCheckboxChangemanage = (e) => {
    setManageStock(e.target.checked); // Update state based on checkbox
  };
  const [calculateStock, setCalculateStock] = useState('');
  const [restrictDispatch, setRestrictDispatch] = useState('');

  const handleRadioChangeStock = (e) => {
    setCalculateStock(e.target.value);
  };

  const handleRadioChangeDispatch = (e) => {
    setRestrictDispatch(e.target.value);
  };

  // Customer Order Time Restriction

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [fromHour, setFromHour] = useState("08");
  const [fromMinute, setFromMinute] = useState("00");
  const [fromPeriod, setFromPeriod] = useState("AM");
  const [toHour, setToHour] = useState("08");
  const [toMinute, setToMinute] = useState("00");
  const [toPeriod, setToPeriod] = useState("PM");
  const [level, setLevel] = useState("");


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleAddTimeSlot = () => {
    const fromTime = `${fromHour}:${fromMinute} ${fromPeriod}`;
    const toTime = `${toHour}:${toMinute} ${toPeriod}`;

    if (level.trim()) {
      setTimeSlots([...timeSlots, { fromTime, toTime, level }]);
      setFromHour("08");
      setFromMinute("00");
      setFromPeriod("AM");
      setToHour("08");
      setToMinute("00");
      setToPeriod("PM");
      setLevel("");
      closeModal();
    }
  };


  // Sales Person Tracking

  const hoursOptions = [...Array(12).keys()].map((h) => ({
    value: h + 1,
    label: (h + 1).toString().padStart(2, "0"),
  }));

  const minutesOptions = [...Array(60).keys()].map((m) => ({
    value: m,
    label: m.toString().padStart(2, "0"),
  }));

  const amPmOptions = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];

  const intervalOptions = [
    { value: "5", label: "5 minutes" },
    { value: "10", label: "10 minutes" },
    { value: "15", label: "15 minutes" },
    { value: "30", label: "30 minutes" },
    { value: "60", label: "1 hour" },
  ];

  // State for Start Time
  const [startHour, setStartHour] = useState(hoursOptions[8]);
  const [startMinute, setStartMinute] = useState(minutesOptions[0]);
  const [startAmPm, setStartAmPm] = useState(amPmOptions[0]);

  // State for End Time
  const [endHour, setEndHour] = useState(hoursOptions[7]);
  const [endMinute, setEndMinute] = useState(minutesOptions[0]);
  const [endAmPm, setEndAmPm] = useState(amPmOptions[1]);

  // State for Interval
  const [interval, setInterval] = useState(intervalOptions[2]);

  // IndiaMART Configuration
  const [isIntegrationEnabled, setIsIntegrationEnabled] = useState(false);
  const [contactNo, setContactNo] = useState('');
  const [apiKey, setApiKey] = useState('');
  const handleCheckboxChangeleadmart = (event) => {
    setIsIntegrationEnabled(event.target.checked);
  };


  // Zoho Configuration

  const [isZohoEnabled, setIsZohoEnabled] = useState(false);
  const [apiUrl, setApiUrl] = useState('');
  const [accountApiUrl, setAccountApiUrl] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [organizationId, setOrganizationId] = useState('');


  const handleToggle = (e) => {
    setIsZohoEnabled(e.target.checked);
  };
  const [isZohoEnableds, setIsZohoEnableds] = useState(false);

  const handleToggles = (e) => {
    setIsZohoEnableds(e.target.checked);
  };

  const handleSave = async () => {
    const saveData = {
      currency: currency,
      system_config: settings,
      extra_setting: Extrasetting,
      product_price_configuration: {
        productPrice,
        enableDiscount,
        enableOfferDiscount,
        enableAdditionalDiscount,
        enableCashDiscount,
        enableAmountAdjustmentFor,
        enableAmountRounding,
      },
      gst_configuration: {
        enableGST,
        gstNumber,
        placeOfSupply,
      },
      lead_unique_key: selectedKey,

      customer_assignment_type: selectedCustomer,

      unit_configuration: {
        enableMultiUnit,
        selectedOptions,
      },

      customer_web_configuration: config,
      customer_app_configuration: configs,
      time_slots: timeSlots,
      secondary_sales: {
        selectedSalesOption,
        levels: formData,
      },
      manage_stock: {
        manageStock,
        calculateStock: manageStock ? calculateStock : '',
        restrictDispatch: manageStock ? restrictDispatch : '',
      },

      user_hierarchy: selectedhierarchy,
      display_stock: selectedstock,
      autoclose: autocloseOrder,
      payment_configuration: paymentConfig,
      auto_register_device: {
        autoRegisterSales: autoRegisterSales,
        autoRegisterCustomer: autoRegisterCustomer,
      },
      sales_person_tracking: {
        startTime: `${startHour.label}:${startMinute.label} ${startAmPm.value}`,
        endTime: `${endHour.label}:${endMinute.label} ${endAmPm.value}`,
        interval: interval.label
      },
      india_mart_configuration: {
        isIntegrationEnabled,
        contactNo: isIntegrationEnabled ? contactNo : null,
        apiKey: isIntegrationEnabled ? apiKey : null
      },
      zoho_configuration: {
        isZohoEnabled: isZohoEnabled,
        isZohoEnableds: isZohoEnableds,
        apiUrl: isZohoEnabled ? apiUrl : null,
        accountApiUrl: isZohoEnabled ? accountApiUrl : null,
        clientId: isZohoEnabled ? clientId : null,
        clientSecret: isZohoEnabled ? clientSecret : null,
        refreshToken: isZohoEnabled ? refreshToken : null,
        organizationId: isZohoEnabled ? organizationId : null
      },
      sms_settings: {
        isSmsEnabled: isSmsEnabled,
        useCustomConfig: useCustomConfig,
        smsGetUrl: useCustomConfig ? smsGetUrl : null,
        smsToNumberKey: useCustomConfig ? smsToNumberKey : null,
        smsMessageKey: useCustomConfig ? smsMessageKey : null,
        queryParams: useCustomConfig ? queryParams : [],
      },
      mail_settings: {
        isMailEnabled: isMailEnabled,
        useCustomMailConfig: useCustomMailConfig,
        mailSettings: useCustomMailConfig
          ? {
            mailFromName: mailSettings.mailFromName,
            smtpHost: mailSettings.smtpHost,
            smtpPort: mailSettings.smtpPort,
            smtpUsername: mailSettings.smtpUsername,
            smtpPassword: mailSettings.smtpPassword,
            smtpEncryption: mailSettings.smtpEncryption
          }
          : null,
        queryParams: useCustomMailConfig ? queryParams : [],
      },
      cloud_telephony_settings: {
        cloudTelephonySettings,
        isCloudTelephonySettings: isCloudTelephonySettings,
        cloudTelephonySettings: isCloudTelephonySettings
          ? cloudTelephonySettings
          : [],

      },
      whatsapp_settings: {
        isWhatsAppEnabled: isWhatsAppEnabled,
        whatsappService: whatsappService,
        settingswp: isWhatsAppEnabled
          ? {
            accessToken: settingswp.accessToken,
            apiEndpoint: settingswp.apiEndpoint,
            defaultCountryCode: settingswp.defaultCountryCode,
          }
          : {},
      },
      terms_conditions_configuration: settingsTerms,
      geo_fencing: {
        enabled: geoFencingEnabled,
        forCustomerLocation: geoFencingForCustomerLocation,
        allowedRadius: geoFencingForCustomerLocation ? allowedRadius : null,
      }
    }
    try {
      let responseData;

      if (EditDetails?.id) {
        // Update existing record
        responseData = await patchData(`company_setting/${EditDetails?.id}`, saveData);
        notify.success('company setting saveed successfully!');
        if(responseData){
          dispatch(company_setting({ "company_setting":responseData?.data }));
          window.location.reload();
        }

      } else {
        // Add new record
        responseData = await postData('company_setting', saveData);
        notify.success('company setting updated successfully!');

        if(responseData){
          dispatch(company_setting({ "company_setting":responseData?.data }));
          setIsEdit(responseData);
          window.location.reload();
        }
      }
      if (responseData) {
        // // Update form state with new or updated data
        // setFormData((prevState) => ({
        //   ...prevState,
        //   ...responseData, // Use API response to update the form
        // }));

        // console.log("Data saved successfully:", responseData);
        // if (!id) navigate(-1); // Navigate back for new record
      } else {
        console.error("Failed to save data.");
      }
    } catch (error) {
      console.error("Error saving data:", error);

    }

    console.log("Save Data Object:", saveData);


  }
  const patchDataform = (res) => {
    setExtrasetting(res?.extra_setting);
    setCloudTelephonySettings(res?.cloud_telephony_settings?.isCloudTelephonySettings);
    if (res?.cloud_telephony_settings?.isCloudTelephonySettings) {
      setCloudTelephonySettingsList(res?.cloud_telephony_settings?.cloudTelephonySettings
      )
    }
    setSettings(res?.system_config);

    // Patch for `product_price_configuration`
    const productPriceConfig = res?.product_price_configuration;
    if (productPriceConfig) {
      setProductPrice(productPriceConfig?.productPrice || "exclusive");
      setEnableDiscount(productPriceConfig?.enableDiscount || "No");
      setEnableOfferDiscount(productPriceConfig?.enableOfferDiscount || "No");
      setEnableAdditionalDiscount(productPriceConfig?.enableAdditionalDiscount || "No");
      setEnableCashDiscount(productPriceConfig?.enableCashDiscount || "No");
      setEnableAmountAdjustmentFor(productPriceConfig?.enableAmountAdjustmentFor || []);
      setEnableAmountRounding(productPriceConfig?.enableAmountRounding || "off");
    }
    setCurrency(res?.currency ?? 'INR');
    const autoRegisterDevice = res?.auto_register_device;
    if (autoRegisterDevice) {
      setAutoRegisterSales(autoRegisterDevice.autoRegisterSales || "first");
      setAutoRegisterCustomer(autoRegisterDevice.autoRegisterCustomer || "first");
    }
    // Logic for `customer_app_configuration`
    const customerAppConfig = res?.customer_app_configuration;
    if (customerAppConfig) {
      setConfigs({
        canEditOrder: customerAppConfig.canEditOrder ?? false,
        canEditSalesReturn: customerAppConfig.canEditSalesReturn ?? false,
        enableAmountAdjustment: customerAppConfig.enableAmountAdjustment ?? false,
        displayStock: customerAppConfig.displayStock || "No",
      })
    }
    // GST Configuration
    const gstConfig = res?.gst_configuration;
    if (gstConfig) {
      setEnableGST(gstConfig.enableGST || "No");
      setGstNumber(gstConfig.gstNumber || "");
      setPlaceOfSupply(gstConfig.placeOfSupply || "");
    }
    // Customer Web Configuration
    const customerWebConfig = res?.customer_web_configuration;
    if (customerWebConfig) {
      setConfig({
        accessCustomer: customerWebConfig.accessCustomer ?? false,
        modifyCustomer: customerWebConfig.modifyCustomer ?? false,
        accessProduct: customerWebConfig.accessProduct ?? false,
        canEditSalesOrder: customerWebConfig.canEditSalesOrder ?? true,
        canEditPurchaseOrder: customerWebConfig.canEditPurchaseOrder ?? false,
        enableAmountAdjustment: customerWebConfig.enableAmountAdjustment ?? false,
        displayStock: customerWebConfig.displayStock || "No",
      });
    }
    const lead_unique_key = res?.lead_unique_key
    if (lead_unique_key) {
      setSelectedKey(lead_unique_key);
    }
    const customer_assignment_type = res?.customer_assignment_type
    if (customer_assignment_type) {
      setSelectedCustomer(customer_assignment_type);
    }
    // Unit Configuration
    const unitConfig = res?.unit_configuration;
    if (unitConfig) {
      setEnableMultiUnit(unitConfig.enableMultiUnit ?? false);
      setSelectedOptions(unitConfig.selectedOptions || []);
    }
    // Unit time_slots
    if (res?.time_slots) {
      setTimeSlots(res?.time_slots ?? [])
    }
    //secondary_sales
    if (res?.secondary_sales) {
      setSelectedSalesOption(res?.secondary_sales?.selectedSalesOption);
      setFormData(res?.secondary_sales?.levels)
    }
    // manage_stock
    if (res?.manage_stock) {
      setManageStock(res?.manage_stock?.manageStock);
      setCalculateStock(res?.manage_stock?.calculateStock);
      setRestrictDispatch(res?.manage_stock?.restrictDispatch)
    }
    // user_hierarchy
    const user_hierarchy = res?.user_hierarchy
    if (res?.user_hierarchy) {
      setSelectedhierarchy(user_hierarchy)
    }
    // display_stock
    const display_stock = res?.display_stock
    if (res?.display_stock) {
      setSelectedstock(display_stock)
    }
    // autoclose
    const autoclose = res?.autoclose
    if (res?.autoclose) {
      setAutocloseOrder(autoclose)
    }
    // payment_configuration
    const payment_configuration = res?.payment_configuration
    if (res?.payment_configuration) {
      setPaymentConfig(payment_configuration)
    }
    // sales_person_tracking

    // india_mart_configuration
    if (res?.india_mart_configuration) {
      setIsIntegrationEnabled(res?.india_mart_configuration?.isIntegrationEnabled);
      setContactNo(res?.india_mart_configuration?.contactNo);
      setApiKey(res?.india_mart_configuration?.apiKey)
    }

    // zoho_configuration
    if (res?.zoho_configuration) {
      setIsZohoEnabled(res?.zoho_configuration?.isZohoEnabled);
      setIsZohoEnableds(res?.zoho_configuration?.isZohoEnableds);
      setApiUrl(res?.zoho_configuration?.apiUrl);
      setAccountApiUrl(res?.zoho_configuration?.accountApiUrl);
      setClientId(res?.zoho_configuration?.clientId);
      setClientSecret(res?.zoho_configuration?.clientSecret);
      setRefreshToken(res?.zoho_configuration?.refreshToken);
      setOrganizationId(res?.zoho_configuration?.organizationId);

      // sms_settings
      if (res?.sms_settings) {
        setSmsEnabled(res?.sms_settings?.isSmsEnabled);
        setSmsGetUrl(res?.sms_settings?.smsGetUrl);
        setUseCustomConfig(res?.sms_settings?.useCustomConfig);
        setSmsMessageKey(res?.sms_settings?.smsToNumberKey)
        setSmsToNumberKey(res?.sms_settings?.smsMessageKey)
        setQueryParams(res?.sms_settings?.queryParams)
      }
      if (res?.mail_settings) {
        setMailEnabled(res?.mail_settings?.isMailEnabled || false);
        setCustomMailConfig(res?.mail_settings?.useCustomMailConfig || false);
      
        if (res?.mail_settings?.useCustomMailConfig) {
          setMailSettings(res?.mail_settings?.mailSettings);
        }
        setQueryParams(res?.mail_settings?.queryParams || []);
      }
      if (res?.whatsapp_settings) {
        setWhatsAppEnabled(res?.whatsapp_settings?.isWhatsAppEnabled || false);
        setWhatsappService(res?.whatsapp_settings?.whatsappService || "");
      
        if (res?.whatsapp_settings?.isWhatsAppEnabled) {
          setSettingswp({
            accessToken: res?.whatsapp_settings?.settingswp?.accessToken || "",
            apiEndpoint: res?.whatsapp_settings?.settingswp?.apiEndpoint || "",
            defaultCountryCode: res?.whatsapp_settings?.settingswp?.defaultCountryCode || "",
          });
        } else {
          setSettingswp({
            accessToken: "",
            apiEndpoint: "",
            defaultCountryCode: "",
          });
        }
      }
      if (res?.terms_conditions_configuration) {
        setSettingsTerms(res?.terms_conditions_configuration || {});
      }

      if (res?.geo_fencing) {
        setGeoFencingEnabled(res?.geo_fencing?.enabled || false);
        setGeoFencingForCustomerLocation(
          res?.geo_fencing?.forCustomerLocation || false
        );
        setAllowedRadius(
          res?.geo_fencing?.forCustomerLocation
            ? res?.geo_fencing?.allowedRadius || null
            : null
        );
      }
    }
  }
  const getData = async () => {
    try {
      const response = await fetchsingleData("company_setting", userData?.orgId);
      if (response?.data) {
        setIsEdit(response?.data);
        patchDataform(response?.data)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  return (
    <>
      <div className='page-header add-news-alignment'>
        <span>Company Settings</span>
        <div className='two-button-alignment'>
          <div ><GlobalButton text='Save' onClick={handleSave} /></div>
          <Link to="/"><GlobalButton text='Discard' outline /></Link>
        </div>
      </div>
      <div className="p-4 font-size">
        <h6>Currency</h6>
        <hr />
        <div className="row">
          <div className=" col-md-6">
            <label htmlFor="currency-select" className="mb-0">Choose your Currency</label>
            <div>
              <select
                id="currency-select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="form-control mt-1"
              >
                {currencyOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <h6 className="mt-3">Modules / System config</h6>
        <hr />

        <div className="row mt-4">
          {settingsConfig.map((setting, index) => (
            <div className="col-md-3 mb-3" key={index}>
              <label>{setting.label}</label>
              <div className="d-flex">
                <div className="d-flex mr-4">
                  <label className="mr-1">No</label>
                  <Input
                    type="radio"
                    name={setting.name}
                    value="No"
                    checked={settings[setting.name] === "No"}
                    onChange={() => handleChange(setting.name, "No")}
                  />
                </div>
                <div className="d-flex">
                  <label className="mr-1">Yes</label>
                  <Input
                    type="radio"
                    name={setting.name}
                    value="Yes"
                    checked={settings[setting.name] === "Yes"}
                    onChange={() => handleChange(setting.name, "Yes")}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <h6 className="mt-3">Extra Settings</h6>
        <hr />
        {/* <div className="row mt-4">
                  {setExtraSettingConfig.map((setting, index) => {
                    if (settings.enableSaleQuotation === "No" && setting.name === "remarksInSaleQuotation") {
                      return null;
                    }
                    if (settings.enableEstimate === "No" && setting.name === "remarksInEstimateProduct") {
                      return null;
                    }
                     // Skip 'remarksInInvoice' if 'enableInvoice' is 'No'
                    if (settings.enableInvoice === "No" && setting.name === "remarksInInvoice") {
                      return null;
                    }
                    if (settings.enableSalesReturn === "No" && setting.name === "remarksInSalesReturnProduct") {
                      return null;
                    }

                return (
                  <div className="col-md-3 mb-3" key={index}>
                    <label>{setting.label}</label>
                    <div className="d-flex">
                      <div className="d-flex mr-4">
                        <label className="mr-1">No</label>
                        <Input
                          type="radio"
                          name={setting.name}
                          value="No"
                          checked={Extrasetting[setting.name] === "No"}
                          onChange={() => ExtrasettinghandleChange(setting.name, "No")}
                        />
                      </div>
                      <div className="d-flex">
                        <label className="mr-1">Yes</label>
                        <Input
                          type="radio"
                          name={setting.name}
                          value="Yes"
                          checked={Extrasetting[setting.name] === "Yes"}
                          onChange={() => ExtrasettinghandleChange(setting.name, "Yes")}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> */}
            <div className="row mt-4">
              {setExtraSettingConfig.map((setting, index) => (
                <div className="col-md-3 mb-3" key={index}>
                  <label>{setting.label}</label>
                  <div className="d-flex">
                    {setting.options ? (
                      setting.options.map((option, i) => (
                        <div className="d-flex mr-4" key={i}>
                          <label className="mr-1">{option}</label>
                          <Input
                            type="radio"
                            name={setting.name}
                            value={option}
                            checked={Extrasetting[setting.name] === option}
                            onChange={() => ExtrasettinghandleChange(setting.name, option)}
                          />
                        </div>
                      ))
                    ) : (
                      <>
                        <div className="d-flex mr-4">
                          <label className="mr-1">No</label>
                          <Input
                            type="radio"
                            name={setting.name}
                            value="No"
                            checked={Extrasetting[setting.name] === "No"}
                            onChange={() => ExtrasettinghandleChange(setting.name, "No")}
                          />
                        </div>
                        <div className="d-flex">
                          <label className="mr-1">Yes</label>
                          <Input
                            type="radio"
                            name={setting.name}
                            value="Yes"
                            checked={Extrasetting[setting.name] === "Yes"}
                            onChange={() => ExtrasettinghandleChange(setting.name, "Yes")}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
        <div>
          <h6 className="mt-3">Product Price Configuration</h6>
          <hr />
          <div className="row mt-4">
            {/* Radio Groups */}
            {radioGroups.map((group, index) => (
              <div className="col-md-6 mb-3" key={index}>
                <label className="">{group.label}</label>
                <div className="d-flex flex-wrap">
                  {group.options.map((option, idx) => (
                    <div className="d-flex align-items-center mr-3" key={idx}>
                      <Input
                        type="radio"
                        name={group.label}
                        value={option.value}
                        checked={group.state === option.value}
                        onChange={() => group.setState(option.value)}
                        className="mr-1"
                      />
                      <label>{option.label}</label>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {/* Enable Amount Adjustment For (Checkboxes) */}
            <div className="col-md-12">
              <label className="">Enable Amount Adjustment For</label>
              <div className="row mt-2">
                {checkboxOptions.map((option, index) => (
                  <div className="col-md-2 d-flex align-items-center mb-2" key={index}>
                    <Input
                      type="checkbox"
                      name="enableAmountAdjustmentFor"
                      value={option}
                      checked={enableAmountAdjustmentFor.includes(option)}
                      onChange={() => handleCheckboxChange(option)}
                      className="mr-1"
                    />
                    <label>{option}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h6 className="mt-3">GST Configuration</h6>
          <hr />
          <div className="row mt-4">
            <div className="col-md-4 mb-3">
              <label>Enable GST</label>
              <div className="d-flex">
                <div className="d-flex mr-4">
                  <label className="mr-1">No</label>
                  <Input
                    type="radio"
                    name="enableGST"
                    value="No"
                    checked={enableGST === "No"}
                    onChange={() => setEnableGST("No")}
                  />
                </div>
                <div className="d-flex">
                  <label className="mr-1">Yes</label>
                  <Input
                    type="radio"
                    name="enableGST"
                    value="Yes"
                    checked={enableGST === "Yes"}
                    onChange={() => setEnableGST("Yes")}
                  />
                </div>
              </div>
            </div>
            {enableGST === "Yes" && (
              <>
                <div className="col-md-4 mb-3">
                  <label htmlFor="gstNumber">GST No.</label>
                  <Input
                    id="gstNumber"
                    type="text"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                    placeholder="Enter GST Number"
                    className="form-control"
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="placeOfSupply">Place Of Supply</label>
                  <select
                    id="placeOfSupply"
                    value={placeOfSupply}
                    onChange={(e) => setPlaceOfSupply(e.target.value)}
                    className="form-control"
                  >
                    <option value="" disabled>
                      Select Place Of Supply
                    </option>
                    {placeOfSupplyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>
        </div>
        <h6 className="mt-3">Lead Configuration</h6>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <label>Lead Unique Key</label>
            <div className="d-flex">
              {Leadoptions.map((option) => (
                <div className="mr-3" key={option}>
                  <label >
                    <input
                      className="mr-1"
                      type="radio"
                      value={option}
                      checked={selectedKey === option}
                      onChange={LeadhandleChange}
                    />
                    {option}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <h6 className="mt-3">Customers assignment to user</h6>
        <hr />
        <div className="d-flex">
          {customeroptions.map((option) => (
            <div className="mr-3" key={option}>
              <label >
                <input
                  className="mr-1"
                  type="radio"
                  value={option}
                  checked={selectedCustomer === option}
                  onChange={handleChangeCustomer}
                />
                {option}
              </label>
            </div>
          ))}
        </div>
        <div>
          <h6 className="mt-3">Unit Configuration</h6>
          <hr />
          <div className="d-flex">
            <label>
              <input
                className="mr-1"
                type="checkbox"
                checked={enableMultiUnit}
                onChange={handleEnableMultiUnitChange}
              />
              Enable Multi Unit
            </label>
          </div>
          {enableMultiUnit && (
            <div className="d-flex mt-3">
              {MultiUnitOptions.map((option) => (
                <div className="mr-3" key={option}>
                  <label>
                    <input
                      className="mr-1"
                      type="checkbox"
                      value={option}
                      checked={selectedOptions.includes(option)}
                      onChange={() => handleMultiUnitChange(option)}
                    />
                    {option}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div>
          <h6 className="mt-3">Customer Web Configuration</h6>
          <hr />
          <div className="d-flex flex-wrap">
            {checkboxOptionsCustomer.map((option) => (
              <div className="mr-3" key={option.name}>
                <label>
                  <input
                    type="checkbox"
                    className="mr-1"
                    name={option.name}
                    checked={config[option.name]}
                    onChange={handleCheckboxCustomer}
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <div>
              <label>Display Stock</label>
            </div>
            <label className="mr-3">
              <input
                type="radio"
                name="displayStock"
                value="No"
                className="mr-1"
                checked={config.displayStock === 'No'}
                onChange={handleRadioChange}
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="displayStock"
                value="Yes"
                className="mr-1"
                checked={config.displayStock === 'Yes'}
                onChange={handleRadioChange}
              />
              Yes
            </label>
          </div>
        </div>
        <div>
          <h6 className="mt-3">Customer App Configuration</h6>
          <hr />
          <div className="d-flex flex-wrap">
            {checkboxOptionsapp.map((option) => (
              <div className="mr-3" key={option.name}>
                <label>
                  <input
                    type="checkbox"
                    className="mr-1"
                    name={option.name}
                    checked={configs[option.name]}
                    onChange={handleCheckboxChangeapp}
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <div>
              <label>Display Stock</label>
            </div>
            <label className="mr-3">
              <input
                type="radio"
                name="displayStockapp"
                value="No"
                className="mr-1"
                checked={configs.displayStock === 'No'}
                onChange={handleRadioChangeapp}
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="displayStockapp"
                value="Yes"
                className="mr-1"
                checked={configs.displayStock === 'Yes'}
                onChange={handleRadioChangeapp}
              />
              Yes
            </label>
          </div>
        </div>
        <div>
          <h6 className="mt-3">Customer Order Time Restriction</h6>
          <hr />
          <div className="d-flex justify-content-end">
            <GlobalButton text=" Add Time Slot" className="add-slot-btn text-end" onClick={openModal}>

            </GlobalButton>
          </div>

          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h3>Add Time Slot</h3>

                {/* From Time */}
                <div className="form-group">
                  <label>From:</label>
                  <div className="time-input">
                    <select value={fromHour} onChange={(e) => setFromHour(e.target.value)}>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={String(i + 1).padStart(2, "0")}>
                          {String(i + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span>:</span>
                    <select value={fromMinute} onChange={(e) => setFromMinute(e.target.value)}>
                      {["00", "15", "30", "45"].map((min) => (
                        <option key={min} value={min}>
                          {min}
                        </option>
                      ))}
                    </select>
                    <select value={fromPeriod} onChange={(e) => setFromPeriod(e.target.value)}>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>

                {/* To Time */}
                <div className="form-group">
                  <label>To:</label>
                  <div className="time-input">
                    <select value={toHour} onChange={(e) => setToHour(e.target.value)}>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={String(i + 1).padStart(2, "0")}>
                          {String(i + 1).padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span>:</span>
                    <select value={toMinute} onChange={(e) => setToMinute(e.target.value)}>
                      {["00", "15", "30", "45"].map((min) => (
                        <option key={min} value={min}>
                          {min}
                        </option>
                      ))}
                    </select>
                    <select value={toPeriod} onChange={(e) => setToPeriod(e.target.value)}>
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                </div>

                {/* Level Details */}
                <div className="form-group">
                  <label>Level Details:</label>
                  <input
                    type="text"
                    placeholder="Enter Level"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  />
                </div>

                {/* Modal buttons */}
                <div className="modal-buttons">
                  <button className="add-btn" onClick={handleAddTimeSlot}>
                    Add Slot
                  </button>
                  <button className="cancel-btn" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Table */}
          <table className="time-slot-table">
            <thead>
              <tr>
                <th>From Time</th>
                <th>To Time</th>
                <th>Level</th>
              </tr>
            </thead>
            <tbody>
              {timeSlots.length > 0 ? (
                timeSlots.map((slot, index) => (
                  <tr key={index}>
                    <td>{slot.fromTime}</td>
                    <td>{slot.toTime}</td>
                    <td>{slot.level}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div>
          <h6 className="mt-3">Secondary Sales</h6>
          <hr />
          <form>
            {/* Disable secondary sales */}
            <div>
              <label>
                <input
                  type="radio"
                  value="disable"
                  checked={selectedSalesOption === 'disable'}
                  onChange={handleRadioChangelevel}
                  className="mr-1"
                />
                Disable secondary sales
              </label>
            </div>

            {/* Single level secondary sales */}
            <div className="row">
              <div className="col-md-6">
                <div>
                  <label>
                    <input
                      type="radio"
                      value="single"
                      checked={selectedSalesOption === 'single'}
                      onChange={handleRadioChangelevel}
                      className="mr-1"
                    />
                    Enable single level secondary sales (Company → {formData.firstLevelName} → {formData.secondLevelName})
                  </label>
                </div>
                <div >
                  <label>
                    <input
                      type="radio"
                      value="two"
                      checked={selectedSalesOption === 'two'}
                      onChange={handleRadioChangelevel}
                      className="mr-1"
                    />
                    Enable two-level secondary sales (Company → {formData.firstLevelName} → {formData.secondLevelName} → {formData.thirdLevelName})
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="three"
                      checked={selectedSalesOption === 'three'}
                      onChange={handleRadioChangelevel}
                      className="mr-1"
                    />
                    Enable three-level secondary sales (Company → {formData.firstLevelName} → {formData.secondLevelName} → {formData.thirdLevelName} → {formData.fourthLevelName})
                  </label>
                </div>
              </div>
              <div className="col-md-6 text-end">
                {selectedSalesOption === 'single' && renderLevelInputs(['First', 'Second'])}
                {selectedSalesOption === 'two' && renderLevelInputs(['First', 'Second', 'Third'])}
                {selectedSalesOption === 'three' && renderLevelInputs(['First', 'Second', 'Third', 'Fourth'])}
              </div>
            </div>
            <div>
              <label>
                <input type="checkbox" className="mr-1" /> Can Change Order to
              </label>
            </div>
          </form>
        </div>
        <div>
          <h6>Customer Stock Management</h6>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <label>
                <input
                  type="checkbox"
                  checked={manageStock}
                  onChange={handleCheckboxChangemanage}
                  className="mr-1"
                />
                Manage customer's stock
              </label>
            </div>
            <div className="col-md-8">
              {manageStock && (
                <div className="row">
                  {/* Calculate Stock Base On */}
                  <div className="col-md-6">
                    <label>Calculate Stock Base On :</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="calculateStock"
                          value="order"
                          checked={calculateStock === 'order'}
                          onChange={handleRadioChangeStock}
                          className="mr-1"
                        />
                        Customer Order
                      </label>
                      <label style={{ marginLeft: "1rem" }}>
                        <input
                          type="radio"
                          name="calculateStock"
                          value="dispatch"
                          checked={calculateStock === 'dispatch'}
                          onChange={handleRadioChangeStock}
                          className="mr-1"
                        />
                        Customer Dispatch
                      </label>
                    </div>
                  </div>

                  {/* Restrict Dispatch on Stock */}
                  <div className="col-md-6">
                    <label>Restrict dispatch on stock :</label>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="restrictDispatch"
                          value="no"
                          checked={restrictDispatch === 'no'}
                          onChange={handleRadioChangeDispatch}
                          className="mr-1"
                        />
                        No
                      </label>
                      <label style={{ marginLeft: "1rem" }}>
                        <input
                          type="radio"
                          name="restrictDispatch"
                          value="yes"
                          checked={restrictDispatch === 'yes'}
                          onChange={handleRadioChangeDispatch}
                          className="mr-1"
                        />
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <h6 className="mt-3">User Hierarchy</h6>
          <hr />
          <div className="d-flex">
            {hierarchyoptions.map((option) => (
              <div className="mr-3" key={option}>
                <label >
                  <input
                    className="mr-1"
                    type="radio"
                    value={option}
                    checked={selectedhierarchy === option}
                    onChange={handleChangeHierarchy}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h6 className="mt-3">Inventory Configuration</h6>
          <hr />
          <label>Display Stock</label>
          <div className="d-flex">
            {stockoptions.map((option) => (
              <div className="mr-3" key={option}>
                <label >
                  <input
                    className="mr-1"
                    type="radio"
                    value={option}
                    checked={selectedstock === option}
                    onChange={handleChangeStock}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        </div>

        <h6 className="mt-3">Autoclose</h6>
        <hr />
        <label>Enable autoclose for</label>
        <div className="section">
          <label>
            <input
              type="checkbox"
              className="mr-1"
              checked={autocloseOrder}
              onChange={() => setAutocloseOrder(!autocloseOrder)}
            />
            Order
          </label>
        </div>

        <h6 className="mt-3">Payment Configuration</h6>
        <hr />
        <div className="section">
          <label>
            <input
              type="checkbox"
              checked={paymentConfig}
              className="mr-1"
              onChange={() => setPaymentConfig(!paymentConfig)}
            />
            Enable Payment Against Direct Sales Invoices
          </label>
        </div>

        <h6 className="mt-3">Auto Register Device</h6>
        <hr />
        <div className="section d-flex">
          <div className="mr-5">
            <div><label>Sales</label></div>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterSales"
                value="all"
                className="mr-1"
                checked={autoRegisterSales === "all"}
                onChange={() => setAutoRegisterSales("all")}
              />
              All
            </label>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterSales"
                value="first"
                className="mr-1"
                checked={autoRegisterSales === "first"}
                onChange={() => setAutoRegisterSales("first")}
              />
              First
            </label>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterSales"
                value="none"
                className="mr-1"
                checked={autoRegisterSales === "none"}
                onChange={() => setAutoRegisterSales("none")}
              />
              None
            </label>
          </div>
          <div>
            <div><label>Customer</label></div>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterCustomer"
                value="all"
                className="mr-1"
                checked={autoRegisterCustomer === "all"}
                onChange={() => setAutoRegisterCustomer("all")}
              />
              All
            </label>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterCustomer"
                value="first"
                className="mr-1"
                checked={autoRegisterCustomer === "first"}
                onChange={() => setAutoRegisterCustomer("first")}
              />
              First
            </label>
            <label className="mr-2">
              <input
                type="radio"
                name="autoRegisterCustomer"
                value="none"
                className="mr-1"
                checked={autoRegisterCustomer === "none"}
                onChange={() => setAutoRegisterCustomer("none")}
              />
              None
            </label>
          </div>
        </div>

        <div >
          <h6 className="mt-3">Sales Person Tracking</h6>
          <hr />
          <p>Continuously track sales person's location</p>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
            <label>Between</label>
            {/* Start Time Picker */}
            <Select
              options={hoursOptions}
              value={startHour}
              onChange={setStartHour}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />
            <Select
              options={minutesOptions}
              value={startMinute}
              onChange={setStartMinute}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />
            <Select
              options={amPmOptions}
              value={startAmPm}
              onChange={setStartAmPm}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />

            <label>to</label>
            {/* End Time Picker */}
            <Select
              options={hoursOptions}
              value={endHour}
              onChange={setEndHour}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />
            <Select
              options={minutesOptions}
              value={endMinute}
              onChange={setEndMinute}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />
            <Select
              options={amPmOptions}
              value={endAmPm}
              onChange={setEndAmPm}
              styles={{ container: (base) => ({ ...base, width: "80px" }) }}
            />

            <label>at every</label>
            {/* Interval Picker */}
            <Select
              options={intervalOptions}
              value={interval}
              onChange={(selectedOption) => setInterval(selectedOption)}
              styles={{ container: (base) => ({ ...base, width: "150px" }) }}
            />
          </div>
        </div>

        <div>
          <h6 className="mt-3">IndiaMART Configuration</h6>
          <hr />
          <div>
            <label>
              <input
                type="checkbox"
                checked={isIntegrationEnabled}
                onChange={handleCheckboxChangeleadmart}
                className="mr-1"
              />
              Fetch lead from IndiaMART
            </label>
          </div>
          {isIntegrationEnabled && (
            <div>
              <div>
                <div> <label>Contact No :</label></div>
                <Input type="text" placeholder="Contact No" className="w-50 " value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)} />
              </div>
              <div>
                <div><label>API KEY :</label></div>
                <Input type="text" placeholder="API KEY" className="w-50 " value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)} />
              </div>
            </div>
          )}
        </div>
        <div>
          <h6 className="mt-3">Zoho Configuration</h6>
          <hr />
          <div className="d-flex">
            <div className="mr-5">
              <label>
                <input
                  type="checkbox"
                  checked={isZohoEnabled}
                  onChange={handleToggle}
                  className="mr-1"
                />
                Enable Zoho Integration
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isZohoEnableds}
                  onChange={handleToggles}
                  className="mr-1"
                />
                Enable Zoho Integration For Customer
              </label>
            </div>
          </div>
          {isZohoEnabled && (
            <div style={{ marginTop: '20px' }} className="row">
              <div className="col-md-3">
                <label>API URL:</label>
                <Input
                  type="text"
                  placeholder="Client API URL"
                  value={apiUrl}
                  onChange={(e) => setApiUrl(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Account API URL:</label>
                <Input
                  type="text"
                  placeholder="Client Account API URL"
                  value={accountApiUrl}
                  onChange={(e) => setAccountApiUrl(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Client ID:</label>
                <Input
                  type="text"
                  placeholder="Client ID"
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Client Secret:</label>
                <Input
                  type="text"
                  placeholder="Client Secret"
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Refresh Token:</label>
                <Input
                  type="text"
                  placeholder="Client Refresh Token"
                  value={refreshToken}
                  onChange={(e) => setRefreshToken(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Organization ID:</label>
                <Input
                  type="text"
                  placeholder="Client Organization ID"
                  value={organizationId}
                  onChange={(e) => setOrganizationId(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <h6 className="mt-3">SMS Settings</h6>
          <hr />
          <label>
            <input
              type="checkbox"
              checked={isSmsEnabled}
              onChange={(e) => setSmsEnabled(e.target.checked)}
              className="mr-1"
            />
            Enable SMS
          </label>

          {isSmsEnabled && (
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={useCustomConfig}
                  onChange={(e) => setUseCustomConfig(e.target.checked)}
                  className="mr-1"
                />
                Use Custom SMS Config
              </label>

              {useCustomConfig && (
                <div className="row">
                  <div className="col-md-3">
                    <label>SMS Get URL:</label>
                    <Input
                      type="text"
                      placeholder="SMS Get URL"
                      value={smsGetUrl}
                      onChange={(e) => setSmsGetUrl(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>SMS To Number Key:</label>
                    <Input
                      type="text"
                      placeholder="SMS To Number Key"
                      value={smsToNumberKey}
                      onChange={(e) => setSmsToNumberKey(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>SMS Message Key:</label>
                    <Input
                      type="text"
                      placeholder="SMS Message Key"
                      value={smsMessageKey}
                      onChange={(e) => setSmsMessageKey(e.target.value)}
                    />
                  </div>

                  <p className="mb-1">Fields (Query Parameters)</p>
                  {queryParams.map((param, index) => (
                    <div key={index} style={{ display: "flex", marginBottom: "10px",gap:"12px",alignItems: "baseline" }}>
                      <Input
                        type="text"
                        placeholder="Key"
                        value={param.key}
                        onChange={(e) => handleFieldChange(index, "key", e.target.value)}
                        className="mr-1"
                      />
                      <Input
                        type="text"
                        placeholder="Value"
                        value={param.value}
                        onChange={(e) => handleFieldChange(index, "value", e.target.value)}
                      />
                      <GlobalButton onClick={() => handleRemoveField(index)} text="Remove" className="ml-1" />
                    </div>
                  ))}
                  <GlobalButton onClick={handleAddField} text="Add" />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <h6 className="mt-3">Mail Settings
          </h6>
          <hr />
          <div>
            <div>
              {/* Enable Mail Checkbox */}
              <label>
                <input
                  type="checkbox"
                  checked={isMailEnabled}
                  onChange={(e) => setMailEnabled(e.target.checked)}
                  className="mr-1"
                />
                Enable Mail
              </label>

              {/* Conditional Rendering Based on Enable Mail */}
              {isMailEnabled && (
                <div>
                  {/* Use Custom Mail Config Checkbox */}
                  <label>
                    <input
                      type="checkbox"
                      checked={useCustomMailConfig}
                      onChange={(e) => setCustomMailConfig(e.target.checked)}
                      className="mr-1"
                    />
                    Use Custom Mail Config
                  </label>

                  {/* Conditional Rendering for Custom Config */}
                  {useCustomMailConfig && (
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                        <div>
                          <label>
                            Mail From Name:
                            <Input
                              type="text"
                              name="mailFromName"
                              value={mailSettings.mailFromName}
                              onChange={handleInputChange}
                              placeholder="Mail From Name"
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            SMTP Host:
                            <Input
                              type="text"
                              name="smtpHost"
                              value={mailSettings.smtpHost}
                              onChange={handleInputChange}
                              placeholder="SMTP Host"
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            SMTP Port:
                            <Input
                              type="text"
                              name="smtpPort"
                              value={mailSettings.smtpPort}
                              onChange={handleInputChange}
                              placeholder="SMTP Port"
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            SMTP Username:
                            <Input
                              type="text"
                              name="smtpUsername"
                              value={mailSettings.smtpUsername}
                              onChange={handleInputChange}
                              placeholder="SMTP Username"
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            SMTP Password:
                            <Input
                              type="password"
                              name="smtpPassword"
                              value={mailSettings.smtpPassword}
                              onChange={handleInputChange}
                              placeholder="SMTP Password"
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            SMTP Encryption:
                            <Input
                              type="text"
                              name="smtpEncryption"
                              value={mailSettings.smtpEncryption}
                              onChange={handleInputChange}
                              placeholder="SMTP Encryption"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

          </div>
        </div>
        <div>
          <h6 className="mt-3">Cloud Telephony Settings
          </h6>
          <hr />
          <div>
            <label>
              <input
                type="checkbox"
                checked={isCloudTelephonySettings}
                onChange={handleCheckboxChangeCloudTelephonySettings}
                style={{ marginRight: '10px' }}
              />
              Enable Cloud Telephony
            </label>
          </div>
          <div>
            {isCloudTelephonySettings && (
              <div>
                <p className="mb-1">Fields ( parameter that have to used with telephony service ) </p>
                <div>
                  {cloudTelephonySettings.map((param, index) => (
                    <div key={index} style={{ display: "flex", marginBottom: "10px",gap:"12px",alignItems: "baseline" }}>
                      <Input
                        type="text"
                        placeholder="Key"
                        value={param.key}
                        onChange={(e) => handleFieldChangeCloudTelephonySettings(index, "key", e.target.value)}
                        style={{ marginRight: "10px" }}
                      />
                      <Input
                        type="text"
                        placeholder="Value"
                        value={param.value}
                        onChange={(e) => handleFieldChangeCloudTelephonySettings(index, "value", e.target.value)}
                        style={{ marginRight: "10px" }}
                      />
                      <GlobalButton onClick={() => handleRemoveFieldCloudTelephonySettings(index)} text="Remove"></GlobalButton>
                    </div>
                  ))}
                  <GlobalButton onClick={handleAddFieldCloudTelephonySettings} text="Add"></GlobalButton>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <h6 className="mt-3">WhatsApp Settings</h6>
            <hr />
            {/* Enable WhatsApp Messaging */}
            <div className="d-flex">
              <Input
                type="checkbox"
                checked={isWhatsAppEnabled}
                onChange={handleCheckboxToggle}
              />
              <label>Enable WhatsApp Messaging
              </label>
            </div>


            {/* Conditional rendering based on Enable WhatsApp */}
            {isWhatsAppEnabled && (
              <div className="d-flex align-items-baseline">
                {/* WhatsApp Service Selection */}
                <div className="d-flex mr-4">

                  <Input
                    type="radio"
                    name="whatsappService"
                    value="businessCloudApi"
                    checked={whatsappService === "businessCloudApi"}
                    onChange={handleServiceChange}
                  />
                  <label className="mr-5"> WhatsApp Business Cloud API
                  </label>


                  <Input
                    type="radio"
                    name="whatsappService"
                    value="wati"
                    checked={whatsappService === "wati"}
                    onChange={handleServiceChange}
                  />
                  <label> Wati
                  </label>
                </div>

                {/* Input fields for WhatsApp Settings */}
                <div style={{ marginTop: "15px", display: "flex", gap: "15px" }}>
                  <div>
                    <label>
                      Permanent Access Token:
                      <Input
                        type="text"
                        name="accessToken"
                        value={settingswp.accessToken}
                        onChange={(e) => handleInputChangeWhatsApp('accessToken', e.target.value)}
                        placeholder="Permanent Access Token"
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      WATI API Endpoint:
                      <Input
                        type="text"
                        name="apiEndpoint"
                        value={settingswp.apiEndpoint}
                        onChange={(e) => handleInputChangeWhatsApp('apiEndpoint', e.target.value)}
                        placeholder="WATI API Endpoint"
                      />
                    </label>
                  </div>
                  <label htmlFor="countryOptions">
                    Default Country Code
                    <select
                      id="countryOptions"
                      name="defaultCountryCode"
                      value={settingswp.defaultCountryCode}
                      onChange={(e) => handleInputChangeWhatsApp('defaultCountryCode', e.target.value)}
                      placeholder="Select Default Country Code"
                      className="form-control mt-1"
                    >
                      {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <h6 className="mt-3">Terms & Conditions Configuration</h6>
          <hr />

          <div>
            <div style={{ lineHeight: '30px' }}>Enable Terms & Conditions For</div>
            {options.map((option) => (
              <label key={option.id} className="mr-4">
                <input
                  type="checkbox"
                  name={option.id}
                  checked={settingsTerms[option.id]}
                  onChange={handleCheckboxChangeTerms}
                  className="mr-1"
                />
                {option.label}
              </label>
            ))}
          </div>
        </div>

        <div>
          <h6 className="mt-3">Geo Fencing</h6>
          <hr />
          <div>
            <div style={{ lineHeight: '30px' }}>
              <label className="mr-1">
                <input
                  type="checkbox"
                  checked={geoFencingEnabled}
                  onChange={handleGeoFencingChange}
                  className="mr-1"
                />
                Enable Geo-Fencing
              </label>
            </div>

            {geoFencingEnabled && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={geoFencingForCustomerLocation}
                    onChange={handleGeoFencingForCustomerLocationChange}
                    className="mr-1"
                  />
                  Enable Geo-Fencing for Customer Location
                </label>

                {geoFencingForCustomerLocation && (
                  <div style={{ marginTop: '10px' }}>
                    <label >
                      Allowed Radius for Customer Location (mtr):
                      <Input
                        type="number"
                        value={allowedRadius}
                        onChange={handleRadiusChange}
                        placeholder="Enter radius"
                        min="1"
                        className="mr-1"
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          <h6 className="mt-3">Clear all software data</h6>
          <hr />
          <p className="mb-1" >This will permanently clear all software data and make you relogin with current user id.</p>
          <GlobalButton text="CLEAR DATA"></GlobalButton>
        </div>

      </div>
    </>
  );
};