import React from 'react'
import './formtable.scss';
import EditIcon from '../../../shared/icons/editIcon';
export default function Formtable() {
    return (
        <div>
            <div className='form-table-design'>
                <div className='account-table-design'>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input type='checkbox' />
                                </th>
                                <th>Action</th>
                                <th>Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                [...Array(10)].map(() => {
                                    return (
                                        <tr>
                                            <td>   <input type='checkbox' /></td>
                                            <td>
                                            <EditIcon/>
                                            </td>
                                            <td>Name here Name here Name here Name here Name here Name here...</td>
                                            <td>
                                                <button className='status'>Active</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
