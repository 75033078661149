import { useEffect, useState } from 'react';
import { fetchData, postData } from '../../../services/apiService';
import './attendancetable.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import SelectBox from '../../../shared/components/select';

export default function AttendanceTable() {
  const [mergedList, setMergedList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState({}); // Initialize with an empty object
  const [error, setErrors] = useState([]);
  const [UserName, setUserName] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async (user_id = null, date = null) => {
  
    try {
        const response = await postData('attendance/userWiseAttendance', {
          created_at: new Date().toISOString(), 
          user_id, 
          date,    
        });
        if (response?.data) setMergedList(response?.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    const userResponse = await fetchData('user');
    if (userResponse?.data) {
      setUserName(
        userResponse.data.map((user) => ({
          label: user.name,
          key: 'userid',
          value: user.id,
          name: user.name,
        }))
      );
    }
};

console.log(mergedList,"mergedList")
  const selectChanged = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      user_id: selectedOption.value,
      username: selectedOption.name,
    }));
  };

  const handleDateChange = (date) => {
    setFormData(prevState => ({
        ...prevState,
        date: date ? dayjs(date) : null, 
    }));
    
};

  const handleApply = (e) => {
    e.preventDefault();
    const { user_id, date } = formData;
    getData(user_id, date ? date.toISOString().split('T')[0] : null); // Fetch with user_id and date
  };

  return (
    <div className="attendance-page-container">
      <div className="actions-container">
        <button className="action-button">Punch in</button>
        <button className="action-button">Users</button>
        <button className="action-button">Days</button>
      </div>
      <div className="content-layout">
        <div className="account-table-design">
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Email</th>
                <th>Punch In</th>
                <th>Punch Out</th>
                <th>Work Duration</th>
                <th>Present/Absent</th>
                <th>Last Location</th>
              </tr>
            </thead>
            <tbody>
              {mergedList?.map((item, index) => (
                <tr key={index}>
                  <td>{item.userName}</td>
                  <td>{item.userEmail}</td>
                  <td>{item.punchInTime}</td>
                  <td>{item.punchOutTime || 'Not Yet Punched Out'}</td>
                  <td>{item.workDuration || 'N/A'}</td>
                  <td className={`status ${item.attendanceStatus.toLowerCase()}`}>
                    {item.attendanceStatus}
                  </td>
                  <td>{item.location || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="filter-form">
          <form onSubmit={handleApply}>
            <div className="form-group">
              <label>Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DatePicker
                                    name='date' value={formData.date} onChange={handleDateChange} />
                            </LocalizationProvider>
            </div>
            <div className="form-group">
              <label>User</label>
              <SelectBox
                name="username"
                value={UserName?.find((i) => i.name === formData.username)}
                onChange={selectChanged}
                options={UserName}
                placeholder="User"
                required
              />
            </div>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
