import { useState } from "react";
import "./area.scss";
import Button from "../../shared/components/button";
import Areatable from "./areatable";
import { Link } from "react-router-dom";
import { deleteData, filterAll } from "../../services/apiService";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { checkAccess } from "../../services/checkFeature";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
export default function Area() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`area/${id}`)));
      setAreaList(areaList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  

  const handleExport = () => {
    const headers = [
      ["Name","City","Country","State" ,"Status"]


    ];
 
    filterAll("area")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.areaName,
      item.cityName,
      item.countryName,
      item.stateName,
      item.status ? "Active" : "Inactive", ,
      ]);
      exportToExcel('Area',headers,datas,'area')
    })
  };
  return (
    <div className="page-24">
      {/* <div
        className="page-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Area</span>
        <div className="button-group" style={{ display: "flex", gap: "10px" }}>
          {selectedIds.length > 0 && (
            <Button text="Delete Selected" onClick={handleDelete} />
          )}
          <Link to="addarea">
            <Button text="Add Area" />
          </Link>
        </div>
      </div> */}
      <div className="">
        <div className="pb-3 customer-header-alignment">
          <div>
            <h2>Area</h2>
            <Breadcumbs activePath="Area" pageName="Area" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {checkAccess('add_area')&&<Link to="addarea">
              <GlobalButton text="Add Area" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_area')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} />}
          </div>
        </div>
      </div>
      <Areatable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        areaList={areaList}
        setProductList={setAreaList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    </div>
  );
}
