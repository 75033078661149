import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ShowImage from '../../../shared/components/showImage';
import TotalAmountDisplay from '../formateamount';



export default function AddEditviewinvoicetable() {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);

    const pdfRef = useRef();

        const handleGeneratePdf = () => {
            const pdfWidth = 420; // Full HD width
            const pdfHeight = 370; // Full HD height

            const doc = new jsPDF({
                orientation: 'a4', // Landscape orientation for wider content
                unit: 'px', // Use pixels for dimensions
                format: [pdfWidth, pdfHeight], // Custom format for full HD
            });

            // Convert the content of the div to PDF
            doc.html(pdfRef.current, {
                callback: (doc) => {
                    doc.save('Invoice.pdf'); // Save the PDF file
                },
                x: 20, // X offset for margins
                y: 20,
                z: 20, // Y offset for margins

                width: pdfWidth - 20, // Adjust the width for margins
                windowWidth: 970, // The width of the browser window


            });
        };

    // const handleGeneratePdf = () => {
    //     const content = pdfRef.current;
    
    //     html2canvas(content, {
    //         scale: 1, // Base scale
    //         useCORS: true, // To handle cross-origin issues if images are present
    //     }).then((canvas) => {
    //         const contentWidth = canvas.width;
    //         const contentHeight = canvas.height;
    
    //         const pdf = new jsPDF('p', 'px', [contentWidth, contentHeight]);
    
    //         // Convert the canvas to an image
    //         const imgData = canvas.toDataURL('image/png');
    
    //         // Adjust content dimensions to fit the PDF page
    //         const pageWidth = pdf.internal.pageSize.getWidth();
    //         const pageHeight = pdf.internal.pageSize.getHeight();
    
    //         const scaleFactor = Math.min(pageWidth / contentWidth, pageHeight / contentHeight);
    
    //         const scaledWidth = contentWidth * scaleFactor;
    //         const scaledHeight = contentHeight * scaleFactor;
    
    //         // Add the scaled image to the PDF
    //         pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
    
    //         // Save the generated PDF
    //         pdf.save('Invoice.pdf');
    //     }).catch((error) => {
    //         console.error('Error generating PDF:', error);
    //     });
    // };
    


   
    
  
    












    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        
        invoiceNo: "",
        invoiceDate: new Date(),
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerContact: "",
        status: true,
        orderproducts: [],
        ordernotification: [],
        totalAmount: 0,
        created_by:'',
        updated_by:'',


    });
         const getData = async () => {
        try {
            // const response = await fetchsingleData(`order/${id}`);
            // debugger
            // if (response?.data) setFormData(response.data);
            const rs = await fetchsingleData(`invoice`,id);
            if (rs?.data) setFormData(rs.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const calculateTotalAmount = () => {
    //     return formData.productDetails.reduce((accumulator, currentValue) => {
    //         return accumulator + Number(currentValue.subTotal);
    //     }, 0).toFixed(2);
    // };
    const appDataJSON = localStorage.getItem('users');
    const appData = JSON.parse(appDataJSON);

    return (
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Invoice {'>'} List</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    <div onClick={handleGeneratePdf}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                        </svg>
                    </div>
         
                        <a href="/invoice" style={{ textDecoration: 'none' }}>
                        <button style={{
                            backgroundColor: '#8CA7C6',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}><div ref={pdfRef} style={{
                marginTop: '1rem',
                padding: '1.5rem',
                backgroundColor: '#fff',
                border: '1px solid rgba(30,46,80,.09)',
                borderRadius: '0.25rem',
                boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                width: '900px',
                scrollBehavior: 'smooth',
                height: 'auto',
                overflowY: 'scroll',

            }}>
              
    
                    <><div style={{ display: 'flex' }}>
                        <div><h4 style={{ paddingBottom: '10px' }}>Invoice to,</h4>
                            <p style={{ fontSize: '0.85rem' }}>{formData.customerName}</p></div>
                    </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Invoice No.</div>
                                    <strong>{formData.invoiceNo}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Invoice createdBy.</div>
                                    <strong>{formData.created_by}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>invoice Date</div>
                                    <strong>{dayjs(formData.invoiceDate).format('DD-MM-YYYY')}</strong>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Customer</div>
                                    <strong>{formData.customerName}</strong>
                                    <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                        {formData.customerAddress}
                                        <br />
                                        <a href={`mailto:${formData.customerContact}`} style={{ color: 'purple' }}>{formData.customerContact}</a>
                                    </p>
                                </div>
                               
                            </div>
                        </div>

                        <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>#</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Item</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Category</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Quantity</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Price</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Discount</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Tax</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Sub Total</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.orderproducts?.map((product, index) => (
                                    <tr key={index}
                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{index + 1}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.name}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.unitName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.quantity}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.price}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.discount}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.tax}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.subTotal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                               
                                <h5 style={{ color: '#28a745' }}><TotalAmountDisplay formData={formData} /></h5>
                            </div>
                        </div>
                    </>
                
            </div>

                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'auto'
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Remark</strong></div>
                </div>
            </div>
        </div>

    );
}