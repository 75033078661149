import { useEffect, useState, useRef } from 'react';
import { fetchCustomData } from '../../../services/apiService';
import ViewIcon from '../../../assets/icons/view.svg';
import Pagination from '../../../shared/components/pagination';
import { Modal } from 'antd';
import mapboxgl from 'mapbox-gl';

const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ';

export default function AllLocationTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [allLocationList, setAllLocationList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [modalTitle, setModalTitle] = useState(''); 


  const pageSize = 10;
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchCustomData('location_tracking/All-user', requestBody, pagination);
      if (response?.data) {
        setAllLocationList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openMapModal = (latitude, longitude, userName, date, time) => {
    setLat(latitude);
    setLong(longitude);
    setModalTitle(`${userName} - ${date} ${time}`); 
    setIsModalOpen(true);
  };
  

  const handleModalClose = () => {
    setIsModalOpen(false);
    setLat(null);
    setLong(null);
    if (mapRef.current) {
      mapRef.current.remove(); // Clean up the map instance
      mapRef.current = null;
    }
  };

  const initializeMap = () => {
    if (!mapContainerRef.current || lat === null || long === null) return;
    if (mapRef.current) {
      mapRef.current.remove();
      mapRef.current = null;
    }

  
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [long, lat], 
      zoom: 12,
      style: 'mapbox://styles/mapbox/streets-v11',
      accessToken: MAPBOX_TOKEN,
    });

    new mapboxgl.Marker()
      .setLngLat([long, lat]) 
      .addTo(mapRef.current);
    setTimeout(() => {
      if (mapRef.current) mapRef.current.resize();
    }, 0);
  };

  useEffect(() => {
    if (isModalOpen && lat !== null && long !== null) {
      initializeMap();
    }
  }, [isModalOpen, lat, long]);
  console.log(allLocationList);

  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>User Role</th>
              <th>Date</th>
              <th>Time</th>
              <th>Distance</th>
              <th>Duration</th>
              <th>Battery Level</th>
              <th>Status</th>
              <th>View Map</th>
            </tr>
          </thead>
          <tbody>
            {allLocationList?.slice().reverse().map((item, index) => (
              <tr key={index}>
                <td>{item?.userName}</td>
                <td>{item?.userRole}</td>
                <td>
                  {item?.date} 
                </td>
                <td>{item?.time}</td>
                <td>{item?.incrementalDistance} Km</td>
                <td>{item?.incrementalTime}</td>
                <td>{item?.battery_level ? `${item.battery_level}%` : ''}</td>
                <td className="status">{item?.visit_type}</td>
                <td>
                  <img
                    src={ViewIcon}
                    alt="View Map"
                    onClick={() => openMapModal(item.latitude, item.longitude,item.userName,item.date,item.time)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
      >
        <div
          ref={mapContainerRef}
          style={{ width: '100%', height: '400px' }}
        />
      </Modal>
    </div>
  );
}
