
import AddSettings from "./addsettings";


export default function CompanySettings() {



    return (
        <div> 
          <AddSettings
          />
        </div>
      );
}