import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { deleteData, filterAll } from "../../services/apiService";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import SystemTypeCategorytable from "./systemtypecategorytable";
import { exportToExcel } from "../../services/globalService";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
export default function SystemTypeCategory() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`systemtypecategory/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  // const handleExport = () => {
  //   // Convert the activityList data to a worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(activityList);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "SystemTypeCategory");

  //   // Export the workbook as an Excel file
  //   XLSX.writeFile(workbook, "SystemTypeCategoryData.xlsx");
  // };

  const handleExport = () => {
    const headers = [
      [	"System Type Name","Category Name", "Status"]

    ];
 
    filterAll("systemtypecategory")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.systemTypeName,
        item.categoryName,
      item.status ? "Active" : "Inactive",
      ]);
      exportToExcel('System Type Category',headers,datas,'systemtypecategory')
    })
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <div className="page-24">
      <div className="">
        <div className="customer-header-alignment pb-3">
          <div>
            <h2>System Type Category</h2>
            <Breadcumbs activePath="System Type Category" pageName="System Type Category" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
            )}
            <Link to="addEditSystemTypeCategory">
              <GlobalButton text="Add System Type" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>
      <SystemTypeCategorytable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        activityList={activityList}
        totalItems={totalItems} 
        setTotalItems={setTotalItems}
        setActivityList={setActivityList}
      />
    </div>
  );
}
