import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';


import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from '../../addcustomers/statusBox';

export default function Addedittrasport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [countryOptions, setCountryOptions] = useState([])
  const [sateOptions, sateCategory] = useState([])
  const [areaOptions, areCategory] = useState([])
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    pincode: '',
    address: '',
    countryId: '',
    stateId: '',
    cityId: '',
    countryName: '',
    stateName: '',
    cityName: '',
    status: true
  });
  const fetchCountry = async () => {
    // const response = await axios.get('https://raw.githubusercontent.com/dr5hn/countries-states-cities-database/master/countries%2Bstates%2Bcities.json');

    // const countryData = [];

    // for (let i = 0; i < response.data.length; i++){
    //   countryData.push({
    //     value : response.data[i].iso3,
    //     label : response.data[i].name,
    //     key: "country",
    //     otherData: response.data[i].states
    //   })
    // }

    // setCountryOptions(countryData)
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(response?.data.map(i => {
          return {
            label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
          }
        }));
      }
      if (id) {
        const response = await fetchsingleData("country", id);
        if (response?.data)
          setFormData(response?.data); // Update state with fetched data
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchState = async (id) => {
    try {
      const response = await fetchData("state", { countryId: id });

      if (response?.data) {
        sateCategory(response?.data.map(i => {
          return {
            label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
          }
        }));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const fetchArea = async (id) => {
    try {
      const response = await fetchData("city", { stateId: id });

      if (response?.data) {
        areCategory(response?.data.map(i => {
          return {
            label: i?.cityName, key: 'city', value: { cityId: i?.id, cityName: i?.cityName }
          }
        }));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setFormData(prevState => ({
            ...prevState,
            // latitude: position.coords.latitude,
            // longitude: position.coords.longitude
          }));
        },
        error => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    fetchCountry()
  }, [])
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationCheck(true)) {
      try {
        if (id) {
          patchData(`transport/${id}`, formData).then(data => {
            if (data) navigate(-1);
          })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
        } else {
          postData('transport', formData)
            .then(data => {
              if (data) navigate(-1);
            })
            .catch(error => {
              console.error('Error fetching data:', error);
            });
        }
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors.isSubmit) validationCheck()
  };
  const selectChanged = (e) => {
    if (e.key === "country") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchState(e.value.countryId)
    } else if (e.key === "state") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchArea(e.value.stateId)

    }
    else {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
    }
  }
  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };
  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData?.name?.trim()) {
      validationErrors.name = 'This field is required';
    }
    if (isSubmit || errors.isSubmit)
      validationErrors.isSubmit = true;
    setErrors(validationErrors);
    return (Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0) ? true : false
  };
  const [errors, setErrors] = useState({});
  return (
    <div className='page-24'>
      {/* <div className='page-header add-news-alignment'>
                <span>Transport {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/transport"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}


      <div className='add-header-design'>
        <div>
          <h2>Add Transport</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add Transport" />
        </div>
        <div className='right-contnet-alignment'>
          <Link to="/transport">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Discard
            </button>
          </Link>
          <button onClick={handleSubmit}>
            {id ? 'Update' : 'Save'}
          </button>
        </div>
      </div>

      <div className='page-main-grid'>
        <div>
          <div className='new-white-box-design'>
            <div className='two-col-grid'>
              <Input label={
                <span>
                  Name<span className="required-star">*</span>
                </span>} name='name' value={formData.name} onChange={handleInputChange} placeholder='Enter Name' type='text' errors={errors.name} required />
              <Input label='Email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' type='text' />
              <Input label='Phone' name='phone' value={formData.phone} onChange={handleInputChange} placeholder='Enter Phone' type='text' />
              <Input label='Address' name='address' value={formData.address} onChange={handleInputChange} placeholder='Enter Address' type='text' />
              <SelectBox
                label='Country'
                name='countryId'
                value={countryOptions.find(i => i.value.countryId === formData.countryId)} onChange={selectChanged}
                options={countryOptions}
                placeholder='Select Country'
              />
              <SelectBox
                label='State'
                name='stateId'
                onChange={selectChanged}
                value={sateOptions?.find((option) => option?.value?.stateId === formData.stateId)}
                options={sateOptions} // Pass the options array to the Select component
                placeholder='Select State'
              />
              <SelectBox
                label='City'
                name='cityId'
                onChange={selectChanged}
                value={areaOptions?.find((option) => option?.value?.cityId === formData?.cityId)}
                options={areaOptions} // Pass the options array to the Select component
                placeholder='Select City'
              />
              <Input label='Pincode' name='pincode' value={formData.pincode} onChange={handleInputChange} placeholder='Enter Pincode' type='text' />
              <div className='d-flex align-items-end'>
                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <StatusBox />
        </div>
      </div>
    </div>
  )
}