import { useState, useEffect } from 'react';
import './leadtable.scss';
import { fetchData } from "../../../services/apiService";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';

export default function Leadtable({ selectedIds, setSelectedIds, totalItems, setTotalItems, activityList, setActivityList }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [NameTerm, setNameTerm] = useState("");
  const [EmailTerm, setEmailTerm] = useState("");
  const [ContactNoTrem, setContactNoTrem] = useState("");
  const [Owner, setOwner] = useState("");
  const [Collaborators, setCollaboratorsTerm] = useState("");
  const [Source, setSourceTerm] = useState("");
  const [Industry, setIndustryTerm] = useState("");
  const [Status, setStatusTerm] = useState("");
  const pageSize = 10;

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setActivityList(activityList);
  }, [activityList]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchData("lead", requestBody, pagination);
      if (response?.data) {
        setActivityList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Disqualified':
        return 'cancelled';
      case 'Not Interested':
        return 'closee';
      case 'New':
      case 'NEW':
        return 'open';
      case 'Qualified':
        return 'overdue';
      default:
        return '';
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Corrected filter logic
  const filteredActivityList = activityList.filter((item) => {
    return (
      (!NameTerm || item.name?.toLowerCase().includes(NameTerm.toLowerCase())) &&
      (!EmailTerm || item.email?.toLowerCase().includes(EmailTerm.toLowerCase())) &&
      (!ContactNoTrem || item.contactno?.toLowerCase().includes(ContactNoTrem.toLowerCase())) &&
      (!Owner || item.ownername?.toLowerCase().includes(Owner.toLowerCase())) &&
      (!Source || item.leadsource?.toLowerCase().includes(Source.toLowerCase())) &&
      (!Industry || item.leadindustry?.toLowerCase().includes(Industry.toLowerCase())) &&
      (!Status || item.leadstatus?.toLowerCase().includes(Status.toLowerCase()))
    );
  });

  return (
    <div className='' >
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={NameTerm} onChange={(e) => setNameTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Email" value={EmailTerm} onChange={(e) => setEmailTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Contact No" value={ContactNoTrem} onChange={(e) => setContactNoTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Owner" value={Owner} onChange={(e) => setOwner(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Source" value={Source} onChange={(e) => setSourceTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Industry" value={Industry} onChange={(e) => setIndustryTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTerm(e.target.value)} className="w-100 border-0" /></th>
              {checkAccess('edit_lead') && <th>Action</th>}
            </tr>
            <tr>
            <th><input type="checkbox" onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact No</th>
              <th>Owner</th>
              <th>Source</th>
              <th>Industry</th>
              <th>Created by</th>
              {checkAccess('edit_lead')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {filteredActivityList.map((item, index) => (
              <tr key={index}>
                <td><input type="checkbox" checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.contactno}</td>
                <td>{item.ownername}</td>
                <td>{item.leadsource}</td>
                <td>{item.leadindustry}</td>
                <td>
                  {checkAccess('read_lead') ? (
                    <Link to={`view/${item.id}`}>
                      <button className={getStatusClass(item.leadstatus)}>{item.leadstatus}</button>
                    </Link>
                  ) : (
                    <button className={getStatusClass(item.leadstatus)} disabled>
                      {item.leadstatus}
                    </button>
                  )}
                </td>
                {checkAccess('edit_lead') && (
                  <td>
                    <Link to={`addlead/${item.id}`}>
                      <EditIcon />
                    </Link>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
