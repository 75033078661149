import React from 'react';
import './formateamount.scss'
const TotalAmountDisplay = ({ formData }) => {
    if (!formData || !formData.productDetails || formData.productDetails.length === 0) {
        return <div className='d-flex justify-content-end'>No products available</div>;
    }

    // Function to format the amount
    const formatAmount = (amount) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        }).format(amount);

    // Calculate subtotal
    const subtotal = formData.productDetails.reduce((acc, product) => {
        // Use 'subTotal' if provided, otherwise calculate it manually
        const quantity = parseFloat(product.qty || 0);
        const unitPrice = parseFloat(product.unitPrice || 0);
        const discount = parseFloat(product.discount || 0);
        const productSubtotal = product.subTotal ? parseFloat(product.subTotal) : (quantity * unitPrice - (quantity * unitPrice * discount) / 100);
        return acc + productSubtotal;
    }, 0);

    // Calculate taxes (if any)
    const taxDetails = formData.productDetails
        .filter((product) => parseFloat(product.tax || 0) > 0) // Filter products with taxes
        .map((product) => {
            const quantity = parseFloat(product.qty || 0);
            const unitPrice = parseFloat(product.unitPrice || 0);
            const discount = parseFloat(product.discount || 0);
            const taxableAmount = quantity * unitPrice - (quantity * unitPrice * discount) / 100;
            const taxPercentage = parseFloat(product.tax || 0);
            const taxAmount = (taxableAmount * taxPercentage) / 100;

            return {
                productName: product.productName || "Unnamed Product",
                taxPercentage,
                taxAmount,
                gstType: formData?.gstType || "IGST", // IGST, CGST, SGST, etc.
            };
        });

    // Total tax and total amount calculation
    const totalTax = taxDetails.reduce((acc, tax) => acc + tax.taxAmount, 0);
    const totalAmount = subtotal + totalTax;

    
    return (
        <div style={{ marginLeft: '7.5rem' }}>
  <div
    style={{
      marginTop: '1rem',
      border: '1px solid #ddd',
      padding: '0rem',
      borderRadius: '5px',
    }}
  >
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '0rem' }}>
      <tbody>
        {/* Sub Total */}
        <tr>
          <td style={{ textAlign: 'left', padding: '0.5rem', color: 'black' }}>
            <span className="font-14">Sub Total</span>
          </td>
          <td style={{ textAlign: 'right', padding: '0.5rem', color: 'black' }}>
            <span className="font-14">{formatAmount(subtotal)}</span>
          </td>
        </tr>

        {/* Tax Details */}
        {taxDetails.map((tax, index) => {
          if (tax?.gstType === 'CGST_SGST') {
            return (
              <React.Fragment key={index}>
                {/* CGST */}
                <tr>
                  <td style={{ textAlign: 'left', padding: '0.5rem', color: 'gray' }}>
                    <span className="font-14">CGST ({tax.taxPercentage / 2}%)</span>
                  </td>
                  <td style={{ textAlign: 'right', padding: '0.5rem', color: 'gray' }}>
                    <span className="font-14">{formatAmount(tax.taxAmount / 2)}</span>
                  </td>
                </tr>
                {/* SGST */}
                <tr>
                  <td style={{ textAlign: 'left', padding: '0.5rem', color: 'gray' }}>
                    <span className="font-14">SGST ({tax.taxPercentage / 2}%)</span>
                  </td>
                  <td style={{ textAlign: 'right', padding: '0.5rem', color: 'gray' }}>
                    <span className="font-14">{formatAmount(tax.taxAmount / 2)}</span>
                  </td>
                </tr>
              </React.Fragment>
            );
          } else {
            return (
              <tr key={index}>
                <td style={{ textAlign: 'left', padding: '0.5rem', color: 'gray' }}>
                  <span className="font-14">
                    {tax.gstType} ({tax.taxPercentage}%)
                  </span>
                </td>
                <td style={{ textAlign: 'right', padding: '0.5rem', color: 'gray' }}>
                  <span className="font-14">{formatAmount(tax.taxAmount)}</span>
                </td>
              </tr>
            );
          }
        })}

        {/* Total */}
        <tr>
          <td style={{ textAlign: 'left', padding: '0.5rem', color: 'black' }}>
            <span className="font-14">Total</span>
          </td>
          <td style={{ textAlign: 'right', padding: '0.5rem', color: 'black' }}>
            <span className="font-14">{formatAmount(totalAmount)}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    );
};

export default TotalAmountDisplay;
