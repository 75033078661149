import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, patchData, postData, deleteData, fetchsingleData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';


export default function AddLocation() {
    const navigate = useNavigate();
    const { id } = useParams();
    
    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);
    
    const [formData, setFormData] = useState({
        name: '',
        punchin: '',
        punchout: '',
        latitude:'',
        longitude:'',
        status: true
    });
    
    const [errors, setErrors] = useState({});
    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };  

    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck(true)) {
            try {
                if (id) {
                    patchData(`location/${id}`, formData).then(data => {
                        if (data) navigate(-1);
                    })
                    .catch(error => {
                        console.error('Error updating data:', error);
                    });
                } else {
                    postData('location', formData)
                    .then(data => {
                        if (data) navigate(-1);
                    })
                    .catch(error => {
                        console.error('Error posting data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };
    
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.name?.trim()) {
            validationErrors.name = 'This field is required';
        }
        if (!formData?.latitude?.trim()) {
            validationErrors.latitude = 'This field is required';
        }
        if (!formData?.longitude?.trim()) {
            validationErrors.longitude = 'This field is required';
        }
        if (isSubmit || errors.isSubmit)
            validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return (Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0) ? true : false;
    };
    
    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };
    
    
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit)
            validationCheck();
    };

    const handlePunchInChange = (e) => {
        setFormData({ ...formData, punchin: e.target.value });
    };

    const handlePunchOutChange = (e) => {
        setFormData({ ...formData, punchout: e.target.value });
    };

    const handleDelete = async () => {
        try {
            await deleteData(`location/${id}`);
            navigate(-1);
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData("location",id);
            if (response?.data) {
                setFormData(response.data); // Update state with fetched data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const punchInOptions = [
        { label: 'Punch in Compulsory', value: 'Punch in Compulsory' },
      
    ];
    const punchOutOptions =[
        { label: 'Punch out Compulsory', value: 'Punch out Compulsory' }
    ]


    return (
        <div>
            <div className='page-header add-news-alignment'>
                <span>Location {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><Button text={id ? 'Update' : 'Save'} /></div>
                    {id && <div onClick={handleDelete}><Button text='Delete' /></div>}
                    <Link to="/location"><Button text='Discard' outline /></Link>
                </div>
            </div>
            <div>
                <div className='add-news-form-design'>
                    <div className='grid'>
                        <div className='bottom-alignment'style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '20px' }}>
                            <Input
                                label='Name'
                                name='name'
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder='Enter Name..'
                                type='text'
                                required
                                errors={errors.name}
                            />
                            <Input
                                label='latitude'
                                name='latitude'
                                value={formData.latitude}
                                onChange={handleInputChange}
                                placeholder='Enter latitude..'
                                type='text'
                                required
                                errors={errors.latitude}
                            />
                             <Input
                                label='longitude'
                                name='longitude'
                                value={formData.longitude}
                                onChange={handleInputChange}
                                placeholder='Enter longitude..'
                                type='text'
                                required
                                errors={errors.longitude}
                            />
                        </div>
                        <div className='bottom-alignment'style={{ display: 'flex', gap: '80px' }}>
                            <label></label>
                            {punchInOptions.map((option) => (
                                <div key={option.value}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name="punchin"
                                        value={option.value}
                                        checked={formData.punchin === option.value}
                                        onChange={handlePunchInChange}
                                    />
                                    <label htmlFor={option.value}>{option.label}</label>
                                </div>
                            ))}
                        </div>
                        <div className='bottom-alignment'>
                           
                            {punchOutOptions.map((option) => (
                                <div key={option.value}>
                                    <input
                                        type="radio"
                                        id={option.value}
                                        name="punchout"
                                        value={option.value}
                                        checked={formData.punchout === option.value}
                                        onChange={handlePunchOutChange}
                                    />
                                    <label htmlFor={option.value}>{option.label}</label>
                                </div>
                            ))}
                        </div>
                        <div className='bottom-alignment'>
                            <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                        </div>
                        
                       
                    </div>
                </div>
            </div>
        </div>
    );
}
