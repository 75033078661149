import React, { useEffect, useState, useContext } from 'react';
import Button from '../../shared/components/button';
import { deleteData, filterAll } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Userrole from '../userrole';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import Invoicetable from './invoicetable';
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";

import { checkAccess } from '../../services/checkFeature';
export default function Invoice() {
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const[activityList,setActivityList]=useState([]);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`invoice/${id}`)));
      setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };  

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Invoice", "Customer", "Date","Amount", "Status"]
    ];
 
    filterAll("invoice")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.invoiceNo,
      item.customerName,
      item.invoiceDate,
      item.subTotal,
      item.status ? "Active" : "Inactive",
      ]);
      exportToExcel('Invoice',headers,datas,'invoice')
    })
  };


 
  {return (
    <div>
      {/* <div className='page-header'>
        <span>Order</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
        {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        {(<Link to="add"><Button text='Add Order'/></Link>)}
      </div>
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Invoice</h2>
            <Breadcumbs activePath="Invoice" pageName="Invoice" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
            )}
            
            {checkAccess('add_invoice')&&<Link to="add">
              <GlobalButton text="Add Invoice" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_invoice')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} />}
          </div>
        </div>
      </div>
    
    <div style={{height:'600px',overflow:'scroll'}}>
      <Invoicetable
       selectedIds={selectedIds} 
       setSelectedIds={setSelectedIds} 
       activityList={activityList}
       setActivityList={setActivityList}
      
/> </div>
    </div>
  )
}}
