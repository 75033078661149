import React, { useEffect, useState } from 'react';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import GlobalButton from '../../../shared/globalButton';
import SelectBox from '../../../shared/components/select';

import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
export default function AddEditSystemTypeCategory() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        systemTypeName: '',
        categoryName: '',
        categoryId: '',
        status: true,
    });
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [errors, setErrors] = useState({});

    // Fetch category names
    useEffect(() => {
        fetchCategoryName();
    }, []);

    // Fetch data for edit mode
    useEffect(() => {
        if (id) getData();
    }, [id]);

    const fetchCategoryName = async () => {
        try {
            const response = await fetchData("systemcategory");
            if (response?.data) {
                setCategoryOptions(
                    response.data.map((i) => ({
                        label: i.categoryName,
                        value: { categoryId: i.id, categoryName: i.categoryName },
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching category names:', error);
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData("systemtypecategory", id);
            if (response?.data) setFormData(response.data);
        } catch (error) {
            console.error('Error fetching system type category data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                const apiCall = id ? patchData : postData;
                const endpoint = id ? `systemtypecategory/${id}` : 'systemtypecategory';
                const response = await apiCall(endpoint, formData);
                if (response) navigate(-1);
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.categoryName?.trim()) validationErrors.categoryName = 'This field is required';
        if (!formData?.systemTypeName?.trim()) validationErrors.systemTypeName = 'This field is required';
        if (isSubmit) validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return Object.keys(validationErrors).filter((key) => key !== 'isSubmit').length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const toggleSwitch = (checked) => {
        setFormData((prevState) => ({ ...prevState, status: checked }));
    };

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <span>System Category Type {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/systemTypeCategory"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}

            <div className='add-header-design'>
                <div>
                    <h2>Add System Category Type</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add System Category Type" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/systemTypeCategory">
                        <button className='light-button'>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        {id ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>

            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input
                                label={
                                    <span>
                                        System Type Name<span className="required-star">*</span>
                                    </span>}
                                name='systemTypeName'
                                value={formData?.systemTypeName}
                                onChange={handleInputChange}
                                placeholder='Enter Category Name..'
                                type='text'
                                required
                                errors={errors.systemTypeName}
                            />
                            <SelectBox
                                label={
                                    <span>
                                        System Categorie<span className="required-star">*</span>
                                    </span>}
                                name="categoryName"
                                value={categoryOptions.find((i) => i.value.categoryId === formData?.categoryId)}
                                options={categoryOptions}
                                placeholder="Select System Category"
                                onChange={(selectedOption) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        categoryId: selectedOption?.value?.categoryId,
                                        categoryName: selectedOption?.value?.categoryName,
                                    }));
                                }}
                                required
                                errors={errors.categoryName}
                            />
                            <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>



        </div>
    );
}
