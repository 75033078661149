import React, { useState, useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import Pagination from "../../../shared/components/pagination";
import './visitTable.scss';

export default function VisitTable() {
    const [visitList, setVisitList] = useState([]);
    const [filteredVisits, setFilteredVisits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5; // Number of rows per page

    useEffect(() => {
        // Fetch data when the component mounts
        getData();
    }, []);
    const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

    const getData = async () => {
        try {
            const currentDate = getCurrentDate();
            console.log(currentDate);
            const requestBody ={
             date:currentDate     
            }
            const response = await fetchData("visit_tracking",requestBody
             );
            if (response?.data) {
                setVisitList(response.data);
                const filteredData = response.data.filter(
                    (visit) => visit.visit_type === "lead" || visit.visit_type === "primary"
                );
                setFilteredVisits(filteredData);
                console.log(filteredData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB");
    };

    const paginatedVisits = filteredVisits.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    return (
        <div className='visit-table-design'>
            <div className='white-box'>
                <div className='visit-header'>
                    <h2>Visits</h2>
                    <div className='right-content'>
                        <span>Export Report</span>
                    </div>
                </div>
                <div className='table-design-change'>
                    <table>
                        <thead>
                            <tr>
                                <th>Visit ID</th>
                                <th>Salesperson Name</th>
                                <th>Visit Type</th>
                                <th>Party Name</th>
                                <th>Customer Name</th>
                                <th>Address</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedVisits.map((visit, index) => (
                                <tr key={index}>
                                    <td><span>{visit.id}</span></td>
                                    <td>
                                        <div className='profile'>
                                            <div>
                                                <span>{visit.userName}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><span>{visit.visit_type}</span></td>
                                    <td><span>{visit.leadName || 'N/A'}</span></td>
                                    <td><span>{visit.customerName || 'N/A'}</span></td>
                                    <td><span>{visit.address || 'N/A'}</span></td>
                                    <td><span>{formatDate(visit.date) || 'N/A'}</span></td>
                                </tr>
                            ))}
                            {paginatedVisits.length === 0 && (
                                <tr>
                                    <td colSpan="7">No visits available for the selected types.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalItems={filteredVisits.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
    );
}
