import React, { useState, useEffect } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, fetchData, patchData, postData, uploadDocument, filterAll } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import UploadImage from '../../../shared/components/uploadImage';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import store from '../../../redux/reduxstore';
import { notify } from '../../toastr/Toastr';

export default function AddEditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const [isEditable, setIsEditable] = useState(true); // To toggle between edit and view modes
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    hsn_code: '',
    categoryId: '',
    categoryName: '',
    remarks: '',
    price: 0,
    discount: 0,
    // offerDiscount: 0,
    tax: 0,
    unitId: '',
    unitName: '',
    moq: '',
    gstNumber: '',
    location: '',
    mrpPrice: 0,
    productUrl: '',
    description: '',
    stock: 0,
    status: true,
    mrpPriceDetail:0,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (window.location.pathname.includes('/view')) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await fetchData(`systemtype`, { typeCategory: { in: ['unit', 'category'] }, status: true });

      if (response?.data) {
        setCategoryOptions(
          response?.data
            .filter((i) => i?.typeCategory === 'category')
            .map((i) => ({
              label: i?.typeName,
              key: 'category',
              value: { id: i?.id, name: i?.typeName }
            }))
        );
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === 'unit')
            .map((i) => ({
              label: i?.typeName,
              key: 'unit',
              value: { id: i?.id, name: i?.typeName }
            }))
        );
      }
      if (id) {
        const response = await fetchsingleData('product', id);
        if (response?.data) setFormData(response?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (!isEditable) return; // Prevent changes in view mode
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors.isSubmit) validationCheck();
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Product Name is required';
    if (!formData.hsn_code) newErrors.hsn_code = 'HSN Code is required';
    if (!formData.unitId) newErrors.unitId = 'Product Unit is required';
    if (!formData.moq) newErrors.moq = 'MOQ is required';
    if (!formData.location.trim()) newErrors.location = 'Location is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEditable) return; // Prevent form submission in view mode
    if (!validateForm()) return;
    const key=company_setting?.extra_setting?.productUniqueKey==="Name" ?'name':'hsn_code';
    
    try {
      if (id) {
        const payload={
          [key]:formData[key],
          // "id": { $ne: id }
        }
        const isExist=await filterAll('product', payload);
        if (isExist?.data?.length > 1) {
          notify.info(`Product ${company_setting?.extra_setting?.productUniqueKey} cannot be duplicated`);
          return;
        }
        patchData(`product/${id}`, formData).then(data => {
          if(data){
            notify.success('Product updated successfully!');
            navigate(-1);
          }
        })
          .catch(error => {
            notify.error('Error while saving Product');
            console.error('Error fetching data:', error);
          });
      } else {
        const payload={
          [key]:formData[key]
        }
        const isExist=await filterAll('product', payload);
        if(isExist?.data?.length){
          notify.info(`Product ${company_setting?.extra_setting?.productUniqueKey} cannot be duplicated`);
          return;
        }
        postData('product', formData)
          .then((data) => {
            if(data){
            notify.success('Product saved successfully!');
              navigate(-1);
            }
          })
          .catch((error) => {
            notify.error('Error while saving Product');
            console.error('Error fetching data:', error);
          });
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }

  };

  const selectChanged = (e) => {
    if (!isEditable) return; // Prevent changes in view mode
    setFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id
    }));
    if (errors.isSubmit) validationCheck();
  };

  const toggleSwitch = (checked) => {
    if (!isEditable) return; // Prevent toggle in view mode
    setFormData({ ...formData, status: checked });
  };

  const handleFileChange = async (e) => {
    if (!isEditable) return; // Prevent file upload in view mode
    const file = e;
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    try {
      const status = await uploadDocument(`document/uploadfile`, formData);
      if (status?.data?.id) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productUrl: `${status?.data?.id}`
        }));
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!(formData.price > 0)) {
      validationErrors.price = 'Please enter product price';
    }
    if (formData.discount < 0 || formData.discount > 100) {
      validationErrors.discount = 'Discount should be in percentage(%)';
    }
    // if (formData.offerDiscount < 0 || formData.offerDiscount > 100) {
    //   validationErrors.offerDiscount = 'Offer Discount should be in percentage(%)';
    // }
    if (formData.tax < 0 || formData.tax > 100) {
      validationErrors.tax = 'Tax should be in percentage(%)';
    }
    if (!formData.unitId) {
      validationErrors.unit = 'Select Product Unit';
    }
    if (!formData.location.trim()) {
      validationErrors.location = 'Please enter location';
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter((i) => i !== 'isSubmit').length === 0;
  };

  return (
    <div className="page-24">
      <div className="add-header-design">
        <div>
          <h2>Add Product</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add Product" />
        </div>
        {isEditable && (<div className='right-contnet-alignment'>
          <Link to="/product">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Cancel
            </button></Link>
          <button onClick={handleSubmit}>
            Save
          </button>
        </div>)}

      </div>
      <div className="page-main-grid">
        <div>
          <div className="new-white-box-design">
            <h2>Basic Details</h2>
            <div className="three-col-grid">
              <div><Input
                label={
                  <span>
                    Product Name <span className="required-star">*</span>
                  </span>
                } name="name" value={formData.name} onChange={handleInputChange} placeholder="Product Name" type="text" disabled={!isEditable} />{errors.name && <span className="error">{errors.name}</span>}</div>

              <div><Input
                label={
                  <span>
                    Product Hsn code <span className="required-star">*</span>
                  </span>
                } name="hsn_code" value={formData.hsn_code} onChange={handleInputChange} placeholder="Product hsn_code" disabled={!isEditable} />{errors.hsn_code && <span className="error">{errors.hsn_code}</span>}</div>
              <SelectBox label="Category" name="category" value={categoryOptions.find((i) => i.value.id === formData.categoryId)} options={categoryOptions} onChange={selectChanged} placeholder="Category" disabled={!isEditable} />
                { company_setting?.extra_setting?.remarksInProduct ==="Yes" &&(
              <div className='input'>
              <label className='label'>Remark</label>
              <TextArea rows={2} name="remarks" value={formData.remarks} onChange={handleInputChange} placeholder="Enter Remarks" disabled={!isEditable}  />
              </div>
              )}
              <Toggle label="Status" checked={formData.status} onChange={toggleSwitch} disabled={!isEditable} />
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>Price Details</h2>
            <div className="three-col-grid">
              <Input label="Price" name="price" value={formData.price} onChange={handleInputChange} placeholder="Price" type="number" disabled={!isEditable} />
              <Input label="Discount (%)" name="discount" value={formData.discount} onChange={handleInputChange} placeholder="Discount" type="number" disabled={!isEditable} />
              <Input label="Tax (%)" name="tax" value={formData.tax} onChange={handleInputChange} placeholder="Tax" type="number" disabled={!isEditable} />
              { company_setting?.extra_setting?.enableMRP ==="Yes" &&(
              <Input label="MRP" name="mrpPriceDetail" value={formData.mrpPriceDetail} onChange={handleInputChange} placeholder="Product MRP" type="number" disabled={!isEditable} />
              )}
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>Stock Details</h2>
            <div className="three-col-grid">
              <div><SelectBox
                label={
                  <span>
                    Product Unit <span className="required-star">*</span>
                  </span>
                } name="unit" value={unitOptions.find((i) => i.value.id === formData.unitId)} options={unitOptions} onChange={selectChanged} placeholder="Product Unit" disabled={!isEditable} />{errors.unit && <span className="error">{errors.unit}</span>}</div>
              <Input label="Stock" name="stock" value={formData.stock} onChange={handleInputChange} placeholder="Stock" type="number" disabled={!isEditable} />
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>Product Image</h2>
            <div className="upload-img">
              <UploadImage action={(e) => handleFileChange(e)} value={formData.productUrl} disabled={!isEditable}></UploadImage>
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>Other Details</h2>
            <div className="three-col-grid">
              <div><Input
                label={
                  <span>
                    MOQ <span className="required-star">*</span>
                  </span>
                } name="moq" value={formData.moq} onChange={handleInputChange} placeholder="MOQ" disabled={!isEditable} />{errors.moq && <span className="error">{errors.moq}</span>}</div>

              <Input label="GST Number" name="gstNumber" value={formData.gstNumber} onChange={handleInputChange} placeholder="GST Number" type="text" disabled={!isEditable} />
              <Input label="MRP" name="mrpPrice" value={formData.mrpPrice} onChange={handleInputChange} placeholder="MRP Price" type="text" disabled={!isEditable} />
              <div><Input
                label={
                  <span>
                    Location <span className="required-star">*</span>
                  </span>
                } name="location" value={formData.location} onChange={handleInputChange} placeholder="Location" type="text" disabled={!isEditable} />{errors.location && <span className="error">{errors.location}</span>}</div>
            </div>
          </div>
        </div>
        <div>
          <StatusBox />
        </div>
      </div>
    </div>
  );
}
