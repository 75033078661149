import React from 'react'
import './searchbar.scss';
import SearchIcon from '../../../../assets/icons/search.svg';
export default function Searchbar() {
  return (
    <div className='searchbar-design'>
      <input type='text' placeholder='Search'/>
      <div className='icon-alignment'>
        <img src={SearchIcon} alt="SearchIcon"/>
      </div>
    </div>
  )
}
