import {useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import { fetchData } from '../../../services/apiService';

import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';

export default function StatetypeTable({selectedIds, setSelectedIds,SetStateList,StateList, setProductList, setTotalItems, totalItems}) {
  // const [StateList, SetStateList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [StateList, setProductList] = useState([]);

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [status, setStatus] = useState(undefined);
  const [stateShortname, setStateShortName] = useState("");
 
  const pageSize = 10; // Items per page


    useEffect(() => {
        // Fetch data when page or pageSize changes
        getData(currentPage, pageSize);
      }, [currentPage]);

    const getData = async (page, pageSize) => {
        try {
          const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
            const response = await fetchData('state',requestBody, pagination);
            if (response?.data) {
              setProductList(response.data || []);  // API returns current page data in 'items'
              setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    };

    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(StateList.map((i) => i.id));
    } else {
      setSelectedIds([]);
    }
  };

  const filteredActivityList = StateList.filter((item) => {
    const searchCountryName = item.countryName?.toLowerCase().includes(countryName?.toLowerCase());
    const searchStateName = item.stateName?.toLowerCase().includes(stateName?.toLowerCase());
    const searchStateShortName = item.stateShortname?.toLowerCase().includes(stateShortname?.toLowerCase());
    const searchStatus = status === undefined || item.status === status;

   
    return (
      (!stateName || searchStateName) &&
      (!countryName || searchCountryName) &&
      (!stateShortname || searchStateShortName) &&
      searchStatus
   
    );
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = StateList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
            <thead>
            <tr>
              <th><div></div></th>
              
              <th><div></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Country Name"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              <th><div><input
                type="text"
                placeholder="State Name"
                value={stateName}
                onChange={(e) => setStateName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

               <th><div><input
                type="text"
                placeholder="State Short Name"
                value={stateShortname}
                onChange={(e) => setStateShortName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

                <th>
                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatus(selectedStatus); 
                    }}
                    className="w-100 border-0"
                  >
                    <option value="">All</option>
                    <option value="true">Active</option> 
                    <option value="false">Inactive</option>
                  </select>
                </div>
                </th>


            </tr>
                <tr>
                    {/* <th>
                        <input type='checkbox'/>
                    </th> */}
                     <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === StateList.length} /></th>
                    <th align='center'>Action</th>
                    <th>Country Name</th>
                    <th>State Name</th>
                    <th>State Short Name</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
               {
                 (filteredActivityList ?? []).map((i)=> {
                    return(
                        <tr key={i.stateId }>
                        {/* <td> <input type='checkbox'/></td> */}
                        <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                        <td><Link to={{ pathname: `addstatetype/${i?.id}`}}> 
                        <EditIcon/>
                        </Link></td>
                        {/* <td>
                          <span className='price-group-text'>{i.name}</span>
                        </td> */}
                        <td>{i?.countryName}</td>
                        <td>{i?.stateName}</td>
                        <td>{i?.stateShortname }</td>
                        
                        <td>
                            <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                        </td>
                    </tr>
                    )
                })
               }
            </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  )
}
