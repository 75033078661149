import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Button from '../../../shared/components/button';
import ShowImage from '../../../shared/components/showImage';
// import { Tab, TabList, TabPanel } from "react-tabs";
// import { Box,TabContext,TabList,TabPanel } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GlobalButton from '../../../shared/globalButton';
import { DatePicker, Form, InputNumber, Modal } from 'antd';
import Input from '../../../shared/components/input';
// import PlusIcon from "../../assets/icons/plus.svg";
import RightIcon from '../../../assets/icons/right-sm.svg';
import { Span } from '@elastic/apm-rum';





export default function AddEditviewCustomerTable({ StateList }) {
  const [value, setValue] = useState(0);

  const [targets, setTargets] = useState([]);
  const [dispatchorders, setDispatchorders] = useState([]);
  const [paymentcollections, setPaymentCollections] = useState([]);
  const [invoices, setInvoices] = useState([]);

console.log('paymentcollections',paymentcollections);

  const [tab, setTab] = useState('details')
  const [targetBeingEdited, setTargetBeingEdited] = useState();
  const [timelines, setTimelines] = useState([]);

  const [formSaving, setFormSaving] = useState(false);

  // const formSavingRef = useRef(0);

  // useEffect(() => {
  //   formSavingRef.current = formSaving
  // }, [formSaving])


  const [form] = Form.useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const [leadstatus, setLeadStatus] = useState([]);
  const [userName, setUserName] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const user = useSelector(state => state.user.user);
  const name = useSelector((state) => state.auth.name);



  const [formData, setFormData] = useState({
    name: '',
    leadsource: '',
    leadindustry: '',
    pricegroup: '',
    address: '',
    title: '',
    city: '',
    area: '',
    pincode: '',
    country: '',
    state: '',
    latitude: '',
    longtitude: '',
    email: '',
    contactno: '',
    ownername: '',
    partnername: '',
    ed: '',
    ewc: '',
    turnover: '',
    leadstatus: '',
    userName: '',
    leadnotification: [],

  });

  // useEffect(()=>{
  //     setValue(0)
  // },[])
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };


  useEffect(()=>{

    const getData = async() =>{
        const requestBody = {
            "reference_type": "customer",
            reference_id : id
        }
         
        const responsetimeline = await fetchData("timeline",requestBody);
console.log(responsetimeline);
        if (responsetimeline?.data) {
            setTimelines(responsetimeline.data);
          } 
    }

    getData()
  
},[newNotification])
  // const getData = async () => {
  //   try {
  //     // const responsee = await fetchData(`customer`);
  //     // if (responsee?.data) {
  //     //     const formattedleadstatus = responsee.data.map(i => ({
  //     //         label: i?.name,
  //     //         key: 'leadstatus',
  //     //         value: { name: i?.name }
  //     //     }));
  //     //     setLeadStatus(formattedleadstatus);
  //     // }


  //     const response = await fetchsingleData("customer", id);
  //     const responseTarget = await fetchData("target", {
  //       customerId: id
  //     });
  //     if (responseTarget?.data) setTargets(responseTarget.data);

  //     if (response?.data) setFormData(response.data);
  //     if (response?.leadnotification) setNewNotification(response.data.leadnotification)
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // const handleSelectChange = async (selectedValue) => {


  //     try {
  //         const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
  //         // const newNotification = { date: currentDateTime, status: selectedValue };
  //         // setNewNotification(newNotification =>({
  //         //     ...newNotification,
  //         //     date: currentDateTime, status: selectedValue

  //         // }))


  //         const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };

  //         setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
  //         // const updatedLeadNotification = [...formData.leadnotification, newNotification];

  //         if (id) {
  //             // const updatedData = { ...formData, leadstatus: selectedValue, collabrator:selectedValue, leadOwner:selectedValue, leadnotification: [...newNotification, updatedNotification] };
  //             // const data = await patchData(`lead/${id}`, updatedData);
  //             // if (data) {
  //             //     setFormData(updatedData);
  //             // }

  //             let tempForm = { ...formData }
  //             tempForm["leadnotification"] = [...newNotification, updatedNotification]
  //             // setFormData(tempForm)
  //         }
  //     } catch (error) {
  //         console.error('Error updating lead status:', error);
  //     }
  // };

  const getData = async () => {
    try {
      // Fetching target data
      const response = await fetchsingleData("customer", id);

      const responseTarget = await fetchData("target", { customerId: id });
      if (responseTarget?.data) setTargets(responseTarget.data);

      // Fetching dispatch orders
      const responseDispatch = await fetchData("dispatchorder");
      const responseInvoice = await fetchData("invoice");
      const responsePaymentCollection = await fetchData("paymentcollection");
      if (responsePaymentCollection?.data) {
        setPaymentCollections(responsePaymentCollection.data);
      } else
      if (responseInvoice?.data) {
        setInvoices(responseInvoice.data);
      } else
      if (responsePaymentCollection?.data) {
        setPaymentCollections(responsePaymentCollection.data);
      } else
      if (responseDispatch?.data) {
        setDispatchorders(responseDispatch.data);
      } else {
        console.error("No data found for dispatch orders.");
      }if (response?.data) setFormData(response.data);
      if (response?.leadnotification) setNewNotification(response.data.leadnotification)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function CustomTabPanel({ children, value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }
  const checkForm = async () => {
    try {
      const data = await form.validateFields(); // Validate and get form data
      setFormSaving(true);

      const payload = {
        ...data,
        fromMonth: data.fromMonth.format("YYYY-MM"),
        toMonth: data.toMonth.format("YYYY-MM"),
        customerId: id,
      };

      if (targetBeingEdited) {
        // Update existing target
        const response = await patchData(`target/${targetBeingEdited}`, payload);
        if (response?.data) {
          setTargets((prev) =>
            prev.map((item) =>
              item.id === targetBeingEdited ? response.data : item
            )
          );
        }
      } else {
        // Add new target
        const response = await postData("target", payload);
        if (response?.data) {
          setTargets((prev) => [...prev, response.data]);
        }
      }

      // Reset form and close modal
      form.resetFields();
      setTargetBeingEdited(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Form submission error:", error);
    } finally {
      setFormSaving(false); // Always reset saving state
    }
  };


  const editTargetClicked = (k, targetId) => {
    k.preventDefault();


    setTargetBeingEdited(targetId)

    const target = targets.find(e => e.id === targetId)

    form.setFieldsValue({
      ...target,
      toMonth: dayjs(target.toMonth),
      fromMonth: dayjs(target.fromMonth)
    });

    setIsModalOpen(true);

  }



  return (
    <div className='page-24'>
      <div className='breadcumbs-alignment pb-3'>
        <span className='active-class'>Customer</span>
        <img src={RightIcon} alt="RightIcon" />
        <span>{formData.cName}</span>
      </div>
      <div className='tab-design mb-4'>
        <button className={tab === 'details' ? 'active' : ''} onClick={() => setTab('details')}>
          Details
        </button>
        <button className={tab === 'target' ? 'active' : ''} onClick={() => setTab('target')}>
          Target
        </button>
        <button className={tab === 'transactions' ? 'active' : ''} onClick={() => setTab('transactions')}>
          Transactions
        </button>
      </div>
      {
        tab === 'details' && (
          <>
            <div className='details-tab-grid'>
              <div className='details-tab-grid-items'>
                <div className='details-white-box-design'>
                  <div className='first-box'>
                    <h2>
                      {formData.cName}
                    </h2>
                    <a href={`mailto:${formData.addressContact}`} style={{ color: 'purple', paddingLeft: '1px' }}> ✆ {formData.addressContact}</a>
                    <a> 🏠{formData.address},{formData.state},{formData.pincode},{formData.country}</a>
                    <p>
                      Longitude{formData.longitude} ,
                      Latitude{formData.latitude}
                    </p>
                    <div className='three-col-grid'>
                      <div>
                        <h3>Price Group</h3>
                        <p>{formData.priceGroup}</p>
                      </div>
                      <div>
                        <h3>
                          Outstanding
                        </h3>
                        <p>
                          {formData.outstandingAmount
                          }
                        </p>
                      </div>
                      <div>
                        <h3>
                          Overdue Bill Amount
                        </h3>
                        <p>
                          {formData.overdueBillAmount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='sec-box'>
                    <h2>
                      Login Details
                    </h2>
                    <div className='text-box'>
                      <p>
                        Email
                      </p>
                      <span>
                        {formData.addressEmail}
                      </span>
                    </div>
                  </div>
                  <div className='sec-box'>
                    <h2>
                    GST Details
                    </h2>
                    <div className='text-box'>
                      <p>
                      overseas
                      </p>
                      <span>
                      {formData.ed}-
                      </span>
                    </div>
                  </div>
                  <div className='sec-box'>
                    <h2>
                    Other Details
                    </h2>
                    <div className='text-box'>
                      <p>
                      Image
                      </p>
                      <span>
                      Not uploaded.
                      </span>
                    </div>
                  </div>
                  <div className='three-box'>
                    <h2>
                    Customer Devices
                    </h2>
                    <div className='new-table-design-change'>
                    <table >
                      <thead>
                        <tr>
                          <th>  Device Name</th>
                          <th>Device ID</th>
                          <th>Application Version</th>
                          <th>Database Version</th>
                          <th>Last Active Session</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center ng-scope" colspan="7">
                            <span>Account yet not registered to any device.</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='details-tab-grid-items'>
                <div className='details-header'>
                  <h2>
                    Timeline
                  </h2>
                  <span>Add</span>
                </div>
                <div className='details-all-card-alignment'>
                  {/* {
                    [...Array(15)].map(() => {
                      return (
                        <div className='card-grid'>
                          <div className='icons'></div>
                          <div>
                            <p>
                              Order #9951 updated.
                            </p>
                            <span>
                              by PUNABHAI Patel on 26-11-2024 05:29 PM |   #9951
                            </span>
                          </div>
                        </div>
                      )
                    })
                  } */}
                    {timelines?.slice().reverse().map((i, index) => (
                <div
                    key={i.id || index}
                    style={{
                        display: "flex",
                        paddingLeft: "3px",
                        paddingBottom: "15px",
                    }}
                >
                    <div style={{ paddingRight: "5px" }}>➨</div>
                    <div style={{ fontSize: "14px" }}>
                    <strong>{i.message}</strong>   On<strong>{dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎hh:mm:ss A')}  </strong>by
          <strong>{i.user_name}</strong>.
                    </div>
                </div>
            ))}
            </div>
                </div>
              </div>



          </>
        )
      }
      {
        tab === 'target' && (
          <>
            <div className='target-btn-right'>
              <GlobalButton text="Add Target" onClick={showModal} />
            </div>

            <div className='new-table-design-change'>
              <div className=''>
                <table>
                  <thead>
                    <tr>
                      {/* <th>
                        <input type='checkbox'/>
                    </th> */}
                      <th><input type='checkbox' /></th>
                      <th align='center'>Action</th>
                      <th>Period</th>
                      <th>Target Type</th>
                      <th>Value</th>
                      {/* <th>Status</th> */}
                    </tr>
                  </thead>
                  <tbody>

                    {targets.map((e) => {
                      return (<tr>
                        <td> <input type='checkbox' /></td>
                        <td> <i className="fa-solid fa-pen-to-square" onClick={(k) => editTargetClicked(k, e.id)}></i></td>
                        <td>{e.period}</td>
                        <td>{e.type}</td>
                        <td>{e.totalTarget}</td>
                      </tr>)
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )
      }
      {
        tab === 'transactions' && (
          <div className="">
            <div className='new-white-box-design'>
            <div className="new-table-design-change">
              <table className="">
                <thead>
                  <tr>
                    <th>Order #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="#">9917</a></td>
                    <td>24-11-2024 12:23 PM</td>
                    <td>pending</td>
                    <td>PUNABHAI</td>
                    <td>8856.00</td>
                    <td>8856.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>

            {/* Dispatches Section */}
            
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Dispatches</h3>
              <div className='new-table-design-change'>
              <table className="">
                <thead>
                  <tr>
                    <th>Dispatch #</th>
                    <th>Order #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                
                  {dispatchorders.map((e) => {
                      return (<tr>
                        <td>{e.dispatchNo}</td>
                        <td>{e.orderNo}</td>
                        <td>{e.date}</td>
                        <td>{e.orderStatus}</td>
                        <td>{e.created_by}</td>
                        <td>{e.subTotal}</td>
                        <td>{e.total}</td>
                  </tr>)
                    })}
                </tbody>
              </table>
              </div>
            </div>

            {/* Invoices Section */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Invoices</h3>
              <div className='new-table-design-change'>
              <table>
                <thead>
                  <tr>
                    <th>Invoice #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                {invoices.map((e) => {
                      return (<tr>
                        <td>{e.invoiceNo}</td>
                        <td>{e.invoiceDate}</td>
                        <td>{e.status}</td>
                        <td>{e.created_by}</td>
                        <td>{e.subAmount}</td>
                        <td>{e.totalAmount}</td>
                  </tr>)
                    })}
                </tbody>
              </table>
              </div>
            </div>

            {/* Payment Collections Section */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Payment Collections</h3>
              <div className='new-table-design-change'>
              <table>
                <thead>
                  <tr>
                    <th>Payment #</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                {paymentcollections.map((e) => {
                      return (<tr>
                        <td>{e.paymentno}</td>
                        <td>{e.date}</td>
                        <td>{e.paymenttypeName}</td>
                        <td>{e.status}</td>
                        <td>{e.created_by}</td>
                        <td>{e.amount}</td>
                  </tr>)
                    })}
                </tbody>
              </table>
              </div>
            </div>

            {/* Deals Section */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Deals</h3>
              <div className='new-table-design-change'>
              <table>
                <thead>
                  <tr>
                    <th>Deal #</th>
                    <th>Date</th>
                    <th>Deal Pipeline</th>
                    <th>Deal Stage</th>
                    <th>Collaborators</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="7" align='center'>
                      <span>No deals found...</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>

            {/* Sale Quotations */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Sale Quotations</h3>
              <div className='new-table-design-change'>
              <table >
                <thead>
                  <tr>
                    <th>Quotation #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="7" align='center'>
                      <span>No quotations found...</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>

            {/* Estimates */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Estimates</h3>
              <div className='new-table-design-change'>
              <table>
                <thead>
                  <tr>
                    <th>Estimate #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="7" align='center'>
                      <span>No estimates found...</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>

            {/* Sales Returns */}
            <div className="new-white-box-design">
              <h3 className='transactions-title'>Sales Return</h3>
              <div className='new-table-design-change'>
              <table>
                <thead>
                  <tr>
                    <th>Estimate #</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Created By</th>
                    <th>Sub Amount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="7" align='center'>
                      <span>No sales returns found...</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
          </div>
        )
      }
      <CustomTabPanel value={value} index={2} />
      <Modal
        title="Target"
        okText="Save"
        confirmLoading={formSaving}
        // open={isModalOpen}
        open={isModalOpen}
        onOk={() => checkForm()}
        onCancel={() => setIsModalOpen(false)}
      // onOk={(e) => checkForm(e)}
      // onCancel={(e) => handleCancel(e)}
      >
        <Form
          layout="vertical"
          form={form}
          // onFinish={checkForm}
          // onFinish={checkForm}
          autoComplete="off"
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
            {/* Row 1: Type and Period */}
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Please enter the type!' }]}
              >
                <Input placeholder="Enter Type" />
              </Form.Item>

              <Form.Item
                name="period"
                label="Period"
                rules={[{ required: true, message: 'Please enter the period!' }]}
              >
                <Input placeholder="Enter Period" />
              </Form.Item>
            </div>

            {/* Row 2: Date Pickers */}
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
              <Form.Item
                name="fromMonth"
                label="From Month"
                rules={[{ required: true, message: 'Please select the starting month!' }]}
              >
                <DatePicker style={{ width: '100%' }} picker="month" />
              </Form.Item>

              <Form.Item
                name="toMonth"
                label="To Month"
                rules={[{ required: true, message: 'Please select the ending month!' }]}
              >
                <DatePicker style={{ width: '100%' }} picker="month" />
              </Form.Item>
            </div>

            {/* Row 3: Total Target */}
            <Form.Item
              name="totalTarget"
              label="Total Target Value"
              rules={[{ required: true, message: 'Please enter the total target value!' }]}
            >
              <InputNumber style={{ width: '100%' }} placeholder="Enter Total Target" />
            </Form.Item>
          </div>
        </Form>

      </Modal>
    </div>
  );
}
