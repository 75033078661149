import {useState} from 'react'
import './leadstatus.scss';
import { deleteData ,filterAll} from '../../services/apiService';
import Button from '../../shared/components/button';
import Leadstatustable from './leadstatustable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';

export default function Leadstatus() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [leadstatusList, setleadstatusList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`leadStatus/${id}`)));
      setleadstatusList(leadstatusList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const handleExport = () => {
    const headers = [
      ["Name","Status"]

    ];
 
    filterAll("leadStatus")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('Lead Status',headers,datas,'leadStatus')
    })
  };
  return (
    <div className='page-24'>
     {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Leadstatus</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        <Link to="addEditleasStatus"><Button text="Add Lead"/></Link>
      </div></div> */}
      <div className="">
        <div className="pb-3 customer-header-alignment">
          <div>
            <h2>Leadstatus</h2>
            <Breadcumbs activePath="Leadstatus" pageName="Leadstatus" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {checkAccess('manage_lead_status')&&<Link to="addEditleasStatus">
              <GlobalButton text="Add Leadstatus" Icon={PlusIcon} />
            </Link>}
            {checkAccess('manage_lead_status')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>
      <Leadstatustable
       selectedIds={selectedIds}
       setSelectedIds={setSelectedIds}
       leadstatusList={leadstatusList}
       setProductList={setleadstatusList}
       totalItems={totalItems}
       setTotalItems={setTotalItems}/>
    </div>
  )
}
