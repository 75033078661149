import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input, DatePicker } from "antd";
import moment from 'moment';
import dayjs from "dayjs";
import "./createquote.scss"
import GlobalButton from "../../../shared/globalButton";
import { checkAccess } from "../../../services/checkFeature";
import store from "../../../redux/reduxstore";

const { TextArea } = Input;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#f1f1f1',
    minHeight: '40px',
    height: '40px',
    fontSize: '14px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '0 6px',
    fontSize: '14px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),
};
export default function Createquote() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const isEditMode = window.location.pathname.includes('/add') || false;
  const name = useSelector(state => state.auth.name);
  useEffect(() => {
    // Fetch data when the component mounts
    getData();
    getCustomer();
    fetchModule()

  }, []);
  const [productOptions, setProductOptions] = useState([]);
  const [customers, setCustomerOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const [newUpdate, setNewUpdate] = useState([]);
  const [usermoduleOptions, setModuleOptions] = useState([]);
  const [linkdropDown, setlinkdropDown] = useState([]);
  const [totalamount, setTotalAmount] = useState(0);
  const [formData, setFormData] = useState({
    quotationDate: dayjs(),
    // customerName: "",
    // customerId: "",
    customeraddress: "",
    customercontact: "",
    quotationfor: "",
    status: true,
    quotationstatus: 'Pending',
    orderproducts: [],
    // quotationnotification: [],
    totalamount: 0,
  });

  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.id,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      const response = await fetchData(`systemtype`, {
        typeCategory: { in: ["unit"] },
        status: true,
      });
      if (response?.data) {
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === "unit")
            .map((i) => {
              return {
                label: i?.typeName,
                key: "unit",
                value: i?.id
              };
            })
        );
      }
      const responseProducts = await fetchData(`product`);
      if (responseProducts?.data) {
        setProductOptions(
          responseProducts?.data?.map((i) => {
            return {
              ...i,
              label: `${i?.name}`,
              key: "name",
              value: i?.id,
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("quotation", id);
        if (response?.data)
          setFormData(response?.data); // Update state with fetched data
        getdowpDownlist(response?.data?.quotationfor?.toLowerCase())
        setProductsForOrder([...response?.data?.orderproducts]);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchModule = async () => {
    try {
      const response = await fetchData('module');
      if (response?.data) {
        setModuleOptions(
          response.data
            .filter(module =>
              module?.modulename === 'Lead' || module?.modulename === 'Customer'
            )
            .map(module => ({
              ...module,
              label: module?.modulename,
              value: module?.modulename,
            }))
        );
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  const getdowpDownlist = async (type) => {
    try {
      const response = await fetchData(type);
      if (response?.data) {
        const modules = await fetchData('module');
        const keyName = (modules?.data ?? []).find(f => f?.modulename?.toLowerCase() === type)?.moduleKey;
        setlinkdropDown(
          (response?.data ?? []).map((module) => ({
            ...module,
            label: module?.[keyName],
            value: module?.id,
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching ' + type, error);
    }
  };
  const handleSelectChange = (event, type) => {
    if (type === 'quotationfor') {
      setFormData({
        ...formData,
        [type]: event.value
      });
      getdowpDownlist(event?.value?.toLowerCase())
    }
    else {
      let formPayload = {
        ...formData,
        [type]: event.value
      }
      if (type === 'link') {
        formPayload = { ...formPayload, customeraddress: linkdropDown?.find(d => d?.value === event?.value)?.address, linkname: linkdropDown?.find(d => d?.value === event?.value)?.label }
      }
      setFormData(formPayload);
    }
  };
  //   const getTransportData = async () => {
  //     try {
  //       if (id) {
  //         const response = await fetchData(`trasport`, {
  //           status: true,
  //         });
  //         if (response?.data) {
  //           setTransportOptions(
  //             response?.data?.map((i) => {
  //               return {
  //                 label: i?.name,
  //                 key: "name",
  //                 value: { id: i?.trasportId, name: i?.name },
  //               };
  //             })
  //           );
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };


  const handleSubmit = (e) => {
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    e.preventDefault();
    if (validationCheck() && productsForOrder?.length > 0) {
      try {
        if (id) {
          patchData(`quotation/${id}`, { ...formData, orderproducts: [...productsForOrder] })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          postData("quotation", { ...formData, orderproducts: [...productsForOrder] })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };


  const handleDateChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      quotationDate: new Date(e)
    }));
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.quotationfor.trim()) {
      validationErrors.quotationfor = "Please select quotation for";
    }
    if (!formData.quotationDate) {
      validationErrors.quotationDate = "Select Order Date";
    }
    if (!formData.link) {
      validationErrors.link = "Please select Lead/Customer";
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter((i) => i !== "isSubmit")
      .length === 0
      ? true
      : false;
  };

  const [productsForOrder, setProductsForOrder] = useState([]);
  const AddProduct = () => {
    const orderproducts = [...productsForOrder];
    orderproducts.push({
      index: orderproducts.length + 1,
      remark:"",
      name: "",
      productId: "",
      unitId: "",
      unitName: "",
      qty: 1,
      unitPrice: 0,
      discount: 0,
      tax: 0,
      subTotal: 0,
      productUrl: '',
    });
    setProductsForOrder([...orderproducts]);


  };

  const productSelectChange = (index, event, type) => {
    if (type === 'p') {
      const product_data = productOptions.find(d => d?.value == event?.value);
      const orderproducts = [...productsForOrder];
      orderproducts[index]['productId'] = product_data?.id;
      orderproducts[index]['name'] = product_data?.name;
      orderproducts[index]['unitName'] = product_data?.unitName;
      orderproducts[index]['unitId'] = product_data?.unitId;
      orderproducts[index]['qty'] = Number(product_data?.moq);
      orderproducts[index]['unitPrice'] = Number(Number(parseFloat(product_data?.price)).toFixed(2));
      orderproducts[index]['tax'] = Number(product_data?.tax);
      orderproducts[index]['discount'] = Number(parseFloat(product_data?.discount));
      orderproducts[index]['subTotal'] = (Number(Number(product_data?.price) * Number(product_data?.moq).toFixed(2)));
      orderproducts[index]['productUrl'] = product_data?.productUrl;
      setProductsForOrder([...orderproducts]);
      calculateTotal(orderproducts);

    }
  }

  const handleInputChangeProduct = (e, index) => {
    const { name, value, checked, type } = e.target;
    const orderproducts = [...productsForOrder];
    if (name === 'qty') {
      orderproducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === 'unitPrice') {
      orderproducts[index][name] = Number(parseFloat(value).toFixed(2));;
    }
    if (name === 'discount') {
      orderproducts[index][name] = value;
    }
    if (name === 'tax') {
      orderproducts[index][name] = value;
    }
    if (name === 'remark') {
      orderproducts[index][name] = value;
    }

    const price = Number(orderproducts[index]['unitPrice']) * Number(orderproducts[index]['qty']) || 0;
    const discount = (price * Number(orderproducts[index]['discount']) / 100) || 0;
    const tax = ((price - discount) * Number(orderproducts[index]['tax']) / 100) || 0;
    orderproducts[index]['subTotal'] = Number(parseFloat(((price - discount) + tax)).toFixed(2));

    setProductsForOrder([...orderproducts]);
    calculateTotal(orderproducts);

  };
  const calculateTotal = (orderproducts) => {
    const total = orderproducts.reduce((acc, orderproducts) => acc + (orderproducts.subTotal || 0), 0);
    setFormData({ ...formData, totalamount: total.toFixed(2) });
    console.log(formData.totalamount);
  }
  const handleRemoveProduct = (indexToRemove, productSubTotal) => {
    const orderproducts = [...productsForOrder];
    orderproducts.splice(indexToRemove, 1);
    setProductsForOrder(orderproducts);
    calculateTotal(orderproducts);
  };
  const handleAddProductClick = () => {
    if (formData.quotationfor) {
      AddProduct();
    }
  };

  return (
    <div className="add-order-page-design-change">
      {isEditMode && (
        <div className="page-header add-news-alignment">
          <span>Quotation {`>`} Create</span>
          <div className="two-button-alignment">
            <div onClick={handleSubmit}>
              <GlobalButton text="Save" />
            </div>
            <Link to="/Quotation">
              <GlobalButton text="Discard" outline />
            </Link>
          </div>
        </div>
      )}
      <div>
        <div className='add-news-form-design' >


          <div className="row mt-3">
            <div className="col-md-6 bottom-alignment select-change-design-change">
              <SelectBox

                label={
                  <span>
                    Quotation For <span className="required-star">*</span>
                  </span>

                }
                name="quotationfor"
                placeholder="Select Quotation For"
                options={usermoduleOptions}
                value={usermoduleOptions?.find(i => i?.value === formData.quotationfor)}
                onChange={(e) => handleSelectChange(e, 'quotationfor')}
                style={{ width: '100%' }}
                errors={errors.quotationfor}
              />
            </div>
            <div className="col-md-6 bottom-alignment select-change-design-change">
              <label className="mb-0" >
                {formData?.quotationfor ? formData?.quotationfor : 'Lead/Customer'}
                <span className="required-star">*</span>
              </label>
              <SelectBox
                name="link"
                placeholder={(formData?.quotationfor ? formData?.quotationfor : 'Lead/Customer')}
                options={linkdropDown}
                value={linkdropDown?.find(i => i?.value === formData.link)}
                onChange={(e) => handleSelectChange(e, 'link')}
                style={{ width: '100%' }}
                errors={errors.link}
              />
            </div>


          </div>
          <div className='row mt-3' >
            {formData?.customeraddress && (<div className="col-md-6">
              <div>
                <label className="quotationDate">Billing Details</label>
                <Input
                  name="customeraddress"
                  value={`${formData.customeraddress}\n${formData.customercontact}`}
                  disabled
                />
              </div>
            </div>)}
            <div className="col-md-6">

              <label className='quotationDate mb-1'> Date<span className="required-star">*</span>
              </label>
              <DatePicker
                name="quotationDate"
                placeholder='Select Date'
                value={dayjs(formData.quotationDate)}
                onChange={handleDateChange}
                showTime
                dateFormat="yyyy-MM-dd HH:mm:ss"
                errors={errors.quotationDate}
              />
            </div>



          </div>

        </div>

        {(
          <div className="add-news-form-design">
            <div className="product-details-header-alignment">
              <h2 className="mb-3">Product Details</h2>

            </div>
            <div className="order-table" style={{
              zIndex: -1,
              position: '',
              top: 'auto',
              //bottom: '100%',
            }}>
              <div className="table-design"
                style={{ overflow: "unset" }}
              >
                <table >
                  <thead>
                    <tr style={{ border: '1px solid #eee' }}>
                      <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                      <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                      <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                      <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                      <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsForOrder.map((i, INDEX) => {
                      return (
                        <tr key={i?.index}>
                          <td className="width-auto">
                            <SelectBox

                              // label="Product"
                              name="name"
                              value={productOptions.find(
                                (option) => option.value === i.productId
                              )}
                              options={productOptions}
                              onChange={(e) =>
                                productSelectChange(INDEX, e, "p")
                              }
                              placeholder="Product Name"
                              type="text"

                              styles={{
                                overflow: "initial",
                                ...customStyles,
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 99999,
                                  position: 'absolute'



                                }),
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%'



                                })
                              }}
                            />
                            {company_setting?.extra_setting?.remarksInSaleQuotation ==="Yes" && (
                             <TextArea  name='remark' value={i?.remark} onChange={(e) =>
                             handleInputChangeProduct(e, INDEX)} placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>
                             )}
                          </td>
                          <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                            <SelectBox
                              // label="Unit"
                              name="unit"
                              isDisabled={true}
                              value={unitOptions.find(
                                (option) => option.value === i.unitId
                              )}
                              options={unitOptions}
                              onChange={(e) =>
                                productSelectChange(INDEX, e, "u")
                              }
                              placeholder="Unit Name"
                              type="text"

                            />
                          </td>
                          <td className="whitesapce-nowrap width-auto">
                            <InputCustom
                              name="qty"
                              value={i?.qty}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="unitPrice"
                              value={i?.unitPrice}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                              disabled={!checkAccess('edit_sale_quotation_price')}
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="discount"
                              value={i?.discount}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                              disabled={!checkAccess('edit_sale_quotation_discount')}
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="tax"
                              value={i?.tax}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="subTotal"
                              value={i?.subTotal}
                              readOnly={true}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <th className="width-auto">
                            <div
                              onClick={() => {
                                const productToRemove = productsForOrder[INDEX]; // Assuming INDEX is the current index of the product
                                handleRemoveProduct(INDEX, productToRemove.subTotal);
                              }}
                              style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                            >
                              <i className="fas fa-trash-alt" ></i>
                            </div>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ paddingTop: '15px' }}>  {isEditMode && (
                <div onClick={handleAddProductClick}>
                  <GlobalButton text="Add Product" />
                </div>
              )}</div>
              <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
                marginLeft: '70%',
                maxWidth: '30%',
                background: '#f5f5f5',
                border: '2px #eee'
              }}>
                <tbody>

                  <tr></tr>
                  <tr>
                    <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                    <td></td>
                    <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.totalamount}</b></td>
                  </tr>
                </tbody>
              </table></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
