import React, { useState, useEffect, useContext } from "react";
import "./dealtable.scss";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { UserContext } from "../../../contexts/UserContext";
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
export default function Dealtable({ selectedIds, setSelectedIds,totalItems,setTotalItems,activityList, setActivityList }) {
  const [ProductList, setDealList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dealList, setProductList] = useState([]);
  const [DealnoTerm, setdealNoTerm] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
const [OwnerTerm, setOwnerTerm] = useState('');
const [DealTerm, setdealTerm] = useState('');
const [Collaborators, setCollaborators] = useState('');
const [Dealstage, setDealstageTerm] = useState('');
const [AmountTerm, setAmountTerm] = useState('');
const [Status, setStatusTerm] = useState('');
const [Lostreason, setLostreasonTerm] = useState('');

  const pageSize = 10;
  const user = useSelector(state => state.user.user);
  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])



  const getData = async (page, pageSize) => {
    try {

      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }
      const response = await fetchData("deal",requestBody, pagination);
      if (response?.data) {
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
  const { RangePicker } = DatePicker;
  const [errors, setErrors] = useState({});
  const getStatusClass = (status) => {
    console.log("status:", status)
    switch (status) {
      case 'won':
        return 'open';
      case 'lost':
        return 'cancelled';
      case 'in progress':
        return 'Pending';
      case 'overdue':
        return 'overdue';
      default:
        return '';
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const filteredActivityList = activityList.filter((item) => {
    // Customer Name Filter
    const matchesDealnoTerm = item.customerName?.toLowerCase().includes(DealnoTerm.toLowerCase());

    // Status Filter
    const matchesDealTerm = item.dealno?.toLowerCase().includes(DealTerm.toLowerCase());

    // Created By Filter
    const matchesOwnerTerm = item.customerName?.toLowerCase().includes(OwnerTerm.toLowerCase());

    const matchesCollaborators = item.description?.toLowerCase().includes(Collaborators.toLowerCase());
    
    // Order# Filter
    const matchesDealstage = item.dealstage?.toLowerCase().includes(Dealstage.toLowerCase());

    const matchesAmount = item.amount?.toLowerCase().includes(AmountTerm.toLowerCase());

    const matchesStatus = item.dealStatus?.toLowerCase().includes(Status.toLowerCase());

    const matchesLostreason = item.lossReason?.toLowerCase().includes(Lostreason.toLowerCase());
    
    // Amount Filter (ensure amountTerm is correctly converted)
    // const amountValue = AmountTerm ? parseFloat(AmountTerm) : null;
    // const matchesAmount = amountValue !== null ? parseFloat(item.amount) === amountValue : true;

    // Date Range Filter
    const matchesDateRange = (selectedDates && selectedDates.length === 2)
      ? dayjs(item.dealdate).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.dealdate).isSameOrBefore(selectedDates[1], 'day')
      : true;


    // Final Filter Condition
    return (
      (!DealnoTerm || matchesDealnoTerm) &&
      (!DealTerm || matchesDealTerm) &&
      (!OwnerTerm || matchesOwnerTerm) &&

      (!Collaborators || matchesCollaborators) &&
      (!Dealstage || matchesDealstage) &&
      (!AmountTerm || matchesAmount) &&
      (!Status||matchesStatus) &&
      (!Lostreason||matchesLostreason) &&


      matchesDateRange 
    );
  });

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
         
            <tr>
              <th><div className="tableSize"></div></th>

              <th><div><input
                  type="text"
                placeholder="Deal No"
                value={DealTerm}
                onChange={(e) => setdealTerm(e.target.value)}
                 className="w-100 border-0"

              /></div></th>

              <th><div ><input
              type="text"
                placeholder="Deal For"
                value={DealnoTerm}
                onChange={(e) => setdealNoTerm(e.target.value)}
                 className="w-100 border-0"
              /></div></th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={['Start Date', 'End Date']} className="my-custom-class" />
                </Space></div> </th>

              <th><div className="tableSize"><input
                type="text"
                placeholder="Owner"
                value={OwnerTerm}
                onChange={(e) => setOwnerTerm(e.target.value)}
                className="w-100 border-0"
                

              /> </div></th>
              <th><div className="tableSize">
              <input
                type="text"
                placeholder="Collaborators "
                value={Collaborators }
                onChange={(e) => setCollaborators(e.target.value)}
             className="w-100 border-0"

              />
               </div></th>
              <th><div className="tableSize"> <input
                type="text"
                placeholder="Deal stage"
                value={Dealstage}
                onChange={(e) => setDealstageTerm(e.target.value)}
               className="w-100 border-0"
                

              /></div></th>

              <th><div className="tableSize"><input
                  type="text"
                  placeholder="Amount"
                  value={AmountTerm}
                  onChange={(e) => setAmountTerm(e.target.value)}
                  className="w-100 border-0"
                  

                /></div></th>

<th><div className="tableSize"><input
                  type="text"
                  placeholder="Status"
                  value={Status}
                  onChange={(e) => setStatusTerm(e.target.value)}
                 className="w-100 border-0"
                /></div></th>

<th><div className="tableSize"><input
                  type="text"
                  placeholder="Lost reason"
                  value={Lostreason}
                  onChange={(e) => setLostreasonTerm(e.target.value)}
                  className="w-100 border-0"
                /></div></th>

                              <th><div className="tableSize"></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
            
              <th> #</th>
              <th>Deal for</th>
              <th>Date</th>
              <th>Owner</th>
              <th>Collaborators by</th>
              <th>Deal stage</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Lost reason</th>
              {checkAccess('edit_deal')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {filteredActivityList?.map((i,index) => {
              return (
                <tr key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#f8f9f9', 
                }}>
                  <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                
                  <td className="whitesapce-nowrap">{i?.dealno}</td>
                  <td>
                    <span className="price-group-text">{i?.customerName}</span>
                  </td>
                  <td className="whitesapce-nowrap">{moment(i?.dealdate).format('DD-MM-YYYY')}</td>
                  <td className="whitesapce-nowrap">Admin{i?.owner}</td>
                  <td className="whitesapce-nowrap">-</td>
                  <td className="whitesapce-nowrap">{i?.dealstage}</td>
                  <td>
                    {i?.products?.reduce((accumulator, currentValue) => {
                      return accumulator + Number(currentValue.subTotal);
                    }, 0).toFixed(2)}
                  </td>
                  {/* <td className="whitesapce-nowrap">{i?.dealSatatus}</td> */}
                  <td>
                    {/* <Link to={{pathname:`view/${i?.dealId}`}} ><button className={` ${getStatusClass(i.dealSatatus)}`} >{i?.dealSatatus}</button></Link> */}
                     
                      {/* <Link to={{ pathname: `view/${i?.id}` }}>
                        <button className={` ${getStatusClass(i?.dealStatus)}`}>
                          {i?.dealSatatus}
                        </button>
                      </Link> */}
                      {checkAccess('read_deal')?(<Link to={{ pathname: `view/${i?.id}` }}>
                        <button className={` ${getStatusClass(i.dealStatus)}`}>
                          {i?.dealStatus}
                        </button>
                      </Link>):( <button className={` ${getStatusClass(i.dealStatus)}`}
                       style={{ pointerEvents: "none", cursor: "default" }}>
                          {i?.dealStatus}
                        </button>)}
                   
                  </td>
                  <td className="whitesapce-nowrap" > {i?.dealSatatus === 'lost' ? i?.lossReason : '-'}</td>
                  {checkAccess('edit_deal')&&<td>
                    <Link to={`adddeal/${i?.id}`} >
                      <EditIcon/>
                    </Link>
                  </td>}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
