import React, { useEffect, useState, useContext } from 'react';

import Button from '../../shared/components/button';
import { deleteData } from '../../services/apiService';
import { Link } from 'react-router-dom';
import Viewprofile from "./viewprofile";
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Userrole from '../userrole';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { fetchData } from '../../services/apiService';
export default function Profile() {
    const user = useSelector(state => state.user.user);
    const [selectedIds, setSelectedIds] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [formData, setFormData]=useState([]);
    const handleDelete = async () => {
        // try {
        //     await Promise.all(selectedIds.map((id) => deleteData(`order/${id}`)));
        //     setActivityList(activityList.filter(item => !selectedIds.includes(item.orderId)));
        //     setSelectedIds([]);
        // } catch (error) {
        //     console.error('Error deleting data:', error);
        // }
    };
    useEffect(() => {
        // Fetch data when the component mounts
        getData();
      }, []);
    const getData = async () => {
        try {
          const response = await fetchData('companyprofile');
          if (response?.data && response.data.length > 0) {
            setFormData(response.data[0]); // Set the first company's data to the state
            setActivityList(response.data); // If needed for another part of the app
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    
        return (
            <div>
                <div className="customer-page-layoutalignment">
                    <div className="customer-header-alignment">
                        <div>
                            <h2>Profile</h2>
                            <Breadcumbs activePath="Profile" pageName="Profile Info" />
                        </div>
                        <div className="right-contnet-alignment">
                            {selectedIds.length > 0 && (
                                <GlobalButton text="Delete Selected" onClick={handleDelete} />
                            )}
                            <Link to={{ pathname : `add/${formData.id}`}}>
                                <GlobalButton text="Edit" Icon={PlusIcon} />
                                </Link>
                            
                        </div>
                    </div>
                </div>

                <Viewprofile />
            </div>
        )
    
}
