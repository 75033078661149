import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Button from '../../../shared/components/button';
import ShowImage from '../../../shared/components/showImage';



export default function ViewDispatchOrder() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [leadstatus, setLeadStatus] = useState([]);
    const [userName, setUserName] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);
    const [dispatchOrder, setDispatchOrder] = useState([])


    const [formData, setFormData] = useState({
        name: '',
        leadsource: '',
        customerName: '',
        lr_no: '',
        date: '',
        transport: '',
        lr_date: '',
        invoiceNo: '',
        orderStatus: '',



    });

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };



    const getData = async () => {
        try {
            const responsee = await fetchData(`dispatchorder`);
            // if (responsee?.data) {
            //     const formattedleadstatus = responsee.data.map(i => ({
            //         label: i?.name,
            //         key: 'leadstatus',
            //         value: { name: i?.name }
            //     }));
            //     setLeadStatus(formattedleadstatus);
            // }


            const response = await fetchsingleData("dispatchorder", id);
            setDispatchOrder(response?.data)
            if (response?.data) setFormData(response.data);
            // if (response?.leadnotification) setNewNotification(response.data.leadnotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const handleSelectChange = async (selectedValue) => {


    //     try {
    //         const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
    //         // const newNotification = { date: currentDateTime, status: selectedValue };
    //         // setNewNotification(newNotification =>({
    //         //     ...newNotification,
    //         //     date: currentDateTime, status: selectedValue

    //         // }))


    //         const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };

    //         setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
    //         // const updatedLeadNotification = [...formData.leadnotification, newNotification];

    //         if (id) {
    //             // const updatedData = { ...formData, leadstatus: selectedValue, collabrator:selectedValue, leadOwner:selectedValue, leadnotification: [...newNotification, updatedNotification] };
    //             // const data = await patchData(`lead/${id}`, updatedData);
    //             // if (data) {
    //             //     setFormData(updatedData);
    //             // }

    //             let tempForm = { ...formData }
    //             tempForm["leadnotification"] = [...newNotification, updatedNotification]
    //             // setFormData(tempForm)
    //         }
    //     } catch (error) {
    //         console.error('Error updating lead status:', error);
    //     }
    // };



    return (

        <div style={{
            marginTop: '20px',
            // background: '#D2E0DF',       
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '4000px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',

            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginBottom: '2rem',
                    position: 'static',
                }}>Customer{'>'}{formData.customerName}</span>

                {/* <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {(<div>
                        <SelectBox name='leadstatus' value={leadstatus?.find(i => i.value.name === formData.leadstatus)} onChange={selectCustChanged} options={leadstatus} placeholder='Select Lead Status' required />
                    </div>)}
                </div> */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}>
                <div style={{
                    marginTop: '1rem',
                    // padding: '1.5rem',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    width: '950px'
                }}>
                    <>
                        <div style={{ padding: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <h2 style={{ justifyContent: '', paddingBottom: '15px' }}>{formData.name}</h2>
                                <div style={{
                                    display: 'flex',
                                    gap: '10px'
                                }}>
                                    {(<div>

                                    </div>)}
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ fontSize: '1.5rem' }}>
                                        Dispatch To
                                    </div>
                                    <div style={{ fontSize: '1.5rem' }}>
                                        {formData.customerAddress}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        🏠{formData.customerAddress}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        GST Type:{formData.customerAddress}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        GST NO:{formData.customerAddress}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Place Of Supply:{formData.customerAddress}
                                    </div>
                                </div>
                                <div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Dispatch No:{formData.dispatchNo}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Order Status:{formData.orderStatus}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Date:{formatDate(formData.date)}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Created By:{formData.created_by}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Dispatch Code:{formData.customerAddress}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Invoice No:{formData.invoiceNo}
                                    </div>
                                    <div style={{ fontSize: '0.85rem' }}>
                                        Transport:{formData.transport}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '1.5rem', fontSize: '0.85rem', borderTop: '1px solid #cccccc', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ fontSize: '0.85rem' }}>
                                LR Details{formData.customerAddress}
                            </div>
                            <div style={{ fontSize: '0.85rem' }}>
                                LR No.{formData.lr_no}
                            </div>
                            <div style={{ fontSize: '0.85rem' }}>
                                LR Date:{formatDate(formData.lr_date)}
                            </div>


                        </div>

                        <div style={{ padding: '1.5rem', fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                            <div style={{ borderBottom: '1px solid #e5e5e5', fontWeight: 'bold' }}>Other Details </div>

                            <div style={{ fontSize: '0.85rem', display: 'flex', justifyContent: 'space-between', paddingTop: '7px' }}>
                                {/* <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Turn-Over</div>
                                <div>{formData.turnover}</div>
                            </div> */}
                                {/* <div>
                                    <div style={{ fontSize: '0.85rem', color: 'black' }}>Attachment</div>
                                    <div>Not uploaded.</div>
                                </div> */}
                                {/* <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Existing Distributor</div>
                                <div>{formData.ed}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Existing Working Company</div>
                                <div>{formData.ewc}</div>
                            </div> */}
                            </div>
                        </div>
                        <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>#</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Item</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Category</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Quantity</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Price</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Discount</strong>
                                    </th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}>
                                        <strong>Sub Total</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {dispatchOrder && Array.isArray(dispatchOrder?.dispatchqty) && dispatchOrder?.dispatchqty.map((item, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{index + 1}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.productName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.category}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.quantity}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.unitPrice}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.discount}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h5 style={{ marginRight: '1rem' }}>Total: { }</h5>
                                <h5 style={{ color: '#28a745' }}> ₹</h5>
                            </div>
                        </div>

                    </>
                </div>

            </div>
        </div >
    );
}
