import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData, uploadDocument } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Upload from 'antd/es/upload/Upload';
import UploadImage from '../../../shared/components/uploadImage';
import GlobalButton from '../../../shared/globalButton';
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';


export default function AddEditexpense() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [UserName, setUserName] = useState([]);

    const [formData, setFormData] = useState({
        username: '',
        expenseno: '',
        date: dayjs(),
        expensetype: '',
        status: true,
        expensestatus: 'pending',
        reason: '',
        photoUrl: '',
        expensenotification: [],
        allowancetype: '',
        isperkm: '',
        amount: '',
        rate: '',
        ExpenseId: '',
        totalkm: '0',
        discription: '',
        editable: '',

    });

    const [errors, setErrors] = useState({});
    const [Expensetype, setExpensetype] = useState([]);

    useEffect(() => {
        getData();
        getexpensetype();

    }, []);

    const getData = async () => {
        try {

            const response = await fetchData(`user`);
            if (response?.data) {
                setUserName(
                    response?.data?.map((i) => {
                        return {
                            label: `${i?.name}`,
                            key: "username",
                            value: {

                                name: ` ${i?.name}`,

                            },
                        };
                    })
                );
            }






            if (id) {
                const response = await fetchsingleData("expense", id);
                if (response?.data) {
                    const fetchedData = response.data;
                    fetchedData.date = dayjs(fetchedData.date); // Convert the date to Day.js object
                    setFormData(fetchedData); // Update state with fetched data
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getexpensetype = async () => {
        try {
            const response = await fetchData(`expensetype`);
            if (response?.data) {
                setExpensetype(
                    response?.data?.map((i) => {
                        return {
                            label: `${i?.name}`,
                            key: "expensetype",
                            value: {
                                id: i?.id,
                                name:` ${i?.name}`,
                                allowancetype:`${i?.allowancetype}`,
                                isperkm: i?.isperkm,
                                amount: i?.amount,
                                rate: i?.rate,
                            },
                        };
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const SelectChanged = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [`${e.key}name`]: e.value?.name,

        }));
        if (e.key === "expensetype") {
            setFormData((prevState) => ({
                ...prevState,
                allowancetype: e.value?.allowancetype,
                isperkm: e.value?.isperkm,
                amount: e.value?.amount,
                rate: e.value?.rate,
                ExpenseId: e.value?.ExpenseId,
            }));
        }
        if (errors.isSubmit) validationCheck();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`expense/${id}`, formData).then(data => {
                        if(data){
                        navigate(-1);
                        console.log('API response:', data);}
                        
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('expense', formData).then(data => {
                        if(data){
                            navigate(-1);
                            console.log('API response:', data);}
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };

    const selectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`${e?.key}`]: e.value?.name,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const selectCustChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`${e?.key}`]: JSON.parse(e?.value)?.name,

        }));
        if (errors.isSubmit) validationCheck();
    };

    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;

        setFormData(prevState => {

            const updatedFormData = {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value,
            };


            if (updatedFormData.rate && updatedFormData.totalkm) {

                const rate = parseFloat(updatedFormData.rate);
                const totalkm = parseFloat(updatedFormData.totalkm);

                if (!isNaN(rate) && !isNaN(totalkm)) {
                    updatedFormData.amount = rate * totalkm;
                }
            }

            return updatedFormData;
        });

        if (errors.isSubmit) validationCheck();
    };

    const handleFileChange = async (e, id) => {
        const file = e;
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', file.name);
        console.log(id, "pass");
        try {
            const status = await uploadDocument(`document/uploadfile`, formData);
            if (status?.data?.id) {
                if (formData?.photoUrl) {
                    // await deleteDocument(formData?.photoUrl);
                }
                setFormData(prevFormData => ({
                    ...prevFormData,
                    photoUrl: `${status?.data?.id}`,
                }));
            }
        } catch (error) {
            console.error("Error uploading the file:", error);
        }
    };

    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            date
        }));
        if (errors.isSubmit) validationCheck();
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};

        if (!(formData.amount > 0)) {
            validationErrors.amount = 'Please enter amount';
        }
        // if (!(formData.expensetype)) {
        //     validationErrors.expensetype = 'Please select expensetype';
        // }
        if (!(formData.date)) {
            validationErrors.date = 'Please select date';
        }
        if (!(formData.username )) {
            validationErrors.username = 'Please select username ';
        }

        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };

    const styles = {
        pageHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 20px',
            backgroundColor: '#f0f0f0',
            borderBottom: '1px solid #d0d0d0',
            marginBottom: '20px',
        },
        formContainer: {
            padding: '20px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
            marginBottom: '20px',
        },
        inputWrapper: {
            marginBottom: '20px',
        },
        buttonAlignment: {
            display: 'flex',
            justifyContent: 'space-between',
        }
    };

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <strong> <span>Expense {`>`} Create</span></strong>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit} ><GlobalButton text="Save" /></div>
                    <Link to="/expense"> <GlobalButton text="Discard" outline /></Link>
                </div>
            </div> */}

            <div className='add-header-design'>
                <div>
                    <h2>Add Expense</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Expense" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/expense">
                        <button className='light-button'>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>


            {/* <div style={styles.formContainer}>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Expense No' name='expenseno' value={formData.expenseno} onChange={handleInputChange} placeholder='Expense no' type='text' errors={errors.expenseno} required />
                    </div>
                    <div style={styles.inputWrapper}>
                        <SelectBox label='username' name='username' value={UserName?.find(i => i.value.name === formData.username)} onChange={selectCustChanged} options={UserName} placeholder='User' errors={errors.unit} required />
                    </div>
                </div>
                <div style={styles.inputWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label='Date' name='date' value={formData.date} onChange={handleDateChange} />
                    </LocalizationProvider>
                </div>

                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Amount' name='amount' value={formData.amount} onChange={handleInputChange} placeholder='Enter claim amount' type='text' errors={errors.amount} required />
                    </div>
                </div>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Expense Type' name='expensetype' value={formData.expensetype} onChange={handleInputChange} placeholder='Enter expense' type='text' errors={errors.exprensetype} required />
                    </div>
                </div>
                <div className='add-news-form-design'>
                    <h2>Product Image</h2>
                    <div className='grid'>
                        <div className='bottom-alignment'>
                            <UploadImage action={(e) => handleFileChange(e, formData.expenseId)} value={formData.photoUrl} ></UploadImage>
                        </div>

                    </div>

                </div>
            </div> */}


            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label='Expense No' name='expenseno' value={formData.expenseno} onChange={handleInputChange} placeholder='Expense no' type='text' errors={errors.expenseno} required />
                            <SelectBox label={
                                <span>
                                    UserName <span className="required-star">*</span>
                                </span>}
                                name='username' value={UserName?.find(i => i.value.name === formData.username)} onChange={selectChanged} options={UserName} placeholder='User' errors={errors.username} required />
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker label={
                                    <span>
                                        Date <span className="required-star">*</span>
                                    </span>}
                                    name='date' value={formData.date} onChange={handleDateChange} errors={errors.date} />
                            </LocalizationProvider>
                            <Input label={
                                <span>
                                    Amoutn<span className="required-star">*</span>
                                </span>}
                                name='amount' value={formData.amount} onChange={handleInputChange} placeholder='Enter claim amount' type='text' errors={errors.amount} required readOnly={!formData.editable} />

                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid pb-3'>
                            <SelectBox
                                label={
                                    <span>
                                      Expense Type<span className="required-star">*</span>
                                     </span> }                                   
                                name="expensetype"
                                value={Expensetype?.find(
                                    (option) => option.value.id === formData.id
                                )}
                                options={Expensetype}
                                onChange={SelectChanged}
                                placeholder="Expense Type"
                                type="text"
                                required={true}
                                errors={errors.expensetype}
                            />
                        </div>
                        <UploadImage action={(e) => handleFileChange(e, formData.id)} value={formData.photoUrl} ></UploadImage>
                        <div className='pt-3'>
                            <textarea label='Note' name='discription' value={formData.discription} onChange={handleInputChange} placeholder='Enter Note' type='text' errors={errors.discription} required  ></textarea>
                        </div>
                    </div>
                    {formData.allowancetype === "travelling" && (
                        <div className='new-white-box-design'>
                            <div className='two-col-grid'>
                                <Input label='Rate' name='rate' value={formData.rate} onChange={handleInputChange} placeholder='Rate' type='text' errors={errors.rate} required readOnly={!formData.editable} />
                                <Input label='Total Km' name='totalkm' value={formData.totalkm} onChange={handleInputChange} placeholder='Total km' type='text' errors={errors.totalkm} required />

                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>
        </div>


    );
}
