import React,{useContext,useState} from 'react'
import './expense.scss';
import Button from '../../shared/components/button';
import Expensetable from './expensetable';
import { deleteData, filterAll } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';
export default function Expense() {
  const navigate=useNavigate();
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList,setActivityList ] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`expense/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const handleExport = () => {
    const headers = [
      ["Expense No", "User","Expense type", "Date","Claim Amount", "Expense status"]
    ];
 
    filterAll("expense")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.expenseno,
      item.username,
      item.expensetypename,
      item.date,
      item.amount,
      item.expensestatus,
      ]);
      exportToExcel('Expense',headers,datas,'expense')
    })
  };

  
  {return (
    <div className='page-24'>
      {/* <div className='page-header'>
        <span>Expense</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
        {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}

        {(<Button text='Add Expense' onClick={(e) => navigate("addexpense")}/>)}
      </div>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-3">
          <div>
            <h2>Expense</h2>
            <Breadcumbs activePath="Expense" pageName="Expense" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
            <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure to delete this record? "
            description="This will lead to delete all records related to this record only!"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
          </Popconfirm>
            )}
            {checkAccess('add_expense')&&<Link onClick={(e) => navigate("addexpense")}>
              <GlobalButton   text="Add Expense" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_expense')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>
      <Expensetable 
       selectedIds={selectedIds} 
       setSelectedIds={setSelectedIds} 
       activityList={activityList}
       totalItems={totalItems} 
       setTotalItems={setTotalItems}
       setActivityList={setActivityList}
/>

    </div>
  )
}}
