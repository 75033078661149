import React, { useState } from 'react';
import Button from '../../shared/components/button';
import Activitytypetable from './activitytypetable';
import { Link } from 'react-router-dom';
import { deleteData, filterAll } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';

export default function Activitytype() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`activitytype/${id}`)));
      setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };


  const handleExport = () => {
    const headers = [
      ["Name","Activity template", "Status"]

    ];
 
    filterAll("activitytype")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
      item.name,
      item.activityTemplate,
      item.status ? "Active" : "Inactive",
      ]);
      exportToExcel('Activity Type',headers,datas,'activitytype')
    })
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <div>
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Activity Type</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
          <Link to="addEditactivitytype"><Button text='Add Activity'/></Link>
        </div>
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Activity Type</h2>
            <Breadcumbs activePath="Activity Type" pageName="Activity Type" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>

              
            )}
            {checkAccess('manage_activity_type')&&<Link to="addEditactivitytype">
              <GlobalButton text="Add Activity Type" Icon={PlusIcon} />
            </Link>}
            {checkAccess('manage_activity_type')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>
      <Activitytypetable 
        selectedIds={selectedIds} 
        setSelectedIds={setSelectedIds} 
        activityList={activityList}
        setActivityList={setActivityList}
      />
    </div>
  );
}
