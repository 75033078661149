import React, { useEffect,useState } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
export default function SystemTypeCategorytable({ selectedIds, setSelectedIds,totalItems,setTotalItems , activityList,setActivityList }) {

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const pageSize = 10; // Items per page

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])

  
  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }
      const response = await fetchData('systemtypecategory',requestBody, pagination);
      if (response?.data) {
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
           
              <th>System Type Name</th>
              <th>System Category</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              (activityList ?? []).map((item) => {
                return (
                  <tr key={item?.id}>
                    <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                
                    <td>{item?.systemTypeName}</td>
                    <td>{item?.categoryName}</td>
                    <td>
                      <button className={item?.status ? "Active" : "Inactive"}>{item?.status ? "Active" : "Inactive"}</button>
                    </td>
                    <td>
                      <Link to={{ pathname: `addEditSystemTypeCategory/${item?.id}` }}>
                        <EditIcon/>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
