import React, { useState, useEffect } from 'react';
import './product.scss';
import Button from '../../shared/components/button';
import Producttable from './producttable';
import { Link } from 'react-router-dom';
import Breadcumbs from '../../shared/components/breadcumbs';
import PlusIcon from '../../assets/icons/plus.svg';
import ExportIcon from '../../assets/icons/export.svg';

import GlobalButton from '../../shared/globalButton';
import AccountTableHeader from '../account/accountTableHeader';
import { checkAccess } from '../../services/checkFeature';
import { exportToExcel } from '../../services/globalService';
import { filterAll } from "../../services/apiService";

export default function Product({}) {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);  // Initialize totalItems state
  const [unitList, setUnitList] = useState([]); // Initialize unitList state

  // Export handler
  const handleExport = () => {
    const headers = [
      ["Product", "Code", "Category", "Price", "Stock", "Status"]
    ];

    filterAll("product")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.name,
          item.hsn_code,
          item.categoryName,
          item.price,
          (item.stock + " " + item.unitName),
          item.status ? "Active" : "Inactive",
        ]);
        exportToExcel('Product', headers, datas, 'product');
      });
  };

  // Fetch product list on mount (or whenever necessary)
  useEffect(() => {
    filterAll('product')
      .then((response) => {
        if (response.data) {
          setProductList(response.data);  // Update product list
          setTotalItems(response.totalCount);  // Assuming API returns total count of items
        }
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
      });
  }, []);

  return (
    <div className='product-page-design-alignment'>
      <div className='customer-header-alignment'>
        <div>
          <h2>
            Product
          </h2>
          <Breadcumbs activePath="Dashboard" pageName="product" />
        </div>
        <div className='right-contnet-alignment'>
          {checkAccess('export_product') && (
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          )}
          {checkAccess('add_product') && (
            <Link to="add">
              <GlobalButton text="Add Product Unit" Icon={PlusIcon} />
            </Link>
          )}
        </div>
      </div>
      <AccountTableHeader />
      {/* Pass required props like productList, selectedIds, etc., to the Producttable component */}
      <Producttable 
        productList={productList} 
        setSelectedIds={setSelectedIds} 
        setProductList={setProductList} 
        setUnitList={setUnitList} 
        totalItems={totalItems}
        selectedIds={selectedIds}
      />
    </div>
  );
}
