import React from 'react'
import './priority.scss';
import Prioritydetails from './prioritydetails';
import Breadcumbs from '../../shared/components/breadcumbs';
import GlobalButton from '../../shared/globalButton';
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import AccountTableHeader from '../account/accountTableHeader';
export default function Priority() {
  return (
    <div className='page-24'>
       <div className="customer-header-alignment">
          <div>
            <h2>Priority</h2>
            <Breadcumbs activePath="Customer" pageName="Customers" />
          </div>
          <div className="right-contnet-alignment">
          
            <GlobalButton text="Product"   />
          </div>
        </div>
        <AccountTableHeader />
        <Prioritydetails
        style={{
          padding: '20px', 
          backgroundColor: '#f9f9f9', 
          borderRadius: '8px', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }} />
    </div>
  )
}
