import React, { useState,useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';

export default function Countrytable({ selectedIds, setSelectedIds,  setActivityList ,activityList, setProductList, setTotalItems, totalItems }) {

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [activityList, setProductList] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [status, setStatus] = useState(undefined);
  const [countryFlag, setCountryFlag] = useState("");
  const [countryShortname, setCountryShortname] = useState("");
  
  const pageSize = 10; // Items per page

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);


  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }
      const response = await fetchData('country',requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []);  // API returns current page data in 'items'
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filteredActivityList = activityList.filter((item) => {
    const searchCountryName = item.countryName?.toLowerCase().includes(countryName?.toLowerCase());
    const searchCountryShortName = item.countryShortname?.toLowerCase().includes(countryShortname?.toLowerCase());
    const searchCountryFlag = item.countryFlag?.toLowerCase().includes(countryFlag?.toLowerCase());
    const searchStatus = status === undefined || item.status === status;

   
    return (
      (!countryName || searchCountryName) &&
      (!countryShortname || searchCountryShortName) &&
      (!countryFlag || searchCountryFlag) &&
      searchStatus
   
    );
  });
    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div></div></th>

              <th><div><input
                type="text"
                placeholder="Country Name"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              <th><div><input
                type="text"
                placeholder="Country Short Name"
                value={countryShortname}
                onChange={(e) => setCountryShortname(e.target.value)}
                className="w-100 border-0"

              /></div></th>

               <th><div><input
                type="text"
                placeholder="Country Flag"
                value={countryFlag}
                onChange={(e) => setCountryFlag(e.target.value)}
                className="w-100 border-0"

              /></div></th>

                <th>
                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatus(selectedStatus); 
                    }}
                    className="w-100 border-0"
                  >
                    <option value="">All</option>
                    <option value="true">Active</option> 
                    <option value="false">Inactive</option>
                  </select>
                </div>
                </th>
                              
                <th><div ></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
            
              <th>Name</th>
              <th>Country ShortName</th>
              <th>Country Flag</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              (filteredActivityList ?? []).map((item) => {
                return (
                  <tr key={item?.id}>
                    <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                   
                    <td>{item?.countryName}</td>
                    <td>{item?.countryShortname}</td>
                    <td>{item?.countryFlag}</td>
                    <td>
                      <button className={item?.status ? "Active" : "Inactive"}>{item?.status ? "Active" : "Inactive"}</button>
                    </td>
                    <td>
                      <Link to={{ pathname: `addEditcountry/${item?.id}` }}> 
                        <EditIcon/>
                      </Link>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
