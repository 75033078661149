import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ShowImage from '../../../shared/components/showImage';



export default function AddEditviewordertable() {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);

    const pdfRef = useRef();

    const handleGeneratePdf = () => {
        const pdfWidth = 420; // Full HD width
        const pdfHeight = 370; // Full HD height

        const doc = new jsPDF({
            orientation: 'a4', // Landscape orientation for wider content
            unit: 'px', // Use pixels for dimensions
            format: [pdfWidth, pdfHeight], // Custom format for full HD
        });

        // Convert the content of the div to PDF
        doc.html(pdfRef.current, {
            callback: (doc) => {
                doc.save('order_details.pdf'); // Save the PDF file
            },
            x: 20, // X offset for margins
            y: 20,
            z: 20, // Y offset for margins

            width: pdfWidth - 20, // Adjust the width for margins
            windowWidth: 970, // The width of the browser window


        });
    };








    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

  

    const [formData, setFormData] = useState({
        id: "",
        // orderDate: dayjs(),
        // customerName: "",
        // customerId: "",
        // customerAddress: "",
        // customerContact: "",
        // status: true,
        orderStatus: 'Pending',
        orderproducts: [],
        ordernotification: [],
        // ordercreatedby: '',
        orderupdate: '',
        orderupdatedby: '',


    });
    const [newNotification, setNewNotification] = useState([]);
    const [timelines, setTimelines] = useState([]);

    useEffect(()=>{

        const getData = async() =>{
            const requestBody = {
                "reference_type": "order",
                reference_id : id
            }
             
            const responsetimeline = await fetchData("timeline",requestBody);
    console.log(responsetimeline);
            if (responsetimeline?.data) {
                setTimelines(responsetimeline.data);
              } 
        }

        getData()
      
    },[newNotification])
    const handleSubmit = async (e, status) => {
        e.preventDefault(); // Prevent default form submit behavior
    
        try {
            const allowedStatuses = ['Open', 'Close', 'Pending', 'Confirmed', 'Dispatched', 'Delivered'];
            if (!allowedStatuses.includes(status)) {
                console.error('Invalid order status:', status);
                return;
            }
    
            const currentDateTime = dayjs().format('DD-MM-YYYY, ⏱︎\hh:mm:ss A');
            const updatedNotification = { date: currentDateTime, status: status, user: name }; // Store notification with the status
    
            // Update the new notification in the state
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
    
            // Prepare the updated form data, including the status change
            const updatedFormData = {
                // ...formData,
                orderStatus: status, // Set the order status to the passed value ('Open', 'Cancelled', etc.)
                // cancellationDateTime: status === 'Cancelled' ? dayjs().format('DD-MM-YYYY HH:mm:ss') : null,
            };
            // setFormData({...formData,orderStatus:status})
            // If an ID exists, update the record; otherwise, create a new order
            if (id) {
                const data = await patchData(`order/${id}`, updatedFormData);
                if (data?.data) {
                        setFormData({...formData,orderStatus:status})
                }  
            } else {
                const data = await postData('order', updatedFormData); // Create new order
                if (data) navigate(-1); // Navigate back if the creation is successful
            }
        } catch (error) {
            console.error('Error saving data:', error); // Handle any errors
        }
    };
    
    
    

    const getData = async () => {
        try {
            // const response = await fetchsingleData(`order/${id}`);
            // debugger
            // if (response?.data) setFormData(response.data);
            const rs = await fetchsingleData(`order`,id);
            if (rs?.data) setFormData(rs.data);
            if (rs?.data.ordernotification) setNewNotification(rs.data.ordernotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const calculateTotalAmount = () => {
        return formData. orderproducts.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.total);
        }, 0).toFixed(2);
    };
    const appDataJSON = localStorage.getItem('users');
    const appData = JSON.parse(appDataJSON);

    return (
        
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Order {'>'} List</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {/* <button
                        onClick={(e) => handleSubmit(e, 'open')}
                        style={{
                            backgroundColor: 'green',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Open
                    </button>
                    <button
                        onClick={(e) => handleSubmit(e, 'cancelled')}
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer'
                        }}
                    >
                        Cancel
                    </button>
                    <a href="/order" style={{ textDecoration: 'none' }}>
                        <button style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: '1px solid #1e2e50',
                            backgroundColor: 'transparent',
                            color: '#1e2e50',
                            cursor: 'pointer'
                        }}>
                            Back
                        </button>
                    </a> */} <div onClick={handleGeneratePdf}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                        </svg>
                    </div>
                    {formData.orderStatus === 'Pending' ? (
  <>
    <div onClick={handleGeneratePdf}>
      {/* PDF generation content */}
    </div>
    <button
       onClick={(e) => handleSubmit(e, 'Open')}
      style={{
        backgroundColor: '#27AE60',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        height: '30px',
        fontSize: '10px',
        fontWeight: 'bold',
      }}
    >
      Open Order
    </button>
    <button
       onClick={(e) => handleSubmit(e, 'cancelled')}
      style={{
        backgroundColor: '#D44545',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        height: '30px',
        fontSize: '10px',
        fontWeight: 'bold',
      }}
    >
      Cancel Order
    </button>
  </>
) : null}


{formData.orderStatus === 'Open' && (
    <>
        <Link to={`/order/dispatchorder/${formData.id}`}>
            <button
                style={{
                    backgroundColor: '#2D5B91',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    border: 'none',
                    cursor: 'pointer',
                    height: '30px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                }}
            >
                Dispatch order
            </button>
        </Link>
    </>
)}

{formData.orderStatus === 'cancelled' && (
    <>
        <button
            onClick={(e) => handleSubmit(e, 'Open')}
            style={{
                backgroundColor: '#27AE60',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                fontSize: '10px',
                fontWeight: 'bold',
            }}
        >
            Open Order
        </button>
        <button
            onClick={(e) => handleSubmit(e, 'Pending')}
            style={{
                backgroundColor: '#f0ad4e',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                fontSize: '10px',
                fontWeight: 'bold',
            }}
        >
            Pending order
        </button>
    </>
)}

{formData.orderStatus === 'Close' && (
    <>
        <button
            onClick={(e) => handleSubmit(e, 'Open')}
            style={{
                backgroundColor: '#27AE60',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                fontSize: '10px',
                fontWeight: 'bold',
            }}
        >
            Open Order
        </button>
        <button
            onClick={(e) => handleSubmit(e, 'Pending')}
            style={{
                backgroundColor: '#f0ad4e',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                height: '30px',
                fontSize: '10px',
                fontWeight: 'bold',
            }}
        >
            Pending order
        </button>
    </>
)}
                    <a href="/order" style={{ textDecoration: 'none' }}>
                        <button style={{
                            backgroundColor: '#8CA7C6',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}><div ref={pdfRef} style={{
                marginTop: '1rem',
                padding: '1.5rem',
                backgroundColor: '#fff',
                border: '1px solid rgba(30,46,80,.09)',
                borderRadius: '0.25rem',
                boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                width: '900px',
                scrollBehavior: 'smooth',
                height: 'auto',
                overflowY: 'scroll',

            }}>
                {formData.orderStatus === 'Cancelled' ? (
                    <div style={{
                        padding: '1rem',
                        backgroundColor: '#f8d7da',
                        color: '#721c24',
                        width: '800px',
                        border: '1px solid #f5c6cb',
                        borderRadius: '0.25rem',
                        marginBottom: '1.5rem'
                    }}>
                        This order was cancelled on {formData.cancellationDateTime}
                    </div>
                ) : (
                    <><div style={{ display: 'flex' }}>
                        <div><h4 style={{ paddingBottom: '10px' }}>Order from,</h4>
                            <p style={{ fontSize: '0.85rem' }}>{formData.customerName}</p></div>
                        <div style={{ flex: '1', textAlign: 'right' }}>
                            <h6>Order update</h6>
                            {formData?.orderupdatedby && formData.orderupdatedby.length > 0 ? (<p style={{ fontSize: '0.85rem' }}>Order is Updateby {formData.orderupdatedby} At {formData.orderupdate}</p>) :
                                (<p>--</p>)}

                        </div>
                    </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order No.</div>
                                    <strong>{formData.orderNo}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order createdBy.</div>
                                    <strong>{formData.ordercreatedby}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order Date</div>
                                    <strong>{dayjs(formData.orderDate).format('DD-MM-YYYY')}</strong>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Customer</div>
                                    <strong>{formData.customerName}</strong>
                                    <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                        {formData.customerAddress}
                                        <br />
                                        <a href={`mailto:${formData.customerContact}`} style={{ color: 'purple' }}>{formData.customerContact}</a>
                                    </p>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order Status</div>
                                    <strong>{formData.orderStatus}</strong>
                                </div>
                            </div>
                        </div>

                        <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>#</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Image</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Item</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Category</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Quantity</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Price</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Discount</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Sub Total</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.orderproducts.length > 0 && formData.orderproducts.map((product, index) => (
                                    <tr key={index}
                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{index + 1}</td>
                                        <td style={{ padding: '1px', borderBottom: '1px solid #edf2f9' }}> <a href={product.photoUrl} target="_blank" rel="noopener noreferrer">
                                            <ShowImage
                                                className="thumbnail"
                                                value={product?.productUrl}
                                                style={{ width: '80px', height: '60px', borderRadius: '4px', paddingBottom: '3px' }}
                                            />
                                        </a></td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.productName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.unitName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.quantity}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.unitPrice}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.discount}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h5 style={{ marginRight: '1rem' }}>Total:</h5>
                                <h5 style={{ color: '#28a745' }}> ₹{calculateTotalAmount()}</h5>
                            </div>
                        </div>
                    </>
                )}
            </div>

                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'auto'
                }}>
                    {/* <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
                     */}
                     <div style={{marginTop:'-8px',paddingBottom:'15px',fontWeight:'bold',fontSize:'20px'}}><strong>Timeline</strong></div>
                    {timelines?.slice().reverse().map((i, index) => (
                <div
                    key={i.id || index}
                    style={{
                        display: "flex",
                        paddingLeft: "3px",
                        paddingBottom: "15px",
                    }}
                >
                    <div style={{ paddingRight: "5px" }}>➨</div>
                    <div style={{ fontSize: "14px" }}>
                    Order <strong> {i.orderNo}</strong> generated by
                         <strong> {i.user_name}</strong> on <strong>  {dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎hh:mm:ss A')}</strong>.
                       
                    </div> 
                </div>
            ))}
        
                </div>
            </div>
        </div>

    );
}