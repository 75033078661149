import React, { useState,useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import store from '../../../redux/reduxstore';
import like from '../../../assets/icons/like.svg';
import beer from '../../../assets/icons/beer.svg';
import chat from '../../../assets/icons/chart-simple.png';
import square from '../../../assets/icons/square-plus.svg';
import sugar from '../../../assets/icons/sugar-cubes.png';
import truck from '../../../assets/icons/delivery-truck.svg';
import './sidebar.scss';
import {checkAccess} from '../../../services/checkFeature'
import Dashboard from '../../../routes/dashboard';
export default function Sidebar({onSidebarItemClick}) {
    const [toogle, setToogle] = useState(false);
    const [toogle2, setToogle2] = useState(false);
    const [toogle3, setToogle3] = useState(false);
    const [toogle4, setToogle4] = useState(false);
    const [toogle5, setToogle5] = useState(false);
    const location = useLocation(); // Get the current location
    const user = useSelector(state => state.user.user);

    const features = store.getState().auth.feature;
    const company_setting = store.getState()?.auth?.company_setting;

    console.log("features", features)

    const handleItemClick = (title) => {
        setToogle(false)
        onSidebarItemClick(title);
    };
    
    const isActive = (path) => {
        return location.pathname === path ? 'active' : ''; // Check if the current path matches the given path
    };

    return (
        <div className='sidebar'>
            <div className='sidebar-body'>
                <Link  to="/dashboard"  className={`sidebar-menu ${isActive('/dashboard')}`} onClick={() => handleItemClick("Dashboard")} >
                    <i className="fa-solid fa-gauge"></i>
                    <span>Dashboard</span>
                </Link>
                <Link to="/activity"  className={`sidebar-menu ${isActive('/activity')}`} onClick={() => handleItemClick("Activity")}>
                <i class="fa fa-bars" aria-hidden="true"></i>
                    <span>My Activity</span>
                </Link>

                <div className={`sidebar-menu ${isActive('/priority') || isActive('/product') || isActive('/product-category') || isActive('/pricegroup') }`} onClick={() => {setToogle(!toogle);}}>
                <div className='svglogo size'> 
                    <img src={sugar} alt="beer" />
                    </div>
                    <div className='all-content-alignment'>
                        <span>Catalog</span>
                        <div className={toogle ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <span><Link to="/product" className={`${isActive('/product')}`}>Product</Link></span>
                    <span><Link to="/pricegroup" className={`${isActive('/pricegroup')}`}>Price Group</Link></span>
                    <span><Link to="/product-category" className={`${isActive('/product-category')}`}>Category</Link></span>
                    <span><Link to="/product-unit" className={`${isActive('/product-unit')}`}>Unit</Link></span>
                    <span><Link to="/priority" className={`${isActive('/priority')}`}>Priority</Link></span>
                </div>
                
                  <Link to="/account" className={`sidebar-menu ${isActive('/account')}`} onClick={() => handleItemClick("Customer")}>
                  <i class="fas fa-users"></i>
                    <span>Customers</span>
                </Link>
                
                <div className='svglogo width'></div>
                {company_setting?.system_config?.enableSaleQuotation ==="Yes" && (
                <Link to="/Quotation"  className={`sidebar-menu ${isActive('/Quotation')}`} onClick={() => handleItemClick("Quotation")}>
                <i class="fa fa-check-square-o"></i>
                    <span>Sale Quotation</span>
                </Link>
                )}
                 <Link to="/order"  className={`sidebar-menu ${isActive('/order')}`} onClick={() => handleItemClick("Orders")}>
                 <i className="fa-solid fa-cart-shopping"></i>
                    <span>Order</span>
                </Link>
                <Link to="/adddispatchorder"  className={`sidebar-menu ${isActive('/adddispatchorder')}`} onClick={() => handleItemClick("Orders")}>
                <div className='svglogo'> 
                <img src={truck} alt="V" />
                </div>
                    <span>Dispatch</span>
                </Link>
                {company_setting?.system_config?.enableSalesReturn ==="Yes" && (
                <Link to="/salesReturn"  className={`sidebar-menu ${isActive('/salesReturn')}`} onClick={() => handleItemClick("salesReturn")}>
                <div className='svglogo'> 
                <i class="fa-solid fa-reply"></i>
                </div>
                    <span>Sales Return</span>
                </Link>
                )}

                {company_setting?.system_config?.enableInvoice ==="Yes" && (
                <Link to="/invoice" className={`sidebar-menu ${isActive('/invoice')}`} onClick={() => handleItemClick("Invoice")}>
                <i class="fa fa-file-text-o"></i>
                    <span>Invoice</span>
                </Link>
                 )}
                 <Link to="/payment-collection" className={`sidebar-menu ${isActive('/payment-collection')}`} onClick={() => handleItemClick("Payments")}>
                    {/* <i className="fa-solid fa-cart-shopping"></i> */}
                    <i class='far fa-money-bill-alt'></i>
                    <span>Payment</span>
                </Link>
                <Link to="/expense" className={`sidebar-menu ${isActive('/expense')}`} onClick={() => handleItemClick("Expenses")}>
                <div className='svglogo size'> 
                    <img src={beer} alt="beer" />
                    </div>
                    <span>Expense</span>
                </Link>
                
                <div className='svglogo width'></div>

                {checkAccess("access_all_deal") ? (<Link to="/deal" className={`sidebar-menu ${isActive('/deal')}`} onClick={() => handleItemClick("Deals")}>
                    {/* <i className="fa-solid fa-cart-shopping"></i> */}
                    <div className='svglogo size'> 
                    <img src={like} alt="like" />
                    </div>
                    <span>Deal</span>
                </Link>) : (<></>)}
                <Link to="/lead" className={`sidebar-menu ${isActive('/lead')}`} onClick={() => handleItemClick("Leads")}>
                <div className='svglogo size'> 
                    <img src={square} alt="beer" />
                    </div>
                    <span>Lead</span>
                </Link>
                
                <div className='svglogo width'></div>

                {checkAccess('manage_location')&&<div className={`sidebar-menu ${isActive('/location') || isActive('/productunit') || isActive('/product-category') || isActive('/pricegroup') }`} onClick={() => {setToogle2(!toogle2);}}>
                    <i className="fa-solid fa-location-dot fa-fw"></i>
                    <div className='all-content-alignment'>
                        <span>Location Tracking</span>
                        <div className={toogle2 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>}
                <div className={toogle2 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <span><Link to="/location">Dashboard</Link></span>
                    <span><Link to="/current-location">Current Location</Link></span>
                    <span><Link to="/all-location">All Locations</Link></span>
                    <span><Link to="/attendance">Attendance</Link></span>
                    <span><Link to="/leave">Leave</Link></span>
                </div>
                <div className='svglogo width'></div>

                {checkAccess('all_dispatch_report')?(<div className={`sidebar-menu ${isActive('/orderwise-report') || isActive('/productwise-report') }`} onClick={() => {setToogle3(!toogle3);}}>
                <div className='svglogo size'> 
                    <img src={chat} alt="chat" />
                    </div>
                    <div className='all-content-alignment'>
                        <span>Pending Dispatch</span>
                        <div className={toogle3 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>):(<></>)}
                <div className={toogle3 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    {checkAccess('orderwise_dispatch_report')?(<span><Link to="/orderwise-report">Orderwise</Link></span>):(<></>)}
                    {checkAccess('productwise_dispatch_report')?(<span><Link to="/productwise-report">Productwise</Link></span>):(<></>)}
                </div>

                <div className={`sidebar-menu ${isActive('/report') }`} onClick={() => {setToogle4(!toogle4);}}>
                <div className='svglogo size'> 
                <img src={chat} alt="chat" />
                </div>
                    <div className='all-content-alignment'>
                        <span>Report</span>
                        <div className={toogle4 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle4 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <span><Link to="/orderReport">Order</Link></span>
                </div>


                <div className='svglogo width'></div>
                <div className={`sidebar-menu ${isActive('/user-role') }`} onClick={() => {setToogle4(!toogle4);}}>
                    <i className="fa-regular fa-user"></i>
                    <div className='all-content-alignment'>
                        <span>User</span>
                        <div className={toogle4 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle4 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <span><Link to="/user">User</Link></span>
                    <span><Link to="/user-role">User Role</Link></span>
                </div>
                <div className={`sidebar-menu ${isActive('/activitytype') || isActive('/countryMaster') || isActive('/systemTypeMaster') || isActive('/statetype')|| isActive('/area') || isActive('/paymenttype') || isActive('/leadstatus')|| isActive('/leadsource') || isActive('/leadIndustry') || isActive('/dealpipeline') || isActive('/deallost') || isActive('/visitFeedbacktype') || isActive('/leavetype') || isActive('/location') || isActive('/requestdata') }`} onClick={() => {setToogle5(!toogle5);}}>
                    <i className="fa-solid fa-gear fa-fw"></i>
                    <div className='all-content-alignment'>
                        <span>Settings</span>
                        <div className={toogle5 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                
                <div className={toogle5 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <span><Link to="/activitytype">Activity Type</Link></span>
                    <span><Link to="/systemTypeMaster">System Category Master</Link></span>
                    <span><Link to="/systemTypeCategory">System Type Category </Link></span>
                    <span><Link to="/countryMaster">Country</Link></span>
                    <span><Link to="/statetype">State</Link></span>
                    <span><Link to="/city">City</Link></span>
                    <span><Link to="/area">Area</Link></span>
                    <span><Link to="/paymenttype">Payment Type</Link></span>
                    <span><Link to="/transport">Transport</Link></span>
                    <span><Link to="/leadstatus">Lead Status</Link></span>
                    <span><Link to="/leadsource">Lead Source</Link></span>
                    <span><Link to="/leadIndustry">Lead Industry</Link></span>
                    <span><Link to="/dealpipeline">Deal Pipeline</Link></span>
                    <span><Link to="/deallost">Deal Lost</Link></span>
                    <span><Link to="/visitFeedbacktype">Visit Feedback Type</Link></span>
                    <span><Link to="/accountCategory">Account Category</Link></span>
                    <span><Link to="/segment">Segment  </Link></span>
                    <span><Link to="/leavetype">Leave Type</Link></span>
                    <span><Link to="/location">Location</Link></span>
                    <span><Link to="/requestdata">Request Log</Link></span>
                    <span><Link to="/expensetype">Expense Type</Link></span>
                    <span><Link to="/companysetting">Company Settings</Link></span>
                </div>
                <Link to="/profile" className={`sidebar-menu ${isActive('/profile')}`} onClick={() => handleItemClick("profile")}>
                <i class="fa fa-user-secret"></i>
                    <span>Company Profile</span>
                </Link>
                <Link
                    to="/subscription"
                    className={`sidebar-menu ${isActive('/subscription')}`}
                    onClick={() => handleItemClick("subscription")}
                >
                    {/* Inline SVG icon for subscription */}
                    <div className='svglogo width'>
                    <i class="fa fa-newspaper-o"></i>
                    </div>


                    <span>Subscription</span>
                </Link>
                {/* <Link to="/setting" className={`sidebar-menu ${isActive('/setting')}`}>
                    <i className="fa-solid fa-gear"></i>
                    <span>Settings</span>
                </Link> */}
            </div>
        </div>
    )
}
