import React, {useEffect, useState} from 'react';
import { Upload,message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import './uploadImage.scss';
import { getuploadedfile } from '../../../services/apiService';
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function UploadImage({ path,action,value }) {
    useEffect(() => {
        if(value)
        fetchdocument()
      }, [value]);
      // const fetchdocument = async ()=>{
      //   const response = await getuploadedfile('document/getDocument/'+value);
      //   if(response?.data){
          
      //       const blob1 = new Blob([response?.data], { type:response.headers.getContentType() });
      //       const url = URL.createObjectURL(blob1)
      //       setImageUrl(url)
      //   }

      // };
      const fetchdocument = async () => {
        try {
          // const blob = await getuploadedfile('https://saleseasy-api.azurewebsites.net/api/document/getDocument/76');
          const blob = await getuploadedfile(BASE_URL+'document/getDocument/'+value);
          const imageUrl = URL.createObjectURL(blob);
          setImageUrl(imageUrl);
        } catch (error) {
          console.error('Error loading document:', error);
        }
      };
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      };
   
    const handleChange = (info) => {
       
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
            setLoading(false);
            return;
          }

      };
      const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </button>
      );
    return ( 
        <Upload
        name="avatar"
        listType="picture-card"
        action={action}
        onChange={handleChange}
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    )
}
