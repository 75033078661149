import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
} from "@mui/material";

export default function AreaComponent() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box p={4}>
      <div className="row">
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="col-md-9">
          <Box flex={1}>
            <div style={{ marginBottom: '20px' }}>
              <Typography variant="h6">Total amount: ₹ 12469010.03</Typography>
            </div>
            <div>
              <Tabs value={0}>
                <Tab label="TABLE" />
                <Tab label="BAR" />
                <Tab label="PIE" />
              </Tabs>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Area</TableCell>
                      <TableCell># of Order</TableCell>
                      <TableCell># of Customer</TableCell>
                      <TableCell># of Product</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Base Quantity</TableCell>
                      <TableCell>Sub Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Gujarat</TableCell>
                      <TableCell>1763</TableCell>
                      <TableCell>472</TableCell>
                      <TableCell>975</TableCell>
                      <TableCell>187355.00</TableCell>
                      <TableCell>187345.00</TableCell>
                      <TableCell>12467282.03</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </div>

        {/* Filter Options */}
        <div className="col-md-3">
          <Box >
            <FormControl>
              <InputLabel>Period</InputLabel>
              <Select defaultValue="Last 180 days">
                <MenuItem value="Last 180 days">Last 180 days</MenuItem>
                <MenuItem value="Custom">Custom</MenuItem>
              </Select>
            </FormControl>


            <FormControl>
              <TextField
                type="date"
                label="From"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <TextField
                type="date"
                label="To"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField label="Customer" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Ordered by" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Price Group" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Customer Category" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Product Category" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Product" size="small" variant="outlined" />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <TextField label="Segment" size="small" variant="outlined" />
            </FormControl>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary">
                APPLY
              </Button>
              <Button variant="text">Clear Filters</Button>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
}
