import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from "moment";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Pagination from "../../../shared/components/pagination";
import EditIcon from "../../../shared/icons/editIcon";
import store from '../../../redux/reduxstore';
export default function SelesReturntable({
  selectedIds,
  totalItems,
  setTotalItems,
  setSelectedIds,
  activityList,
  setActivityList,
}) {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [SalesReturn, setSalesReturnTerm] = useState(""); // Search term for Sales Return
  const [Customer, setCustomerTerm] = useState(""); // Search term for Customer
  const [Reason, setReasonTerm] = useState("");
  const [Amount, setAmountTerm] = useState("");
  const [ReturnBy, setReturnByTerm] = useState("");
  const [SalesReturnStatus, setSalesReturnStatusTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [errors, setErrors] = useState({});

  const name = useSelector(state => state.auth.name);
  const userData = store.getState().auth.userData
  console.log(userData,"---[-")

  const userDataRef = useRef(userData);
  useEffect(() => {
      // Update the ref whenever isExamStarted changes
      userDataRef.current = userData;
    }, [userData]);

  const pageSize = 10;

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList);
  }, [activityList]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = { page, limit: pageSize };
      const response = await fetchData("salesreturn", requestBody, pagination);

      if (response?.data) {
        setActivityList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const getStatusClass = (status) => {
    switch (status) {
      case "Close":
        return "Close";
      case "Approved":
        return "Approved";
      case "Rejected":
        return "Rejected";
      case "Cancelled":
        return "Cancelled";
      case "Pending":
        return "Pending";
      case "Open":
        return "Open";
      default:
        return "";
    }
  };

  const onRangeChange = (dates) => setSelectedDates(dates);

  const filteredActivityList = activityList.filter((item) => {
    const matchesDateRange =
      selectedDates.length === 2
        ? dayjs(item.selesreturnData).isSameOrAfter(selectedDates[0], "day") &&
          dayjs(item.selesreturnData).isSameOrBefore(selectedDates[1], "day")
        : true;

    return (
      (!SalesReturn ||
        (item.salesReturnNo?.toString() || "").toLowerCase().includes(SalesReturn.trim().toLowerCase())) &&
      (!Customer ||
        item.customerName?.toLowerCase().includes(Customer.trim().toLowerCase())) &&
      (!Reason ||
        item.reason?.toLowerCase().includes(Reason.trim().toLowerCase())) &&
      (!Amount ||
        item.totalamount?.toString().includes(Amount.trim())) &&
      (!ReturnBy ||
        item.userName?.toLowerCase().includes(ReturnBy.trim().toLowerCase())) &&
      (!SalesReturnStatus ||
        item.status
          ?.toLowerCase()
          .includes(SalesReturnStatus.trim().toLowerCase())) &&
      matchesDateRange
    );
  });

  const { RangePicker } = DatePicker;
  const rangePresets = [
    { label: "Last 7 Days", value: [dayjs().subtract(7, "day"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().subtract(14, "day"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().subtract(30, "day"), dayjs()] },
  ];

  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div style={{ width: '100%' }}></div></th>
              <th><div style={{ width: '100%' }}></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Sales Return"
                value={SalesReturn}
                onChange={(e) => setSalesReturnTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px" }}

              /></div></th>

        
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>

              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Customer"
                value={Customer}
                onChange={(e) => setCustomerTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

              /></div></th>

              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder="Reason"
                value={Reason}
                onChange={(e) => setReasonTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' ,padding:"7px", borderRadius:"3px" }}

              /></div></th>

              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Amount"
                  value={Amount}
                  onChange={(e) => setAmountTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

                /></div></th>

<th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Return By"
                  value={ReturnBy}
                  onChange={(e) => setReturnByTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

                /></div></th>

<th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Sales Return Status"
                  value={SalesReturnStatus}
                  onChange={(e) => setSalesReturnStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

                /></div></th>
            </tr>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === activityList.length}
                />
              </th>
              <th>Action</th>
              <th>Sales Return</th>
              <th>Date</th>
              <th>Customer</th>
              <th>Reason</th>
              <th>Amount</th>
              <th>Return By</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredActivityList.reverse().map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedIds.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </td>
                <td>
                  <Link to={`add/${item.id}`}>
                    <EditIcon />
                  </Link>
                </td>
                <td>{item.salesReturnNo || "N/A"}</td>
                <td>{dayjs(item.selesreturnData).format("YYYY-MM-DD") || "N/A"}</td>
                <td>{item.customerName || "N/A"}</td>
                <td>{item.reason || "N/A"}</td>
                <td>{item.totalamount || "N/A"}</td>
                <td>{item.userName || "N/A"}</td>
                <td onClick={(e) => e.stopPropagation()}>
                <Link to={{ pathname: `view/${item.id}` }}>
                      <button className={` ${getStatusClass(item.salesReturnstatus)}`}>
                        {item.salesReturnstatus}
                      </button>
                    </Link>
                    {/* <button className={` ${getStatusClass(item.salesReturnstatus)}`}
                     style={{ pointerEvents: "none", cursor: "default" }}>
                        {item.salesReturnstatus}
                      </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
