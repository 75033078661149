// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const initialState = {
    isAuthenticated: false,
    user: null,
    name: '',
    totalorder: '',
    token: '',
    openorder: '',
    cancelorder: '',
    pendingorder: '',
    closeorder: '',
    userData: {},
    company_setting:{},
    feature:[],
 
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.feature=action.payload.feature;
            state.company_setting=action.payload?.company_setting;
            state.userData = action.payload.user;
        },
        company_setting: (state, action) => {
            state.company_setting=action.payload?.company_setting;
        },
        setUsername: (state, action) => {
            state.name = action.payload; // Directly set the username from the action payload
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.name = null;
            state.token = null;
            state.userData = null;
        },
        setTotalorder: (state, action) => {
            state.totalorder = action.payload;
        },
        totalopenorder: (state, action) => {
            state.openorder = action.payload;

        },
        totalpendingorder: (state, action) => {
            state.pendingorder = action.payload;

        },
        totalcancelorder: (state, action) => {
            state.cancelorder = action.payload;

        },
        totalcloseorder: (state, action) => {
            state.closeorder = action.payload;

        },

    },
});

export const { login, logout, setUsername, setTotalorder, totalopenorder, totalpendingorder, totalcancelorder, totalcloseorder,company_setting } = authSlice.actions;
export default authSlice.reducer;
