import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditPaymentCollection() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [customerName, setCustomerName] = useState([]);
    const [paymentType, setPaymentType] = useState([]);
    const name = useSelector(state => state.auth.name);

    const [formData, setFormData] = useState({
        cName: '',
        customerId: '',
        paymentno: '',
        date: dayjs(),
        paymenttypeId: '',
        paymenttypeName: '',
        amount:null,
        description: '',
        created_by: '',
        status: true,
        paymentstatus: 'pending',
        paymentnotification: [],
        updatedpayment: '',
        updatedby: '',
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        getData();
    }, []);


    const getData = async () => {
        try {
            const response = await fetchData(`customer`);
            if (response?.data) {
                const formattedCustomerNames = response.data.map(i => ({
                    label: `[${i?.code}] ${i?.cName}`,
                    key: 'cName',
                    id: i?.id,
                    name: `[${i?.code}] ${i?.cName}`,
                    caddress: i?.address,
                    Email: i?.email,
                    id: i?.id,
                    value: i?.id

                }));
                setCustomerName(formattedCustomerNames);
            }
            const responsee = await fetchData(`paymenttype`);
            if (responsee?.data) {
                const paymentttype = responsee.data.map(i => ({
                    label: i.name, // Display label for the option
                    name: i.name, // Display label for the option
                    key: 'paymenttype', // Optional: for unique identification if needed
                    value: i.id // Value should be the primitive `id` or `name` if used for comparison
                }));
                setPaymentType(paymentttype);
                console.log(paymentType);
            }

            if (id) {
                const response = await fetchsingleData("paymentcollection", id);
                if (response?.data) {
                    const fetchedData = response.data;
                    fetchedData.date = dayjs(fetchedData.date); // Convert the date to Day.js object
                    setFormData(fetchedData); // Update state with fetched data
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e) => {
        const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`paymentcollection/${id}`, { ...formData, updatedpayment: currentDateTime, updatedby: name }).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('paymentcollection', { ...formData, created_by: name }).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };
    console.log("formdata", formData)
    const selectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`${e?.key}Id`]: e?.value,
            [`${e?.key}Name`]: e.name,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const selectCustChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`${e?.key}`]: e?.name,
            [`customerId`]: e?.id,
            [`address`]: e?.cadd,
            ['email']: e?.Email,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit) validationCheck();
    };

    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            date
        }));
        if (errors.isSubmit) validationCheck();
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};

        if (!(formData.amount > 0)) {
            validationErrors.amount = 'Please enter amount';
        }
        if (!(formData.cName)) {
            validationErrors.cName = 'Please Select Cutomer';
        }
        if (!(formData.date)) {
            validationErrors.date = 'Please Select Date';
        }

        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };
    const styles = {
        pageHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            top: 'auto',
        },
        twoButtonAlignment: {
            display: 'flex',
            gap: '10px'
        },
        formContainer: {
            marginBottom: '20px'
        },
        grid: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',

        },
        inputWrapper: {
            flex: '1 1 calc(33.33% - 20px)', // This will ensure three select boxes in one row
            minWidth: '300px'
        },
        input: {
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box'
        }

        // const styles = {
        //     pageHeader: {
        //         display: 'flex',
        //         justifyContent: 'space-between',
        //         alignItems: 'center',
        //         padding: '10px 20px',
        //         backgroundColor: '#f0f0f0',
        //         borderBottom: '1px solid #d0d0d0',
        //         marginBottom: '20px',
        //     },
        //     formContainer: {
        //         padding: '20px',
        //         backgroundColor: '#fff',
        //         borderRadius: '8px',
        //         boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        //     },
        //     grid: {
        //         display: 'grid',
        //         gridTemplateColumns: '1fr 1fr',
        //         gap: '20px',
        //         marginBottom: '20px',
        //     },
        //     inputWrapper: {
        //         marginBottom: '20px',
        //     },
        //     buttonAlignment: {
        //         display: 'flex',
        //         justifyContent: 'space-between',
        //     }
    };

    return (
        <div className='page-24'>
            <div className='add-header-design'>
                <div>
                    <h2>Add Payment Collection</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Payment Collection" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/payment-collection">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>

            {/* <div style={styles.formContainer}>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Payment No' name='paymentno' value={formData.paymentno} onChange={handleInputChange} placeholder='Payment no' type='text' errors={errors.paymentno} required />
                    </div>
                    <div style={styles.inputWrapper}>
                        <SelectBox label='Customer Name' name='cName' value={customerName?.find(i => i.value.name === formData.cName)} onChange={selectCustChanged} options={customerName} placeholder='Customer name' errors={errors.unit} required />
                    </div>
                </div>
                <div style={styles.inputWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label='Date' name='date' value={formData.date} onChange={handleDateChange} />
                    </LocalizationProvider>
                </div>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <SelectBox label='Payment Type' name='paymenttype' value={paymentType?.find(i => i.value.name === formData.paymenttype)} onChange={selectChanged} options={paymentType} placeholder='Payment type' errors={errors.unit} required />
                    </div>
                </div>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Amount' name='amount' value={formData.amount} onChange={handleInputChange} placeholder='Enter amount' type='text' errors={errors.amount} required />
                    </div>
                </div>
                <div style={styles.grid}>
                    <div style={styles.inputWrapper}>
                        <Input label='Description' name='description' value={formData.description} onChange={handleInputChange} placeholder='Enter description' type='text' errors={errors.description} required />
                    </div>
                </div>
            </div> */}
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label='Payment No' name='paymentno' value={formData.paymentno} onChange={handleInputChange} placeholder='Payment no' type='text' errors={errors.paymentno} required />
                            <SelectBox label={
                                <span>
                                    Customer Name<span className="required-star">*</span>
                                </span>}

                                name='cName' value={customerName?.find(i => i.id === formData.customerId)} onChange={selectCustChanged} options={customerName} placeholder='Customer name' errors={errors.cName} required />
                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DatePicker label={
                                    <span>
                                        Date<span className="required-star">*</span>
                                    </span>}
                                    name='date' value={formData.date} onChange={handleDateChange} />
                            </LocalizationProvider>
                            <SelectBox
                                options={paymentType}
                                label="Payment Type"
                                onChange={selectChanged}
                                value={paymentType?.find(
                                    (option) => option.value.id === formData.paymenttypeId
                                )}
                                required
                            />
                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label={
                                <span>
                                    Amount<span className="required-star">*</span>
                                </span>}

                                name='amount' value={formData.amount} onChange={handleInputChange} placeholder='Enter amount' type='text' errors={errors.amount} required />
                            <Input label='Description' name='description' value={formData.description} onChange={handleInputChange} placeholder='Enter description' type='text' errors={errors.description} required />

                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>
        </div>

    );
}
