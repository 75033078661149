import React, { useState, useEffect } from "react";
import "./invoicetable.scss";
import { Link,useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import plugin
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import Pagination from '../../../shared/components/pagination';
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";


export default function Invoicetable({ selectedIds, setSelectedIds, activityList,setActivityList }) {
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin
  const navigate = useNavigate(); 
  const [invoiceList, setInvoiceList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for customerName
  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10;
  const [createdByTerm, setCreatedByTerm] = useState(""); // Search term for created by
  const [customers, setCustomerOptions] = useState([]);
  const [orderTerm, setOrderTerm] = useState("");
  const [AmountTerm, setAmountTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [invoiceTerm, setinvoiceTerm] = useState("");
  const user = useSelector(state => state.user.user);
  
    useEffect(() => {
    getCustomer();
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    setTotalItems(activityList.length)
  }, [activityList])

  const { RangePicker } = DatePicker;

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }
      const response = await fetchData("invoice",requestBody, pagination);
      if (response?.data) setInvoiceList(response?.data);
      if (response?.data) {
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.customerId,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
 

  // Combined search filter logic for customerName, status, orderDate, and createdBy
  const filteredActivityList = activityList.filter((item) => {
    const matchesCustomerName = item.customerName?.toLowerCase().includes(searchTerm?.toLowerCase());
    const matchesStatus = 
    typeof item.status === 'string' && 
    item.status.toLowerCase().includes(statusTerm?.toLowerCase() || '');
  
    const matchesinvoice = item.invoiceNo?.toLowerCase().includes(invoiceTerm?.toLowerCase());

    // const matchesCreatedBy = item.ordercreatedby?.toLowerCase().includes(createdByTerm?.toLowerCase());
    // const matchesOrder = item.orderNo?.toLowerCase().includes(orderTerm?.toLowerCase());
    const matchesAmount = item.productDetails?.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.subTotal); // Sum the subtotal of each product
    }, 0).toFixed(2);

    const matchesAmountSearch = Number(matchesAmount) === Number(AmountTerm);

   
    const matchesDateRange = (selectedDates && selectedDates.length === 2)
      ? dayjs(item.invoiceDate).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.invoiceDate).isSameOrBefore(selectedDates[1], 'day')
      : true; 
    return (
      (!searchTerm || matchesCustomerName) &&
      (!statusTerm || matchesStatus) &&
      (invoiceTerm||matchesinvoice)&&
      // (!createdByTerm || matchesCreatedBy) &&
      // (!orderTerm || matchesOrder) &&
      (!AmountTerm || matchesAmountSearch) &&
      matchesDateRange // Ensure the item matches the selected date range
    );
  });

 
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (

    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Invoice"
                value={invoiceTerm}
                onChange={(e) => setinvoiceTerm(e.target.value)}
              className="w-100 border-0"
              /></div></th>
              <th>
  <div >
    <SelectBox
      value={customers?.find((option) => option.label === searchTerm) || null}
      options={customers}
      onChange={(selectedOption) => setSearchTerm(selectedOption?.label || '')} // Clear if null
      onClear={() => setSearchTerm('')} // Clear button handler
      placeholder="Customer"
      type="text"
      required={true}
      errors={errors.customerName}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999, // Ensure dropdown is on top of other elements
          maxHeight: '400px',
          overflowY: 'auto',
          border: 'none', // Enable scrolling if the dropdown is too large
        }),
        control: (provided) => ({
          ...provided,
          minWidth: '100%',
          border: 'none',
          marginTop: '-8px',
          maxHeight: '25px',
          textAlign: 'left',
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          border: 'none', // Render the dropdown with a very high z-index
        }),
      }}
      menuPortalTarget={document.body}
      isClearable={true} // Add clear button
    />
  </div>
</th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange}  placeholder={[' Start Date', ' End Date']}  className="my-custom-class" />
                </Space></div> </th>
              <th><div className="tableSize"><input
                type="text"
                placeholder=" Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
               className="w-100 border-0"
              /></div></th>
              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder=" Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                className="w-100 border-0"
                /></div></th>
            </tr>
            <tr>
             
                <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
                 {checkAccess('edit_invoice')&&<th>Action</th>}
              <th>Invoice #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Amount</th>
             <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* {filteredActivityList?.reverse().map((i, index) => (
           
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
                 
                }}
              >
                {('true' === user.manageorder) && (
                  <td>
                    <input
                      type='checkbox'
                      checked={selectedIds.includes(i.orderId)}
                      onChange={() => handleCheckboxChange(i.orderId)}
                    />
                  </td>
                )}
                {('true' === user.manageorder) && (
                  <td>
                    <Link to={{ pathname: `add/${i?.orderId}` }}>
                    
                    </Link>
                  </td>
                )}

                <td className="whitesapce-nowrap">{i?.orderNo}</td>
                <td><span className="price-group-text">{i?.customerName}</span></td>
                <td className="whitesapce-nowrap">{moment(i?.orderDate).format('DD-MM-YYYY')}</td>
                <td>
                  {i?.products?.reduce((accumulator, currentValue) => {
                    return accumulator + Number(currentValue.subTotal);
                  }, 0).toFixed(2)}
                </td>
                <td>{i?.ordercreatedby}</td>
                <td>
                  {user.readorder === 'true' ? (
                    <Link to={{ pathname: `view/${i?.orderId}` }}>
                      <button className={` ${getStatusClass(i.orderStatus)}`}>
                        {i?.orderStatus}
                      </button>
                    </Link>
                  ) : (
                    <button className={` ${getStatusClass(i.orderStatus)}`}>
                      {i?.orderStatus}
                    </button>
                  )}
                </td>
              </tr>
))} */}
           {filteredActivityList?.reverse().map((i, index) => {
  const currentDate = moment().format('DD-MM-YYYY'); 
  const invoiceDate = moment(i?.invoiceDate).format('DD-MM-YYYY');

  return (
    <tr
      key={i.id} 
      style={{
        backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
        cursor: checkAccess('read_invoice') ? 'pointer' : 'default', 
      }}
      onClick={() => {
        if (checkAccess('read_invoice')) {
          navigate(`/invoice/view/${i.id}`);
        }
      }}
    >
     
      <td>
        <input
          type="checkbox"
          checked={selectedIds.includes(i.id)}
          onClick={(e) => e.stopPropagation()} 
          onChange={() => handleCheckboxChange(i.id)}
        />
      </td>

      
      {checkAccess('edit_invoice') && (
        <td
          onClick={(e) => e.stopPropagation()} 
        >
          <Link to={`/invoice/add/${i.id}`}>
            <EditIcon />
          </Link>
        </td>
      )}

     
      <td
        className="whitesapce-nowrap"
        style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
      >
        {i?.invoiceNo}
      </td>

      
      <td>
        <span
          className="price-group-text"
          style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
        >
          {i?.customerName}
        </span>
      </td>

      
      <td
        className="whitesapce-nowrap"
        style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
      >
        {invoiceDate}
      </td>

    
      <td
        style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
      >
        {i?.orderproducts
          ?.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.subTotal);
          }, 0)
          .toFixed(2)}
      </td>

     
      <td
        style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="status">
          {i?.status ? 'Active' : 'Inactive'}
        </button>
      </td>
    </tr>
  );
})}


          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
