import React, { useEffect, useRef, useState } from 'react'
import { persistStore, purgeStoredState } from 'redux-persist';
import './header.scss';
import store from '../../../redux/reduxstore';
import { useDispatch, useSelector } from 'react-redux';
import ProfileIcon from '../../../assets/icons/profile.webp';
import Mobileviewsidebar from '../mobileviewsidebar';
import { useNavigate } from 'react-router-dom';
import {signOutUser} from '../../../services/cognitoService';
import { logout as logoutinAction,} from '../../../redux/auth';
import { clearuser } from '../../../redux/rba';
import { persistor } from '../../../redux/reduxstore';
import DownIcon from '../../../assets/icons/down-arrow.svg';
import CalanderIcon from '../../../assets/icons/calander.svg';
import EmailIcon from '../../../assets/icons/email.svg';
import NotificationIcon from '../../../assets/icons/notification.svg';
import CountryIcon from '../../../assets/icons/country.svg';
import Searchbar from './searchbar';
import { notify } from '../../../routes/toastr/Toastr';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { fetchData, patchData } from '../../../services/apiService';
import { Button, List, Skeleton } from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { connectSocket, disconnectSocket, emitEvent, addEventListener, removeEventListener } from '../../../services/socketService'
import { CardBody } from 'react-bootstrap';

export default function Header({ pageTitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toogle, setToogle] = useState(false);
  const [mobileheader, setMobileheader] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const [notifications, setNotifications] = useState([])
  const [page, setPage] = useState(1)
  const [notificationEditingId, setNotificationEditingId] = useState()
  // const userData=useSelector(state=>state.userData);
  const name=useSelector(state=>state.user.user);

  
  const userData = store.getState().auth.userData

  const userDataRef = useRef(userData);

  useEffect(() => {
    // Update the ref whenever isExamStarted changes
    userDataRef.current = userData;
  }, [userData]);

  const signout =async ()=>{  
    await signOutUser();
    dispatch(logoutinAction());
    dispatch(clearuser());
    notify.info('You have successfully logged out.');
    // navigate('/login')
    persistor.purge().then(() => {
      console.log("Persisted state cleared.");
      console.log("User state after clearing:", name);
      navigate('/login');
    });
  }
  const dashboard = async()=>{
    navigate('/dashboard');
  }

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const handleIconClick = () => {
    setShowCalendar((prev) => !prev);
  };

  useEffect(() => {
    getNotitifications();

    //()

    return () => {
     // disconnectSocket();
    };
  }, []);

  useEffect(() => {
    // Event listener for full-screen change
    const handleFullscreenChange = async (body) => {
      // console.log(body?.userId === userDataRef.current?.id);
      
      if (body?.userId === userDataRef.current?.id){
        // const tempNotifications = [...notifications]
        // tempNotifications.push(body)
        // console.log(tempNotifications.length);
        
        // setNotifications(tempNotifications)

        getNotitifications()
      }
    };

    addEventListener('inAppNotification', handleFullscreenChange);
    
    // Cleanup the event listeners when the component is unmounted
    return () => {
      removeEventListener('inAppNotification', handleFullscreenChange);
    };
  }, []);

  

  const getNotitifications = async () => {
    try {
      const response = await fetchData("notification", { userId : userData.id}, {}, {
        page : page,
        limit : 3
      });
      if (response?.data) {
        setNotifications(
          [...response.data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        );
        // const dataToBeSet = response.data.toSorted((a, b) => a.id >  b.id)
        // setNotifications(dataToBeSet); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setShowCalendar(false); // Close the popup on date selection
  };
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(10); // Example count, update as needed
  
  // Toggle popup open/close on click
  const togglePopup = (e) => {
    e.stopPropagation();  // Prevent the event from bubbling up to document or parent
    setIsPopupOpen(!isPopupOpen);
  };

  // Handle mouse enter and leave, only open on hover unless clicked
  const handleMouseEnter = () => {
    if (!isPopupOpen) setIsPopupOpen(true);
  };
  
  const handleMouseLeave = () => {
    if (!isPopupOpen) setIsPopupOpen(false);
  };

  const readButtonClicked = (e, notificationId) => {
    setNotificationEditingId(notificationId)
    patchData(`notification/${notificationId}`, {readStatus : true}).then(data => {
      // navigate(-1);
      const tempNotifications = [...notifications];
      setNotifications(tempNotifications.map((e) => {

        return e.id === notificationId ? data.data : e
      }))
      // console.log('API response:', data);
      setNotificationEditingId("")
    })
      .catch(error => {
        console.error('Error fetching data:', error);
        setNotificationEditingId("")
      });
  }
  return (
    <>
      <header>
        <div className='header-alignment'>
          <div className='search-design-change'>
          <Searchbar/>
          </div>
          <div className='right-content-alignment'>
            <div>
              <div className="calendar-icon" onClick={handleIconClick} style={{ cursor: "pointer" }}>
                <img src={CalanderIcon} alt="Calendar Icon" />
              </div>

              {showCalendar && (
                 <div
                 className="calendar-popup"
                 style={{ position: "absolute", left: "50%", zIndex: 1000 }}
               >
                 <LocalizationProvider dateAdapter={AdapterDayjs}>
                   <StaticDatePicker
                     orientation="landscape"
                     value={selectedDate} // Bind selected date
                     onChange={(newDate) => setSelectedDate(newDate)} // Update state on change
                   />
                 </LocalizationProvider>
               </div>
              )}

            </div>
            {/* <div className='icon-counter-alignment'>
              <img src={EmailIcon} alt="EmailIcon"/>
              <div className='counter'>2</div>
            </div> */}
            <div>
              <div
                className="icon-counter-alignment"
                onMouseEnter={handleMouseEnter}  // Open popup on hover (if not clicked)
                onMouseLeave={handleMouseLeave}  // Close popup on hover leave (if not clicked)
                onClick={togglePopup}  // Toggle popup on click (prevents auto-close after click)
              >
                <img src={NotificationIcon} alt="Notification Icon" />
                <div className="counter">{notifications?.length}</div>
              </div>

              {/* Notification Popup */}
              {isPopupOpen && (
                <div className="notification-popup" style={{ height: '300px', overflowY: 'auto', border: '1px solid #ddd' }}>
                  <div className="popup-header">
                    <h4>Notifications</h4>
                    <a className="" onClick={togglePopup}>X</a>
                  </div>
                  {/* <div className="popup-content">
                    <p>You have {notifications.length} new notifications.</p>
                  </div> */}
                  {/* {
                    notifications.map((e) => {
                      return <div className="popup-content">
                        <p>{e.notificationText}</p>
                      </div>
                    })
                  } */}

                  <List
                    dataSource={notifications}
                    renderItem={(item) => (
                      <List.Item
                        actions={[<Button icon={notificationEditingId === item.id ? <LoadingOutlined/> : <></>} variant="link" onClick={(e) => readButtonClicked(e, item.id)} disabled={item.readStatus}>Mark as read</Button>]}
                      >
                        <Skeleton avatar title={false} loading={item?.loading} active>
                          
                          <div>{item.notificationText}</div>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </div>
              )}
            </div>
            {/* <div className='country-img'>
              <img src={CountryIcon} alt="CountryIcon"/>
            </div> */}
        <div className='profile-all-contnet-alignment'>
          <div className='profile'>
            <div className='profile-contnet-alignment'>
              <img className='img' src={ProfileIcon} alt="ProfileIcon"  />
              <div className='right-content'>
                <div>
                <p>
                {userData?.name || "Guest"}
              </p>
              <span>{userData?.role || "User"}</span> 
                </div>
                <div onClick={() => setToogle(!toogle)}>
                  <img src={DownIcon} alt="DownIcon"/>
                </div>
              </div>
            </div>
            <div className={toogle ? 'profile-design show' : 'profile-design hide'}>
              <span>Profile</span>
              <span onClick={dashboard}>Dashboard</span>
              <span>Post & Activity</span>
              <span>Setting & Privacy</span>
              <span>Help Center</span>
              <span onClick={signout}>Logout</span>
            </div>
          </div>
          <i className="fa-solid fa-bars" onClick={() => setMobileheader(!mobileheader)}></i>
        </div>
        </div>
        </div>
       
      </header>
      <Mobileviewsidebar mobileheader={mobileheader} setMobileheader={setMobileheader} />
    </>
  )
}
