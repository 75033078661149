import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditleave() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [UserName, setUserName] = useState([]);
    const [LeaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const name = useSelector(state => state.auth.name);

    const [formData, setFormData] = useState({
        leaveno: null,
        username: '',
        date: dayjs(),
        leavetype: '',
        reason: '',
        leaveweight: '',
        created_by: '',
        status: 'Pending',
        user_id: null,
    });
    const LeaveWeightOptions = [
        { label: "Full Day", value: "Full Day" },
        { label: "Half Day", value: "Half Day" },
        { label: "Quarter Day", value: "Quarter Day" },
    ];
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getData();
    }, []);


    // const getData = async () => {
    //     try {

    //         const response = await fetchData(`user`);
    //         if (response?.data) {
    //             setUserName(
    //                 response?.data?.map((i) => {
    //                     return {
    //                         label: `${i?.name}`,
    //                         key: "username",
    //                         value: {

    //                             name: ` ${i?.name}`,

    //                         },
    //                     };
    //                 })
    //             );
    //         }
    //         if (id) {
    //             const response = await fetchsingleData("leave", id);
    //             if (response?.data) {
    //                 const fetchedData = response.data;
    //                 fetchedData.date = dayjs(fetchedData.date); 
    //                 setFormData(fetchedData); 
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };
    const getData = async () => {
        try {
            // Fetch users
            const userResponse = await fetchData(`user`);
            if (userResponse?.data) {
                setUserName(
                    userResponse.data.map((user) => ({
                        label: user.name, // Display name in dropdown
                        key: "userid",
                        value: user.id, // Save user ID
                        name: user.name, // Additional name information if needed
                    }))
                );
            }
            const leaveTypeResponse = await fetchData(`leavetype`);
            if (leaveTypeResponse?.data) {
                setLeaveTypeOptions(
                    leaveTypeResponse.data.map((i) => ({
                        label: i.name,
                        key: "leavetype",
                        value: i.name,

                    }))
                );
            }

            // Fetch existing leave data if editing
            if (id) {
                const response = await fetchsingleData("leave", id);
                if (response?.data) {
                    const fetchedData = response.data;
                    fetchedData.date = dayjs(fetchedData.date);
                    setFormData(fetchedData);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleSubmit = (e) => {
        const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
        e.preventDefault();
        if (validationCheck()) {
            console.log(1);
            try {
                if (id) {
                    patchData(`leave/${id}`, formData).then(data => {
                        if(data){
                            navigate(-1);
                            console.log('API response:', data);}
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('leave', formData ).then(data => {
                        if(data){
                            navigate(-1);
                            console.log('API response:', data);}
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };
    console.log("formdata", formData)
    const selectChanged = (selectedOption) => {
        setFormData((prevState) => ({
            ...prevState,
            user_id: selectedOption.value,
            username: selectedOption.name,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit) validationCheck();
    };

    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            date: date ? dayjs(date) : null, // Ensure the date is either dayjs or null
        }));
        if (errors.isSubmit) validationCheck();
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};

        if (!(formData.username)) {
            validationErrors.username = 'Please Select User';
        }
        if (!(formData.date)) {
            validationErrors.date = 'Please Select Date';
        }
        if (!formData.leavetype) {
            validationErrors.leavetype = "Please select leave type";
        }
        if (!formData.leaveweight) {
            validationErrors.leaveweight = "Please select leave weight";
        }

        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };
    const styles = {
        pageHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            top: 'auto',
        },
        twoButtonAlignment: {
            display: 'flex',
            gap: '10px'
        },
        formContainer: {
            marginBottom: '20px'
        },
        grid: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',

        },
        inputWrapper: {
            flex: '1 1 calc(33.33% - 20px)',
            minWidth: '300px'
        },
        input: {
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box'
        }


    };

    return (
        <div className='page-24'>
            <div className='add-header-design'>
                <div>
                    <h2>Add Leave</h2>
                    <Breadcumbs activePath="Leave" pageName="Add Leave" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/leave">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>


            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label='Leave No' name='leaveno' value={formData.leaveno} onChange={handleInputChange} placeholder='Leave no' type='text' errors={errors.leaveno} required />
                            <SelectBox label={
                                <span>
                                    UserName <span className="required-star">*</span>
                                </span>}
                                name='username' value={UserName?.find(i => i.name === formData.username)} onChange={selectChanged} options={UserName} placeholder='User' errors={errors.username} required />
                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DatePicker label={
                                    <span>
                                        Date<span className="required-star">*</span>
                                    </span>}
                                    name='date' value={formData.date} onChange={handleDateChange} />
                            </LocalizationProvider>

                            <SelectBox
                                label={
                                    <span>
                                        Leave Weight <span className="required-star">*</span>
                                    </span>
                                }
                                name="leaveweight"
                                value={LeaveWeightOptions.find((option) => option.value === formData.leaveweight)}
                                onChange={(e) =>
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        leaveweight: e.value,
                                    }))
                                }
                                options={LeaveWeightOptions}
                                placeholder="Select Leave Weight"
                                errors={errors.leaveweight}
                                required
                            />

                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <SelectBox
                                label={
                                    <span>
                                        Leave Type <span className="required-star">*</span>
                                    </span>
                                }
                                name="leavetype"
                                value={LeaveTypeOptions.find((i) => i.value === formData.leavetype)}
                                onChange={(e) =>
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        leavetype: e.value,
                                    }))
                                }
                                options={LeaveTypeOptions}
                                placeholder="Select Leave Type"
                                errors={errors.leavetype}
                                required
                            />

                            <Input label='Reason' name='reason' value={formData.reason} onChange={handleInputChange} placeholder='Enter Reason' type='text' errors={errors.reason} required />

                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>
        </div>

    );
}
