import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Attendancetable from './attendancetable';
import { deleteData } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";


export default function Attendance() {
  return (
    <div>
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Attendace</h2>
            <Breadcumbs activePath="Attendance" pageName="User Attendance" />
          </div>
        </div>
      </div>
      <div className='price-group-table-alignment'>
        <Attendancetable></Attendancetable>
      </div>
    </div>
  )
}
