import React,{useState} from 'react'
import { deleteData } from '../../services/apiService';
import './pricegroup.scss';
import Button from '../../shared/components/button';
import PricegroupTable from './pricegroupTable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { filterAll } from '../../services/apiService';
import { checkAccess } from '../../services/checkFeature';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
export default function Pricegroup() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [PriceList, setpricelist] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleExport = () => {
    const headers = [
      ["Name", "Code","Price", "Discount","Offer Discount", "Status"]
    ];
 
    filterAll("pricegroup")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.code,
        item.price,
        item.discount,
        item.offerdiscount,
        item.status ? "Active" : "Inactive",
      ]);
      exportToExcel('Pricegroup',headers,datas,'pricegroup')
    })
  };
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`pricegroup/${id}`)));
      setpricelist(PriceList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };
  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};
  return (
    <div>
      {/* <div className='page-header'>
        <span>Price Group</span>
        <Link to="addprice"><Button text='Add Price'/></Link>
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Price Group</h2>
            <Breadcumbs activePath="Price Group" pageName="Price Group" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {checkAccess('manage_price_group')&&<Link to="addprice">
              <GlobalButton text="Add Price Group" Icon={PlusIcon} />
            </Link>}
            {checkAccess('manage_price_group')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} />}
          </div>
        </div>
      </div>
      <div className='price-group-table-alignment'>
        <PricegroupTable
         selectedIds={selectedIds}
         setSelectedIds={setSelectedIds}
         PriceList={PriceList}
         setProductList={setpricelist}
         totalItems={totalItems}
         setTotalItems={setTotalItems}/>
      </div>
    </div>
  )
}
