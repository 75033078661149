import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { useSelector } from 'react-redux';

export default function Leaveview() {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        orderStatus: 'Pending',
        ordernotification: [],

    });
    const [newNotification, setNewNotification] = useState([]);
    const [timelines, setTimelines] = useState([]);

    const handleSubmit = async (e, status) => {
        e.preventDefault();

        try {
            const allowedStatuses = ['Open', 'Close', 'Pending', 'Confirmed', 'Dispatched', 'Delivered'];
            if (!allowedStatuses.includes(status)) {
                console.error('Invalid order status:', status);
                return;
            }

            const currentDateTime = dayjs().format('DD-MM-YYYY, ⏱︎hh:mm:ss A');
            const updatedNotification = { date: currentDateTime, status: status, user: name };

            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            const updatedFormData = {
                orderStatus: status,
            };

            if (id) {
                const data = await patchData(`leave/${id}`, updatedFormData);
                if (data?.data) {
                    setFormData({ ...formData, orderStatus: status });
                }
            } else {
                const data = await postData('leave', updatedFormData);
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData(`leave`, id);
            if (response?.data) {
                setFormData(response.data);
                if (response.data.ordernotification) {
                    setNewNotification(response.data.ordernotification);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (

        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Leave {'>'} List</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>

                    <a href="/order" style={{ textDecoration: 'none' }}>
                        <button style={{
                            backgroundColor: '#8CA7C6',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}><div style={{
                marginTop: '1rem',
                padding: '1.5rem',
                backgroundColor: '#fff',
                border: '1px solid rgba(30,46,80,.09)',
                borderRadius: '0.25rem',
                boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                width: '900px',
                scrollBehavior: 'smooth',
                height: 'auto',
                overflowY: 'scroll',

            }}>

                <><div style={{ display: 'flex' }}>
                    <div><h6 style={{ paddingBottom: '5px' }}>Leave from,</h6>
                        <p style={{ fontSize: '0.85rem' }}>
                       {formData.username}
                        </p></div>

                </div>

                    <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <div style={{ flex: '1', marginRight: '1rem' }}>
                            <strong> <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Date.</div></strong>
                               {dayjs(formData.date).format('DD-MM-YYYY')}
                            </div>
                            <div style={{ flex: '1', textAlign: 'center' }}>
                            <strong> <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Type</div></strong>
                                {formData.leavetype}
                            </div>
                            <div style={{ flex: '1', textAlign: 'right' }}>
                            <strong><div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Weight</div></strong>
                                {(formData.leaveweight)}
                            </div>
                        </div>
                    </div>

                    <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <div style={{ flex: '1', marginRight: '1rem' }}>
                                <strong><div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Reason:</div></strong>
                                <strong>{formData.customerName}</strong>
                                <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                    {formData.reason}
                                   
                                </p>
                            </div>
                            <div style={{ flex: '1', textAlign: 'right' }}>
                                <strong><div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Status</div></strong>
                              {formData.status}
                            </div>
                        </div>
                    </div>
                </>

            </div>

                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'auto'
                }}>
                    {/* <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
                     */}
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
                    {timelines?.slice().reverse().map((i, index) => (
                        <div
                            key={i.id || index}
                            style={{
                                display: "flex",
                                paddingLeft: "3px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div style={{ paddingRight: "5px" }}>➨</div>
                            <div style={{ fontSize: "14px" }}>
                                Order <strong> {i.orderNo}</strong> generated by
                                <strong> {i.user_name}</strong> on <strong>  {dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎hh:mm:ss A')}</strong>.

                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>

    );
}