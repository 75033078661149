import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import CustomerComponent from "./ CustomerComponent";
import UserComponent from "./user";
import AreaComponent from "./AreaComponent";
import TimeComponent from "./Time";
import ProductComponent from "./Product";
import ProductCategoryComponent from "./ProductCategory";
import SegmentComponent from "./SegmentComponent";


// Main Component
export default function OrderReport() {
  const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  // Function to render the corresponding component based on selected tab
  const renderTabContent = () => {
    switch (mainTabValue) {
      case 0:
        return <CustomerComponent />;
      case 1:
        return <UserComponent />;
      case 2:
        return <AreaComponent />;
      case 3:
        return <TimeComponent />;

      case 4:
        return <ProductComponent />;
      case 5:
        return <ProductCategoryComponent />;
      case 6:
        return <SegmentComponent />;
      default:
        return <Typography variant="h6">No Data Available</Typography>;
    }
  };

  return (
    <Box p={4}>
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        border="1px solid #ccc"
        borderRadius="8px"
        p={2}
      >
        <Typography variant="h5" style={{ cursor: "pointer" }}>
          {"<"} Order Report by
        </Typography>

        <Box>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              padding: "8px 16px",
              fontSize: "14px",
              height: "36px",
              minWidth: "60px",
            }}
          >
            PRINT
          </Button>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              padding: "8px 16px",
              fontSize: "14px",
              height: "36px",
              minWidth: "60px",
              backgroundColor: "#B0B0B0",
            }}
          >
            EXPORT
          </Button>
        </Box>
      </Box>

      {/* Main Tabs */}
      <Tabs value={mainTabValue} onChange={handleMainTabChange}>
        <Tab label="Customer" />
        <Tab label="User" />
        <Tab label="Area" />
        <Tab label="Time" />
        <Tab label="Product" />
        <Tab label="Product Category" />
        <Tab label="Segment" />
      </Tabs>

      {/* Content Rendering */}
      <Box mt={4}>{renderTabContent()}</Box>
    </Box>
  );
}
