import React from 'react'

export default function EditIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5_3479)">
<path d="M0.879 14.339C0.316352 14.9015 0.000169912 15.6644 0 16.46L0 17.9998H1.53975C2.33533 17.9996 3.09827 17.6834 3.66075 17.1208L13.668 7.11353L10.8863 4.33179L0.879 14.339Z" fill="#858D9D"/>
<path d="M17.3587 0.641397C17.176 0.458573 16.9591 0.313538 16.7204 0.214584C16.4817 0.11563 16.2258 0.0646973 15.9674 0.0646973C15.709 0.0646973 15.4531 0.11563 15.2144 0.214584C14.9757 0.313538 14.7588 0.458573 14.5762 0.641397L11.9467 3.27165L14.7284 6.0534L17.3587 3.4239C17.5415 3.24126 17.6865 3.02439 17.7855 2.78567C17.8844 2.54695 17.9354 2.29106 17.9354 2.03265C17.9354 1.77423 17.8844 1.51835 17.7855 1.27963C17.6865 1.04091 17.5415 0.82403 17.3587 0.641397Z" fill="#858D9D"/>
</g>
<defs>
<clipPath id="clip0_5_3479">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}
