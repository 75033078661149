import React, { useState, useEffect,useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Button from '../../../shared/components/button';
import RightIcon from '../../../assets/icons/right-sm.svg';
import './leadview.scss';



 

export default function AddEditviewleadtable() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getData();
        }
        fetchUsers();
    }, [id]);

    const [leadstatus, setLeadStatus] = useState([]);
    const [timelines, setTimelines] = useState([]);

    const [userName, setUserName]=useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);
    


    const [formData, setFormData] = useState({
        name: '',
        leadsource: '',
        leadindustry: '',
        pricegroup: '',
        address: '',
        collabrator:'',
        collabratorId: '',
        leadOwner:'',
        leadOwnerId : '',
        title: '',
        city: '',
        area: '',
        pincode: '',
        country: '',
        state: '',
        latitude: '',
        longtitude: '',
        email: '',
        contactno: '',
        ownername: '',
        partnername: '',
        ed: '',
        ewc: '',
        turnover: '',
        leadstatus: '',
        userName:'',
        leadnotification: [],

    });

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            if (id) {
                const data = await patchData(`lead/${id}`);
                if (data) {
                    // setFormData(updatedFormData);
                }
            } else {
                const data = await postData('lead');
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    const [countryOptions, setCountryOptions] = useState([])
    const fetchUsers = async () => {
        try {
            const response = await fetchData("user");
    
            if(response?.data) {
                // Map the user names to create dropdown options
                const nameOptions = response.data.map(user => ({
                    label: user.name,  // The label to display in the dropdown
                    value: user.id     // The value associated with each option (you can use 'id' or any other unique property)
                }));
    
                setUserName(nameOptions); // Assuming this is the state to hold dropdown options
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    useEffect(()=>{

        const getData = async() =>{
            const requestBody = {
                "reference_type": "lead",
                reference_id : id
            }
             
            const responsetimeline = await fetchData("timeline",requestBody);
    console.log(responsetimeline);
            if (responsetimeline?.data) {
                setTimelines(responsetimeline.data);
              } 
        }

        getData()
      
    },[newNotification])

    const getData = async () => {
        try {
       
            const responsee = await fetchData(`leadStatus`);
            if (responsee?.data) {
                const formattedleadstatus = responsee.data.map(i => ({
                    label: i?.name,
                    key: 'leadstatus',
                    value: { name: i?.name }
                }));
                setLeadStatus(formattedleadstatus);
            }


            const response = await fetchsingleData("lead",id);
            if (response?.data) setFormData(response.data);
            if (response?.leadnotification) setNewNotification(response.data.leadnotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSelectChange = async (selectedValue) => {


        try {
            const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
            // const newNotification = { date: currentDateTime, status: selectedValue };
            // setNewNotification(newNotification =>({
            //     ...newNotification,
            //     date: currentDateTime, status: selectedValue

            // }))


            const updatedNotification = { date: currentDateTime, status: selectedValue,user: name };

            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
            // const updatedLeadNotification = [...formData.leadnotification, newNotification];

            if (id) {
                // const updatedData = { ...formData, leadstatus: selectedValue, collabrator:selectedValue, leadOwner:selectedValue, leadnotification: [...newNotification, updatedNotification] };
                // const data = await patchData(`lead/${id}`, updatedData);
                // if (data) {
                //     setFormData(updatedData);
                // }

                let tempForm = {...formData}
                tempForm["leadnotification"] = [...newNotification, updatedNotification] 
                // setFormData(tempForm)
            }
        } catch (error) {
            console.error('Error updating lead status:', error);
        }
    };

    const selectCustChanged = async (e) => {
        const selectedValue = e?.label;

        const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
        const updatedNotification = { date: currentDateTime, status: selectedValue,user: name };
        

        const updatedData = { collabrator:selectedValue, collabratorId: e.value, leadnotification : [...formData.leadnotification, updatedNotification]};

        const data = await patchData(`lead/${id}`, updatedData);
        if (data) {
            setFormData(data?.data);
        }
        
        handleSelectChange(selectedValue);
    };

    const selectLeadStatusChanged = async (e) => {
        const selectedValue = e?.value?.name;
        const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
        const updatedNotification = { date: currentDateTime, status: selectedValue,user: name };
        

        const updatedData = { leadstatus : selectedValue };
        const data = await patchData(`lead/${id}`, updatedData);
        if (data) {
            setFormData(data?.data);
        }
        handleSelectChange(selectedValue);
    };

    const selectLeadOwnerChanged = async (e) => {
        const selectedValue = e.label

        const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
        const updatedNotification = { date: currentDateTime, status: selectedValue,user: name };
        

        // setFormData(prevState => ({
        //     ...prevState,
        //     leadOwnerId: e.value,
        //     leadOwner: selectedValue,
        // }));

        const updatedData = { leadOwner:selectedValue, leadOwnerId: e.value };
        const data = await patchData(`lead/${id}`, updatedData);
        if (data) {
            setFormData(data?.data);
        }
        handleSelectChange(selectedValue);
    };

    return (
        <div className='page-24'> 
        <div>
        <div className='breadcumbs-alignment pb-3'>
                    <span className='active-class'>Lead</span>
                    <img src={RightIcon} alt="RightIcon" />
                    <span>{formData.name}</span>
                </div>
        <div style={{
            marginTop: '20px',
            // background: '#D2E0DF',       
            // background:'#C0C0C0',     
            padding: '20px',
            maxWidth: '4000px',
            margin: '0 auto'
        }}>
                <div className="d-flex align-items-center" >
                <div className='mr-4' style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {(<div>
                        <SelectBox name='userName'  value={userName?.find(i => i.value === formData.leadOwnerId)} onChange={selectLeadOwnerChanged} options={userName} placeholder=' Lead Owner' required />
                    </div>)}
                </div>
                 <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {(<div>
                        
                        {/* {JSON.stringify(userName?.find(i => i.value === formData.collabratorId))} */}
                        <SelectBox name='userName' value={userName?.find(i => i.value === formData.collabratorId)} onChange={selectCustChanged} options={userName} placeholder='Collaborators' required />
                    </div>)}
                </div>
                
                </div>
                <div style={{display:'flex',justifyContent:'end'}}>
                {/* <Link to={`/account/addcustomer`}><Button text='Convert To Customer' /></Link> */}
                <Link to="/account/addcustomer" state={{ formData: formData }}><Button text='Convert To Customer' /></Link>
</div>
                <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}> 
                    <div style={{
                    marginTop: '1rem',
                    // padding: '1.5rem',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    width: '950px'
                }}>
                <>
                    <div style={{padding:'1.5rem' }}>
                      <div style={{display:'flex',justifyContent:'space-between'}} >
                        <h2 style={{  justifyContent:'',paddingBottom: '15px' }}>{formData.name}</h2>
                        {/* <div style={{display:'flex',justifyContent:'end'}}> */}
                            <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {(<div>
                        <SelectBox 
  name="leadstatus"
  value={leadstatus?.find(i => i.value.name === formData.leadstatus)}
  onChange={selectLeadStatusChanged}
  options={leadstatus}
  placeholder="Select Lead Status"
  required 
/>

                    </div>)}
                </div>
                {/* </div> */}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ paddingRight: '2px' }}>✆</div>
                            <a href={`mailto:${formData.contactno}`} style={{ color: 'purple', paddingLeft: '1px' }}>{formData.contactno}</a>
                        </div>
                        <div style={{ fontSize: '0.85rem', marginTop: '0.5rem', marginBottom: '17px' }}>
                            🏠{formData.address},{formData.state}-{formData.pincode},{formData.country}
                        </div>
                        <div style={{ fontSize: '0.85rem', marginTop: '0.5rem', marginBottom: '17px',color: '#828282' }}>
                            Longitude{formData.longitude} ,

                            Latitude{formData.latitude}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: '#777777', }}>Source</div>
                                <div>{formData.leadsource}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: '#777777' }}>Industry</div>
                                <div>{formData.leadindustry}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: '#777777' }}>Price Group</div>
                                <div>{formData.pricegroup}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding:'1.5rem' , fontSize: '0.85rem',borderTop: '1px solid #cccccc', paddingTop: '1rem', marginTop: '1rem' }}>
                        <div style={{borderBottom: '1px solid #e5e5e5',fontWeight: 'bold'}}>Owner Details </div>

                        <div style={{ display: 'flex',   marginTop: '7px', justifyContent: 'space-between', }}>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Owner Name</div>
                                <div>{formData.ownername}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Partner Name</div>
                                <div>{formData.partnername}</div>
                            </div>
                            <div>
                                <div style={{fontSize: '0.85rem', color: 'black' }}>Attachment</div>
                                <div>Not uploaded.</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding:'1.5rem' , fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                        <div style={{borderBottom: '1px solid #e5e5e5',fontWeight: 'bold'}}>Counter category </div>

                        <div style={{ fontSize: '0.85rem',display: 'flex',  justifyContent: 'space-between',  paddingTop: '7px' }}>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Turn-Over</div>
                                <div>{formData.turnover}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Existing Distributor</div>
                                <div>{formData.ed}</div>
                            </div>
                            <div>
                                <div style={{ fontSize: '0.85rem',color: 'black' }}>Existing Working Company</div>
                                <div>{formData.ewc}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div style={{ padding:'1.5rem' , fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                        <div style={{borderBottom: '1px solid #e5e5e5',fontWeight: 'bold'}}>Deal Details </div>
                    </div>
<div style={{display:'flex',justifyContent:'end'}}>
<Link to="/deal/adddeal" state={{ formData: formData }}>
    <Button text="Add Deal" />
</Link>
</div>
                    <table style={{ width: '100%', marginTop: '0rem', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Deal #</strong></th>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Date</strong></th>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Pipeline Name</strong></th>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Pipeline Status</strong></th>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Owner</strong></th>
                                <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Created By</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ width: '100%' }}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                            </tr>
                        </tbody>
                    </table>
                </>
            </div>
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    // backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: '683px',
                    overflowY: 'auto'
                }}>
                    <div style={{marginTop:'-8px',paddingBottom:'15px',fontWeight:'bold',fontSize:'20px'}}><strong>Timeline</strong></div>
                    {timelines?.slice().reverse().map((i, index) => (
                <div
                    key={i.id || index}
                    style={{
                        display: "flex",
                        paddingLeft: "3px",
                        paddingBottom: "15px",
                    }}
                >
                    <div style={{ paddingRight: "5px" }}>➨</div>
                    <div style={{ fontSize: "14px" }}>
                        On<strong>{dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎HH:mm:ss')}</strong>
                        , the lead status was updated by <strong>{i.user_name}</strong>.
                    </div>
                </div>
            ))}
            </div>
        </div>
        </div>
        </div>
        </div>
    );
}
