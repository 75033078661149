import React, { useState, useEffect } from 'react';
import './productcategorytable.scss';
import { fetchData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';

export default function ProductCategoryTable({
  categoryList,
  setSelectedIds,
  setProductList,
  setTotalItems,
  totalItems,
  selectedIds,
}) {
  const [productList, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;

  const [Category, setCategoryTerm] = useState("");
  const [Status, setStatusTrem] = useState("");

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page,
        limit: pageSize,
      };
      const response = await fetchData('systemtype', requestBody, pagination, {
        typeCategory: 'category',
      });
      if (response?.data) {
        setProductList(response.data || []); // API returns current page data in 'data'
        setTotalItems(response?.totalDocuments || 0); // API returns total count of items in 'totalDocuments'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(categoryList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const filteredCategory = categoryList.filter((item) => {
    return (
      (!Category || item.typeName?.toLowerCase().includes(Category.trim().toLowerCase())) &&
      (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) 
    );
  });


  return (
    <div className="page-px-24">
      <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div className="tableSize"></div></th>
             
              <th><input type="text" placeholder="Category" value={Category} onChange={(e) => setCategoryTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
             
              <th><div className="tableSize"></div></th>
            </tr>

            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    selectedIds.length === categoryList.length &&
                    categoryList.length > 0
                  }
                />
              </th>
              <th>Category</th>
              <th>Status</th>
              {checkAccess('manage_product_category') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {(filteredCategory ?? []).map((item) => (
              <tr key={item.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedIds.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </td>
                <td>{item?.typeName}</td>
                <td>
                  <button className="active-button">
                    {item?.status ? 'Active' : 'Deactive'}
                  </button>
                </td>
                {checkAccess('manage_product_category') && (
                  <td>
                    <Link to={{ pathname: `edit/${item?.id}` }}>
                      <EditIcon />
                    </Link>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} // Total items from API response
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
