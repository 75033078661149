import { createSlice } from '@reduxjs/toolkit';
import { purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  user: {
    _id: '',
    name: '',
    status: false,
    userroleId: '',
    createdOn: '',
    updatedOn: '',
  },
  features: [], // Array to store all fetched features dynamically
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { user, fetchedFeatures } = action.payload;

      // Set user details
      state.user = user;

      // Store fetched features dynamically
      state.features = fetchedFeatures || [];
    },
    clearuser(state) {
      state.user = initialState.user;
      state.features = [];
      purgeStoredState({ storage, key: 'root' });
    },
  },
});

export const { setUser, clearuser } = userSlice.actions;

export default userSlice.reducer;
