import { useState, useEffect } from "react";
import { fetchData } from "../../../services/apiService";
import "./requestdatatable.scss";
import Pagination from '../../../shared/components/pagination';
export default function Requestdatatable() {
  // const [RequestList, SetRequestList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [RequestList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Items per page

  useEffect(() => {
      // Fetch data when page or pageSize changes
      getData(currentPage, pageSize);
    }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }

      const response = await fetchData("requestdata",requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []);  // API returns current page data in 'items'
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = RequestList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th>Request Description</th>
              <th>Response</th>
              <th>Response Code</th>
              <th>#Sr No.</th>
              <th>Created By</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {RequestList.map((item, index) => (
              <tr key={index}>
                <td>{item.RequestDescription}</td>
                <td>{item.response}</td>
                <td>{item.responsecode}</td>
                <td>{item.srno}</td>
                <td>{item.createdby}</td>
                <td>{item.createdat}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
