import React, { useEffect, useState } from 'react'
import './transporttable.scss';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { checkAccess } from '../../../services/checkFeature';

export default function Transporttable({settrasportList,selectedIds,setSelectedIds,trasportList, setProductList, setTotalItems, totalItems})  {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [trasportList, setProductList] = useState([]);
  const [name, setName ]= useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(undefined);
  const pageSize = 10; // Items per page

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

    const getData = async (page, pageSize) => {
      try {
        const requestBody = {};
        const pagination = {
          page: page,
          limit: pageSize,
        }
        const response = await fetchData('transport',requestBody, pagination);
        if (response?.data) {
          setProductList(response.data || []);  // API returns current page data in 'items'
          setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };
    const handleSelectAll = (e) => {
      if (e.target.checked) {
        setSelectedIds(trasportList.map((i) => i.id));
      } else {
        setSelectedIds([]);
      }
    };

    const filteredActivityList = trasportList.filter((item) => {
      const searchName = item.name?.toLowerCase().includes(name?.toLowerCase());
      const searchAddress = item.address?.toLowerCase().includes(address?.toLowerCase());
      const searchEmail = item.email?.toLowerCase().includes(email?.toLowerCase());
      const searchStatus = status === undefined || item.status === status;

     
      return (
        (!name || searchName) &&
        (!address || searchAddress) &&
        (!email || searchEmail) &&
         searchStatus
     
      );
    });
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const paginatedData = trasportList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    return (
      <div className='account-table-page-alignment'>
      <div className='account-table-design'>
          <table>
            <thead>
            <tr>
              <th><div className="w-100"></div></th>
              
              <th><div className="w-100"></div></th>

              <th><div><input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              <th><div ><input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-100 border-0"

              /></div></th>

               <th><div ><input
                type="text"
                placeholder="Email"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="w-100 border-0"

              /></div></th>


<th>
                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatus(selectedStatus); 
                    }}
                    className="w-100 border-0"
                  >
                    <option value="">All</option>
                    <option value="true">Active</option> 
                    <option value="false">Inactive</option>
                  </select>
                </div>
                </th>

            </tr>
              <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === trasportList.length} /></th>
                {checkAccess('manage_transport')&&<th>Action</th>}
                
                <th>Name</th>
                <th>Email</th>
                <th>Address</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                (filteredActivityList ?? [])?.map((i) => {
                  return (
                    <tr key={i.id}>
                      <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                      {checkAccess('manage_transport')&&<td>
                        <Link to={{ pathname: `addEdittrasport/${i?.id}` }}>
                        <EditIcon/>
                        </Link>
                      </td>}
                      <td>{i?.name}</td>
                      <td>{i?.email}</td>
                      <td>{i?.address}</td>
                      <td>
                        <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='pagination-alignment'>
          <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
        </div>
      </div>
    )
  }
  
