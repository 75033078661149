import React, { useState, useEffect } from 'react';
import './productunittable.scss';
import { fetchData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';

export default function Productunittable({
  PriceList,
  totalItems,
  unitList = [], // Default empty array if undefined
  setProductList,
  setSelectedIds,
  setTotalItems,
  selectedIds = [], // Default empty array if undefined
  activityList = [] // Default empty array if undefined
}) {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;
  const [UnitName, setUnitNameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page,
        limit: pageSize,
      };
      const response = await fetchData('systemtype', requestBody, pagination, {
        typeCategory: 'unit',
      });
      if (response?.data) {
        setProductList(response?.data || []); // API returns current page data in 'data'
        setTotalItems(response?.totalDocuments || 0); // API returns total count of items in 'totalDocuments'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(unitList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  

  const filteredproductUnit = unitList.filter((item) => {
    return (
      (!UnitName || item.typeName?.toLowerCase().includes(UnitName.trim().toLowerCase())) &&
      (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) 
    );
  });

  return (
    <div className="page-px-24">
      <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div className="tableSize"></div></th>
             
              <th><input type="text" placeholder="Unit Name" value={UnitName} onChange={(e) => setUnitNameTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
             
              <th><div className="tableSize"></div></th>
            </tr>

            <tr>
              {checkAccess('manage_product_unit') && (
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedIds.length === activityList?.length} // Safe check for length
                  />
                </th>
              )}
              <th>Unit Name</th>
              <th>Status</th>
              {checkAccess('manage_product_unit') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {(filteredproductUnit ?? []).map((i) => {
              return (
                <tr key={i.id}>
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                  )}

                  <td>{i?.typeName}</td>
                  <td>
                    <button className="status">{i?.status ? 'Active' : 'Deactive'}</button>
                  </td>
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} // Total items from API response
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
