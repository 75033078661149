import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';

export default function AccountcategoryTable({selectedIds,setSelectedIds,settrasportList , trasportList , setProductList, setTotalItems, totalItems}){
    
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [trasportList, setProductList] = useState([]);
  const pageSize = 10; // Items per page
  const [Name, setNameTerm] = useState("");
  const [color, setcolorTrem] = useState("");
  const [Status, setStatusTrem] = useState("");

  useEffect(() => {
      // Fetch data when page or pageSize changes
      getData(currentPage, pageSize);
    }, [currentPage]);


    const getData = async (page, pageSize) => {
      try {
        const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }

        const response = await fetchData('accountcategory',requestBody, pagination);
        if (response?.data) {
          setProductList(response.data || []);  // API returns current page data in 'items'
          setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    const paginatedData = trasportList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };
   const handleSelectAll = (e) => {
      if (e.target.checked) {
        setSelectedIds(trasportList.map((i) => i.id));
      } else {
        setSelectedIds([]);
      }
    };

    const filteredFeedback = trasportList.filter((item) => {
      return (
        (!Name || item.name?.toLowerCase().includes(Name.trim().toLowerCase())) &&
        (!color || (item.color?.toString().trim() || '').toLowerCase().includes(color.trim().toLowerCase())) &&
        (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) 
      );
    });

    return (
      <div className='account-table-page-alignment'>
      <div className='account-table-design'>
          <table>
            <thead>
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={Name} onChange={(e) => setNameTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="color" value={color} onChange={(e) => setcolorTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
           
            </tr>

              <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === filteredFeedback.length} /></th>
                <th>Action</th>
                <th>Name</th>
                <th>Color</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                (filteredFeedback ?? [])?.map((i) => {
                  return (
                    <tr key={i.id}>
                      <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                      <td>
                        <Link to={{ pathname: `accountcategory/${i?.id}` }}>
                        
                        <EditIcon/></Link>
                      </td>
                      <td>{i?.name}</td>
                      <td>{i?.color}</td>
                      <td>
                        <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='pagination-alignment'>
          <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
        </div>
      </div>
    )
  }
  