import React, {useEffect, useState} from 'react';
import { getuploadedfile } from '../../../services/apiService';
import './showImage.scss';
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function ShowImage({ value,style }) {
    useEffect(() => {
        if(value)
        fetchdocument()
      }, [value]);
      // const fetchdocument = async ()=>{
      //   const data = await getDocument(value);
      //   if(data){
      //       const url = URL.createObjectURL(data)
      //       setImageUrl(url)
      //   }

      // };

      const fetchdocument = async () => {
        try {
          // const blob = await getuploadedfile('https://saleseasy-api.azurewebsites.net/api/document/getDocument/76');
          const blob = await getuploadedfile(BASE_URL+'document/getDocument/'+value);
          const imageUrl = URL.createObjectURL(blob);
          setImageUrl(imageUrl);
        } catch (error) {
          console.error('Error loading document:', error);
        }
      };

    const [imageUrl, setImageUrl] = useState();
   
    return imageUrl ? (
      <img  src={imageUrl} alt="avatar" style={style} />
    ) : (
      <div>Loading..</div>
    );
}
