import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; 
import EditIcon from "../../../shared/icons/editIcon";
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from "../../../services/checkFeature";


export default function Quotetable({ selectedIds,totalItems,setTotalItems, setSelectedIds,  activityList, setActivityList }) {
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin
  const [orderList, setOrderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for customerName
  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [createdByTerm, setCreatedByTerm] = useState(""); // Search term for created by
  const [customers, setCustomerOptions] = useState([]);
  const [orderTerm, setOrderTerm] = useState("");
  const [AmountTerm, setAmountTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  
  const user = useSelector(state => state.user.user);
  const [formData, setFormData] = useState({
    quotation_no: "",
    orderDate: moment(),
    customerName: "",
    customerId: "",
    customerAddress: "",
    customerContact: "",
    status: true,
    quotationstatus: 'Pending',
    products: [],
    quotation_notification: [],
    created_by: '',
    orderupdate: '',
    orderupdatedby: '',




  });
  useEffect(() => {
    getCustomer();
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])
  
  const { RangePicker } = DatePicker;

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
      const response = await fetchData("quotation",requestBody, pagination);
      if (response?.data) setOrderList(response?.data);
      if (response?.data) {
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getCustomer = async () => {
    try {
      const [customerResponse, leadResponse] = await Promise.all([
        fetchData(`customer`),
        fetchData(`lead`),
      ]);
  
      const customerOptions = customerResponse?.data?.map((i) => ({
        label: `${i?.cName || "Unnamed Customer"} [Customer]`,  // Customer name first
        key: "customer",
        value: i?.cName,
      }));
  
      const leadOptions = leadResponse?.data?.map((i) => ({
        label: `${i?.name || "Unnamed Lead"} [Lead]`,  // Lead name first
        key: "lead",
        value: i?.name,
      }));
  
      setCustomerOptions([...customerOptions, ...leadOptions]);
    } catch (error) {
      console.error("Error fetching customer or lead data:", error);
    }
  };
  
  
  const [errors, setErrors] = useState({});
  // const getCustomer = async () => {
  //   try {
  //     const response = await fetchData(`customer`);
  //     if (response?.data) {
  //       setCustomerOptions(
  //         response?.data?.map((i) => {
  //           return {
  //             label: `[${i?.code}] ${i?.cName}`,
  //             key: "customer",
  //             value: {
  //               id: i?.customerId,
  //               name: `[${i?.code}] ${i?.cName}`,
  //               address: `${i?.address} - ${i?.pincode}.`,
  //               contact: i?.addressContact,
  //             },
  //           };
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Close':
        return 'Closee';
      case 'Approved':
        return 'Approved';
        case 'Rejected':
          return 'Rejected';
      case 'Cancelled':
        return 'Cancelled';
      case 'Pending':
        return 'Pending';
      case 'Open':
        return 'Open';
      default:
        return '';
    }
  };
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };


  // Combined search filter logic for customerName, status, orderDate, and createdBy
  const filteredActivityList = activityList.filter((item) => {
    const matchesCustomerName = searchTerm
      ? item.linkname?.toLowerCase().includes(searchTerm.toLowerCase())
      : true; // Matches the customer/lead dropdown selection
  
    const matchesStatus = item.quotationstatus?.toLowerCase().includes(statusTerm?.toLowerCase());
    const matchesCreatedBy = item.created_by?.toLowerCase().includes(createdByTerm?.toLowerCase());
    const matchesOrder = String(item.quotation_no || '').toLowerCase().includes(orderTerm?.toLowerCase());
    const matchesAmount = item.orderproducts?.reduce((accumulator, currentValue) => {
      return accumulator + Number(currentValue.subTotal); // Sum the subtotal of each product
    }, 0).toFixed(2);
  
    const matchesAmountSearch = Number(matchesAmount) === Number(AmountTerm);
  
    const matchesDateRange = (selectedDates && selectedDates.length === 2)
      ? dayjs(item.orderDate).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.orderDate).isSameOrBefore(selectedDates[1], 'day')
      : true;
  
    return (
      (!searchTerm || matchesCustomerName) &&
      (!statusTerm || matchesStatus) &&
      (!createdByTerm || matchesCreatedBy) &&
      (!orderTerm || matchesOrder) &&
      (!AmountTerm || matchesAmountSearch) &&
      matchesDateRange
    );
  });

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (

    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
            {/* <style>
              {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
            </style> */}
            <tr>
              <th><div style={{ width: '100%' }}></div></th>
             
              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder=" Quote No#"
                value={orderTerm}
                onChange={(e) => setOrderTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px" }}

              /></div></th>

              <th><div style={{ alignItems: 'center', maxWidth: '100%' }}>
              <SelectBox
  value={customers?.find((option) => option.label === searchTerm) || null}
  options={customers}
  onChange={(selectedOption) => setSearchTerm(selectedOption?.label || '')} // Clear if null
  onClear={() => setSearchTerm('')} // Clear button handler
  placeholder="Customer"
  type="text"
  required={true}
  errors={errors.customerName}
  styles={{
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown is on top of other elements
      maxHeight: '400px',
      overflowY: 'auto',
      border: 'none', // Enable scrolling if the dropdown is too large
    }),
    control: (provided) => ({
      ...provided,
      minWidth: '100%',
      border: 'none',
      marginTop: '-8px',
      maxHeight: '25px',
      textAlign: 'left',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      border: 'none', // Render the dropdown with a very high z-index
    }),
  }}
  menuPortalTarget={document.body}
  isClearable={true} // Add clear button
  components={{
    ClearIndicator: (props) => (
      <div
        {...props.innerProps}
        style={{ cursor: 'pointer', padding: '5px', color: 'gray' }}
      >
        &#x2715; {/* Clear button (cross symbol) */}
      </div>
    ),
  }}
/>
</div></th>

              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder=" Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

              /></div></th>
              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder=" Created By"
                value={createdByTerm}
                onChange={(e) => setCreatedByTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' ,padding:"7px", borderRadius:"3px" }}

              /></div></th>
              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder=" Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

                /></div></th>
                 <th><div style={{ width: '100%' }}></div></th>
            </tr>
            <tr>

              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
           
              <th>Quotation #</th>
              <th>Sale Quotation For</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Created by</th>
              <th>Status</th>
              {checkAccess('edit_sale_quotation')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
           
            {filteredActivityList?.reverse().map((i, index) => {
              const currentDate = moment().format('DD-MM-YYYY'); // Get current date in the same format as orderDate
              const orderDate = moment(i?.quotationDate).format('DD-MM-YYYY');

              return (
                <tr
                  key={index}
                  // style={{
                  //   backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
                  // }}
                >

                  <td>
                    <input
                      type='checkbox'
                      checked={selectedIds.includes(i.id)}
                      onChange={() => handleCheckboxChange(i.id)}
                    />
                  </td>

                


                  <td className="whitesapce-nowrap"
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}>{i?.quotation_no}</td>
                  <td>
                    <span className="price-group-text gray-text"
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}>{i?.linkname}</span></td>

                  {/* Apply bold if the orderDate matches the current date */}
                  <td
                    className="whitesapce-nowrap"
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                  >
                    <span className="gray-text">{orderDate}</span>
                  </td>

                  <td
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}>
                    {/* {i?.orderproducts?.reduce((accumulator, currentValue) => {
                      return accumulator + Number(currentValue.subTotal);
                    }, 0).toFixed(2)} */}
                    {i?.totalamount}
                  </td>
                  <td
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}>{i?.created_by}</td>
                  <td
                    style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                  >

                    {checkAccess('read_sale_quotation')?(<Link to={{ pathname: `view/${i?.id}` }}>
                      <button className={` ${getStatusClass(i?.quotationstatus)}`}>
                        {i?.quotationstatus}
                      </button>
                    </Link>):( <button className={` ${getStatusClass(i?.quotationstatus)}`}
                     style={{ pointerEvents: "none", cursor: "default" }}>
                        {i?.quotationstatus}
                      </button>)}

                  </td>
                 {checkAccess('edit_sale_quotation')&& <td>
                    <Link to={{ pathname: `add/${i?.id}` }}>
                    <EditIcon/>
                    </Link>
                  </td>}
                </tr>
              );
            })}

          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
             currentPage={currentPage}
             totalItems={totalItems} // Total items from API response
             pageSize={pageSize}
             onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
