import React, { useEffect, useState, useContext } from 'react';

import Button from '../../shared/components/button';
import { deleteData } from '../../services/apiService';
import { Link } from 'react-router-dom';
// import Viewprofile from "./viewprofile";
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Userrole from '../userrole';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { fetchData } from '../../services/apiService';
export default function Subscription() {
    // const user = useSelector(state => state.user.user);
    // const [selectedIds, setSelectedIds] = useState([]);
    // const [activityList, setActivityList] = useState([]);
    // const [formData, setFormData]=useState([]);
    const handleDelete = async () => {
    };



    return (



        <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px' }}>
            <h5 style={{ marginTop: '12px', paddingLeft: '10px' }}>Subscription</h5>
            <hr style={{ marginTop: '0.5px' }} />
            <div className="profile-info">

            </div>

            <div className='row' >
                <div className='col-md-12 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "20px" }}>YOUR UNIQUE CUSTOMER ID IS</p>
                        <p style={{ color: "#3c763d", fontSize: "16px" }}>591646</p>
                    </div>
                </div>
            </div>

            <h5 style={{ marginTop: '25px', paddingLeft: '10px' }}>Subscription Duration</h5>
            <hr style={{ marginTop: '0.5px' }} />
            <div className="profile-info">

            </div>
            <div className='row' >
                <div className='col-md-4 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "15px" }}>START DATE</p>
                        <p style={{ color: "#3c763d", fontSize: "25px" }}>19-07-2022</p>
                    </div>
                </div>

                <div className='col-md-4 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "15px" }}>LATEST RENEWAL DATE</p>
                        <p style={{ color: "#428bca", fontSize: "25px" }}>-</p>
                    </div>
                </div>

                <div className='col-md-4 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "15px" }}>EXPIRY DATE</p>
                        <p style={{ color: "#D64937", fontSize: "25px" }}>15-01-2025</p>
                    </div>
                </div>
            </div>

            <h5 style={{ marginTop: '25px', paddingLeft: '10px' }}>User Limit Detail</h5>
            <hr style={{ marginTop: '0.5px' }} />
            <div className="profile-info">

            </div>

            <div className='row' >
                <div className='col-md-6 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "20px" }}>CUSTOMER</p>
                        <p style={{ color: "#3c763d", fontSize: "15px" }}>( Created / Limit )</p>
                        <p style={{ color: "#3c763d", fontSize: "25px" }}>5 / 5</p>
                    </div>
                </div>

                <div className='col-md-6 container'>
                    <div className='text-center' style={{ padding: '25px', background: '#f2f2f2' }}>
                        <p style={{ color: "#000", fontSize: "20px" }}>STAFF USER</p>
                        <p style={{ color: "#3c763d", fontSize: "15px" }}>( Created / Limit )</p>
                        <p style={{ color: "#3c763d", fontSize: "25px" }}>5 / 5</p>
                    </div>
                </div>
            </div>
        </div>
    )

}
