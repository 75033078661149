import React, { useContext, useState } from 'react'
import './paymentcollection.scss';
import Paymentcollectiontable from './paymentcollectiontable';
import Link from 'antd/es/typography/Link';
import { deleteData, filterAll } from '../../services/apiService';
import { useSelector } from 'react-redux';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
// import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";

export default function Paymentcollection() {
  const user = useSelector(state => state.user.user);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`paymentcollection/${id}`)));
      // console.log(selectedIds)
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      // console.log(tempActivityList)
      setActivityList(tempActivityList);
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Payment","Customer", "Date","Payment type", "Amount","Description","Created by", "Status"]


    ];
 
    filterAll("paymentcollection")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.paymentno,
      item.cName,
      item.date,
      item.paymenttypeName,
      item.amount,
      item.description,
      item.created_by,
      item.paymentstatus,
      ]);
      exportToExcel('Payment',headers,datas,'paymentcollection')
    })
  };



    return (


      <div className='page-24'>
        {/* <div className='page-header'>
          <span>Payment collection</span>
          <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
            {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}

             <Button text='Add Payment collection' onClick={(e) => navigate("addpaymentcollection")} />
           
          </div>
        </div> */}
        <div className="">
        <div className="customer-header-alignment pb-3">
          <div>
            <h2>Payment collection</h2>
            <Breadcumbs activePath="Payment collection" pageName="Payment collection" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
            <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure to delete this record? "
            description="This will lead to delete all records related to this record only!"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
          </Popconfirm>
            )}
            <Link onClick={(e) => navigate("addpaymentcollection")}>
              <GlobalButton text="Add Payment collection" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport}  />
          </div>
        </div>
      </div>
        <Paymentcollectiontable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          activityList={activityList}
          
          setActivityList={setActivityList}
        />
      </div>
    )
  
}
