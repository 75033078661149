import {useState} from 'react'
import { deleteData, filterAll } from '../../services/apiService';
import Button from '../../shared/components/button';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import Accountsegment from './SegmentTable';
export default function Segment() {
  const [selectedIds,setSelectedIds]=useState([]);
  const [trasportList,settrasportList]=useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`segment/${id}`)));
      settrasportList(trasportList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };


  const handleExport = () => {
    const headers = [
      ["Name","Status"]

    ];
 
    filterAll("segment")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('AccountCategoryType',headers,datas,'segment')
    })
  };

  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};
  return (
    <div className='page-24'>
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>VisitFeedback</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        <Link to="addEditvisitfeedback"><Button text='Add segment
'/></Link>
      </div>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-3">
          <div>
            <h2>segment
</h2>
            <Breadcumbs activePath="segment" pageName="segment" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            <Link to="add">
              <GlobalButton text="Add segment
" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>
      <Accountsegment
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      trasportList={trasportList}
      setProductList={settrasportList}
      totalItems={totalItems}
      setTotalItems={setTotalItems}
      />
    </div>
  )
}
