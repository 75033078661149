import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';

export default function AddEditviewdispatchorder() {
    const navigate = useNavigate();
    const { id } = useParams();
    const name = useSelector((state) => state.auth.name);



    const pdfRef = useRef();

    const handleGeneratePdf = () => {
        const pdfWidth = 420; // Full HD width
        const pdfHeight = 370; // Full HD height

        const doc = new jsPDF({
            orientation: 'b5', // Landscape orientation for wider content
            unit: 'px', // Use pixels for dimensions
            format: [pdfWidth, pdfHeight], // Custom format for full HD
        });

        // Convert the content of the div to PDF
        doc.html(pdfRef.current, {
            callback: (doc) => {
                doc.save('payment_details.pdf'); // Save the PDF file
            },
            x: 20, // X offset for margins
            y: 20,
            z: 20, // Y offset for margins

            windowWidth: document.documentElement.scrollWidth, // Width of the entire content
            windowHeight: document.documentElement.scrollHeight,

        });
    };





    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        orderNo: "",
        orderDate: dayjs(),
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerContact: "",
        status: true,
        orderStatus: 'Pending',
        products: [],
        date: '',
        dispatchcode: '',
        dispatchno: '',
        invoicedate: '',
        invoiceno: '',
        ordernotification: [],
        dispatchcreatedby: '',
    });
    const [newNotification, setNewNotification] = useState([]);
    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
            const updatedNotification = { date: currentDateTime, status: status, user: localStorage.getItem('username') };
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            const updatedFormData = {
                ...formData,
                orderStatus: status,
                ordernotification: [...newNotification, updatedNotification]
            };
            if (id) {
                const data = await patchData(`order/${id}`, updatedFormData);
                if (data) {
                    setFormData(updatedFormData);
                    if (status === 'Pending') {
                        navigate(`/order/view/${formData.orderId}`);
                    }
                    setFormData(updatedFormData);
                    if (status === 'cancelled') {
                        navigate(`/order/view/${formData.orderId}`);
                    }
                    if (status === 'Close') {
                        navigate(`/order/view/${formData.orderId}`);
                    }

                }
            } else {
                const data = await postData('order', updatedFormData);
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData("order",id);
            if (response?.data) setFormData(response.data);
           
            if (response?.data?.ordernotification) setNewNotification(response.data.ordernotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const calculateTotalAmount = () => {
        return formData.products.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.subTotal);
        }, 0).toFixed(2);
    };

    return (
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Order {'>'} List</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}><div onClick={handleGeneratePdf}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                        </svg>
                    </div>
                    <button
                        onClick={(e) => handleSubmit(e, 'Pending')}
                        style={{
                            backgroundColor: '#f0ad4e',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Pending
                    </button>
                    <button
                        onClick={(e) => handleSubmit(e, 'cancelled')}
                        style={{
                            backgroundColor: '#D44545',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => handleSubmit(e, 'Close')}
                        style={{
                            backgroundColor: '#524141',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Close order
                    </button>

                    <Link to={`/order/adddispatchorder/${formData.id}`} ><button
                        style={{
                            backgroundColor: '#1C81AC',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Add
                    </button></Link>

                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}><div ref={pdfRef} style={{
                marginTop: '1rem',
                padding: '1.5rem',
                backgroundColor: '#fff',
                border: '1px solid rgba(30,46,80,.09)',
                borderRadius: '0.25rem',
                boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                width: '900px'
            }}>
                {formData.orderStatus === 'cancelled' && formData.cancellationDateTime ? (
                    <div style={{
                        padding: '1rem',
                        backgroundColor: '#f8d7da',
                        color: '#721c24',
                        border: '1px solid #f5c6cb',
                        borderRadius: '0.25rem',
                        marginBottom: '1.5rem'
                    }}>
                        This order was cancelled on {formData.cancellationDateTime}
                    </div>
                ) : (
                    <>
                        <h2 style={{ paddingBottom: '10px' }}>Order from,</h2>
                        <p style={{ fontSize: '0.85rem' }}>{formData.customerName}</p>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order No.</div>
                                    <strong>{formData.orderNo}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order Date</div>
                                    <strong>{dayjs(formData.orderDate).format('DD-MM-YYYY')}</strong>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Customer</div>
                                    <strong>{formData.customerName}</strong>
                                    <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                        {formData.customerAddress}
                                        <br />
                                        <a href={`mailto:${formData.customerContact}`} style={{ color: 'purple' }}>{formData.customerContact}</a>
                                    </p>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Order Status</div>
                                    <strong>{formData.orderStatus}</strong>
                                </div>
                            </div>
                        </div>

                        <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>#</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Date</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Invoice No</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Invoice Date</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Transport</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>createdBy</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{formData.dispatchno}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{dayjs(formData.date).format('DD-MM-YYYY')}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{formData.invoiceno}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{dayjs(formData.invoicedate).format('DD-MM-YYYY')}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{formData.transport}</td>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{formData.dispatchcreatedby}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
            </div>
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',

                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: '600px',
                    overflowY: 'auto'
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Remark</strong></div>
                    {/* {formData.ordernotification.reverse().map((i, index) => (

                        < div style={{ display: 'flex', paddingLeft: '3px', paddingBottom: '15px' }}> <div style={{ paddingRight: '5px' }}>➨</div><div style={{ fontSize: '14px' }}>On <strong>{i.date}</strong> the expense status was updated to <strong>{i.status}</strong> by the <strong>{i.user}</strong>.</div></div>
                    ))} */}
                </div>
            </div>
        </div>
    );
}