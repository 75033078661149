import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from "moment";
import { useSelector } from "react-redux";
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Pagination from "../../../shared/components/pagination";
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function Dispatchtable({
  selectedIds,
  setSelectedIds,
  totalItems,
  setTotalItems,
  activityList,
  setActivityList,
}) {
  const user = useSelector((state) => state.user.user);
  const [customers, setCustomerOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Customer Name filter
  const [statusTerm, setStatusTerm] = useState(""); // Order Status filter
  const [orderTerm, setOrderTerm] = useState(""); // Order# filter
  const [amountTerm, setAmountTerm] = useState(""); // Amount filter
  const [createdByTerm, setCreatedByTerm] = useState(""); // Created By filter
  const [selectedDates, setSelectedDates] = useState([]); // Date Range filter
  const [dispatchOrder, setDispatchOrder] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered list
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [dispatchId, setDispatchid] = useState(""); // Search term for dispatch ID
  const { RangePicker } = DatePicker;

  useEffect(() => {
    getCustomer();
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    // Recalculate filteredOrders whenever filters change
    const filtered = filterDispatchOrders(activityList);
    setFilteredOrders(filtered);
  }, [searchTerm, statusTerm, orderTerm, amountTerm, createdByTerm, selectedDates, dispatchId, activityList]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchData("dispatchorder", requestBody, pagination);
      if (response?.data) {
        setActivityList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCustomer = async () => {
    try {
      const response = await fetchData("customer");
      if (response?.data) {
        setCustomerOptions(
          response.data.map((customer) => ({
            label: `[${customer.code}] ${customer.cName}`,
            value: `[${customer.code}] ${customer.cName}`,
            
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const filterDispatchOrders = (orders) => {
    return orders.filter((item) => {
      const matchesCustomerName = searchTerm
        ? item.customerName?.toLowerCase().includes(searchTerm.toLowerCase())
        : true;

      const matchesOrderCode = (item.id?.toString() || '').toLowerCase().includes(dispatchId.toLowerCase())

      const matchesStatus = statusTerm
        ? item.orderStatus?.toLowerCase().includes(statusTerm.toLowerCase())
        : true;

      const matchesOrderNumber = orderTerm
        ? String(item.orderNo || "").toLowerCase().includes(orderTerm.toLowerCase())
        : true;

      const matchesAmount = amountTerm
        ? Number(item.subTotal).toFixed(2) === Number(amountTerm).toFixed(2)
        : true;

      const matchesCreatedBy = createdByTerm
        ? item.created_by?.toLowerCase().includes(createdByTerm.toLowerCase())
        : true;

      const matchesDateRange =
        selectedDates.length === 2
          ? dayjs(item.orderDate).isSameOrAfter(dayjs(selectedDates[0]), "day") &&
            dayjs(item.orderDate).isSameOrBefore(dayjs(selectedDates[1]), "day")
          : true;

      return (
        matchesCustomerName &&
        matchesOrderCode &&
        matchesStatus &&
        matchesOrderNumber &&
        matchesAmount &&
        matchesCreatedBy &&
        matchesDateRange
      );
    });
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (e) => {
    setSelectedIds(e.target.checked ? filteredOrders.map((item) => item.id) : []);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [errors, setErrors] = useState({});
  const getStatusClass = (status) => {
    switch (status) {
      case "Close":
        return "Closee";
      case "Cancelled":
        return "Cancelled";
      case "Pending":
        return "Pending";
      case "Open":
        return "Open";
      default:
        return "";
    }
  };

  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === filteredOrders.length}
                     className="w-100 border-0"
                />
              </th>
              {checkAccess("edit_dispatch") && <th>Action</th>}
              <th>
                <input
                  type="text"
                  placeholder="Dispatch"
                  value={dispatchId}
                  onChange={(e) => setDispatchid(e.target.value)}
                     className="w-100 border-0"
                />
              </th>
              <th>
              <SelectBox
  value={customers?.find((option) => option.label === searchTerm) || null}
  options={customers}
  onChange={(selectedOption) => setSearchTerm(selectedOption?.label || '')} // Clear if null
  placeholder="Customer"
  type="text"
  required={true}
  errors={errors.customerName}
  styles={{
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown is on top of other elements
      maxHeight: '400px',
      overflowY: 'auto',
      border: 'none', // Enable scrolling if the dropdown is too large
    }),
    control: (provided) => ({
      ...provided,
      minWidth: '100%',
      border: 'none',
      marginTop: '-8px',
      maxHeight: '25px',
      textAlign: 'left',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      border: 'none', // Render the dropdown with a very high z-index
    }),
  }}
  menuPortalTarget={document.body}
  isClearable={true} // Enable the clear button functionality
  components={{
    ClearIndicator: (props) => (
      <div
        {...props.innerProps}
        style={{
          cursor: 'pointer',
          padding: '5px',
          color: 'gray',
          fontSize: '16px',
          lineHeight: '1',
        }}
      >
        &#x2715; {/* Clear button (cross symbol) */}
      </div>
    ),
  }}
  onClear={() => setSearchTerm('')} // Clear the search term when the clear button is clicked
/>

              </th>
              <th>
                <Space direction="vertical">
                  <RangePicker
                    presets={[
                      { label: "Last 7 Days", value: [dayjs().subtract(7, "day"), dayjs()] },
                      { label: "Last 30 Days", value: [dayjs().subtract(30, "day"), dayjs()] },
                    ]}
                    onChange={(dates) => setSelectedDates(dates || [])}
                    placeholder={[" Start Date", " End Date"]}
                  />
                </Space>
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Amount"
                  value={amountTerm}
                  onChange={(e) => setAmountTerm(e.target.value)}
                     className="w-100 border-0"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Created By"
                  value={createdByTerm}
                  onChange={(e) => setCreatedByTerm(e.target.value)}
                     className="w-100 border-0"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                     className="w-100 border-0"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((item, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedIds.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </td>
                {checkAccess("edit_dispatch") && (
                  <td>
                    <Link to={`add/${item.orderId}`}>
                      <EditIcon />
                    </Link>
                  </td>
                )}
                <td>
                  <Link to={{ pathname: `view/${item?.id}` }}>
                    <button className="status">{item.id}</button>
                  </Link>
                </td>
                <td>{item.customerName}</td>
                <td>{moment(item.orderDate).format("DD-MM-YYYY")}</td>
                <td>{item.subTotal}</td>
                <td>{item.created_by}</td>
                <td>
                  <button className={getStatusClass(item.orderStatus)}>
                    {item.orderStatus}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
