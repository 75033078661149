import React from 'react'
import './form.scss';
import Button from '../../shared/components/button';
import Formtable from './formtable';
export default function Form() {
  return (
    <div>
      <div className='page-header'>
        <span>Forms</span>
        <Button text='Add Forms'/>
      </div>
      <Formtable/>
    </div>
  )
}
