import React, { useEffect, useState } from 'react';
import Map, { Marker, Popup, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { fetchCustomData, fetchData, fetchCustomDataId, filterAll } from '../../services/apiService';
import Breadcumbs from '../../shared/components/breadcumbs';
import './location.scss';
import moment from "moment";

const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ'; // Replace with your Mapbox token

const MapComponent = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true); // Manage sidebar state
  const [users, setUserData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [visits, setVisits] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);


  useEffect(() => {
    getData();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true
    };
    return new Date(dateString).toLocaleString("en-IN", options);
  };
  function formatVisitStart(visitStart) {
    return moment(visitStart).format("D-M-YYYY h:mm A");
  }
  const formatDateTime = (isoString) => {
    if (!isoString) return 'N/A';
    const date = new Date(isoString);
    return date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };
  function convertUTCToIST(utcDateString) {
    const utcDate = new Date(utcDateString);
    const istOffset = 5.5 * 60 * 60 * 1000; 
    const istTime = new Date(utcDate.getTime() + istOffset);
    return istTime;
  }

  const calculateTimeDifference = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffMs = endTime - startTime;
    const diffSecs = Math.floor(diffMs / 1000);
    const hours = Math.floor(diffSecs / 3600);
    const minutes = Math.floor((diffSecs % 3600) / 60);
    const seconds = diffSecs % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const getData = async () => {
    try {
      const response = await fetchData('user');
      if (typeof response?.data === 'object') {
        setUserData(response?.data?.map((i) => {
          return {
            ...i
          };
        })); // Update state with fetched data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchOrderData = async (selectedUser) => {
    // if (!selectedUser) return;

    try {
      const currentDate = selectedUser.visit_start;
      const data = { salesperson_id: selectedUser.user_id, orderDate: currentDate };

      const response = await filterAll("order", data);
      if (response) {
        setOrders(response.data || 0);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  useEffect(() => {
    if (modalOpen && selectedUser?.user_id) {
      fetchOrderData(selectedUser);
    }
  }, [modalOpen, selectedUser]);
  console.log(orders, "order");

  useEffect(() => {
    if (selectedUser?.locations) {
      const filteredVisits = selectedUser.locations.filter((i) => {
        return i?.visit_type !== "regular_tracked_point";
      });
      setVisits(filteredVisits.length);
     
    }
  }, [selectedUser]);
console.log("selecteduser",selectedUser);

  const getLocationsData = async (user) => {
    try {
      const response = await fetchCustomDataId('location_tracking/current-user', user?.id);
      if (typeof response?.data === 'object') {
        const { visit_locations, ...restData } = response.data;
        setSelectedUser({
          ...user,
          ...restData,
          locations: visit_locations?.filter((i) => {
            if (i?.latitude !== null && i?.longitude !== null) {
              return {
                ...i,
                time: i?.date,
              };
            }
          })
        });

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Define colors based on visit_type
  const getColorBasedOnVisitType = (visitType) => {
    switch (visitType) {
      case 'regular_tracked_point':
        return 'blue';
      case 'primary':
        return 'green';
      case 'lead':
        return 'orange';
      default:
        return 'gray'; 
    }
  };



  const [filterVisitType, setFilterVisitType] = useState({
    lead: true,
    primary: true,
    regular_tracked_point: true,
  });

  const handleFilterChange = (field, isChecked) => {
    setFilterVisitType((prev) => ({
      ...prev,
      [field]: isChecked,
    }));
  };

  const visitTypeToShow = (loc) => {
    return filterVisitType[loc?.visit_type];
  }
  const totalDistancecover = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;

    const R = 6371; 
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; 
  };
  useEffect(() => {
    if (selectedUser?.locations) {
      const validVisits = selectedUser.locations.filter(
        (location) => location.visit_type !== "regular_tracked_point"
      );
      setVisits(validVisits.length);
      let distance = 0;
      for (let i = 0; i < selectedUser.locations.length - 1; i++) {
        const current = selectedUser.locations[i];
        const next = selectedUser.locations[i + 1];

        if (
          current.latitude &&
          current.longitude &&
          next.latitude &&
          next.longitude
        ) {
          distance += totalDistancecover(
            current.latitude,
            current.longitude,
            next.latitude,
            next.longitude
          );
        }
      }
      setTotalDistance(distance);
    }
  }, [selectedUser]);

  return (
    <>
      <div className="location-page-header-alignment">
        <div className="location-header-alignment">
          <Breadcumbs activePath="Location Tracking" pageName="Location" />
          <div className="right-all-contnet-alignment">
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.lead}
                style={{ accentColor: "#dc7633" }}
                onChange={(e) =>
                  handleFilterChange("lead", e.target.checked)
                }
              />
              <span>Lead visit</span>
            </div>
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.primary}
                style={{ accentColor: "green" }}
                onChange={(e) =>
                  handleFilterChange("primary", e.target.checked)
                }
              />
              <span>Primary visit</span>
            </div>
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.regular_tracked_point}
                style={{ accentColor: "blue" }}
                onChange={(e) =>
                  handleFilterChange("regular_tracked_point", e.target.checked)
                }
              />
              <span>Regular tracked points</span>
            </div>
            <div className="checkbox-text">
              <div className="dot"></div>
              <span>First & Last Tracked Points</span>
            </div>
          </div>
        </div>
        <div className="location-grid">
          <div className="location-grid-items">
            <div className="user-list">
              <h3>User List</h3>
              {users.map((user) => (
                <a
                  key={user.id}
                  className="name-dot-grid"
                  style={selectedUser?.id == user?.id ? { 'background-color': '#f5f5f5' } : {}}
                  onClick={() => getLocationsData(user)}
                >
                  <div className="dot-green"></div>
                  <div>
                    <p>{user.name}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="location-grid-items">
            <div style={styles.mapContainer}>
              <Map
                initialViewState={{
                  latitude: 21.1702,
                  longitude: 72.8311,
                  zoom: 13,
                }}
                style={{ width: "100%", height: "100%" }} // Full height map
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={MAPBOX_TOKEN}
              >
                {selectedUser && (
                  <>
                    {/* Draw route connecting the points */}
                    <Source
                      id="route"
                      type="geojson"
                      data={{
                        type: "Feature",
                        geometry: {
                          type: "LineString",
                          coordinates: selectedUser.locations.map((loc) => [
                            loc.longitude,
                            loc.latitude,
                          ]),
                        },
                      }}
                    >
                      <Layer
                        id="routeLayer"
                        type="line"
                        paint={{
                          "line-color": "#888",
                          "line-width": 4,
                        }}
                      />
                    </Source>

                    {/* Show markers with sequential numbers */}
                    {selectedUser?.locations?.filter((loc) => {
                      return visitTypeToShow(loc)
                    })?.map((loc, index) => {
                      const markerColor = getColorBasedOnVisitType(
                        loc?.visit_type
                      ); // Get color based on visit_type
                      return (
                        <Marker
                          key={index}
                          latitude={loc.latitude}
                          longitude={loc.longitude}
                          anchor="bottom"
                          onClick={() => setSelectedPoint(loc)} // Set selected point when clicked
                        >
                          <div
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Map_pin_icon.svg"
                              alt={`Location Pin ${index}`}
                              style={{ width: "30px", height: "30px" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "-20px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                color: "white",
                                backgroundColor: markerColor, // Set marker color dynamically
                                padding: "2px 5px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1}
                            </div>
                          </div>
                        </Marker>
                      );
                    })}
                  </>
                )}

                {/* Popup for selected point */}
                {/* {selectedPoint && (
                  <Popup
                    latitude={selectedPoint.latitude}
                    longitude={selectedPoint.longitude}
                    closeButton={true}
                    closeOnClick={false}
                    onClose={() => setSelectedPoint(null)}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "20%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "white",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                        padding: "20px",
                        borderRadius: "8px",
                        zIndex: 1000,
                      }}
                    > <h4>Customer Details</h4>
                      <p>
                        <strong>Name:</strong> {selectedPoint.customerName}
                      </p>
                      <p>
                        <strong>Address:</strong> {selectedPoint.address}
                      </p>
                      Time: {selectedPoint.time}
                    </div>
                  </Popup>
                )} */}
                {selectedPoint && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                        width: "80%",
                        maxWidth: "600px",
                        position: "relative",
                      }}
                    >
                      {/* Close Button */}
                      <button
                        onClick={() => setSelectedPoint(null)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>

                      {/* Modal Content */}
                      <div>

                        {selectedPoint.visit_type === "primary" && (
                          <>
                            <h4>Customer Details</h4>
                            <p>
                              <strong>Name:</strong> {selectedPoint.customerName}
                            </p>
                            <p>
                              <strong>Address:</strong> {selectedPoint.address}
                            </p>
                            <p>
                              <strong>Date:</strong> {formatVisitStart(selectedPoint.visit_start)}
                            </p>
                            <p>
                              <strong>Time Taken:</strong>{" "}
                              {calculateTimeDifference(selectedPoint.visit_start, selectedPoint.visit_end)}
                            </p>
                          </>
                        )}

                        {selectedPoint.visit_type === "lead" && (
                          <>
                            <h4>LeadDetails</h4>
                            <p>
                              <strong>Lead Name:</strong> {selectedPoint.leadName || "N/A"}
                            </p>
                            <p>
                              <strong>Address:</strong> {selectedPoint.address || "N/A"}
                            </p>
                            <p>
                              <strong>Date</strong> {formatVisitStart(selectedPoint.visit_start) || "N/A"}
                            </p>
                            <p>
                              <strong>Time Taken:</strong>{" "}
                              {calculateTimeDifference(selectedPoint.visit_start, selectedPoint.visit_end)}
                            </p>
                          </>
                        )}

                        {selectedPoint.visit_type !== "primary" &&
                          selectedPoint.visit_type !== "lead" && (
                            <p>
                              <strong>Date:</strong> {formatVisitStart(selectedPoint.visit_start) || "N/A"}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                )}

              </Map>
            </div>
          </div>
          <div
            className={modalOpen ? "menu-open menu-open-change" : "menu-open"}
            onClick={() => setModalOpen(!modalOpen)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </div>
        </div >
      </div >
      <div
        className={modalOpen ? "location-modal show" : "location-modal hide"}
      >
        <div className="sticky-card">
          <div className="location-modal-header">
            <div className="header-grid">
              <img src="https://app.koops.in/application/assets/images/user-thumbnail.png" />
              <div>
                <p>{selectedUser?.name}</p>
                <span>{formatDate(selectedUser?.punchIntime)}</span>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <i class="fas fa-battery-full" style={{ fontSize: "16px",color: "white" }}></i>
                  <span style={{ fontSize: "14px" }}>
                    {selectedUser?.battery_percent || 0}%
                  </span>
                 
                </div>
                <span> <p><strong>{totalDistance.toFixed(2)}</strong> km</p></span>
              </div>

            </div>

            <div className="header-four-col">
              <div className="items">
                <p>VISIT</p>
                <span>{visits}</span>
              </div>
              <div className="items">
                <p>CUSTOMERS</p>
                <span>{selectedUser?.customers}</span>
              </div>
              <div className="items">
                <p>LEAD</p>
                <span>{selectedUser?.leads}</span>
              </div>
              <div className="items">
                <p>ORDERS</p>
                <span>{selectedUser?.orders}</span>
              </div>
            </div>
          </div>
          <div className="sub-header-alignment">
            <div className="title-sub-text-alignment">
              <p>Expense</p>
              <span>0 Exp.</span>
            </div>
            <div className="all-text-alignment">
              <div>
                <p>TOTAL</p>
                <span>0</span>
              </div>
              <div>
                <p>APPROVED</p>
                <span>₹0</span>
              </div>
              <div>
                <p>PENDING</p>
                <span>₹0</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="all-card-box">
          {selectedUser && isSidebarExpanded && (
            <>
              <h3>{selectedUser.name}'s Journey</h3>
              <div style={styles.journeyList}>
                {selectedUser.locations.map((loc, index) => (
                  <div className="new-grid" key={index}>
                    <div className="line"></div>
                    <div className="bottom-sapcing">
                      <h4> 15-11-2024 10:23 AM</h4>
                      <div className="white-box">
                        <strong>{index + 1}. </strong>
                        <p>{`Time: ${loc.time}`}</p>
                        <p>{`Lat: ${loc.latitude}, Lon: ${loc.longitude}`}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div> */}
        <div className="all-card-box">
          {selectedUser && isSidebarExpanded && (
            <>
              <h3>{selectedUser.name}'s Journey</h3>
              <div style={{ margin: "20px 0" }}>
                {selectedUser?.locations?.filter((i) => {
                  return i?.visit_type !== 'regular_tracked_point'
                }).map((loc, index) => {
                  const visitStartFormatted = formatDate(loc.visit_start);
                  const visitEndFormatted = formatDate(loc.visit_end);
                  const timeTaken = calculateTimeDifference(loc.visit_start, loc.visit_end);

                  return (
                    <div className="new-grid" key={index}>
                      <div className="line"></div>
                      <div className="bottom-spacing">
                        <h4>{formatDate(loc.visit_start)}</h4>
                        <div className="white-box">
                          <strong>{index + 1}. </strong>
                          <p>{`Time Taken: ${timeTaken}`}</p>
                          <p>
                            {loc.visit_type === "primary"
                              ? `${loc.customerName} [Customer]`
                              : `${loc.leadName} [Lead]`}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// Styles for the layout
const styles = {
  mapContainer: {
    flex: 1,
    height: 'calc(100vh - 160px)', // Full screen height for the map
  },
  leftSidebar: {
    width: '250px',
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    overflowY: 'auto',
  },
  rightSidebar: {
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    overflowY: 'auto',
    borderLeft: '2px solid #ccc',
    transition: 'width 0.3s ease', // Smooth transition for the sidebar width
  },
  userItem: {
    padding: '10px',
    cursor: 'pointer',
    marginBottom: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  toggleButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '10px',
    width: '100%',
  },
  journeyList: {
    paddingTop: '10px',
  },
  journeyItem: {
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
};

export default MapComponent;
