import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import EditIcon from '../../../shared/icons/editIcon';


export default function AddEditdealpipeline() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        status: true,
        stages: [],
    });
    const [stages, setStages] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [newStageName, setNewStageName] = useState('');
    const [editIndex, setEditIndex] = useState(null); 
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            const response = await fetchsingleData('dealpipeline', id);
            if (response?.data) {
                setFormData(response.data);
                if (response.data.stages) {
                    setStages(response.data.stages);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.name?.trim()) {
            validationErrors.name = 'This field is required';
        }
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return Object.keys(validationErrors).filter((i) => i !== 'isSubmit').length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            const updatedFormData = { ...formData, stages };
            try {
                if (id) {
                    patchData(`dealpipeline/${id}`, updatedFormData)
                        .then((data) => {
                            if (data) navigate(-1);
                        })
                        .catch((error) => {
                            console.error('Error updating data:', error);
                        });
                } else {
                    postData('dealpipeline', updatedFormData)
                        .then((data) => {
                            if (data) navigate(-1);
                        })
                        .catch((error) => {
                            console.error('Error creating data:', error);
                        });
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };

    const handleAddStage = async () => {
        if (newStageName.trim()) {
            if (editIndex !== null) {
                // Update an existing stage
                const updatedStages = stages.map((stage, index) =>
                    index === editIndex ? { name: newStageName.trim() } : stage
                );
                setStages(updatedStages);
                setEditIndex(null);
            } else {
                // Add a new stage
                const newStage = { name: newStageName.trim() };
                const updatedStages = [...stages, newStage];
                setStages(updatedStages);
            }

            setNewStageName('');
            setModalOpen(false);

            if (id) {
                try {
                    await patchData(`dealpipeline/${id}`, { ...formData, stages });
                } catch (error) {
                    console.error('Error saving stage:', error);
                }
            }
        }
    };

    const handleEditStage = (index) => {
        setNewStageName(stages[index].name);
        setEditIndex(index);
        setModalOpen(true);
    };

    const handleDeleteStage = (index) => {
        const updatedStages = stages.filter((_, i) => i !== index);
        setStages(updatedStages);

        if (id) {
            patchData(`dealpipeline/${id}`, { ...formData, stages: updatedStages }).catch((error) =>
                console.error('Error deleting stage:', error)
            );
        }
    };
    

    return (
        <div className='page-24'>
            <div className='add-header-design'>
                <div>
                    <h2>{id ? 'Edit' : 'Add'} Deal Pipeline</h2>
                    <Breadcumbs activePath="Dashboard" pageName={`${id ? 'Edit' : 'Add'} Deal Pipeline`} />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/dealpipeline">
                        <button className='light-button'>Discard</button>
                    </Link>
                    <button onClick={handleSubmit}>{id ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input
                                label={
                                    <span>
                                        Name<span className="required-star">*</span>
                                    </span>
                                }
                                name='name'
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder='Enter deal pipeline name...'
                                type='text'
                                errors={errors.name}
                                required
                            />
                            <div className='d-flex align-items-end'>
                                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                            </div>
                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <div className='new-box-timeline'>
                            <div onClick={() => {setModalOpen(true)
                                
                            }
                                
                            }>
                                <div className='center-alignment'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" /></svg>
                                </div>
                                <h3>Add Stage</h3>
                            </div>
                        </div>
                        <div className='new-box-timeline-all-alignment'>
                            {stages.map((stage, index) => (
                                <>
                                <div className='line-center'></div>
                                <div key={index} className='new-box-timeline'>
                                    <div className='left-content-alignment'>
                                        <span>{stage.name}</span>
                                    </div>
                                    <div className='right-content-alignment'>
                                  <div onClick={()=>handleEditStage(index)}> <EditIcon onClick={() => handleEditStage(index)}/></div>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleDeleteStage(index)}>
                                            <path d="M15.75 2.99999H13.425C13.0662 1.25518 11.5313 0.00225 9.74998 0H8.24996C6.46863 0.00225 4.93378 1.25518 4.57498 2.99999H2.24999C1.83578 2.99999 1.5 3.33577 1.5 3.74998C1.5 4.16419 1.83578 4.5 2.24999 4.5H2.99998V14.25C3.00247 16.32 4.67995 17.9975 6.74999 18H11.25C13.32 17.9975 14.9975 16.32 15 14.25V4.5H15.75C16.1642 4.5 16.5 4.16422 16.5 3.75001C16.5 3.3358 16.1642 2.99999 15.75 2.99999ZM8.25 12.75C8.25 13.1642 7.91422 13.5 7.50001 13.5C7.08577 13.5 6.74999 13.1642 6.74999 12.75V8.25001C6.74999 7.8358 7.08576 7.50002 7.49998 7.50002C7.91419 7.50002 8.24996 7.8358 8.24996 8.25001V12.75H8.25ZM11.25 12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75001 13.1642 9.75001 12.75V8.25001C9.75001 7.8358 10.0858 7.50002 10.5 7.50002C10.9142 7.50002 11.25 7.8358 11.25 8.25001V12.75ZM6.12825 2.99999C6.44726 2.10171 7.29677 1.50114 8.25 1.49998H9.75001C10.7032 1.50114 11.5528 2.10171 11.8718 2.99999H6.12825Z" fill="#858D9D" />
                                        </svg>

                                    </div>
                                </div>
                                
                                </>

                            ))}
                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>


            {/* <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <h2>Add New Stage</h2>
                    <Input
                        label="Stage Name"
                        name="stageName"
                        value={newStageName}
                        onChange={(e) => setNewStageName(e.target.value)}
                        placeholder="Enter stage name"
                        type="text"
                    />
                    <Button onClick={handleAddStage}>Add Stage</Button>
                </Box>
            </Modal> */}
<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
    <Box
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.3)',
            p: 4,
        }}
    >
        <h2
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#333',
                marginBottom: '20px',
            }}
        >
            {editIndex === null ? 'Add New Stage':'Edit Stage' }
        </h2>
        <div style={{ marginBottom: '16px' }}>
            <label
                style={{
                    display: 'block',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#555',
                    marginBottom: '8px',
                }}
            >
                Stage Name
            </label>
            <input
                type="text"
                name="stageName"
                value={newStageName}
                onChange={(e) => setNewStageName(e.target.value)}
                placeholder="Enter stage name"
                style={{
                    width: '100%',
                    padding: '12px',
                    fontSize: '16px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    outline: 'none',
                    transition: 'border 0.2s ease',
                }}
                onFocus={(e) => (e.target.style.border = '1px solid #007bff')}
                onBlur={(e) => (e.target.style.border = '1px solid #ccc')}
            />
        </div>
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px',
                marginTop: '24px',
            }}
        >
            <button
                onClick={() => {
                    setModalOpen(false);
                    setNewStageName(null);
                    setEditIndex(null);
                }}
                
                style={{
                    padding: '10px 20px',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#555',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    transition: 'background 0.2s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#e2e6ea')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#f8f9fa')}
            >
                Cancel
            </button>
            <button
                onClick={handleAddStage}
                style={{
                    padding: '10px 20px',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#fff',
                    backgroundColor: '#007bff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    transition: 'background 0.2s ease',
                }}
                onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
                onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')}
            >
                {editIndex === null ? 'Add Stage' : 'Update Stage'}
            </button>
        </div>
    </Box>
</Modal>


        </div>
    );
}
