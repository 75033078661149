import React, { useEffect, useState } from 'react'
import './addcustomers.scss';
import Button from '../../shared/components/button';
import Input from '../../shared/components/input';
import Select from '../../shared/components/select';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SelectBox from '../../shared/components/select';
import { fetchData, fetchsingleData, patchData, postData } from '../../services/apiService';
import axios, { toFormData } from 'axios';
import Breadcumbs from '../../shared/components/breadcumbs';
import PlusIcon from '../../assets/icons/plus.svg';
import CancelIcon from '../../assets/icons/Cancel.svg';
import StatusBox from './statusBox';
import Toggle from '../../shared/components/switch';
import store from '../../redux/reduxstore';


export default function Addcustomers() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [gstTypes, setGstTypes] = useState([
    { label: 'Registered Business', value: 'registered' },
    { label: 'Unregistered Business', value: 'unregistered' },
    { label: 'Overseas', value: 'overseas' },
  ]);


  useEffect(() => {
    if (id) {
      setIsEdit(true);
      getProductData();
    }
    fetchCountry();
    fetchState();
    fetchCity();
    fetchArea();
    placeofsupply();
    getuser()
    if (leadFormData?.id && leadFormData?.name) {
      console.log("=====")
      // Set the initial values for the SelectBox in formData
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerId: leadFormData.id,
        customerName: leadFormData.name,
      }));
    }
  }, [id]);

  const getProductData = async () => {
    const response = await fetchsingleData("customer", id);
    if (response?.data)
      setFormData(response?.data);
    if (response?.data?.stateId) fetchArea(response?.data?.stateId)
    if (response?.data?.countryId) fetchState(response?.data?.countryId)

  }
  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const [formData, setFormData] = useState({
    cName: '',
    code: '',
    status: true,
    priceGroup: '',
    priority:'',
    outstandingAmount: null,
    overdueBillAmount: null,
    email: '',
    password: '',
    repassword: '',
    address: '',
    title: '',
    pincode: '',
    countryId: null,
    countryName: '',
    stateName: '',
    stateId: null,
    cityId: null,
    city: '',
    posId: null,
    pos: '',
    areaId: null,
    latitude: null,
    longitude: null,
    addressEmail: '',
    addressContact: '',
    pricegroupId: null,
    gstNo: '',
    gsttype:'',
    userName:"",
    userId:null,

  });
  const location = useLocation();
  const [posOptions, satPos] = useState([]);
  const leadFormData = location.state?.formData; // Access leadId from location state
  const [UserNames, setUserName] = useState([]);
  const [sateOptions, sateCategory] = useState([])
  const [priceOptions, priceCategory] = useState([])
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [place, setPlaceofSupply] = useState([]);


  // const fetchCountry = async () => {

  //   try {
  //     if (id) {
  //       const response = await fetchsingleData("country", id);
  //       if (response?.data)
  //         setFormData(response?.data); // Update state with fetched data
  //     }

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  // const fetchState = async (id) => {
  //   if(id !== null){
  //     try {
  //       const response = await fetchData("state", { countryId: id });

  //       if (response?.data) {
  //         sateCategory(response?.data.map(i => {
  //           return {
  //             label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
  //           }
  //         }));
  //       }

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   } else {
  //     try {
  //       const response = await fetchData("state");

  //       if (response?.data) {
  //         satPos(response?.data.map(i => {
  //           return {
  //             label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
  //           }
  //         }));
  //       }

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }

  // }

  // const fetchArea = async (id) => {
  //   try {
  //     const response = await fetchData("city", { stateId: id });
  //     console.log('arra',response.data);
  //     if (response?.data) {

  //       areCategory(response?.data.map(i => {
  //         return {
  //           label: i?.cityName, key: 'area', value: { areaId: i?.id, cityName: i?.cityName}
  //         }
  //       }));
  //     }

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }
  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
      if (leadFormData?.id) {
        setFormData({ link: leadFormData?.id, cName: leadFormData?.name, address: leadFormData?.address, title: leadFormData?.title, countryName: leadFormData?.country, pincode: leadFormData?.pincode, latitude: leadFormData?.latitude, longitude: leadFormData?.longitude, email: leadFormData?.email, phone: leadFormData?.contactno, city: leadFormData?.city, stateName: leadFormData?.state });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getuser = async () => {
    try {

        const response = await fetchData(`user`);
        if (response?.data) {
            setUserName(
                response?.data?.map((i) => {
                  return {
                    label: i?.name,
                    key: "userName",
                    value: { userId: i?.id, userName: i?.name },
                  };
                })
            );
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
  const placeofsupply = async () => {
    try {
      const response = await fetchData("state");

      if (response?.data) {
        setPlaceofSupply(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { posId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, city: i?.cityName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchArea = async (cityId) => {
    try {
      const response = await fetchData("area", { cityId: cityId });

      if (response?.data) {
        setAreaOptions(
          response?.data.map((i) => {
            return {
              label: i?.areaName,
              key: "area",
              value: { cityId: i?.id, city: i?.areaName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const fetchPrice = async () => {
    try {
      const response = await fetchData("pricegroup");

      if (response?.data) {
        priceCategory(response?.data.map(i => {
          return {
            label: i?.name, key: 'pricegroup', value: { pricegroupId: i?.id, pricegroupName: i?.price }
          }
        }));
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {

          setFormData(prevState => ({
            ...prevState,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }));

        },
        error => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }


    fetchPrice()

  }, [])
  const validateForm = () => {
    const newErrors = {};
    if (!formData.cName) newErrors.cName = 'Customer Name is required';
    if (!formData.code && !company_setting?.extra_setting?.autoGenerateCustomerCode==='Yes') newErrors.code = 'Customer Code is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.countryId) newErrors.countryId = 'Country is required';
    if (!formData.stateId) newErrors.stateId = 'State is required';
    if (!formData.cityId) newErrors.cityId = 'City is required';

    if (!formData.gsttype) {
      newErrors.gsttype = 'GST Type is required';
    } else if (formData.gsttype === 'registered') {

      if (!formData.gstNo) newErrors.gstNo = 'GST No is required for registered businesses';
      if (!formData.pos) newErrors.pos = 'Place of Supply is required for registered businesses';
    } else if (formData.gsttype === 'unregistered') {
      if (!formData.pos) newErrors.pos = 'Place of Supply is required for unregistered businesses';
    }
    if (formData.addressContact.length !== 10) {
      newErrors.addressContact = 'Please enter a valid 10-digit phone number.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function generateCustomerCode(customerName) {
    if (!customerName || customerName.trim().length === 0) {
        throw new Error("Customer name cannot be empty");
    }
    let adjustedName = customerName.trim().toUpperCase();
    while (adjustedName.length < 3) {
        adjustedName += 'X';
    }
    const namePart = adjustedName.substring(0, 3);
    const timestamp = Date.now().toString().slice(-5);
    const randomPart = Math.floor(1000 + Math.random() * 9000);
    const customerCode = `${namePart}${timestamp}${randomPart}`;
    return customerCode;
}

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    let payloadCustomer = formData;
    ['posId', 'latitude', 'longitude'].forEach((key) => {
      if (!payloadCustomer?.[key]) {
        delete payloadCustomer[key]
      }
    });
    try {
      if (id) {
        patchData(`customer/${id}`, payloadCustomer).then(data => {
          if (data) {
            navigate(-1);
          }
          console.log('API response:', data);
        })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } else {
        if(company_setting?.extra_setting?.autoGenerateCustomerCode==='Yes') {
          const ccode=generateCustomerCode(formData?.cName);
          payloadCustomer={...payloadCustomer,"code":ccode}
        }
        postData('customer', payloadCustomer)
          .then(data => {
            if (data) {
              navigate(-1);
            }
            console.log('API response:', data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });


      }

    } catch (error) {
      console.error('Error saving data:', error);

    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  const selectChanged = (e) => {
    if (e.key === "country") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchState(e.value.countryId)
    } else if (e.key === "state") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchArea(e.value.stateId)

    }
    else {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
    }
  }
  const selectgsttype = (selectedOption) => {

    setFormData((prevData) => ({
      ...prevData,
      gsttype: selectedOption.value,
    }));
  };
  const selectpostype = (selectedOption) => {

    setFormData((prevData) => ({
      ...prevData,
      pos: selectedOption.value.stateName,
      posId: Number(selectedOption?.value?.posId),
    }));
  };
  const selectuser = (selectedOption) => {
   
    setFormData((prevData) => ({
      ...prevData,
      userName: selectedOption.value.userName, 
      userId: Number(selectedOption?.value?.userId),
    }));
  };

  return (
    <div className='add-customer-page-design-alignment'>
      <div className='add-customer-header-alignment'>
        <div>
          <h2>Add Customers</h2>
          <Breadcumbs activePath="Customer" pageName="Add Customers" />
        </div>
        <div className='right-contnet-alignment'>
          <button className='light-button'>
            <img src={CancelIcon} alt="CancelIcon" />
            Cancel
          </button>
          <button onClick={handleSubmit}>
            {id ? 'Update Customer' : 'Add Customer'}
          </button>
        </div>
      </div>

      <div className='add-customer-grid'>
        <div className='add-customer-grid-items'>
          <div className='add-news-form-design-customer'>
            <h2>General Information</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Name <span className="required-star">*</span>
                    </span>
                  }
                  name="cName"
                  value={formData.cName}
                  onChange={handleInputChange}
                  placeholder="Customer Name"
                />
                {errors.cName && <p className="error">{errors.cName}</p>}
              </div>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Code 
                      {company_setting?.extra_setting?.autoGenerateCustomerCode==='No'&&(
                        <span className="required-star">*</span>
                      )}
                    </span>
                  }
                  name="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  readOnly={company_setting?.extra_setting?.autoGenerateCustomerCode==='Yes'}
                  placeholder="Customer Code"
                />
                {errors.code && <p className="error">{errors.code}</p>}
              </div>
              <div className='bottom-alignment'>
                <SelectBox
                  label='Price group'
                  name='priceGroup'
                  value={priceOptions.find(i => i.value.id === formData.pricegroupId)} options={priceOptions} onChange={selectChanged}
                  placeholder='Select Price Group'
                  required
                />
              </div>
              {company_setting?.customer_assignment_type ==="Direct assign customer to user" && (

              <div className='bottom-alignment'>
              <SelectBox label={
                                <span>
                                    Users
                                </span>}
                                name='userName'  value={UserNames.find(i => i?.value?.userId === formData?.userId)} onChange={(e) => selectuser(e)} options={UserNames} placeholder='User' />
              </div>
              )}
              <div className='bottom-alignment'>
                <Input label='Outstanding Amount' name='outstandingAmount' value={formData.outstandingAmount} onChange={handleInputChange} placeholder='Outstanding Amount' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Overdue Bill Amount' name='overdueBillAmount' value={formData.overdueBillAmount} onChange={handleInputChange} placeholder='Overdue Bill Amount' />
              </div>
              {company_setting?.extra_setting?.priorityForCustomers ==="Yes" && (
              <div className='bottom-alignment'>
                <Input label='Priority' name='priority' value={formData.priority} onChange={handleInputChange} placeholder='Customer Priority' type="text" />
              </div>
              )}
              <div className='bottom-alignment'>
                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
              </div>
            </div>
          </div>
          <div className='add-news-form-design-customer'>
            <h2>Login Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input label='Email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Password' name='password' value={formData.password} onChange={handleInputChange} placeholder='Password' type='password' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Re-password' name='repassword' value={formData.repassword} onChange={handleInputChange} placeholder='Confirm Password' type='password' />
              </div>
            </div>
          </div>
          <div className='add-news-form-design-customer'>
            <h2>GST Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      GST type<span className="required-star">*</span>
                    </span>
                  }

                  name='gsttype'
                  value={gstTypes.find((option) => option.value === formData.gsttype)} options={gstTypes} onChange={(e) => selectgsttype(e)}
                  placeholder='GST Type'
                />
                {errors.gsttype && <span className="error">{errors.gsttype}</span>}
              </div>
              <div className='bottom-alignment'>
                {formData.gsttype === 'registered' && (
                  <Input
                    label={
                      <span>
                        GST no <span className="required-star">*</span>
                      </span>
                    }

                    name='gstNo'
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    placeholder='GST No.'
                    type='text'
                  />

                )}

                {errors.gstNo && <span className="error">{errors.gstNo}</span>}
              </div>
              <div className='Place of Supply'>
                {(formData.gsttype === 'registered' || formData.gsttype === 'unregistered') && (
                  <SelectBox

                    label={
                      <span>
                        Place of Supply <span className="required-star">*</span>
                      </span>
                    }
                    name='pos'
                    value={place.find(i => i?.value.posId === formData.posId)}
                    options={place}
                    onChange={(e) => selectpostype(e)}
                    placeholder='Place of Supply'
                  />
                )}
                {errors.pos && <span className="error">{errors.pos}</span>}
              </div>
            </div>
          </div>
          <div className='add-news-form-design-customer'>
            <h2>Primary Contact Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Address <span className="required-star">*</span>
                    </span>
                  }
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
                {errors.address && <p className="error">{errors.address}</p>}
              </div>
              <div className='bottom-alignment'>
                <Input label='Title' name='title' value={formData.title} onChange={handleInputChange} placeholder='Title' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Pincode' name='pincode' value={formData.pincode} onChange={handleInputChange} placeholder='Pincode' type='text' />
              </div>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      Country <span className="required-star">*</span>
                    </span>
                  }
                  name="countryName"
                  value={countryOptions.find(
                    (i) => i.value.countryId === formData.countryId
                  )}
                  options={countryOptions}
                  placeholder="Select Country"
                  onChange={(selectedOption) => {
                    fetchState(selectedOption?.value?.countryId);

                    setFormData((prevState) => ({

                      ...prevState,
                      countryId: Number(selectedOption?.value?.countryId),
                      countryName: selectedOption?.value?.country,
                    }));
                  }}


                />

                {errors.countryId && <p className="error">{errors.countryId}</p>}

              </div>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      State <span className="required-star">*</span>
                    </span>
                  }
                  name="stateName"
                  value={stateOptions.find(
                    (i) => i.value.stateId === formData.stateId
                  )}
                  options={stateOptions}
                  placeholder="Select State"
                  onChange={(selectedOption) => {
                    fetchCity(selectedOption?.value?.stateId);

                    setFormData((prevState) => ({
                      ...prevState,
                      stateId: Number(selectedOption?.value?.stateId),
                      stateName: selectedOption?.value?.stateName,
                    }));
                  }}

                />

                {errors.stateId && <p className="error">{errors.stateId}</p>}
              </div>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      City <span className="required-star">*</span>
                    </span>
                  }
                  name="city"
                  value={cityOptions.find(
                    (i) => i.value.cityId === formData.cityId
                  )}
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(selectedOption) => {
                    fetchArea(selectedOption?.value?.cityId);

                    setFormData((prevState) => ({
                      ...prevState,
                      cityId: Number(selectedOption?.value?.cityId),
                      city: selectedOption?.value?.city,
                    }));
                  }}

                />
                {errors.cityId && <p className="error">{errors.cityId}</p>}

              </div>

              <div className='bottom-alignment'>
                <Input label='Latitude' name='latitude' value={formData.latitude} onChange={handleInputChange} placeholder='Latitude' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Longitude' name='longitude' value={formData.longitude} onChange={handleInputChange} placeholder='Longitude' />
              </div>

              <div className='bottom-alignment'>
                <Input label='Email' name='addressEmail' value={formData.addressEmail} onChange={handleInputChange} placeholder='Email' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label={
                  <span>
                    Contact no <span className="required-star">*</span>
                  </span>
                } name='addressContact' value={formData.addressContact} onChange={handleInputChange} placeholder='Contact No.' type='text' />

                {errors.addressContact && <div className="error">{errors.addressContact}</div>}</div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

