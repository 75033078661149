import React, { useState, useEffect, useContext } from 'react';
import './paymentcollectiontable.scss';
import { fetchData } from '../../../services/apiService';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import plugin
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';


export default function Paymentcollectiontable({ selectedIds, setSelectedIds, activityList, setActivityList}) {
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [customers, setCustomerOptions] = useState([]);
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [paymentType, setPaymentType] = useState([]);
  const [AmountTerm, setAmountTerm] = useState("");
  const [createdByTerm, setCreatedByTerm] = useState("");
  const [description,   setDescription] = useState("");

  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10;


  const [searchTerm, setSearchTerm] = useState("");
  const user = useSelector(state => state.user.user);


  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
    getCustomer();
    fetchPaymentTypes();
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    setTotalItems(activityList.length)
  }, [activityList])
  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.customerId,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fetchPaymentTypes = async () => {
    const response = await fetchData(`paymenttype`);
    const paymentttype = response.data.map(i => ({
      label: i?.name,
      key: 'paymenttype',
      value: { id: i?.paymentTypeId, name: i?.name }
    }));
    setPaymentType(paymentttype);
  };


  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
      const response = await fetchData('paymentcollection',requestBody, pagination);
    
      if (response?.data) {
        setActivityList(response.data || [])
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };


  const getStatusClass = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'cancel':
        return 'cancelled';
      case 'confirm':
        return 'open';
      default:
        return '';
    }
  };
  const { RangePicker } = DatePicker;


  const onRangeChange = (dates) => {
    setSelectedDates(dates); 
  };
  const filteredActivityList = activityList.filter((item) => {
    
    const matchesCustomerName = item.cName?.toLowerCase().includes(searchTerm.toLowerCase());

    
    const matchesStatus = item.paymentstatus?.toLowerCase().includes(statusTerm.toLowerCase());

  

   
    const matchesCreatedBy = item.created_by?.toLowerCase().includes(createdByTerm.toLowerCase());

    const matchesedescription = item.description?.toLowerCase().includes(description.toLowerCase());
    
    // Order# Filter
    const matchesOrder = item.paymentno?.toLowerCase().includes(paymentTerm.toLowerCase());

    const matchesAmount = (item.amount || "").toString().toLowerCase().includes((AmountTerm || "").toLowerCase());

    // Amount Filter (ensure amountTerm is correctly converted)
    // const amountValue = AmountTerm ? parseFloat(AmountTerm) : null;
    // const matchesAmount = amountValue !== null ? parseFloat(item.amount) === amountValue : true;

    // Date Range Filter
    const matchesDateRange = (selectedDates && selectedDates.length === 2)
      ? dayjs(item.date).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.date).isSameOrBefore(selectedDates[1], 'day')
      : true;

    const matchesPaymentType = selectedPaymentType?.label
      ?String(item.paymenttype || '').toLowerCase() === selectedPaymentType.label.toLowerCase()
      : true;

    
    return (
      (!searchTerm || matchesCustomerName) &&
      (!statusTerm || matchesStatus) &&
      (!createdByTerm || matchesCreatedBy) &&
      (!description || matchesedescription) &&
      (!AmountTerm || matchesAmount) &&
      matchesDateRange && // Ensure the item matches the selected date range
      matchesPaymentType // Ensure the item matches the selected payment type
    );
  });


  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];



  return (
    <div className=''>
      <div className='account-table-design'>
        <table > 
          <thead>
            <style>
              {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
            </style>
            <tr>
              <th><div style={{ width: '100%' }}></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Payment"
                value={paymentTerm}
                onChange={(e) => setPaymentTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th>
                <div >
                  <SelectBox
                    value={
                      customers?.find((option) => option.label === searchTerm) || null
                    } // Safely access customers
                    options={customers || []} // Default to an empty array if customers is null/undefined
                    onChange={(selectedOption) =>
                      setSearchTerm(selectedOption?.label || "") // Handle null selectedOption
                    }
                    placeholder="Customer"
                    type="text"
                    required={true}
                    errors={errors?.customerName} // Safely access errors
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999, // Ensure dropdown is on top of other elements
                        maxHeight: '400px',
                        overflowY: 'auto',
                        border: 'none', // Enable scrolling if the dropdown is too large
                      }),
                      control: (provided) => ({
                        ...provided,
                        minWidth: '100%',
                        border: 'none',
                        marginTop: '-8px',
                        maxHeight: '25px',
                        textAlign: 'left',
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        border: 'none', // Render the dropdown with a very high z-index
                      }),
                    }}
                    menuPortalTarget={document.body}
                    isClearable={true} // Add clear button
                    components={{
                      ClearIndicator: (props) => (
                        <div
                          {...props.innerProps}
                          style={{
                            cursor: 'pointer',
                            padding: '5px',
                            color: 'gray',
                          }}
                        >
                          &#x2715; {/* Clear button (cross symbol) */}
                        </div>
                      ),
                    }}
                  />
                </div>
              </th>

              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={['Start Date', 'End Date']} className="my-custom-class" />
                </Space></div> </th>
              <th><div style={{ alignItems: 'center', maxWidth: '100%' }}><SelectBox
                value={paymentType?.find((option) => option.label === selectedPaymentType?.label) || null}
                options={paymentType}
                onChange={(selectedOption) => setSelectedPaymentType(selectedOption)}
                placeholder={selectedPaymentType?.label || "Payment Type"}
                type="text"
                required={true}
                errors={errors.paymentType}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                    maxHeight: '400px',
                    overflowY: 'auto',
                    border: 'none',
                  }),
                  control: (provided) => ({
                    ...provided,
                    minWidth: '100%',
                    border: 'none',
                    marginTop: '-8px',
                    maxHeight: '25px',
                    textAlign: 'left',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                    border: 'none',
                  }),
                }}
                menuPortalTarget={document.body}
                isClearable={true} // Add clear button
                    components={{
                      ClearIndicator: (props) => (
                        <div
                          {...props.innerProps}
                          style={{
                            cursor: 'pointer',
                            padding: '5px',
                            color: 'gray',
                          }}
                        >
                          &#x2715; {/* Clear button (cross symbol) */}
                        </div>
                      ),
                    }}
              /></div></th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /> </div></th>
              <th><div style={{ width: '100%' }}>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              />
               </div></th>
              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder="Created By"
                value={createdByTerm}
                onChange={(e) => setCreatedByTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%' }}

                /></div></th>
                              <th><div style={{ width: '100%' }}></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              
           
              <th>Payment #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Payment type</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Created by</th>
              <th>Status</th>
              {checkAccess('edit_payment_collection')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {filteredActivityList?.reverse().map((item, index) => {
              return (
                <tr key={index}

                  // style={{
                  //   backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#f8f9f9', // Alternate row colors
                  // }}
                >
                  <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                
                  <td>{item?.paymentno}</td>
                  <td>
                    <span className='price-group-text'>{item?.cName}</span>
                  </td>
                  <td className='whitesapce-nowrap'>{dayjs(item?.date).format('DD-MM-YYYY')}</td>
                  <td>{item?.paymenttypeName}</td>
                  <td className='whitesapce-nowrap'>{item?.amount}</td>
                  <td>
                    <span className='gray-text'>{item?.description}</span>
                  </td>
                  <td>
                    <span className='gray-text'>{item?.created_by}</span>
                  </td>
                 <td>
                 {checkAccess("read_payment_collection")?( <Link to={{ pathname: `view/${item?.id}` }}>
                        <button className={` ${getStatusClass(item?.paymentstatus)}`}>
                          {item?.paymentstatus}
                        </button>
                      </Link>):( <button className={` ${getStatusClass(item?.paymentstatus)}`}
                      style={{ pointerEvents: "none", cursor: "default" }}>
                          {item?.paymentstatus}
                        </button>)}

                  </td>
                  {checkAccess('edit_payment_collection')&&<td>
                    <Link to={{ pathname: `addpaymentcollection/${item?.id}` }}> 
                      <EditIcon/>
                    </Link>
                  </td>}

                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
