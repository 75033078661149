import React, { useEffect, useState } from 'react'
import './expensetype.scss';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
export default function Expensetypetable({ selectedIds, setSelectedIds, expensetypeList, setProductList, setTotalItems, totalItems }) {

    const [currentPage, setCurrentPage] = useState(1); // Current page state
    // const [expensetypeList, setProductList2] = useState([]);
    
    const pageSize = 10; // Items per page

    useEffect(() => {
        // Fetch data when page or pageSize changes
        getData(currentPage, pageSize);
      }, [currentPage]);

    const getData = async (page, pageSize) => {
        try {
            const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
            const response = await fetchData('expensetype',requestBody, pagination);
            if (response?.data) {
                setProductList(response.data || []);  // API returns current page data in 'items'
                setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
              }
            } catch (error) {
              console.error('Error fetching data:', error);
            }
    };
    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds(expensetypeList.map((i) => i.id));
        } else {
            setSelectedIds([]);
        }
    };
    const getStatusClass = (status) => {
        switch (status) {
            case 'daily':
                return 'opeen';
            case 'travelling':
                return 'Pending';
            case 'onsystem':
                return 'Pending';
            case 'oncounter':
                return 'opeen';
            case 'no':
                return 'cancelled';

        }
    };

    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    
    const paginatedData = expensetypeList.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    return (
        <div className='account-table-page-alignment'>
      <div className='account-table-design'>
                <table>
                    <thead>
                        <tr>
                            <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === expensetypeList.length} /></th>
                            <th>Action</th>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Allowance Type</th>
                            <th>
                                Is per Km
                            </th>

                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (expensetypeList ?? []).map((i) => {
                                return (
                                    <tr key={i?.id}>
                                        <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                                        <td>
                                            <Link to={{ pathname: `addexpensetype/${i?.id}` }}> 
                                            <EditIcon/>
                                            </Link>
                                        </td>
                                        <td>{i?.name}</td>
                                        <td>{i?.amount ? i.amount : i.rate}</td>
                                       
                                        <td>
                                            <button className={` ${getStatusClass(i.allowancetype)}`}>
                                                {i?.allowancetype}
                                            </button>
                                        </td>
                                        <td>
                                            <button className={` ${getStatusClass(i.isperkm)}`}>
                                                {i?.isperkm}
                                            </button>
                                        </td>
                                        <td>
                                            <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className='pagination-alignment'>
                <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
            </div>
        </div>
    )
}
