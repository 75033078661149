import React from 'react'
import './forgotpassword.scss';
import Input from '../../shared/components/input';

export default function Forgotpassword() {
    return (
        <div className='forgot-password'>
            <div className='box'>
                <div className='header'>
                    <h1>Reset your password
                    </h1>
                </div>
                <div className='body'>
                    <p>Please enter your email address. We will send you an email to reset your password.</p>
                    <div className='alignment'>
                        <Input label='Email Id' placeholder="admin@gmail.com" />
                    </div>
                </div>
                <div className='button'>
                Send email
                </div>
            </div>
        </div>
    )
}
