import React, { useState, useEffect, useContext } from 'react';
import { fetchData } from '../../../services/apiService';
import './leavetable.scss';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import plugin
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';


export default function Leavetable({ selectedIds, setSelectedIds, activityList, setActivityList }) {
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    const [currentPage, setCurrentPage] = useState(1);
    const [ProductList, setProductList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 10;
    const [searchTerm, setSearchTerm] = useState("");
    const user = useSelector(state => state.user.user);

    useEffect(() => {
        getData(currentPage, pageSize);
    }, [currentPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getData = async (page, pageSize) => {
        try {
            const requestBody = {};
            const pagination = {
                page: page,
                limit: pageSize,
            }
            const response = await fetchData('leave', requestBody, pagination);
            if (response?.data) {
                setActivityList(response.data || [])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };
    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds(activityList.map((item) => item.id));
        } else {
            setSelectedIds([]);
        }
    };


    const getStatusClass = (status) => {
        switch (status) {
            case 'pending':
                return 'Pending';
            case 'Reject':
                return 'cancelled';
            case 'Approved':
                return 'open';
            case 'Half Day':
                return 'Pending';
            case 'Full Day':
                return 'open';
            case 'Quarter Day':
                return 'cancelled';
            default:
                return '';
        }
    };


    return (
        <div className=''>
            <div className='account-table-design'>
                <table >
                    <thead>
                        <tr>
                            <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
                            <th>Leave No</th>
                            <th>User</th>
                            <th>Date</th>
                            <th>Leave type</th>
                            <th>Reason</th>
                            <th>Leaveweight</th>
                            <th>Created by</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityList?.reverse().map((item, index) => {
                            return (
                                <tr key={index}
                                >
                                    <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>

                                    <td>{item?.leaveno}</td>
                                    <td>
                                        <span className='price-group-text'>{item?.username}</span>
                                    </td>
                                    <td className='whitesapce-nowrap'>{dayjs(item?.date).format('DD-MM-YYYY')}</td>
                                    <td>{item?.leavetype}</td>
                                    <td className='whitesapce-nowrap'>{item?.reason}</td>
                                    <td className='whitesapce-nowrap'><button className={` ${getStatusClass(item?.status)}`}>{item?.leaveweight}</button></td>

                                    <td>
                                        <span className='gray-text'>{item?.created_by}</span>
                                    </td>
                                    <td>
                                        <Link to={{ pathname: `view/${item?.id}` }}>
                                            <button className={` ${getStatusClass(item?.status)}`}>
                                                {item?.status}
                                            </button>
                                        </Link>

                                    </td>
                                    <td>
                                        <Link to={{ pathname: `addleave/${item?.id}` }}>
                                            <EditIcon />
                                        </Link>
                                    </td>

                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className='pagination-alignment'>
                    <Pagination
                        currentPage={currentPage}
                        totalItems={totalItems} // Total items from API response
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
}
