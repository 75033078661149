import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './producttable.scss';
import { fetchData, deleteData } from '../../../services/apiService'; // Import deleteData
import Toggle from '../../../shared/components/switch';
import ShowImage from '../../../shared/components/showImage';
import ViewIcon from '../../../assets/icons/view.svg';
import RemoveIcon from '../../../assets/icons/remove.svg';
import PenIcon from '../../../assets/icons/pen.svg';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";

export default function Producttable() {
  const [productList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductTerm, setProductTerm] = useState(""); // Initialize as an empty string
  const [ProductCode, setProductCodeTerm] = useState("");
  const [Category, setCategoryTrem] = useState("");
  const [Price, setPriceTerm] = useState(""); // Initialize as an empty string
  const [Stock, setStockTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [Location, setLocationTrem] = useState("");

  // Handle pagination page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  // Fetch data from API with pagination
  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };

      const response = await fetchData('product', requestBody, pagination);

      if (response?.data) {
        setProductList(response.data || []); 
        setTotalItems(response?.totalDocuments || 0); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Handle delete action
  const handleDelete = async (id) => {
    try {
      await deleteData(`product/${id}`);
      setProductList((prev) => prev.filter((product) => product.id !== id));
      setTotalItems(totalItems - 1);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async (e, id) => {
    e.preventDefault();

    setConfirmLoading(true);
    await handleDelete(id);
    setOpen(false);
    setConfirmLoading(false);
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  // Filtered product list with corrected logic
  const filteredProductList = productList.filter((item) => {
    return (
      (!ProductTerm || item.name?.toLowerCase().includes(ProductTerm.trim().toLowerCase())) &&
      (!ProductCode || (item.hsn_code?.toString().trim() || '').toLowerCase().includes(ProductCode.trim().toLowerCase())) &&
      (!Category || (item.categoryName?.toString().trim() || '').toLowerCase().includes(Category.trim().toLowerCase())) &&
      (!Price || item.price?.toString().trim().includes(Price.trim())) && // Convert price to string for comparison
      (!Stock || item.stock?.toString().trim().includes(Stock.trim())) && // Convert stock to string for comparison
      (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) &&
      (!Location || (item.location?.toString().trim() || '').toLowerCase().includes(Location.trim().toLowerCase()))
    );
  });
  
  return (
    <div className='productunittable-alignment'>
      <div className='product-table-design'>
        <table>
          <thead>
            <tr>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Product" value={ProductTerm} onChange={(e) => setProductTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Product Code" value={ProductCode} onChange={(e) => setProductCodeTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Category" value={Category} onChange={(e) => setCategoryTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Price" value={Price} onChange={(e) => setPriceTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Stock" value={Stock} onChange={(e) => setStockTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Location" value={Location} onChange={(e) => setLocationTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><div className="tableSize"></div></th>
            </tr>

            <tr>
              <th><input type='checkbox' /></th>
              <th>Product</th>
              <th>Product Code</th>
              <th>Category</th>
              <th>Price</th>
              <th>Stock</th>
              <th>Status</th>
              <th>Location</th>
              {checkAccess('edit_product') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {filteredProductList.map((i) => (
              <tr key={i.id}>
                <td><input type='checkbox' /></td>
                <td align="center">
                  <div className="image-text">
                    {i?.productUrl ? (
                      <>
                        <ShowImage value={i.productUrl} />
                        <div>{i.name}</div>
                      </>
                    ) : (
                      <div>{i.name}</div>
                    )}
                  </div>
                </td>

                <td><span>{i?.hsn_code}</span></td>
                <td><span className='gray-text'>{i?.categoryName}</span></td>
                <td><span>{i?.price}</span></td>
                <td><span>{i?.stock} {i?.unitName}</span></td>
                <td>
                  {checkAccess('read_product') ? (
                    <Link to={{ pathname: `view/${i?.id}` }}>
                      <button className='active-button'>{i?.status ? 'Active' : 'Deactive'}</button>
                    </Link>
                  ) : (
                    <button className='active-button' style={{ pointerEvents: "none", cursor: "default" }}>
                      {i?.status ? 'Active' : 'Deactive'}
                    </button>
                  )}
                </td>
                <td><span className='gray-text'>{i?.location}</span></td>
                <td align="center">
                  <div className="three-icon-alignment">
                    <img src={ViewIcon} alt="View Icon" />
                    {checkAccess('edit_product') && (
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <img src={PenIcon} alt="Pen Icon" />
                      </Link>
                    )}
                    <Popconfirm
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      title="Are you sure to delete this record?"
                      description="This will lead to delete all records related to this record only!"
                      open={i?.open}
                      onConfirm={(e) => handleOk(e, i.id)}
                      okButtonProps={{ loading: confirmLoading }}
                      onCancel={handleCancel}
                    >
                      <img
                        src={RemoveIcon}
                        alt="Remove Icon"
                        onClick={showPopconfirm}
                        style={{ cursor: 'pointer' }}
                      />
                    </Popconfirm>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination-alignment'>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} // Total items from API response
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
