import React from 'react'
import './breadcumbs.scss';
import RightIcon from '../../../assets/icons/right-sm.svg';
export default function Breadcumbs({activePath , pageName}) {
  return (
    <div className='breadcumbs-alignment'>
      <span className='active-class'>{activePath}</span>
      <img src={RightIcon} alt="RightIcon"/>
      <span>{pageName}</span>
    </div>
  )
}
