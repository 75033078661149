import React, { useState, useEffect, useRef, useContext } from 'react';
import 'moment/locale/it.js';
import './expenseview.scss'
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import ApprovalModal from './ApprovalModal';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import ShowImage from '../../../shared/components/showImage';
import { border } from '@mui/system';

export default function AddEditviewexpense() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [newNotification, setNewNotification] = useState([]);
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        username: '',
        expenseno: '',
        date: dayjs(),
        expensetype: '',
        amount: '',
        expensestatus: 'pending',
        approved: '00',
        expensenotification: [],
        discription: '',
        expensetypename: '',
    });
    const [pdfGenerated, setPdfGenerated] = useState(false);
    const reportTemplateRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [approvedPrice, setApprovedPrice] = useState('');

    const pdfRef = useRef();

    const handleGeneratePdf = () => {
        const pdfWidth = 420; // Full HD width
        const pdfHeight = 370; // Full HD height

        const doc = new jsPDF({
            orientation: 'b5', // Landscape orientation for wider content
            unit: 'px', // Use pixels for dimensions
            format: [pdfWidth, pdfHeight], // Custom format for full HD
        });

        // Convert the content of the div to PDF
        doc.html(pdfRef.current, {
            callback: (doc) => {
                doc.save('order_details.pdf'); // Save the PDF file
            },
            x: 20, // X offset for margins
            y: 20,
            z: 20, // Y offset for margins

            width: pdfWidth - 20, // Adjust the width for margins
            windowWidth: 970, // The width of the browser window

        });
    };


    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {

            const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
            const updatedNotification = { date: currentDateTime, status: status, user: name };
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            const updatedFormData = {
                ...formData,
                expensestatus: status === 'approvel' ? 'approvel' : 'reject',
                approved: status === 'reject' ? '00' : 'amount',
                expensenotification: [...newNotification, updatedNotification]
            };
            if (id) {
                const data = await patchData(`expense/${id}`, updatedFormData);

                console.log("data::", data)
                if (data) {
                    setFormData(updatedFormData);

                    // window.location.reload(false);
                }
            } else {
                const data = await postData('expense', updatedFormData);
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleApprovalSubmit = async (e) => {
        e.preventDefault();
        try {
            const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
            const updatedNotification = { date: currentDateTime, status: 'approvel', user: name };
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            const updatedFormData = {
                ...formData,
                expensestatus: 'approvel',
                approved: approvedPrice,
                expensenotification: [...newNotification, updatedNotification]
            };
            if (id) {
                const data = await patchData(`expense/${id}`, updatedFormData);
                if (data) setFormData(updatedFormData);
            } else {
                const data = await postData('expense', updatedFormData);
                if (data) navigate(-1);
            }
            setShowModal(false);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const getData = async () => {
        console.log("new notification", newNotification)
        try {
            const response = await fetchsingleData("expense",id);
            if (response?.data) setFormData(response?.data);
            if (response?.data?.expensenotification) setNewNotification(response?.data?.expensenotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // console.log((formData.expensenotification).reverse());



    const handleApproveClick = () => {
        setShowModal(true);
    };


    return (
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}> EXPENSE{'>'} #{formData.expenseno}</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    {/* <div onClick={handleGeneratePdf}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                        </svg>
                        </div> */}
                   

                        <button
                            onClick={(e) => handleApproveClick(e)}
                            style={{
                                backgroundColor: '#27AE60',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                border: 'none',
                                cursor: 'pointer',
                                height: '30px',
                                fontSize: '10px',
                                fontWeight: 'bold',

                            }}
                        >
                            Approvel
                        </button>
                   <button
                        onClick={(e) => handleSubmit(e, 'reject')}
                        style={{
                            backgroundColor: '#D44545',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Reject
                    </button>
                    <a href="/expense" style={{ textDecoration: 'none' }}>
                        <button style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: '1px solid #1e2e50',
                            backgroundColor: 'transparent',
                            color: '#1e2e50',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }} >
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    width: '900px'
                }} ref={pdfRef}>
                    <h2 style={{ paddingBottom: '10px' }}> from,</h2>

                    <p style={{ fontSize: '0.85rem', color: '#a7289f' }}>{formData.username}</p>

                    <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <div style={{ flex: '1', marginRight: '1rem' }}>
                                <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Expense Type.</div>
                                <strong>{formData.
                                    expensetypename}</strong>
                            </div>
                            <div style={{ flex: '1', textAlign: 'right' }}>
                                <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Expense Date</div>
                                <strong>{dayjs(formData.date).format('DD-MM-YYYY')}</strong>
                            </div>
                        </div>
                    </div>

                    <div style={{ direction: 'flex', flexDirection: 'row' }}> <div> <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', marginBottom: '1rem' }}>
                            <div style={{ flex: '1', marginRight: '1rem'}}>
                                <div><div> <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Expense Status</div>
                                    <strong>{formData.expensestatus}</strong></div>

                                </div>
                                <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                    {formData.address}

                                    <a href={`mailto:${formData.email}`} style={{ color: 'purple' }}>{formData.email}</a>
                                </p>
                             

                            </div>
                            {/* <div >
                            <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Expense Status</div>
                            <div className="shadow p-0.5 mb-4 mt-1 bg-white rounded-2 border pull-right" style={{ border: '1px solid #000', paddingLeft:'10px', borderRadius: '0px', width: '15rem',height:'4rem',overflow:'scroll'}}>{formData.discription}</div>
                            </div> */}
                        </div>
                    </div>

                        <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '0.5rem 0' }}><strong>Claim Amount:</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'right', padding: '0.5rem 0' }}><strong>Discription</strong></th>
                                </tr>


                            </thead>

                            <tbody>
                                <tr>
                                    <td style={{ padding: '0.5rem 0', borderBottom: '1px solid #edf2f9' }}>₹{formData.amount}</td>
                                    <td style={{ padding: '0.5rem 0', borderBottom: '1px solid #edf2f9', textAlign: 'right' }}>{formData.discription}</td>
                                </tr>





                            </tbody>
                        </table>

                    </div>
                        <div style={{ paddingTop: '5px', marginTop: '20px', border: '2px solid #edf2f9', width: '768px', height: '400px', borderRadius: '25px', backgroundColor: '#f8f9f9' }}>
                            <div style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '0.5rem 0', alignContent: 'center', paddingLeft: '32px', maxWidth: 'fit-content', marginLeft: 'auto', marginRight: 'auto' }}>
                                <strong>Expense document:</strong>
                            </div>
                            <div className="thumbnail-container" style={{ alignContent: 'center', paddingLeft: '32px' }}>
                                <a href={formData.photoUrl} target="_blank" rel="noopener noreferrer">
                                    <ShowImage
                                        className="thumbnail"
                                        value={formData.photoUrl}
                                        style={{ width: '700px', height: '350px', borderRadius: '4px', paddingBottom: '22px' }}
                                    />
                                </a>
                            </div>

                        </div>


                    </div>

                    <div style={{ marginTop: '1.5rem' }}>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <h5 style={{ marginRight: '1rem' }}>Total Approve :</h5>
                            <h5 style={{ color: '#28a745' }}>₹{formData.approved}</h5>
                        </div>
                    </div>
                </div>
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: '858px',
                    overflowY: 'auto'
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Remark</strong></div>

                    {
                        newNotification.slice().reverse().map((i, index) => (

                            < div style={{ display: 'flex', paddingLeft: '3px', paddingBottom: '15px' }}> <div style={{ paddingRight: '5px' }}>➨</div><div style={{ fontSize: '14px' }}>On <strong>{i.date}</strong> the expense status was updated to <strong>{i.status}</strong> by the <strong>{i.user}</strong>.</div></div>
                        ))}
                </div>
            </div>


            <ApprovalModal
                show={showModal}
                approvedPrice={approvedPrice}
                setApprovedPrice={setApprovedPrice}
                onClose={() => setShowModal(false)}
                onSubmit={handleApprovalSubmit}
            />
        </div>
    );
}
