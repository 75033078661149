import { Link } from 'react-router-dom'
import { fetchData } from '../../../services/apiService';
import EditIcon from '../../../shared/icons/editIcon';
import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/components/pagination";
import { checkAccess } from '../../../services/checkFeature';

export default function PricegroupTable({
  setSelectedIds ,
  PriceList ,
  setProductList ,
  setTotalItems ,
  totalItems ,
  selectedIds 
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [Name, setNameTerm] = useState(""); // Initialize as an empty string
  const [Code, setCodeTerm] = useState("");
  const [Discount, setDiscountTerm] = useState("");
  const [Price, setPriceTerm] = useState(""); // Initialize as an empty string
  const [OfferDiscount, setOfferDiscountTrem] = useState("");
  const [Status, setStatusTrem] = useState("");
  // Fetch data when the component mounts or page changes
  useEffect(() => {
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchData("pricegroup", requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []); // Set data from API
        setTotalItems(response?.totalDocuments || 0); // Set total items count
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(PriceList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

    // Filtered product list with corrected logic
    const filteredPriceGroup = PriceList.filter((item) => {
      return (
        (!Name || item.name?.toLowerCase().includes(Name.trim().toLowerCase())) &&
        (!Code || (item.code?.toString().trim() || '').toLowerCase().includes(Code.trim().toLowerCase())) &&
        (!Discount || (item.discount?.toString().trim() || '').toLowerCase().includes(Discount.trim().toLowerCase())) &&
        (!Price || item.price?.toString().trim().includes(Price.trim())) && // Convert price to string for comparison
        (!OfferDiscount || item.offerDiscount?.toString().trim().includes(OfferDiscount.trim())) && // Convert stock to string for comparison
        (!Status || (item.status?.toString().trim() || '').toLowerCase().includes(Status.trim().toLowerCase())) 
      );
    });
  


  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={Name} onChange={(e) => setNameTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Code" value={Code} onChange={(e) => setCodeTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Price" value={Price} onChange={(e) => setPriceTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Discount" value={Discount} onChange={(e) => setDiscountTerm(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Offer Discount" value={OfferDiscount} onChange={(e) => setOfferDiscountTrem(e.target.value)} className="w-100 border-0" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0" /></th>
           
            </tr>

            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === PriceList.length && PriceList.length > 0}
                />
              </th>
              {checkAccess('manage_price_group')&&<th align="center">Action</th>}
              <th>Name</th>
              <th>Code</th>
              <th>Price</th>
              <th>Discount(%)</th>
              <th>Offer Discount(%)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredPriceGroup.length > 0 ? (
              filteredPriceGroup.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  {checkAccess('manage_price_group')&&<td>
                    <Link to={{ pathname: `addprice/${item.id}` }}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </Link>
                  </td>}
                  <td>
                    <span className="price-group-text">{item.name}</span>
                  </td>
                  <td>{item.code}</td>
                  <td>{item.price}</td>
                  <td>{item.discount}</td>
                  <td>{item.offerDiscount}</td>
                  <td>
                  {item.status ? 'Active' : 'Deactive'}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" align="center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
