import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import "./adddispatchorder.scss";
import Button from '../../../shared/components/button';
import InputCustom from "../../../shared/components/input";
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
export default function AddEditdispatchorder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const name = useSelector((state) => state.auth.name);
  const isEditMode = window.location.pathname.includes('/add') || false;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const [transportType, setTransportType] = useState([]);
  const [customers, setCustomerOptions] = useState([]);
  const [orderNo, setOrderOption] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [productsForOrder, setProductsForOrder] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#f1f1f1',
      minHeight: '40px',
      height: '40px',
      fontSize: '14px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 6px',
      fontSize: '14px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
  };
  useEffect(() => {
    setProductOptions(() => productsForOrder?.map((i) => {
      return {
        label: `[${i?.productCode}] ${i?.productName}`,
        key: "product",
        value: {
          id: i?.id,
          productId: i?.productId,
          productName: i?.productName,
          price: i?.unitPrice,
          unitId: i?.unitId,
          unitName: i?.unitName,
          moq: i?.moq,
          tax: i?.tax,
          discount: i?.discount,
          productUrl: i?.productUrl,
          productCode: i?.productCode,
        },
      };
    }));
  }, [productsForOrder])
  const [formData, setFormData] = useState({
    id: '',
    dispatchCode: '',
    dispatchNo: '',
    customerName: "",
    orderNo: '',
    date: dayjs(),
    invoiceNo: '',
    invoiceDate: dayjs(),
    transport: '',
    lr_no: "",
    lr_date: dayjs(),
    orderproducts: [],
    total: 0,
    subTotal: 0,
    orderStatus: '',
    customerId: "",
    customerAddress: "",
    customerContact: "",
    orderId: ''

  });
  const AddProduct = () => {
    const orderproducts = [...productsForOrder];
    orderproducts.push({
      productName: "",
      productId: "",
      unitId: "",
      unitName: "",
      quantity: 1,
      price: 0,
      discount: 0,
      tax: 0,
      subTotal: 0,
      productUrl: '',
      productStatus: 'dispatchPending',
      productCode: '',
    });
    setProductsForOrder([...orderproducts]);



    const updatedFormData = {
      ...formData,
      orderproducts: orderproducts
    };

    setFormData(updatedFormData);
    console.log("form data", formData?.orderproducts)

  };
  const handleAddProductClick = () => {
    if (formData.customerId) {
      AddProduct();
    }
  };
  const productSelectChange = (index, event, type) => {
    if (type === 'p') {
      const orderproducts = [...formData.orderproducts];
      orderproducts[index]['productId'] = event.value.productId;
      orderproducts[index]['productName'] = event.value.productName;
      orderproducts[index]['unitName'] = event.value.unitName;
      orderproducts[index]['unitId'] = event.value.unitId;
      orderproducts[index]['quantity'] = Number(event.value.moq);
      orderproducts[index]['price'] = Number(Number(parseFloat(event.value.price)).toFixed(2));
      orderproducts[index]['tax'] = Number(event.value.tax);
      orderproducts[index]['discount'] = Number(parseFloat(event.value.discount));
      orderproducts[index]['subTotal'] = (Number(Number(event.value.price) * Number(event.value.moq).toFixed(2)));
      orderproducts[index]['productUrl'] = event.value.productUrl;
      orderproducts[index]['productCode'] = event.value.productCode;
      setFormData({ ...formData, orderproducts: orderproducts });
      calculateTotal(orderproducts);

    }
  }
  const handleInputChangeProduct = (e, index) => {
    const { name, value, checked, type } = e.target;
    const orderproducts = [...productsForOrder];
    if (name === 'quantity') {
      orderproducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === 'price') {
      orderproducts[index][name] = Number(parseFloat(value).toFixed(2));;
    }
    if (name === 'discount') {
      orderproducts[index][name] = value;
    }
    if (name === 'tax') {
      orderproducts[index][name] = value;
    }

    const price = Number(orderproducts[index]['price']) * Number(orderproducts[index]['quantity']) || 0;
    const discount = (price * Number(orderproducts[index]['discount']) / 100) || 0;
    const tax = ((price - discount) * Number(orderproducts[index]['tax']) / 100) || 0;
    orderproducts[index]['subTotal'] = Number(parseFloat(((price - discount) + tax)).toFixed(2));
    setFormData({ ...formData, orderproducts: orderproducts });
    setProductsForOrder([...orderproducts]);
    calculateTotal(orderproducts);

  };
  const calculateTotal = (orderproducts) => {
    const total = orderproducts.reduce((acc, orderproducts) => acc + (orderproducts.subTotal || 0), 0);
    setFormData({ ...formData, totalAmount: total.toFixed(2) });
    console.log(formData.totalAmount);
  }
  const handleRemoveProduct = (indexToRemove) => {
    const orderproducts = [...formData.orderproducts];
    orderproducts.splice(indexToRemove, 1);
    const total = orderproducts.reduce((acc, orderproducts) => acc + (orderproducts.subTotal || 0), 0);
    setFormData(() => { return { ...formData, totalAmount: total.toFixed(2), orderproducts: orderproducts } });
    // calculateTotal(orderproducts);
  };


  const [errors, setErrors] = useState({});

  useEffect(() => {
    getData();
    getCustomer();
  }, []);
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      orderproducts: [...productsForOrder],
    }));
  }, [productsForOrder]);


  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.id,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      const response = await fetchData('trasport');

      if (response?.data) {
        const formattedTransporterNames = response.data.map(i => ({
          label: i?.name,
          key: 'transport',
          value: i?.name, // Directly use the name as the value
        }));
        setTransportType(formattedTransporterNames);
      }

      if (response?.data) {
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === "unit")
            .map((i) => {
              return {
                label: i?.typeName,
                key: "unit",
                value: { id: i?.systemtypeId, name: i?.typeName },
              };
            })
        );
      }

      const res = await fetchData('order');
      if (res?.data) {
        setOrderOption(
          res?.data?.map((i) => {
            return {
              label: `${i?.orderNo}`,
              key: "orderNo",
              value: i?.orderNo,
              id: i?.id,
            };
          })
        );
      }

      if (id) {
        const response = await fetchsingleData("order", id);
        if (response?.data) {
          const fetchedData = response.data;


          // Map through orderproducts to calculate subTotal
          const updatedorderproducts = response?.data?.orderproducts.map((product) => {
            const price = product.unitPrice * product.quantity || 0;
            const discount = (price * product.discount) / 100 || 0;
            const tax = ((price - discount) * product.tax) / 100 || 0;
            return {
              ...product,
              total: Number(((price - discount) + tax).toFixed(2)),
            };
          });
          setFormData({
            customerId: fetchedData.customerId,
            customerName: fetchedData?.customerName,
            date: dayjs(fetchedData.date),
            orderproducts: fetchedData.orderproducts,
            invoiceDate: dayjs(fetchedData.invoiceDate),
            customerName: fetchedData?.customerName,
            customerAddress: fetchedData.customerAddress,
            customerContact: fetchedData.customerContact,
            orderId: fetchedData.id,
            orderNo: fetchedData?.orderNo
          }); // Update state with fetched data
          setProductsForOrder(updatedorderproducts); // Update with calculated products
        }
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleSubmit = (e) => {
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    e.preventDefault();
    if (validationCheck(true)) {
      try {
        if (id) {
          patchData(`order/${id}`, formData).then(data => {
            navigate(-1);
            console.log('API response:', data);
          }).catch(error => {
            console.error('Error fetching data:', error);
          });
        } else {
          postData('order', formData).then(data => {
            navigate(-1);
            console.log('API response:', data);
          }).catch(error => {
            console.error('Error fetching data:', error);
          });
        }
      } catch (error) {
        console.error('Error saving data:', error);
        // Handle error
      }
    }
    if (validationCheck(true) && productsForOrder?.length > 0) {
      try {
        if (id) {
          patchData(`order/${id}`, { ...formData, orderproducts: [...productsForOrder], orderupdatedby: name, orderupdate: currentDateTime })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {

          postData("order", { ...formData, orderproducts: [...productsForOrder], ordercreatedby: name, })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };

  const saveDispatchOrder = (e) => {
    e.preventDefault();

    // Transform orderproducts to only include orderId and dispatchqty


    if (validationCheck()) {

      try {
        const transformedOrderProducts = formData.orderproducts.map((item) => ({
          id: item.id, // Replace 'id' with 'orderId' if needed
          dispatchqty: item.quantity, // Ensure dispatchqty exists in your formData
        }));
        // Prepare the request body
        const requestBody = {
          ...formData,
          orderproducts: transformedOrderProducts, // Only the required fields

        };
        if (requestBody?.totalAmount)
          delete requestBody.totalAmount;
        console.log("Request Body:", requestBody);

        postData("dispatchorder", requestBody)
          .then((data) => {
            navigate(-1);
            console.log("API response:", data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };



  const selectChanged = async (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      [selectedOption.key]: selectedOption.value,
    }));

    if (selectedOption.key === "customer") {
      setFormData((prevState) => ({
        ...prevState,
        customerAddress: selectedOption.value?.address,
        customerContact: selectedOption.value?.phone,
      }));

      const customerId = selectedOption.value?.id;
      if (customerId) {
        const res = await fetchData("order");
        if (res?.data) {
          const filteredOrders = res.data
            .filter(
              (order) =>
                order.customerId === customerId &&
                (order.orderStatus === "Open" || order.orderStatus === "Pending")
            )
            .map((order) => ({
              label: `${order.orderNo}`,
              key: "orderNo",
              value: order.orderNo,
              id: order.id,
            }));

          setOrderOption(filteredOrders); // Update the dropdown options with filtered orders
        }
      }
    }

    if (selectedOption.key === "orderNo") {
      setFormData((prevState) => ({
        ...prevState,
        orderNo: selectedOption.value,
      }));

      const selectedOrder = selectedOption.id;
      if (selectedOrder) {
        const response = await fetchsingleData("order", selectedOrder);
        if (response?.data) {
          const fetchedData = response.data;

          // Filter and map through orderproducts to calculate subTotal for dispatchPending products
          const updatedorderproducts = fetchedData.orderproducts
            .filter((product) => product.productStatus === "dispatchPending") // Include only dispatchPending products
            .map((product) => {
              const price = product.price * product.quantity || 0;
              const discount = (price * product.discount) / 100 || 0;
              const tax = ((price - discount) * product.tax) / 100 || 0;
              return {
                ...product,
                subTotal: Number(((price - discount) + tax).toFixed(2)),
              };
            });

          // Update form data and product details table
          setFormData(fetchedData); // Update form data (still includes all details)
          setProductsForOrder(updatedorderproducts); // Update product details table with filtered data
        }
      }
    }

    if (errors.isSubmit) validationCheck();
  };


  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors.isSubmit) validationCheck();
  };

  const handleDateChange = (date) => {
    setFormData(prevState => ({
      ...prevState,
      date
    }));
    if (errors.isSubmit) validationCheck();
  };

  const handleInvoiceDateChange = (invoiceDate) => {
    setFormData(prevState => ({
      ...prevState,
      invoiceDate
    }));
    if (errors.isSubmit) validationCheck();
  };
  const handleLrDateChange = (lr_date) => {
    setFormData(prevState => ({
      ...prevState,
      lr_date
    }));
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!(formData?.invoiceNo?.length > 0)) {
      validationErrors.invoiceNo = 'Please enter invoice no';
    }
    if (!formData.customerName.trim()) {
      validationErrors.customerName = "Please select customer";
    }
    if (!formData.date) {
      validationErrors.date = "Please select Date";
    }

    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
  };


  const styles = {
    pageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#f0f0f0',
      borderBottom: '1px solid #d0d0d0',
      marginBottom: '20px',
    },
    formContainer: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)', // Updated to 4 columns
      gap: '20px',
      marginBottom: '20px',
    },
    inputWrapper: {
      marginBottom: '20px',
    },
    buttonAlignment: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = productsForOrder.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div>
      <div className='page-header add-news-alignment'>
        <strong><span>Dispatch {`>`} Create</span></strong>
        <div className='two-button-alignment'>
          <div onClick={saveDispatchOrder}><Button text="Save" /></div>
          <Link to={`/order/dispatchorder/${formData.id}`}><Button text="Discard" outline /></Link>
        </div>
      </div>
      <div style={styles.formContainer}>
        {/* Grid container for four fields in a row */}
        <div style={styles.grid}>
          <div style={styles.inputWrapper}>
            <Input
              label='Dispatch No'
              name='dispatchNo'
              value={formData.dispatchNo}
              onChange={handleInputChange}
              placeholder='Dispatch No'
              type='text'
              errors={errors.dispatchNo}
              required
            />
          </div>
          <div className="bottom-alignment select-change-design-change">
            <SelectBox
              label="Customer"
              styles={customStyles}
              name="customer"
              value={customers?.find(option => option.value.id === formData.customerId)}
              options={customers}
              onChange={selectChanged}
              placeholder="Customer Name"
              type="text"
              required
              errors={errors.customerName}
            />
          </div>
          <div className="bottom-alignment select-change-design-change">
            <SelectBox
              label="Order"
              styles={customStyles}
              name="orderNo"
              value={orderNo?.find((i) => i.value === formData.orderNo)}
              options={orderNo}
              onChange={selectChanged}
              placeholder="Order No"
              type="text"
              required
              errors={errors.orderNo}
            />
          </div>
          <div style={styles.inputWrapper}>
            <SelectBox
              label='Transport'
              name='transport'
              value={transportType?.find(i => i.value === formData.transport)}
              onChange={selectChanged}
              options={transportType}
              placeholder='Transport Type'
              errors={errors.transport}
              required
            />
          </div>
        </div>

        {/* Second grid row */}
        <div style={styles.grid}>
          <div style={styles.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={
                  <span>
                     Date<span className="required-star">*</span>
                  </span>}
                name='date'
                value={formData.date}
                onChange={handleDateChange}
                placeholder='Dispatch Date'
                errors={errors.date}
              />
            </LocalizationProvider>
          </div>
          <div style={styles.inputWrapper}>
            <Input
              label='Dispatch Code'
              name='dispatchCode'
              value={formData.dispatchCode}
              onChange={handleInputChange}
              placeholder='Dispatch Code'
              type='text'
              errors={errors.dispatchCode}
              required
            />
          </div>
          <div style={styles.inputWrapper}>
            <Input
              label='Invoice No'
              name='invoiceNo'
              value={formData.invoiceNo}
              onChange={handleInputChange}
              placeholder='Invoice No'
              type='text'
              errors={errors.invoiceNo}
              required
            />
          </div>
          <div style={styles.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Invoice Date'
                name='invoiceDate'
                value={formData.invoiceDate}
                onChange={handleInvoiceDateChange}
                placeholder='Invoice Date'
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="col-md-4">
          <legend><span>Billing Details</span></legend>
          <div className="shipping_address">
            <span className="ng-binding">{formData?.customerAddress}</span>
            <span ng-if="dispatch.billing_contact_no || dispatch.billing_email" className="ng-binding ng-scope">
              {formData?.customerContact}
            </span>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <legend><span>LR Details</span></legend>
          <div style={styles.grid}>
            <div style={styles.inputWrapper}>
              <Input
                label='LR No.'
                name='lr_no'
                value={formData.lr_no}
                onChange={handleInputChange}
                placeholder='LR No.'
                type='text'
                errors={errors.lr_no}
                required
              />
            </div>
            <div style={styles.inputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='LR Date'
                  name='lr_date'
                  value={formData.lr_date}
                  onChange={handleLrDateChange}
                  placeholder='Lr Date'
                />
              </LocalizationProvider>
            </div>
          </div>
          <legend><span>Product Details</span></legend>
          <div className="add-news-form-design">
            {/* <div className="product-details-header-alignment">
              <h2 style={{
                zIndex: 1111,
              }}>Product Details</h2>

            </div> */}
            <div className="order-table" style={{
              zIndex: -1,
              position: '',
              top: 'auto',
              //bottom: '100%',
            }}>
              <div className="table-design"
                style={{ overflow: "unset" }}
              >
                <table >
                  <thead>
                    <tr style={{ border: '1px solid #eee' }}>
                      <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                      <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                      <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                      <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                      <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                      <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.orderproducts?.map((i, INDEX) => {
                      return (
                        <tr key={INDEX}>
                          <td className="width-auto">
                            <SelectBox

                              label="Product"
                              name="product"
                              value={productOptions.find(
                                (option) => option.value.id === i.id
                              )}
                              options={productOptions}
                              onChange={(e) =>
                                productSelectChange(INDEX, e, "p")
                              }
                              placeholder="Product Name"
                              type="text"

                              styles={{
                                overflow: "initial",
                                ...customStyles,
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 99999,
                                  position: 'absolute'



                                }),
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%'



                                })
                              }}
                            />
                          </td>
                          <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                            <SelectBox
                              label="Unit"
                              name="unit"
                              isDisabled={true}
                              value={unitOptions.find(
                                (option) => option.value.id === i.unitId
                              )}
                              options={unitOptions}
                              onChange={(e) =>
                                productSelectChange(INDEX, e, "u")
                              }
                              placeholder="Unit Name"
                              type="text"

                            />
                          </td>
                          <td className="whitesapce-nowrap width-auto">
                            <InputCustom
                              name="quantity"
                              value={i?.quantity}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="price"
                              value={i?.unitPrice}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="discount"
                              value={i?.discount}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="tax"
                              value={i?.tax}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <td className="width-auto">
                            <InputCustom
                              name="subTotal"
                              value={i?.total}
                              readOnly={true}
                              onChange={(e) =>
                                handleInputChangeProduct(e, INDEX)
                              }
                              type="number"
                            />
                          </td>
                          <th className="width-auto">
                            <div
                              onClick={() => {
                                handleRemoveProduct(INDEX);
                              }}
                              style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                            >
                              <i className="fas fa-trash-alt" ></i>
                            </div>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div style={{ paddingTop: '15px' }}>  {isEditMode && (
                <div onClick={handleAddProductClick}>
                  <Button text="Add Product" />
                </div>
              )}</div>
              {/* <div> <table>
                <tr>
                  <th className="width-50">Sub Total</th>
                  <th></th>
                  <th className="width-50">Total</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Megha</td>
                  <td>19</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>Subham</td>
                  <td>25</td>
                  <td>Male</td>
                </tr>
              </table></div> */}
              <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
                marginLeft: '70%',
                maxWidth: '30%',
                background: '#f5f5f5',
                border: '2px #eee'
              }}>
                <tbody>

                  <tr></tr>
                  <tr>
                    <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                    <td></td>
                    <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.totalAmount}</b></td>
                  </tr>
                </tbody>
              </table></div>
            </div>
            <div className='pagination-alignment'>
              <Pagination
                currentPage={currentPage}
                totalItems={productsForOrder.length}
                pageSize={pageSize}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>

      </div>
    </div>

  );
}