import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import "./deal.scss";
import { UserContext } from '../../../contexts/UserContext';
import ShowImage from '../../../shared/components/showImage';

import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
// import { Stepper, Step } from 'react-form-stepper';
// import Button from '../../../shared/components/button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepButton } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import { clearPrewarmedResources } from 'mapbox-gl';
import RightIcon from '../../../assets/icons/right-sm.svg';
import SelectBox from '../../../shared/components/select';




export default function AddEditViewDealTable() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [activestep, setActiveStep] = useState(0);
    const [showWonLostOptions, setShowWonLostOptions] = useState(false);
    const [showLostPopup, setShowLostPopup] = useState(false);
    const [lossReason, setLossReason] = useState('');
    const [completedSteps, setCompletedSteps] = useState([]);
    const [timelines, setTimelines] = useState([]);
    const user = useSelector(state => state.user.user);
    const name = useSelector(state => state.auth.name)
    const [finalStatus, setFinalStatus] = useState("");
    const [products, setProducts] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    // const [dropdownVisible, setDropdownVisible] = useState(false);
    // const [wonLostValue, setWonLostValue] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Track dropdown visibility
    const [wonLostValue, setWonLostValue] = useState('');
    const [dealPipelineOptions, setDealPipeline] = useState([]);


    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    useEffect(() => {

        const getData = async () => {
            const requestBody = {
                "reference_type": "deal",
                reference_id: id
            }

            const responsetimeline = await fetchData("timeline", requestBody);
            console.log(responsetimeline);
            if (responsetimeline?.data) {
                setTimelines(responsetimeline.data);
            }
        }

        getData()
        fetchDealPipeline();


    }, [newNotification])
    const pdfRef = useRef();

    const handleStatusChange = (event) => {
        if (event.target.value === "WON") {
            setCompletedSteps(steps);
        }
        setFinalStatus(event.target.value);
        console.log("event.target.value", event.target.value, id)
        const updatedFormData = {
            ...formData,
            dealStatus: event.target.value,
        };
        if (id) {
            patchData(`deal/${id}`, updatedFormData);
        }
        setCompletedSteps(event.target.value)
        setFormData({
            ...formData,
            dealStatus: event.target.value, // Update dealStatus based on the clicked step
        });
    };

    const fetchDealPipeline = async () => {
        try {
          const response = await fetchData("dealpipeline");

          if (response?.data) {
            setDealPipeline(
              response?.data.map((i) => {
                return {
                  label: i?.name,
                  key: "dealpipeline",
                  value: { dealPipelineId: i?.id, name: i?.name,stages:i?.stages },
                };
              })
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    const handleGeneratePdf = () => {
        const pdfWidth = 300;
        const pdfHeight = 370;

        const doc = new jsPDF({
            orientation: 'b5',
            unit: 'px',
            format: [pdfWidth, pdfHeight],
        });
        doc.html(pdfRef.current, {
            callback: (doc) => {
                const pdfBlob = doc.output('blob');
                const pdfUrl = URL.createObjectURL(pdfBlob);
                const newWindow = window.open(pdfUrl);
                if (newWindow) {
                    newWindow.onload = () => {
                        newWindow.print();
                    };
                } else {
                    console.error('Failed to open new window for printing');
                }
            },
            x: 20,
            y: 20,
            z: 20,
            width: pdfWidth - 40,
            windowWidth: 970,
        });
    };

    const [formData, setFormData] = useState({
        dealno: "",
        dealdate: dayjs(),
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerContact: "",
        status: true,
        dealStatus: 'in progress',
        name:"",
        dealPipelineId:null,
        dealstage: 'Approach',
        closingdate: '',
        lossReason: '',
        products: [],
        dealnotification: [],
    });
    const [steps,setSteps] = useState([])
    // const steps = ["Approach", "Screening", "Follow up", "Quotation", "Negotiation", "WON/LOST"];
    useEffect(() => {
        if (formData.closingdate && dayjs(formData.closingdate).isBefore(dayjs(), 'day') && (formData.dealStatus !== 'won') && (formData.dealStatus !== 'lost')) {
            // updateStatusToOverdue();
        }
    }, [formData.closingdate]);
    const handleStepClick = async (step) => {
        if (step === 'WON/LOST') {
            setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
            return;
        }
        setWonLostValue('');
        setIsDropdownVisible(false); // Hide dropdown if any other step is clicked
        const stepIndex = steps.indexOf(step);
        const newCompletedSteps = steps.slice(0, stepIndex + 1);
        setCompletedSteps(newCompletedSteps);
        setActiveStep(step);

        try {
            const updatedFormData = {
                ...formData,
                dealStatus: step,
            };

            if (id) {
                const data = await patchData(`deal/${id}`, updatedFormData);
                if (data) {
                    setFormData(updatedFormData);
                }
            } else {
                const data = await postData('deal', updatedFormData);
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const selectPipelineChanged = async (e) => {
        const selectedValue = e.label
        const updatedData = { dealPipeline:selectedValue, dealPipelineId: e.value?.dealPipelineId
        };
        const stages = {stages:e.value?.stages}
        const steps = stages.stages?.map(stage => stage?.name)
        if(steps && steps?.length>0){
            setSteps([...steps,'WON/LOST'])

        }
        const data = await patchData(`deal/${id}`, updatedData);
        if (data) {
            setFormData(data?.data);
        }
    };

    const handleDropdownChange = async (selectedValue) => {
        setWonLostValue(selectedValue);
        setActiveStep(selectedValue); // Update active step to the selected value

        const updatedCompletedSteps = selectedValue === 'Won' ? steps : steps; // If 'Won', mark all steps as active; if 'Lost', all steps turn red
        setCompletedSteps(updatedCompletedSteps);

        setIsDropdownVisible(false); // Close the dropdown

        try {
            const updatedFormData = {
                ...formData,
                dealStatus: selectedValue,
            };

            if (id) {
                const data = await patchData(`deal/${id}`, updatedFormData);
                if (data) {
                    setFormData(updatedFormData);
                }
            } else {
                const data = await postData('deal', updatedFormData);
                if (data) navigate(-1);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    const getData = async () => {
        try {
            debugger
            const response = await fetchsingleData("deal", id);
            const dealpipeline = await fetchsingleData("dealpipeline",response?.data?.dealPipelineId);
            const steplist=dealpipeline?.data?.stages?.map(d=>d?.name);
            setSteps([...steplist,'WON/LOST']);
            const steps=[...steplist,'WON/LOST'];
            if (response?.data) {
                setFormData(response.data);
                if (['Lost', 'Won'].includes(response.data?.dealStatus)) {
                    setWonLostValue(response.data?.dealStatus);
                    setActiveStep(response.data?.dealStatus); // Update active step to the selected value
                    const updatedCompletedSteps = response.data?.dealStatus === 'Won' ? steps : steps; // If 'Won', mark all steps as active; if 'Lost', all steps turn red
                    setCompletedSteps(updatedCompletedSteps);
                    setIsDropdownVisible(false); // Close the dropdown

                } else {
                    const clickedStepIndex = steps.indexOf(response.data?.dealStatus);
                    const newCompletedSteps = steps.slice(0, clickedStepIndex + 1);
                    setCompletedSteps(newCompletedSteps);
                }
                setProducts(response.data.products || []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const calculateTotalAmount = () => {
        return formData.products.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.subTotal);
        }, 0).toFixed(2);
    };

    console.log("completedSteps", completedSteps[completedSteps.length - 1])


    const handleWonLostSelection = async (status) => {
        if (status === 'lost') {
            setShowLostPopup(true);
        } else {
            try {
                const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
                const updatedNotification = { date: currentDateTime, status: status, user: name };
                setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
                const updatedFormData = {
                    ...formData,
                    dealStatus: status,
                    dealstage: 'done',
                    dealnotification: [...newNotification, updatedNotification]
                };
                const data = await patchData(`deal/${id}`, updatedFormData);
                if (data) {
                    setFormData(updatedFormData);
                    setActiveStep(steps.length - 1);
                    setShowWonLostOptions(false);
                }
            } catch (error) {
                console.error(`Error updating deal status to ${status}:`, error);
            }
        }
    };
    const handleLostReasonSubmit = async () => {
        try {
            const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
            const updatedNotification = { date: currentDateTime, status: 'lost', user: name };
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
            const updatedFormData = {
                ...formData,
                dealStatus: 'lost',
                dealstage: '-',
                lossReason: lossReason,
                dealnotification: [...newNotification, updatedNotification]
            };
            const data = await patchData(`deal/${id}`, updatedFormData);
            if (data) {
                setFormData(updatedFormData);
                setActiveStep(steps.length - 1);
                setShowWonLostOptions(false);
                setShowLostPopup(false);
            }
        } catch (error) {
            console.error('Error updating deal status to LOST with reason:', error);
        }
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '60%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const buttonStyle = {
        position: 'relative',
        color: 'var(--clr-blue)',
        border: '3px solid var(--clr-blue)',
        borderRadius: '5px',
        background: 'transparent',
        overflow: 'hidden',
        transition: 'color 0.5s ease-in, transform 0.5s ease-in-out',
        padding: '10px 20px',
        height: '30px',
        fontSize: '10px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const beforeStyle = {
        content: '""',
        position: 'absolute',
        height: '70px',
        width: '150px',
        background: 'var(--clr-blue)',
        transform: 'translate(-25px, 33px)',
        transition: 'transform 0.45s ease-in',
        zIndex: -1
    };

    const wonButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#27AE60',
    };

    const lostButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#D44545',
    };
    const appDataJSON = localStorage.getItem('users');
    const appData = JSON.parse(appDataJSON);

  
    // useEffect(()=>{
    //     const data = dealPipelineOptions.find((pipeline)=>pipeline?.value?.dealPipelineId === formData?.dealPipelineId)
    //     const steps = data?.value?.stages.map(e=>e?.name)
    //     if(steps && steps.length>0){
    //         setSteps([...steps,'WON/LOST'])

    //     }
    // },[dealPipelineOptions])
    return (
        <div className='page-24'>
            <div>
                <div className='breadcumbs-alignment pb-3'>
                    <span className='active-class'>Deal Status</span>
                    <img src={RightIcon} alt="RightIcon" />
                    <span>{formData.dealno}</span>
                </div>

                <div>
                    <span style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginBottom: '7rem',
                        position: 'static',
                    }}>Expected Closing:{dayjs(formData.closingdate).format('DD-MM-YYYY')}</span>
                    <div style={{
                        display: 'flex',
                        gap: '10px'
                    }}>
                        <div onClick={handleGeneratePdf} style={{ cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                            </svg>
                        </div>
                        {/* Additional buttons can be added here */}
                    </div>


                    <div>
                    <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                     Deal Pipeline <span className="required-star">*</span>
                    </span>
                  }
                  name="dealPipeline"
                  value={dealPipelineOptions.find(
                    (i) => i?.value?.dealPipelineId === formData?.dealPipelineId
                  )}
                  options={dealPipelineOptions}
                  onChange={selectPipelineChanged}
                  placeholder="Select Pipeline Status"
                />

                {/* {errors.countryId && <p className="error">{errors.pi}</p>} */}

              </div>

                    </div>
                </div>
                <div className="arrow-type-button">
                    <ul>
                        {steps &&  steps.map((step) => (
                            <li
                                key={step}
                                className={`${completedSteps.includes(step) ? (wonLostValue === 'Lost' ? 'lost-class' : 'active-class') : ''
                                    } ${activestep === step ? 'active-class' : ''}`}
                                onClick={() => handleStepClick(step)}
                            >
                                {step === 'WON/LOST' ? (
                                    <div className="dropdown">
                                        <span>{wonLostValue || 'WON/LOST'}</span>
                                        {isDropdownVisible && (
                                            <div className="dropdown-menu">
                                                <button onClick={() => handleDropdownChange('Won')}>Won</button>
                                                <button onClick={() => handleDropdownChange('Lost')}>Lost</button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    step
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                {showWonLostOptions && (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px' }}>
                        <button
                            style={wonButtonStyle}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#27AE60'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#27AE60'}
                            onClick={() => handleWonLostSelection('won')}
                        >
                            WON
                        </button>
                        <button
                            style={lostButtonStyle}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#D44545'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#D44545'}
                            onClick={() => handleWonLostSelection('lost')}
                        >
                            LOST
                        </button>
                    </div>
                )}

                <Modal
                    open={showLostPopup}
                    onClose={() => setShowLostPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Enter Reason for Losing the Deal
                        </Typography>
                        <TextField
                            fullWidth
                            label="Reason"
                            variant="outlined"
                            value={lossReason}
                            onChange={(e) => setLossReason(e.target.value)}
                            multiline
                            rows={4}
                            margin="normal"
                        />
                        <button
                            type="button"
                            onClick={handleLostReasonSubmit}
                            style={{ ...buttonStyle, backgroundColor: '#2196f3', color: '#fff', marginTop: '10px' }}
                        >
                            Submit
                        </button>
                    </Box>
                </Modal>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',  // This will create space between the two divs
                    gap: '10px'  // Adds a gap between the divs
                }}>

                    {/* First div */}
                    <div style={{
                        marginTop: '5px',
                        padding: '1.5rem',
                        backgroundColor: '#fff',
                        border: '1px solid rgba(30,46,80,.09)',
                        borderRadius: '0.25rem',
                        boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                        width: '100%',
                        height: '473px',
                        overflow: 'scroll',
                    }}>
                        <div ref={pdfRef}>
                            <h2 style={{ paddingBottom: '10px' }}>Deal from,</h2>
                            <p style={{ fontSize: '0.85rem' }}>{formData.customerName}</p>

                            <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                    <div style={{ flex: '1', marginRight: '1rem' }}>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Deal No.</div>
                                        <strong>{formData.dealno}</strong>
                                    </div>
                                    <div style={{ flex: '1', textAlign: 'right' }}>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Deal Date</div>
                                        <strong>{dayjs(formData.dealdate).format('DD-MM-YYYY')}</strong>
                                    </div>
                                </div>
                            </div>

                            <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                    <div style={{ flex: '1', marginRight: '1rem' }}>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Deal From</div>
                                        <strong>{formData.customerName}[{formData?.dealfor}]</strong>
                                        <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                            {formData.customerAddress}
                                            <br />
                                            <a href={`mailto:${formData.customerContact}`} style={{ color: 'purple' }}>
                                                {formData.customerContact}
                                            </a>
                                        </p>
                                    </div>
                                    <div style={{ flex: '1', textAlign: 'right' }}>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Status</div>
                                        <strong>{formData.dealStatus}</strong>
                                    </div>
                                </div>
                            </div>

                            <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>#</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Image</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Item</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Category</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Quantity</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Price</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Discount</strong></th>
                                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Sub Total</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.products.map((product, index) => (
                                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{index + 1}</td>
                                            <td style={{ padding: '1px', borderBottom: '1px solid #edf2f9' }}>
                                                <a href={product.photoUrl} target="_blank" rel="noopener noreferrer">
                                                    <ShowImage
                                                        className="thumbnail"
                                                        value={product.photoUrl}
                                                        style={{ width: '80px', height: '60px', borderRadius: '4px', paddingBottom: '3px' }}
                                                    />
                                                </a>
                                            </td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.productName}</td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.unitName}</td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.qty}</td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.unitPrice}</td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.discount}</td>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{product.subTotal}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div style={{ marginTop: '1.5rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <h5 style={{ marginRight: '1rem' }}>Total:</h5>
                                    <h5 style={{ color: '#28a745' }}> ₹{calculateTotalAmount()}</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Second div */}
                    <div style={{
                        marginTop: '5px',
                        padding: '1.5rem',
                        backgroundColor: '',
                        border: '1px solid rgba(30,46,80,.09)',
                        borderRadius: '0.25rem',
                        boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                        maxWidth: '400px',
                        marginLeft: '5px',
                        width: '400px',
                        scrollBehavior: 'smooth',
                        height: '473px',
                        overflowY: 'auto'
                    }}>
                        <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
                        {timelines?.slice().reverse().map((i, index) => (
                            <div key={i.id || index} style={{ display: "flex", paddingLeft: "3px", paddingBottom: "15px" }}>
                                <div style={{ paddingRight: "5px" }}>➨</div>
                                <div style={{ fontSize: "14px" }}>
                                    <strong>{i.message}</strong> On <strong>{dayjs(i.timestamp).format('DD-MM-YYYY, HH:mm:ss')}</strong>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            </div >
        </div>
    );
}
