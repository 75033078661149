import React, { useEffect, useState } from "react";
import "./userview.scss";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";
import PlusIcon from "../../../assets/icons/plus.svg";
import { fetchData, filterAll, patchData, postData,fetchsingleData, deleteData } from "../../../services/apiService";
import RemoveIcon from '../../../assets/icons/remove.svg';
import ShowImage from '../../../shared/components/showImage';
import { Link, useParams } from "react-router-dom";
import Pagination from "../../../shared/components/pagination";
import dayjs from "dayjs";
import { Modal } from 'antd';
import Input from '../../../shared/components/input';
import SelectBox from '../../../shared/components/select';
import { Form, DatePicker } from "antd";
import PenIcon from '../../../assets/icons/pen.svg';
import { notify } from "../../toastr/Toastr";

export default function Userview() {
  // const navigate = useNavigate();
  const { id } = useParams();
  const [activitiesid, setactivitiesid] = useState('');
  const [activeTab, setActiveTab] = useState('Details');
  const [userDetails, setuserdetails] = useState([]);
  const [deviceDetails, setdevice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [timelines, setTimelines] = useState([]);
  const pageSize = 5;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [myactivitiess, setmyactivitiess] = useState([]);
  const [activityOptions, setActivityOptions] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [userNameOptions, setUserNameOptions] = useState([]);
  const [usermoduleOptions, setModuleOptions] = useState([]);
  const [linkdropDown, setlinkdropDown] = useState([]);
  const [formData, setFormData] = useState({
      activityname: '',
      activitydate: dayjs(),
      username: '',
      module: '',
      linkname: '',
      link_id: '',
      userId:'',
      status: true,
      activityvalue: ''

  });
  const [errors, setErrors] = useState({});
  const resetForm = () => {
    setFormData({
      activityname: '',
      activitydate: dayjs(),
      username: '',
      module: '',
      linkname: '',
      link_id: '',
      userId:'',
      status: true,
      activityvalue: ''
    });
  };
const closeModal = () => {
  resetForm();
  setIsModalOpen(false);
};
  const showModal = (e) => {
    e.preventDefault();
    resetForm();
    setactivitiesid('');
    setIsModalOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
    }));
    if (errors.isSubmit) validationCheck(false);
};

const handleSelectChange = (event, type) => {
    if (type === 'module') {
        setFormData({
            ...formData,
            [type]: event.value
        });
        getdowpDownlist(event?.value?.toLowerCase())
    }
    else {
        let formPayload = {
            ...formData,
            [type]: event.value
        }
        if (type === 'link_id') {
            formPayload = { ...formPayload, linkname: linkdropDown?.find(d => d?.value === event?.value)?.label }
        }
        setFormData(formPayload);
    }
};
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const fetchActivity = async () => {
    try {
        const response = await fetchData('activitytype');
        if (response?.data) {
            setActivityOptions(
                response.data.map((activity) => ({
                    label: activity.name,
                    value: activity.name,
                }))
            );
        }
    } catch (error) {
        console.error('Error fetching activities:', error);
    }
};
const fetchmyactivities = async () => {
  try {
      const response = await filterAll('myactivities',{"userId":id});
      if (response?.data) {
          setmyactivitiess( response.data);
      }
  } catch (error) {
      console.error('Error fetching activities:', error);
  }
};

const fetchUser = async () => {
    try {
        const response = await fetchData('user');
        if (response?.data) {
            setUserNameOptions(
                response.data.map((user) => ({
                    label: user.name,
                    value: user.name,
                }))
            );
        }
    } catch (error) {
        console.error('Error fetching users:', error);
    }
};

const fetchModule = async () => {
    try {
        const response = await fetchData('module');
        if (response?.data) {
            setModuleOptions(
                response.data.map((module) => ({
                    ...module,
                    label: module?.modulename,
                    value: module?.modulename,
                }))
            );
        }
    } catch (error) {
        console.error('Error fetching modules:', error);
    }
};
const getdowpDownlist = async (type) => {
    try {
        const response = await fetchData(type);
        if (response?.data) {
            const modules = await fetchData('module');
            const keyName = (modules?.data ?? []).find(f => f?.modulename?.toLowerCase() === type)?.moduleKey;
            setlinkdropDown(
                (response?.data ?? []).map((module) => ({
                    label: module?.[keyName],
                    value: module?.id,
                }))
            );
        }
    } catch (error) {
        console.error('Error fetching ' + type, error);
    }
};
const validationCheck = (isSubmit) => {
  const validationErrors = {};
  if (!formData?.activityname?.trim()) {
      validationErrors.activityname = 'This field is required';
  }
  if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;
  setErrors(validationErrors);
  return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
};
const handleDateChange = (e) => {
  setFormData((prevState) => ({
      ...prevState,
      activitydate: new Date(e)
  }));
};

const handleSubmit = () => {
  if (validationCheck(true)) {
      try {
          const method = activitiesid ? patchData : postData;
          const url = activitiesid ? `myactivities/${activitiesid}` : 'myactivities';
          method(url, {...formData,userId:id})
              .then((data) => {
                if(data?.data){
                  notify.success(activitiesid?'Activity updated successfully!':'Activity saved successfully!');
                  resetForm();
                  setIsModalOpen(false);
                  fetchmyactivities();
                }
              })
              .catch((error) => {
                  console.error('Error saving data:', error);
              });
      } catch (error) {
          console.error('Error saving data:', error);
      }
  }
};



  useEffect(() => {
    if (id) {
      getData(id);  // Call getData with userId
    }
    getdevice(currentPage, pageSize);
    fetchActivity();
    fetchmyactivities();
        fetchUser();
        fetchModule();
  }, [id]);



  const chnageStatusofDevice = (deviceInfo) => {
    const payloasforDevice = {
      status: !deviceInfo?.status
    }
    try {
      patchData("devicelogin" + '/' + deviceInfo?.id, payloasforDevice)
        .then((data) => {
          if (data?.data) {
            setdevice((prevDetails) =>
              prevDetails?.map((device) =>
                device?.id === data?.data?.id ? { ...device, "status": !deviceInfo?.status } : device
              )
            );
          }
        })
        .catch((error) => {
          console.error('Error saving data:', error);
        });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  }
  const getData = async (id) => {
    try {
      const response = await filterAll("user", { id });
      if (response?.data) {
        setuserdetails(response.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };

  useEffect(() => {
    getdevice(currentPage, pageSize);
  }, [currentPage]);

  useEffect(()=>{

    const getData = async() =>{
        const requestBody = {
            "reference_type": "user",
            reference_id : id
        }
         
        const responsetimeline = await fetchData("timeline",requestBody);
      console.log(responsetimeline);
              if (responsetimeline?.data) {
                  setTimelines(responsetimeline.data);
                } 
          }

          getData()
        
      },[]);

      const editmyactivitiess = (activity) => {
        if (activity) {
          setIsModalOpen(true);
          setactivitiesid(activity?.id);
          setFormData({
            ...activity,
            activitydate: dayjs(activity?.activitydate)
          });
          getdowpDownlist(activity?.module?.toLowerCase());
        }
      };
      const deletemyactivity = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this activity?');
        if (!confirmed) return;
        try {
          await deleteData(`myactivities/${id}`);
          setmyactivitiess((prev) => prev.filter((acty) => acty?.id !== id));
          notify.success('Activity deleted successfully!');
        } catch (error) {
          console.error('Error deleting product:', error);
          notify.error('Failed to delete product. Please try again.');
        }
      };
  const getdevice = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      }
      const response = await fetchData("devicelogin", requestBody, pagination);
      console.log("response", response)
      if (response?.data) {
        setdevice(response.data || []);
        setTotalItems(response?.totalDocuments)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <div className="customer-page-layoutalignment">
          <div className="customer-header-alignment">
            <div>
              <h2>user</h2>
              <Breadcumbs activePath="Profile" pageName="Profile Info" />
            </div>
            <div className="right-contnet-alignment">
              <Link to="/user">{(
                <GlobalButton text="Back" />
              )}</Link>
                <Link to={"/user/adduser/"+id}>{(
                <GlobalButton text="Edit" Icon={PlusIcon} />
              )}</Link>

            </div>
          </div>
        </div>
      </div>
      <div className="tabs mb-0">
        <div
          className={`tab ${activeTab === 'Details' ? 'active' : ''}`}
          onClick={() => handleTabClick('Details')}
        >
          Details
        </div>
        <div
          className={`tab ${activeTab === 'Area Assign' ? 'active' : ''}`}
          onClick={() => handleTabClick('Area Assign')}
        >
          Area Assign
        </div>
        <div
          className={`tab ${activeTab === 'Route' ? 'active' : ''}`}
          onClick={() => handleTabClick('Route')}
        >
          Route
        </div>
      </div>

      
        {activeTab === 'Details' && (
          <>
          <div className="row px-3">
          <div className="left-section col-md-10">
            {/* Profile Info */}
            <div className="section  mt-3 new-white-box-design">
              <h6 className="mb-3">Profile Info</h6>
              <hr />
              {userDetails.map((user) => (
                <div className="row">
                  <div className="profile-info__avatar mx-4 col-md-2">
                    <ShowImage value={user?.profileUrl}></ShowImage>
                  </div>
                  <div className="col-md-10">
                    <div className="profile-row row" key={user.id}>
                      <div style={{ marginLight: '10px' }} className="col-md-4">
                        <p className="fw-bold font-14">Name:</p>
                        <p><span className="font-13">{user.name}</span></p>
                      </div>

                      <div className="col-md-4">
                        <p className="fw-bold font-14">Code:</p>
                        <p> <span className="font-13">{user.code || '-'}</span></p>
                      </div>
                      <div className="col-md-4"><p className="fw-bold font-14">User Role: </p><p><span className="font-13">{user.role}</span></p></div>
                      <div className="col-md-4 pt-3"> <p className="fw-bold font-14">Status: </p><p><span className={user.status ? 'status active' : 'status inactive'}>{user.status ? 'Active' : 'Inactive'}</span></p></div>
                    </div>
                  </div>
                </div>

              ))}
            </div>


            {/* Settings */}
            <div className="section settings new-white-box-design">
              <h6 className="mb-3">Settings</h6>
              <hr />
              {userDetails.map((user) => (
                <div style={{ margin: '10px' }} className="row">
                  {/* <div className="col-md-4">
                  <p className="fw-bold font-14">Holiday List:</p>
                  <p><span className="font-13">{user.holidayList || '-'}</span></p>
                </div> */}
                  <div className="col-md-4">
                    <p className="fw-bold font-14">Price Group:</p>
                    <p><span className="font-13">{user.priceGroupName || '-'}</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="fw-bold font-14">Location:</p>
                    <p><span className="font-13">{user.location || '-'}</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="fw-bold font-14">Product Category:</p>
                    <p><span className="font-13">{user.productCategory || '-'}</span></p>
                  </div>
                </div>
              ))}

            </div>


            {/* Login Details */}
            <div className="section login-details new-white-box-design">
              <h6 className="mb-3">Login Details</h6>
              <hr />
              {userDetails.map((user) => (
                <div className="col-md-4">
                  <p className="fw-bold font-14" key={user.id}>Email: </p>
                  <p><span className="font-13">{user.email}</span></p>
                </div>

              ))}
            </div>


            {/* Contact Details */}
            <div className="section contact-details new-white-box-design">
              <h6 className="mb-3">Contact Details</h6>
              <hr />
              {userDetails.map((user) => (
                <div className="row px-3">
                  <div className="col-md-4">
                    <p className="fw-bold font-14">Address:</p>
                    <p><span className="font-13">{user.address || '-'}</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="fw-bold font-14">Pincode:</p>
                    <p><span className="font-13">{user.pincode || '-'}</span></p>
                  </div>
                  <div className="col-md-4">
                    <p className="fw-bold font-14">City:</p>
                    <p><span className="font-13">{user.city || '-'}</span></p>
                  </div>
                  <div className="col-md-4 mt-3">
                    <p className="fw-bold font-14">Area:</p>
                    <p><span className="font-13">{user.areaName || '-'}</span></p>
                  </div>
                  <div className="col-md-4 mt-3">
                    <p className="fw-bold font-14">Country:</p>
                    <p><span className="font-13">{user.country || '-'}</span></p>
                  </div>
                  <div className="col-md-4 mt-3">
                    <p className="fw-bold font-14">State:</p>
                    <p><span className="font-13">{user.state || '-'}</span></p>
                  </div>
                  <div className="col-md-4 mt-3">
                    <p className="fw-bold font-14">Contact No.:</p>
                    <p><span className="font-13">{user.contactNo || '-'}</span></p>
                  </div>
                </div>
              ))}
            </div>


            {/* User Devices */}
            <div className="section user-devices new-white-box-design">
              <h6 className="mb-3">User Devices</h6>
              <hr />
              <div className='account-table-page-alignment'>
              <div className='account-table-design'>
              <table className="table-response">
                <thead>
                  <tr>
                    <th>Device Name</th>
                    <th>Device ID</th>
                    <th>Application Version</th>
                    <th>Database Version</th>
                    <th>Last Active Session</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {deviceDetails.length > 0 ? (
                    deviceDetails.map((device, index) => (
                      <tr key={index}>
                        <td>{device.deviceName || '-'}</td>
                        <td>{device.deviceId || '-'}</td>
                        <td>{device.appVersion || '-'}</td>
                        <td>{device.dbVersion || '-'}</td>
                        <td>{device.lastActiveSession || '-'}</td>
                        <td>
                          <button className={device.status === true ? 'Active' : 'Inactive'}>
                            {device?.status ? 'Active' : 'Inactive'}
                          </button>
                        </td>
                        <td>
                          <button className={device?.status ? 'Deactivate' : 'Activate'} onClick={() => chnageStatusofDevice(device)}>
                            {device?.status ? 'Deactivate' : 'Activate'}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        No devices found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className='pagination-alignment'>
                <Pagination
                  currentPage={currentPage}
                  totalItems={totalItems} // Total items from API response
                  pageSize={pageSize}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>

          </div>
          </div>
          </div>

          {/* Right Section */}
          <div class="right-section col-md-2 p-0 backgoundclr">
          <div class="header mb-0 px-2 bg-white">
            <h6>Timeline</h6>
            <button class="add-btn" onClick={showModal} >ADD</button>
          </div>
          <div className="m-0 borders"></div>

          <div className="px-2">
          <div class="timeline-section px-2 mt-0">
            <p class="section-title fw-bold font-14 pt-1">PLANNED</p>
            {(myactivitiess??[])?.map((act) => (
            <div class="timeline-item planned">
              <div class="item-icon pr-1">&#128222;</div>
              <div class="item-details w-100">
                <p class="item-title">{act?.activityvalue??'XYZ'} {act?.activityname??"ABC"}</p>
                <p class="item-date">{act?.activitydate}</p>
                <div className="d-flex align-items-end justify-content-between"> 
                <p class="item-to">to {act?.username}</p>
                <div >
                 <div className='three-icon-alignment'>
                      <img src={PenIcon} onClick={() => editmyactivitiess(act)} alt='PenIcon' />
                    <img
                      src={RemoveIcon}
                      onClick={() => deletemyactivity(act?.id)}
                      alt='RemoveIcon'
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
                </div>
              </div>
              <input type="checkbox" class="item-checkbox" />
            </div>
            ))}
          </div>

          <div class="timeline-section">
          <div class="filter-options d-flex justify-content-between">
          <p class="section-title fw-bold font-14">PAST</p>
           
              <span className="font-13">filter</span>
            </div>
            {timelines.map((i) => (
            <div class="timeline-item past">
              <div class="item-icon pr-2">&#128421;</div>
              <div class="item-details w-100">
              <p>{i.message}</p>
              On<p>{dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎HH:mm:ss')}</p>
              <p>{i.user_name}</p>
              </div>
            </div>
            ))}
          </div>
          </div>
        </div>    
        </div>
      </>
      
        )}
        

        {activeTab === 'Area Assign' && (
          <div className="area-assign">
            <h6 className="mt-3">Area Assign</h6>
          </div>
        )}

        {activeTab === 'Route' && (
          <div className="route">
            <h6 className="mt-3">Route</h6>
          </div>
        )}

      <Modal
              title={activitiesid ? "Edit Activity" : "Add Activity"}
              okText={activitiesid ? "Update" : "Save"}
              // confirmLoading={formSaving}
              // open={isModalOpen}
              open={isModalOpen}
              onOk={handleSubmit}
              onCancel={closeModal}
            // onOk={(e) => checkForm(e)}
            // onCancel={(e) => handleCancel(e)}
            >

      <div className='row p-4'>
                      {/* <div className='two-col-grid'> */}
                        <div className='col-md-6'>
                        <SelectBox
                              label="Activity Type"
                              name="activityname"
                              options={activityOptions}
                              value={activityOptions?.find(i => i?.value === formData.activityname) || ''} // Ensure null for no selection
                              onChange={(e) => handleSelectChange(e, 'activityname')}
                              style={{ width: '100%' }}
                          />
                        </div>
                          <div className="col-md-6">
                          <Input
                              label={(formData?.activityname ? formData?.activityname : 'Activity Value')}
                              placeholder={"Enter " + (formData?.activityname ? formData?.activityname : 'Activity Value')}
                              name='activityvalue'
                              value={formData.activityvalue|| ''}
                              onChange={handleInputChange}
                              type='text'
                              required
                              errors={errors.activityvalue}
                          />
                          </div>
                          <div className='col-md-6 mt-3'>
                              <label className='activitydate mb-1'>Activity Date</label>
                              <DatePicker
                                  name="activitydate"
                                  placeholder='Select Date'
                                  value={dayjs(formData.activitydate)}
                                  onChange={handleDateChange}
                                  showTime
                                  dateFormat="yyyy-MM-dd HH:mm:ss"
                              />
                          </div>
                          <div className='col-md-6 mt-3'>
                          <SelectBox
                              label="Assign To"
                              name="username"
                              placeholder="Select Assign to"
                              options={userNameOptions}
                              onChange={(e) => handleSelectChange(e, 'username')}
                              value={userNameOptions?.find(i => i?.value === formData?.username)|| ''}
                              style={{ width: '100%' }}
                          />
                          </div>
                          <div className='col-md-6 mt-3'>
                          <SelectBox
                              label="Linked To"
                              name="module"
                              placeholder="Select Linked to"
                              options={usermoduleOptions}
                              value={usermoduleOptions?.find(i => i?.value === formData.module)|| ''}
                              onChange={(e) => handleSelectChange(e, 'module')}
                              style={{ width: '100%' }}
                          />
                          </div>
                          <div className='col-md-6 mt-3'>
                          <SelectBox
                              name="link_id"
                              label={(formData?.module ? formData?.module : 'Linked')}
                              placeholder={"Select " + (formData?.module ? formData?.module : 'Linked')}
                              options={linkdropDown}
                              value={linkdropDown?.find(i => i?.value === formData.link_id)|| ''}
                              onChange={(e) => handleSelectChange(e, 'link_id')}
                              style={{ width: '100%' }}
                          />
                          </div>
                      {/* </div> */}
                  </div>
            

            </Modal>

        
      
    </>
  );

}