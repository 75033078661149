import React, { useEffect, useState } from 'react';
import './accounttable.scss';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../services/apiService';
import ProductImage from '../../../assets/images/product.svg';
import ViewIcon from '../../../assets/icons/view.svg';
import PenIcon from '../../../assets/icons/pen.svg';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';

export default function Accounttable({ buttonClicked, selectedIds, setSelectedIds, setActivityList }) {
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [activityList, setProductList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [codeTerm, setCodeTerm] = useState("");
    const [nameTerm, setNameTerm] = useState("");
    const [priceTerm, setPriceTerm] = useState("");
    const [emailTerm, setEamilTerm] = useState("");
    const [noTerm, setNoTerm] = useState("");
    const [statusTerm, setStatusTerm] = useState('');
    const [createdbyTerm, setCreatedbyTerm] = useState("");


    const pageSize = 10; // Items per page

    useEffect(() => {
        // Fetch data when page or pageSize changes
        getData(currentPage, pageSize);
    }, [currentPage]);

    const getData = async (page, pageSize) => {
        try {
            const requestBody = {};
            const pagination = {
                page: page,
                limit: pageSize,
            }
            const response = await fetchData('customer', requestBody, pagination);
            if (response?.data) {
                setProductList(response.data || []);  // API returns current page data in 'items'
                setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };


    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds(activityList.map((item) => item.id));
        } else {
            setSelectedIds([]);
        }
    };

    const filteredActivityList = activityList.filter((item) => {
        const matchesCodeTerm = item.code?.toLowerCase().includes(codeTerm?.trim().toLowerCase());
        const matchesStatus = String(item.status).toLowerCase().includes(statusTerm?.trim().toLowerCase());
        const matchesCreatedBy = item.created_by?.toLowerCase().includes(createdbyTerm?.trim().toLowerCase());
        const matchesNameTerm = item.cName?.toLowerCase().includes(nameTerm?.trim().toLowerCase());
        const matchesPriceTerm = item.priceGroup?.toLowerCase().includes(priceTerm?.trim().toLowerCase());
        const matchesEmailTerm = item.addressEmail?.toLowerCase().includes(emailTerm?.trim().toLowerCase());
        const matchesNoTerm = item.addressContact?.toLowerCase().includes(noTerm?.trim().toLowerCase());

        return (
            (!codeTerm || matchesCodeTerm) &&
            (!statusTerm || matchesStatus) &&
            (!createdbyTerm || matchesCreatedBy) &&
            (!nameTerm || matchesNameTerm) &&
            (!priceTerm || matchesPriceTerm) &&
            (!emailTerm || matchesEmailTerm) &&
            (!noTerm || matchesNoTerm)
        );
    });



    return (
        <>
            <div className='account-table-page-alignment'>
                <div className='account-table-design'>
                    <table>
                        <thead>
                            <style>
                                {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
                            </style>
                            <tr>


                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Name"
                                    value={nameTerm}
                                    onChange={(e) => setNameTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Code"
                                    value={codeTerm}
                                    onChange={(e) => setCodeTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Price Group"
                                    value={priceTerm}
                                    onChange={(e) => setPriceTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Email"
                                    value={emailTerm}
                                    onChange={(e) => setEamilTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Contact no"
                                    value={noTerm}
                                    onChange={(e) => setNoTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th>
                                    <div style={{ textAlign: 'center', width: '100%' }}>
                                        <input
                                            type="text"
                                            placeholder="Status"
                                            value={statusTerm} // bind the value to statusTerm state
                                            onChange={(e) => setStatusTerm(e.target.value)} // update the state when the input changes
                                            className="search-input input-height"
                                            style={{ border: 'none', width: '100%' }}
                                        />
                                    </div>
                                </th>


                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Created by"
                                    value={createdbyTerm}
                                    onChange={(e) => setCreatedbyTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ width: '100%' }}></div></th>


                                <th><div style={{ width: '100%' }}></div></th>
                            </tr>
                            <tr>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Price Group</th>
                                <th>Email</th>
                                <th>Contact no</th>
                                <th>status</th>
                                <th>Created by</th>
                                <th>View Map</th>
                                {checkAccess('edit_account') && <th>Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredActivityList.map((i) => (
                                <tr key={i.id}>
                                    <td>
                                        <div className='image-text'>

                                            <span>{i.cName}</span>
                                        </div>
                                    </td>
                                    <td>{i.code}</td>
                                    <td>{i.priceGroup}</td>
                                    <td>{i.addressEmail}</td>
                                    <td>{i.addressContact}</td>
                                    {/* <td>
                                        <button className='danger-button'>Active</button>
                                    </td> */}
                                    <td>
                                    {checkAccess('read_account')?(<Link to={{ pathname: `view/${i?.id}` }}>
                    
                        <button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button>
                       
                      </Link>):(  <button className={i?.status ? "Active" : "Inactive"}
                       style={{ pointerEvents: "none", cursor: "default" }}>{i?.status ? "Active" : "Inactive"}</button>)}
                      </td>
                      <td>{i.created_by ? i.created_by : "-"}</td>

                                    <td>
                                        <img onClick={() => buttonClicked(i.latitude, i.longitude)} src={ViewIcon} alt="ViewIcon" />
                                    </td>
                                    {checkAccess('edit_account') && <td align='center'>
                                        <Link to={`addcustomer/${i?.id}`} >
                                            <img src={PenIcon} alt="Edit" />
                                        </Link>
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='pagination-alignment'>
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
