import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import userReducer from './rba'

import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
});

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user'], // list of reducers you want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});

export const persistor = persistStore(store);
export default store;