import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ModalOverlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled(motion.div)`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    text-align: center;
`;

const ModalTitle = styled.h2`
    margin-bottom: 20px;
`;

const ModalInput = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
`;

const ModalButton = styled.button`
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${(props) => props.bgColor || 'grey'};
    color: white;
    
`;

const ApprovalModal = ({ show, approvedPrice, setApprovedPrice, onClose, onSubmit,e }) => {
    if (!show) return null;

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1 },
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    return (
        <ModalOverlay
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
        >
            <ModalContainer
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={modalVariants}
                transition={{ duration: 0.3 }}
            >
                <ModalTitle>Approve Expense</ModalTitle>
                <ModalInput
                    type="number"
                    value={approvedPrice}
                    onChange={(e) => setApprovedPrice(e.target.value)}
                    placeholder="Enter approved amount"
                />
                <div>
                    <ModalButton style={{ padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            backgroundColor: '#27AE60',
                            color: 'white',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',}}onClick={(e)=>onSubmit(e)}>Submit</ModalButton>
                    <ModalButton style={{ padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            backgroundColor: '#D44545',
                            color: 'white',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',}} onClick={(e)=>onClose(e)}>Cancel</ModalButton>
                </div>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ApprovalModal;
