import React from 'react'
import './globalButton.scss';
export default function GlobalButton({text , Icon , lightbutton,onClick}) {
  return (
    <div className='global-button'>
      <button className={lightbutton ? 'light-button' : ""}
      onClick={onClick}
      >
        {
          Icon && (
            <img src={Icon} alt="Icon"/>
          )
        }
      {text}
      </button>
    </div>
  )
}

