import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/button";
import Input from "../../../shared/components/input";
import {
  fetchsingleData,
  fetchData,
  patchData,
  postData,
  deleteData,
} from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import SelectBox from "../../../shared/components/select";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from "../../addcustomers/statusBox";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";
export default function AddArea() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  useEffect(() => {
    // Fetch data when the component mounts if there is an id
    if (id) {
      getData();
    }
    fetchCountry();
    fetchState();
    fetchCity();
  }, []);

  const [formData, setFormData] = useState({
    areaName: "",
    cityName: "",
    cityId: "",
    stateName: "",
    stateId: "",
    countryName: "",
    countryId: "",
    status: true,
    code:"",
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("country", id);
        if (response?.data)
          setFormData({
            ...formData,
            countryId: response?.data?.countryId,
            countryName: response?.data?.countryName,
          }); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("state", id);
        if (response?.data)
          setFormData({
            ...formData,
            stateName: response?.data?.stateName,
            stateId: response?.data?.stateId,
          }); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, cityName: i?.cityName },
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("city", id);
        if (response?.data)
          setFormData({
            ...formData,
            cityName: response?.data?.cityName,
            cityId: response?.data?.cityId,
          }); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationCheck(true)) {
      try {
        if (id) {
          patchData(`area/${id}`, formData)
            .then((data) => {
              notify.success('Area updated successfully!');
              if (data) navigate(-1);
            })
            .catch((error) => {
              notify.error('Error while saving Area');
              console.error("Error fetching data:", error);
            });
        } else {
          postData("area", formData)
            .then((data) => {
              notify.success('Area saved successfully!');
              if (data) navigate(-1);
            })
            .catch((error) => {
              notify.error('Error while saving Area');
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };


  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData?.countryId) {
      validationErrors.countryName = "Please Select a Country.";
    }

    if (!formData?.stateName?.trim()) {
      validationErrors.stateName = "Please Select State Name.";
    }

    if (!formData?.cityName) {
      validationErrors.cityName = "Please Select City Name.";
    }

    if (!formData?.cityName) {
      validationErrors.cityName = "Please Select City Name.";
    }
    if (!formData?.areaName) {
      validationErrors.areaName = "Please Select Area Name.";
    }

    if (isSubmit || errors.isSubmit) {
      validationErrors.isSubmit = true;
    }

    setErrors(validationErrors);
    console.log("Validation Errors:", validationErrors); // Debug validation errors
    return (
      Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
    );
  };

  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors.isSubmit) validationCheck();
  };

  const handleDelete = async () => {
    try {
      await deleteData(`area/${id}`);
      navigate(-1);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const getData = async () => {
    try {
      const response = await fetchsingleData("area", id);
      if (response?.data) setFormData(response?.data); // Update state with fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="page-24">
      {/* <div className="page-header add-news-alignment">
        <span>
          Area {`>`} {id ? "Edit" : "Create"}
        </span>
        <div className="two-button-alignment">
          <div onClick={handleSubmit}>
            <GlobalButton text={id ? "Update" : "Save"} />
          </div>
          <Link to="/area">
            <GlobalButton text="Discard" outline />
          </Link>
        </div>
      </div> */}


      <div className='add-header-design'>
        <div>
          <h2>Add Area</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add Area" />
        </div>
        <div className='right-contnet-alignment'>
          <Link to="/area">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Discard
            </button>
          </Link>
          <button onClick={handleSubmit}>
            {id ? "Update" : "Save"}
          </button>
        </div>
      </div>

      <div className="page-main-grid">
        <div>
          <div className="new-white-box-design">
            <div className="two-col-grid">
            <Input
                label={
                  <span>
                    Area<span className="required-star">*</span>
                  </span>}

                name="areaName"
                value={formData.areaName}
                onChange={handleInputChange}
                placeholder="Enter Area Name"
                type="text"
                required
                errors={errors.areaName}
              />
              {company_setting?.extra_setting?.enableAreaCode ==="Yes" && (
               <Input
                label={
                  <span>
                    Code
                  </span>}

                name="code"
                value={formData.code}
                onChange={handleInputChange}
                placeholder="Enter Area Code"
                type="text"
                required
                errors={errors.code}
              />
            )}
              <SelectBox
                label={
                  <span>
                    country<span className="required-star">*</span>
                  </span>}

                name="countryName"
                value={countryOptions.find(
                  (i) => i.value.countryId === formData.countryId
                )} // Correct comparison
                options={countryOptions}
                placeholder="Select Country"
                onChange={(selectedOption) => {
                  console.log("Selected Country:", selectedOption);
                  fetchState(selectedOption?.value?.countryId);
                  setFormData((prevState) => ({
                    ...prevState,
                    countryId: selectedOption?.value?.countryId, // Update countryId
                    countryName: selectedOption?.value?.countryName, // Update countryName
                  }));
                }}
                required
                errors={errors.countryName}
              />
              <SelectBox
                label={
                  <span>
                    State<span className="required-star">*</span>
                  </span>}

                name="stateName"
                value={stateOptions.find(
                  (i) => i.value.stateId === formData.stateId
                )} // Correct comparison
                options={stateOptions}
                placeholder="Select State"
                onChange={(selectedOption) => {
                  console.log("Selected State:", selectedOption);
                  fetchCity(selectedOption?.value?.stateId);

                  setFormData((prevState) => ({
                    ...prevState,
                    stateId: selectedOption?.value?.stateId, // Update countryId
                    stateName: selectedOption?.value?.stateName, // Update countryName
                  }));
                }}
                required
                errors={errors.countryName}
              />
              <SelectBox
                label={
                  <span>
                    City<span className="required-star">*</span>
                  </span>}

                name="cityName"
                value={cityOptions.find(
                  (i) => i.value.cityId === formData.cityId
                )} // Correct comparison
                options={cityOptions}
                placeholder="Select City"
                onChange={(selectedOption) => {
                  console.log("Selected City:", selectedOption);
                  setFormData((prevState) => ({
                    ...prevState,
                    cityId: selectedOption?.value?.cityId, // Update countryId
                    cityName: selectedOption?.value?.cityName, // Update countryName
                  }));
                }}
                required
                errors={errors.cityName}
              />
             
              <div className="d-flex align-items-end">
                <Toggle
                  label="Status"
                  checked={formData.status}
                  onChange={toggleSwitch}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <StatusBox />
        </div>
      </div>
    </div>
  );
}
