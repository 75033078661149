import React from 'react'
import './input.scss';
export default function Input({ label, placeholder, icon, path , colorchange,required,errors , type, readOnly, ...other }) {
  return (
    <div className={colorchange ? 'input color-change' : 'input'}>
      <label className={required ? 'required' : ''}>{label}</label>
      <div className='relative'>
        <input type={type} placeholder={placeholder} {...other} readOnly={readOnly}  />
        {
          icon && (
            <div className='icon'>
              <i className={path}></i>
            </div>
          )
        }

      </div>
      {errors && <label  style={{color:'red'}} >{errors}</label>}
    </div>
  )
}
