import React from 'react'
import './mobileviewsidebar.scss';
import Sidebar from '../sidebar';
export default function Mobileviewsidebar({ setMobileheader, mobileheader }) {
    return (
        <div className={mobileheader ? 'mobile-sidebar show' : 'mobile-sidebar hide'}>
            <div className='mobile-header'>
                <p>BRAHMANI</p>
                <i onClick={() => setMobileheader(false)} className="fa-solid fa-xmark"></i>
            </div>
            <Sidebar />
        </div>
    )
}
