import React from 'react';
import './select.scss'; // assuming you have a CSS file for styling
import Select from 'react-select'
export default function SelectBox({ label, value,options, onCountryChange, icon, path, colorchange,required,errors, isDisabled, ...other }) {
  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#fff',
      fontSize:'14px',
      height:'40px',
      borderColor: state.isFocused ? '#E0E2E7' : '#E0E2E7',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: '#E0E2E7',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#6b5b95' : state.isFocused ? '#e0d4f1' : '#fff',
      color: state.isSelected ? '#fff' : '#333',
      fontSize:'14px',
      height:'40px',

      '&:active': {
        backgroundColor: '#6b5b95',
        color: '#fff',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6b5b95',
      fontSize:'14px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#6b5b95',
      fontSize:'14px',

      '&:hover': {
        color: '#4e4c9f',
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: '#d3d3d3',
      fontSize:'14px',

    }),
  };

  return (
    <div >
      <label className={required ? 'input-label required' : 'input-label'}>{label}</label>
      <div className='relative'>
      <Select
      styles={customStyles}  options={options} onChange={onCountryChange} {...other}  value={value} isDisabled={isDisabled}  />
        {
          icon && (
            <div className='icon'>
              <i className={path}></i>
            </div>
          )
        }

      </div>
      {errors && <label  style={{color:'red'}} >{errors}</label>}
    </div>
    
  );
}
