import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData } from '../../../services/apiService';
import './expensetable.scss';
import Button from '../../../shared/components/button';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { checkAccess } from '../../../services/checkFeature';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';

export default function Expensetable({ selectedIds, setSelectedIds,totalItems,setTotalItems,  activityList,setActivityList }) {
  const [expenseList, setExpenseList] = useState([]);
  const navigate = useNavigate();
  const user = useSelector(state => state.user.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductList, setProductList] = useState([]);
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [selectedDates, setSelectedDates] = useState([]);

  const [expenseTerm,setExpenseTerm]= useState("");
  const [userTerm, setuserTerm] = useState("");
  const [expensetypeTerm , setexpensetypeTerm] = useState("");
  const [claimAmountTerm, setclaimAmountTerm] = useState("");
  const [expensstatusTerm , setexpensestatusTerm] = useState("");
  const pageSize = 10;
  useEffect(() => {
    // Fetch d ata when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])


  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
      const response = await fetchData('expense',requestBody, pagination);
      if (response?.data) {
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getStatusClass = (status) => {
    console.log("status:", status)
    switch (status.toLowerCase()) {
      case 'approvel':
        return 'open';
      case 'reject':
        return 'cancelled';
      case 'pending':
        return 'Pending';
      default:
        return '';
    }
  };
  const { RangePicker } = DatePicker;
  const [errors, setErrors] = useState({});
    // Combined search filter logic for customerName, status, orderDate, and createdBy
    const filteredActivityList = activityList.filter((item) => {
      // const matchesexpense = item.expenseno?.toLowerCase().includes(expenseTerm?.toLowerCase());
      // const matchesuserTerm = item.username?.toLowerCase().includes(userTerm?.toLowerCase());
  
      // const matchesexpensetype = item.expensetypename?.toLowerCase().includes(expensetypeTerm?.toLowerCase());

      // const matchesclaimAmountTerm =(item.amount?.toString().trim() || '').toLowerCase().includes(claimAmountTerm?.toLowerCase());

      // const matchessetexpensestatus = (item.status?.toString().trim() || '').toLowerCase().includes(expensstatusTerm?.toLowerCase());

      // const matchesAmount = item.productDetails?.reduce((accumulator, currentValue) => {
      //   return accumulator + Number(currentValue.subTotal); // Sum the subtotal of each product
      // }, 0).toFixed(2);
  
      // const matchesAmountSearch = Number(matchesAmount) === Number(AmountTerm);
  
  
      const matchesDateRange = (selectedDates && selectedDates.length === 2)
        ? dayjs(item.data).isSameOrAfter(selectedDates[0], 'day') && dayjs(item.date).isSameOrBefore(selectedDates[1], 'day')
        : true;

  
        return (
          (!expenseTerm || item.expenseno?.toLowerCase().includes(expenseTerm.toLowerCase())) &&
          (!userTerm || item.username?.toLowerCase().includes(userTerm.toLowerCase())) &&
          (!expensetypeTerm || item.expensetypename?.toLowerCase().includes(expensetypeTerm.toLowerCase())) &&
          (!claimAmountTerm || (item.amount?.toString().trim() || '').toLowerCase().includes(claimAmountTerm.toLowerCase())) &&
          (!expensstatusTerm || item.expensstatusTerm?.toLowerCase().includes(expensstatusTerm.toLowerCase())) &&  matchesDateRange 
        )// Ensure the item matches the selected date range
        });

       

    const rangePresets = [
      {
        label: 'Last 7 Days',
        value: [dayjs().subtract(7, 'day'), dayjs()],
      },
      {
        label: 'Last 14 Days',
        value: [dayjs().subtract(14, 'day'), dayjs()],
      },
      {
        label: 'Last 30 Days',
        value: [dayjs().subtract(30, 'day'), dayjs()],
      },
      {
        label: 'Last 90 Days',
        value: [dayjs().subtract(90, 'day'), dayjs()],
      },
    ];
    const onRangeChange = (dates) => {
      setSelectedDates(dates); // Update the state with the selected date range
    };

  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-design'>
        <table>
          <thead>
         
            <tr>
              <th><div className="tableSize"></div></th>
              
              <th><div className="tableSize"></div></th>

              <th><div ><input
                type="text"
                placeholder="Expense No"
                value={expenseTerm}
                onChange={(e) => setExpenseTerm(e.target.value)}
               className="w-100 border-0"
              /></div></th>
              
              <th><div ><input
                type="text"
                placeholder="User"
                value={userTerm}
                onChange={(e) => setuserTerm(e.target.value)}
                className="w-100 border-0"
              /></div></th>
             
<th><div ><input
                type="text"
                placeholder="Expense type"
                value={expensetypeTerm}
                onChange={(e) => setexpensetypeTerm(e.target.value)}
                className="w-100 border-0"
              /></div></th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>


              <th><div className="tableSize"><input
                type="text"
                placeholder=" Claim Amount"
                value={claimAmountTerm}
                onChange={(e) => setclaimAmountTerm(e.target.value)}
                className="w-100 border-0"
              /></div></th>


              <th><div className="tableSize"> <input
                type="text"
                placeholder="Expense status"
                value={expensstatusTerm}
                onChange={(e) => setexpensestatusTerm(e.target.value)}
               className="w-100 border-0"
              /></div></th>


              {/* <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder=" Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%' }}

                /></div></th> */}


                {/* <th><div className="tableSize"></div></th> */}
            </tr>
            <tr>
              
                <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              {checkAccess('edit_expense')&&<th>Action</th>}
              <th>Expense No</th>
              <th>User</th>
              <th>Expense type</th>
              <th>Date</th>
              <th>Claim Amount</th>
              <th>Expense status</th>
            </tr>
          </thead>
          <tbody>
            {filteredActivityList.map((i, index) => (
              <tr key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#f8f9f9', // Alternate row colors
                }}>
                <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
                {checkAccess('edit_expense')&& <td align='center'><Link to={`addexpense/${i?.id}`}>
                <EditIcon/>
                </Link></td>}
             
                <td>{i.expenseno}</td>
                <td>{i.username}</td>
                <td>{i.expensetypename
                }</td>
                <td>{dayjs(i?.date).format('DD-MM-YYYY')}</td>
                <td>{i.amount}</td>
                <td>
                  {/* <div onClick={(e) => navigate(`/expense/expenseview/${i?.expenseId}`)}> 
                   <button className={` ${getStatusClass(i.expensestatus)}`}>{i.expensestatus}</button>
                   </div>  */}
                
                   {checkAccess('read_expense')?( <Link to={{ pathname: `/expense/expenseview/${i?.id}` }}>
                      <button className={` ${getStatusClass(i.expensestatus)}`}>
                        {i?.expensestatus}
                      </button>
                    </Link>):(<button className={` ${getStatusClass(i.expensestatus)}`}
                     style={{ pointerEvents: "none", cursor: "default" }}>
                        {i?.expensestatus}
                      </button>)}
                
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
