import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import './userroletable.scss';
import { fetchData } from '../../../services/apiService';
import EditIcon from '../../../shared/icons/editIcon';
import { checkAccess } from '../../../services/checkFeature';
export default function Userroletable({ selectedIds, setSelectedIds, activityList, setActivityList }) {
  const [userRole, setUserRoleTerm] = useState("");
  const [userrole, setUserRole] = useState([]);
  const [NameTerm, setNameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");

  useEffect(() => {
    
    getData();
  }, []);
  const getData = async () => {
    try {
      const response = await fetchData("userrole");
      if (response?.data) setUserRole(response?.data); 
      if (typeof response?.data === 'object') {
        setActivityList(response?.data); 
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const filteredActivityList = activityList.filter((item) => {
    return (
     
      (!userRole || item.role?.toLowerCase().includes(userRole.toLowerCase())) &&
      (!Status || (item.status?.toString() || '').toLowerCase().includes(Status.toLowerCase())) 
    );
  });


  return (
    <div className=''>
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="User" value={userRole} onChange={(e) => setUserRoleTerm(e.target.value)} /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} /></th>
            </tr>
            <tr>
            <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              {checkAccess('edit_user_role')&&<th>Action</th>}
              <th>RoleType</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
           {
            filteredActivityList?.map((i, index) => {
              return(
                <tr key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',}}
                >
           <td> <input type='checkbox' checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} /></td>
           {checkAccess('edit_user_role')&&<td><Link to={{pathname: `/user-role/adduserrole/${i?.id}`}}>
                <EditIcon/>
                </Link></td>}
                <td>{i?.role}</td>
                <td>
                {checkAccess('read_user_role')?(<Link to={{pathname: `/user-role/adduserrole/view/${i?.id}`}} ><button className={i?.status ? "Active" : "Inactive"}>{i?.status ? "Active" : "Inactive"}</button></Link>):(<button className='status'>{i?.status ? "Active" : "Inactive"}</button>)}
                </td>
              </tr>
              )
            })
           }
          </tbody>
        </table>
      </div>
    </div>
  )
}
