import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, postData } from '../../../services/apiService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditRequestData() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        RequestDescription: '',
        response: '',
        responsecode: '',
        srno: '',
        createdby: '',
        createdat: '',
    });

    const [errors, setErrors] = useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validationCheck()) {
            try {

                {
                    const data = await postData('requestdata', formData);
                    if (data) navigate(-1);
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }

    };

    const getData = async () => {
        try {
            const response = await fetchsingleData("requestdata", id);
            if (response?.data) setFormData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.RequestDescription?.trim()) {
            validationErrors.RequestDescription = 'This field is required';
        }
        if (!formData?.response?.trim()) {
            validationErrors.response = 'This field is required';
        }
        if (!formData?.responsecode?.trim()) {
            validationErrors.responsecode = 'This field is required';
        }
        if (!formData?.srno?.trim()) {
            validationErrors.srno = 'This field is required';
        }
        if (!formData?.createdby?.trim()) {
            validationErrors.createdby = 'This field is required';
        }
        if (!formData?.createdat?.trim()) {
            validationErrors.createdat = 'This field is required';
        }
        if (isSubmit) {
            validationErrors.isSubmit = true;
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
        if (errors.isSubmit) validationCheck();
    };

    return (
        <>
            <div className='page-24'>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <span>requestdata {`>`} {'Create'}</span>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <GlobalButton text='Save' onClick={(e) => handleSubmit(e)} />
                    <Link to="/requestdata"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}


                <div className='add-header-design'>
                    <div>
                        <h2>Add Activity</h2>
                        <Breadcumbs activePath="Dashboard" pageName="Add Activity" />
                    </div>
                    <div className='right-contnet-alignment'>
                        <Link to="/requestdata">
                            <button className='light-button'>
                                Discard
                            </button>
                        </Link>
                        <button onClick={(e) => handleSubmit(e)}>
                            Save
                        </button>
                    </div>
                </div>

                <div className='page-main-grid'>
                    <div>
                        <div className='new-white-box-design'>
                        <form onclick={(e) => handleSubmit(e)} >
                            <div className='two-col-grid'>
                            <Input
                        label='Request Description'
                        name='RequestDescription'
                        value={formData.RequestDescription}
                        onChange={handleInputChange}
                        placeholder='Enter request description..'
                        type='text'
                        errors={errors.RequestDescription}
                        required
                    />
                    <Input
                        label='Response'
                        name='response'
                        value={formData.response}
                        onChange={handleInputChange}
                        placeholder='Enter response..'
                        type='text'
                        errors={errors.response}
                        required
                    />
                    <Input
                        label='Response Code'
                        name='responsecode'
                        value={formData.responsecode}
                        onChange={handleInputChange}
                        placeholder='Enter response code..'
                        type='text'
                        errors={errors.responsecode}
                        required
                    />
                    <Input
                        label='Sr No.'
                        name='srno'
                        value={formData.srno}
                        onChange={handleInputChange}
                        placeholder='Enter number..'
                        type='text'
                        errors={errors.srno}
                        required
                    />
                    <Input
                        label='Created By'
                        name='createdby'
                        value={formData.createdby}
                        onChange={handleInputChange}
                        placeholder='Enter created by name..'
                        type='text'
                        errors={errors.createdby}
                        required
                    />
                    <Input
                        label='Created At'
                        name='createdat'
                        value={formData.createdat}
                        onChange={handleInputChange}
                        placeholder='Enter created at..'
                        type='text'
                        errors={errors.createdat}
                        required
                    />
                            </div>
                        </form>
                        </div>
                    </div>
                    <div>
                        <StatusBox/>
                    </div>
                </div>

               
            </div>
        </>
    );
}
