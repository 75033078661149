import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import GlobalButton from '../../../shared/globalButton';
import { notify } from '../../toastr/Toastr';



import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditcountry() {
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        // Fetch data when the component mounts
        if (id) {
            getData();
        }
    }, []);
    const [formData, setFormData] = useState({
        countryName: '',
        countryShortname: '',
        countryFlag: '',
        status: true
    });
    const [errors, setErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`country/${id}`, formData).then(data => {
                        if (data)
                            notify.success('Country updated Successfully!');
                        navigate(-1);
                    })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    postData('country', formData)
                        .then(data => {
                            if (data)
                                notify.success('Country saved Successfully!');
                            navigate(-1);
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });

                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.countryName?.trim()) {
            validationErrors.countryName = 'This field is required';
        }
        if (isSubmit || errors.isSubmit)
            validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return (Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0) ? true : false
    }
    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit)
            validationCheck()
    };
    const getData = async () => {
        try {
            const response = await fetchsingleData("country", id);
            if (response?.data)
                setFormData(response?.data); // Update state with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <span>Country {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/countryMaster"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}

            <div className='add-header-design'>
                <div>
                    <h2>Add Country</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add className" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/countryMaster">
                        <button className='light-button'>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Cancel
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        {id ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>

            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label={
                                <span>
                                    Country Name <span className="required-star">*</span>
                                </span>}
                                name='countryName' value={formData.countryName} onChange={handleInputChange} placeholder='Enter Country Name..' type='text' required errors={errors.countryName} />
                            <Input label='Country ShortName' name='countryShortname' value={formData.countryShortname} onChange={handleInputChange} placeholder='Enter Country ShortName..' type='text' />
                            <Input label='Country Flag' name='countryFlag' value={formData.countryFlag} onChange={handleInputChange} placeholder='Enter Country Flag..' type='text' />
                            <div className='d-flex align-items-end'>
                                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>
        </div>
    )
}
