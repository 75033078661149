import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import CurrentLocationTable from './currentlocationTable';
import { deleteData } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";


export default function CurrentLocations() {

  const [currentLocationList, SetCurrentLocationList] = useState([]);


  return (
    <div>
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>City</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
          <Link to="addcity"><Button text='Add City'/></Link>
        </div>
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Current Location</h2>
            <Breadcumbs activePath="Current Location" pageName="Current Location" />
          </div>
        </div>
      </div>
      <div className='price-group-table-alignment'>
        <CurrentLocationTable
        currentLocationList={currentLocationList}
        SetCurrentLocationList={SetCurrentLocationList}/>
      </div>
    </div>
  )
}
