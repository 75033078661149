import React, { useEffect, useState } from 'react'
import './addnews.scss';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { Link, useNavigate } from 'react-router-dom';
import { postData } from '../../../services/apiService';

export default function Addnews() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    newsTitle: '',
    expiry: '',
    content: '',
    sendToSalesApp: false,
    sendToCustomerApp: true,
    status : "Active"
});

const handleSubmit = (e) => {
  e.preventDefault();

  try {
      // Make API call to save data
      postData('news',formData)
      .then(data => {
        console.log('API response:', data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

      // Navigate to another page after successful save
      navigate(-1);
  } catch (error) {
      console.error('Error saving data:', error);
      // Handle error
  }
};

const handleInputChange = (e) => {
  const { name, value, checked, type } = e.target;
  setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
  }));
};
  

  return (
    <div>
      <div className='page-header add-news-alignment'>
        <span>News {`>`} Create</span>
        <div className='two-button-alignment'>
          <div onClick={handleSubmit}><Button text="Save" /></div>
          <Button text="Discard" outline/>
        </div>
      </div>
      <div className='add-news-sapcing-alignment'>
      <div className='add-news-form-design'>
        <h2>Basic Details</h2>
        <div className='grid'>
        <div className='bottom-alignment'>
            <Input label='Title' name='newsTitle' value={formData.newsTitle} onChange={handleInputChange} placeholder='title' />
        </div>
        <div className='bottom-alignment'>
            <Input label='Expiry Date' name='expiry' value={formData.expiry} onChange={handleInputChange} placeholder='Expiry Date'  type='date'/>
        </div>
        <div className='bottom-alignment'>
            <Input label='Content' name='content' value={formData.content} onChange={handleInputChange} placeholder='Content'  type='text'/>
        </div>
        <div className='bottom-alignment'>
          <div className='checkbox-design'>
            <input type='checkbox' name='sendToSalesApp' checked={formData.sendToSalesApp} onChange={handleInputChange}/>
            <label>Send To Sales App</label>
          </div>
          <div className='checkbox-design'>
            <input type='checkbox' name='sendToCustomerApp' checked={formData.sendToCustomerApp} onChange={handleInputChange}/>
            <label>Send To Customer App</label>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  )
}
