import React, { useEffect, useState } from 'react';
import './viewprofile.scss';
import { fetchData } from '../../../services/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import ShowImage from '../../../shared/components/showImage';
export default function Viewprofile({ setActivityList }) {
  const [formData, setFormData] = useState({
    name: '',
    website: '',
    contactNo: '',
    personName: '',
    fax: '',
    email: '',
    address: '',
    pincode: '',
    GST: '',
    PAN: '',
    PlaceofSupply: '',
    TAN: '',
    countryName: '',
    stateName: '',
    areaName: '',
    profileUrl : ''
  });

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // Fetch data when the component mounts
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await fetchData('companyprofile');
      if (response?.data && response.data.length > 0) {
        setFormData(response.data[0]); // Set the first company's data to the state
        setActivityList(response.data); // If needed for another part of the app
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
    <div className='page-px-24'>
    <div className='new-white-box-design'>
      <h2>
      Profile Info
      </h2>
      <div className="profile-info">

<div className=" profile-info__section--left d-flex">
  <div className="profile-info__avatar mr-4">
  <ShowImage value={formData?.profileUrl}></ShowImage>
  </div>
  <div style={{marginLight: '10px'}}>
<p><strong>Name</strong></p>
  <p>{formData.name || '-'}</p>
  <p><strong>Registered Contact No</strong></p>
  <p>{formData.contactNo || '-'}</p>
  <p><strong>Support Person Name</strong></p>
  <p>{formData.personName || '-'}</p>
</div> 
</div>



<div >
  <p><strong>Website</strong></p>
  <p>{formData.website || '-'}</p>
  <p><strong>Fax</strong></p>
  <p>{formData.fax || '-'}</p>
  <p><strong>Support Contact No</strong></p>
  <p>{formData.contactNo || '-'}</p>
</div>

<div >
  <p><strong>Registered Email</strong></p>
  <p>{formData.email || '-'}</p>
  <p><strong>Contact Person Name</strong></p>
  <p>{formData.personName || '-'}</p>
</div>

</div>
    </div>
    <div className='new-white-box-design'>
    <h2>
    Address Details
    </h2>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '30%' }}>
            <p><strong>Address</strong></p>
            <p>{formData.address || '-'}</p>
          </div>
          <div style={{ width: '20%' }}>
            <p><strong>Pincode</strong></p>
            <p>{formData.pincode || '-'}</p>
          </div>
          <div style={{ width: '20%' }}>
            <p><strong>Country</strong></p>
            <p>{formData.countryName || '-'}</p>
          </div>
          <div style={{ width: '20%' }}>
            <p><strong>State</strong></p>
            <p>{formData.stateName || '-'}</p>
          </div>
        </div>
        <p style={{  marginTop: '20px'}}><strong>City</strong></p>
        <p style={{ marginTop: '5px' }}>{formData.areaName || '-'}</p>
    </div>
    <div className='new-white-box-design'>
      <h2>
      Statutory Details
      </h2>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '20%' }}>
            <p><strong>GST</strong></p>
            <p>{formData.GST || '-'}</p>
          </div>
          <div style={{ width: '30%' }}>
            <p><strong>Place Of Supply</strong></p>
            <p>{formData.PlaceofSupply || '-'}</p>
          </div>
          <div style={{ width: '20%' }}>
            <p><strong>PAN</strong></p>
            <p>{formData.PAN || '-'}</p>
          </div>
          <div style={{ width: '20%' }}>
            <p><strong>TAN</strong></p>
            <p>{formData.TAN || '-'}</p>
          </div>
        </div>
    </div>
    </div>
    </>
  );
}


