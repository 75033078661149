import store from '../redux/reduxstore';
import {logout} from '../redux/auth';


export const createUser = async (userdetails)=>{
//   console.log({
//     username:userdetails?.email?.split("@")[0],
//     password:userdetails.password,
//     options:{
//         userAttributes:{
//             email:userdetails.email    
//         },
//         autoSignIn:true
       
//     }
// })
    try{
      
    }catch(e){
        console.log('error singup in', e);
    }
}

export const signOutUser = async ()=>{
    try {
       store.dispatch(logout())
      } catch (error) {
        console.log('error signing in', error);
      }
}
export const currentsession = async ()=>{
  try {
      return store.getState().auth.isAuthenticated;
    } catch (error) {
      console.log('error signing in', error);
    }
}
export const getToken = async ()=>{
    try {
      const token = store.getState().auth.token
      if(!token)
        await signOutUser();
      return token;
        // const token = useSelector(state=)
      } catch (error) {
        await signOutUser();
        console.log('error signing in', error);
      }
}


