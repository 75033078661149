import React,{useState} from 'react'
import './productcategory.scss';
import Button from '../../shared/components/button';
import Productcategorytable from './productcategorytable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { deleteData, filterAll } from '../../services/apiService';
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
export default function Productcategory() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`systemtype/${id}`)));
      setcategoryList(categoryList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };
  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};
  const handleExport = () => {
    const headers = [
      ["Activity Type", "Activity Value", "Date","Assign To", "Linked To","Linked Name", "Status"]
    ];
 
    filterAll("myactivities")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.activityname,
        item.activityvalue,
        item.activitydate,
        item.username,
        item.module,
        item.linkname,
        "Active"
      ]);
      exportToExcel('Myactivities',headers,datas,'myactivities')
    })
  };
  return (
    <div>
      {/* <div className='page-header'>
        <span>Product Category</span>
       <Link to="add"><Button text='Add Product Category'/></Link> 
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Product Category</h2>
            <Breadcumbs activePath="Product Category" pageName="Product Category" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            <Link to="add">
              <GlobalButton text="Add Product Category" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport}  />
          </div>
        </div>
      </div>
      <Productcategorytable
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      categoryList={categoryList}
      setProductList={setcategoryList}
      totalItems={totalItems}
      setTotalItems={setTotalItems}/>
    </div>
  )
}
