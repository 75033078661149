import * as XLSX from "xlsx";

export const exportToExcel = (filename, headers, data, sheetName = "Sheet1") => {
   const worksheetData = [...headers, ...data];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C }); 
      if (worksheet[cellAddress]) {
        worksheet[cellAddress].s = {
          fill: { fgColor: { rgb: "D3D3D3" } }, 
          font: { bold: true },
        };
      }
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet,sheetName);
    XLSX.writeFile(workbook, filename+".xlsx");
};
