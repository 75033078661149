import React, { useEffect,useState } from 'react';
import './activitytypetable.scss';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { ChecksumAlgorithm } from '@aws-sdk/client-s3';
import { checkAccess } from '../../../services/checkFeature';

export default function Activitytypetable({ selectedIds, setSelectedIds,  setActivityList }) {


  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [activityList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Items per page
  const [name, setName ]= useState("");
  const [activityTemplate, setActivityTemplate] = useState("");
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  const getData = async (page, pageSize) => {
    try {  
      const requestBody = {};
      const pagination = {
      page: page,
      limit: pageSize,
    }
      const response = await fetchData('activitytype',requestBody, pagination);
      if (response?.data) {
        setProductList(response.data || []);  // API returns current page data in 'items'
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  // const filteredActivityList = activityList.filter((item) => {
  //   const searchName = item.name?.toLowerCase().includes(name?.toLowerCase());
  //   const searchActivityTemplate = item.activityTemplate?.toLowerCase().includes(activityTemplate?.toLowerCase());
  //   const searchStatus =(item.status?.toString() || '').includes(status?.toLowerCase());
   
  //   return (
  //     (!name || searchName) &&
  //     (!activityTemplate || searchActivityTemplate) &&
  //     (!status || searchStatus)
   
  //   );
  // });
  const filteredActivityList = activityList.filter((item) => {
    const searchName = item.name?.toLowerCase().includes(name?.toLowerCase());
    const searchActivityTemplate = item.activityTemplate?.toLowerCase().includes(activityTemplate?.toLowerCase());
    const searchStatus = status === undefined || item.status === status;
  
    return (
      (!name || searchName) &&
      (!activityTemplate || searchActivityTemplate) &&
      // (!status || searchStatus)
      searchStatus
    );
  });
  
  return (
    <div className='page-px-24'>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div className="w-100"></div></th>
              
              <th><div className="w-100"></div></th>

              <th><div><input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              <th><div ><input
                type="text"
                placeholder=" Activity template"
                value={activityTemplate}
                onChange={(e) => setActivityTemplate(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              {/* <th><div> <input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                 className="w-100 border-0"

              /></div></th> */}
              <th>

<div>
  <select
    value={status === undefined ? "" : status.toString()} // Default to empty string when status is undefined
    onChange={(e) => {
      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
      console.log("Selected Status:", selectedStatus); // Logs the value as boolean or undefined
      setStatus(selectedStatus); // Updates the state with true, false, or undefined (for all)
    }}
    className="w-100 border-0"
  >
    <option value="">All</option>  {/* Default option to show all */}
    <option value="true">Active</option>     {/* Option for true */}
    <option value="false">Inactive</option> {/* Option for false */}
  </select>
</div>





</th>

            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              {checkAccess('manage_activity_type')&&<th>Action</th>}
              <th>Name</th>
              <th>Activity template</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              (filteredActivityList ?? []).map((item) => {
                return (
                  <tr key={item?.id}>
                    <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                    {checkAccess('manage_activity_type')&&<td>
                      <Link to={{ pathname: `addEditactivitytype/${item?.id}` }}>
                      <EditIcon/>
                      </Link>
                    </td>}
                    <td>{item?.name}</td>
                    <td>{item?.activityTemplate}</td>
                    <td>
                      <button className={item?.status ? "Active" : "Inactive"}>{item?.status ? "Active" : "Inactive"}</button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
