import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";


export default function SegmentComponent() {


  return (
    <Box p={4}>
        <div>
            hi67
        </div>

    </Box>
  );
}
