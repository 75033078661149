import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/button";
import Input from "../../../shared/components/input";
import {
  fetchData,
  fetchsingleData,
  patchData,
  postData,
} from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectBox from "../../../shared/components/select";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";

import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from "../../addcustomers/statusBox";

export default function AddEditCityData() {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // Fetch data when the component mounts if there is an id
    if (id) {
      getData();
    }
    fetchCountry();
    fetchState();
  }, []);

  const [formData, setFormData] = useState({
    cityName: "",
    stateName: "",
    stateId: "",
    countryName: "",
    countryId: "",
    status: true,
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("country", id);
        if (response?.data) setFormData({ ...formData, countryId: response?.data?.countryId, countryName: response?.data?.countryId }); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
      if (id) {
        const response = await fetchsingleData("state", id);
        if (response?.data) setFormData({ ...formData, stateName: response?.data?.stateName, stateId: response?.data?.stateId }); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form...");

    if (validationCheck(true)) {
      try {
        if (id) {
          patchData(`city/${id}`, formData)
            .then((data) => {
              navigate(-1);
            })
            .catch((error) => {
              console.error("Error updating data:", error);
            });
        } else {
          postData("city", formData)
            .then((data) => {
              navigate(-1);
            })
            .catch((error) => {
              console.error("Error posting data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData?.countryId) {
      validationErrors.countryName = "Please Select a Country.";
    }

    if (!formData?.stateName?.trim()) {
      validationErrors.stateName = "Please Select State Name.";
    }

    if (!formData?.cityName) {
      validationErrors.cityName = "Please enter a city.";
    }

    if (isSubmit || errors.isSubmit) {
      validationErrors.isSubmit = true;
    }

    setErrors(validationErrors);
    console.log("Validation Errors:", validationErrors); // Debug validation errors
    return (
      Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
    );
  };

  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors.isSubmit) {
      validationCheck();
    }
  };

  const getData = async () => {
    try {
      const response = await fetchsingleData("city", id);
      if (response?.data) {
        setFormData(response.data); // Update state with fetched data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="page-24">
      {/* <div className="page-header add-news-alignment">
        <span>
          City Master {`>`} {id ? "Edit" : "Create"}
        </span>
        <div className="two-button-alignment">
          <div onClick={handleSubmit}>
            <GlobalButton text={id ? "Update" : "Save"} />
          </div>
          <Link to="/stateType">
            <GlobalButton text="Discard" outline />
          </Link>
        </div>
      </div> */}


      <div className='add-header-design'>
        <div>
          <h2>Add City Master</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add City Master" />
        </div>
        <div className='right-contnet-alignment'>
          <Link to="/stateType">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Discard
            </button>
          </Link>
          <button onClick={handleSubmit}>
            {id ? "Update" : "Save"}
          </button>
        </div>
      </div>

      <div className="page-main-grid">
        <div>
          <div className="new-white-box-design">
            <form onSubmit={handleSubmit}>
              <div className="two-col-grid">
                <SelectBox
                  label={
                    <span>
                      Country<span className="required-star">*</span>
                    </span>}

                  name="countryName"
                  value={countryOptions.find(
                    (i) => i.value.countryId === formData.countryId
                  )} // Correct comparison
                  options={countryOptions}
                  placeholder="Select Country"
                  onChange={(selectedOption) => {
                    console.log("Selected Country:", selectedOption);
                    fetchState(selectedOption?.value?.countryId);
                    setFormData((prevState) => ({
                      ...prevState,
                      countryId: selectedOption?.value?.countryId, // Update countryId
                      countryName: selectedOption?.value?.countryName, // Update countryName
                    }));
                  }}
                  required
                  errors={errors.countryName}
                />
                <SelectBox
                  label={
                    <span>
                      State<span className="required-star">*</span>
                    </span>}

                  name="stateName"
                  value={stateOptions.find(
                    (i) => i.value.stateId === formData.stateId
                  )} // Correct comparison
                  options={stateOptions}
                  placeholder="Select State"
                  onChange={(selectedOption) => {
                    console.log("Selected State:", selectedOption);
                    setFormData((prevState) => ({
                      ...prevState,
                      stateId: selectedOption?.value?.stateId, // Update countryId
                      stateName: selectedOption?.value?.stateName, // Update countryName
                    }));
                  }}
                  required
                  errors={errors.stateName}
                />
                <Input
                  label={
                    <span>
                      City<span className="required-star">*</span>
                    </span>}

                  name="cityName"
                  value={formData.cityName}
                  onChange={handleInputChange}
                  placeholder="Enter City Name.."
                  type="text"
                  required
                  errors={errors.cityName}
                />
                <div className="d-flex align-items-end">
                  <Toggle
                    label="Status"
                    checked={formData.status}
                    onChange={toggleSwitch}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <StatusBox />
        </div>
      </div>

    </div>
  );
}
