import { io } from 'socket.io-client';
// import { getToken, getUserType } from './cognitoService'

let socket;

const connectSocket = async () => {  
  socket = io(`https://saleseasy-dev-api.synoris.co`);

  socket.on('connect', () => {
    console.log('Connected to server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });
};
const disconnectSocket = () => {
  if (socket) {
    // Remove event listeners
    socket.off('connect');
    socket.off('disconnect');

    // Disconnect the socket
    socket.disconnect(); // This line ensures the socket disconnects from the server
    socket = null; // Optionally, clear the socket reference
  }
};


const emitEvent = (event, data) => {
  if (socket) {
    socket.emit(event, data);
  }
};

const addEventListener = (event, callback) => {
  if (socket) {
    socket.on(event, callback);
  }
};

const removeEventListener = (event, callback) => {
  if (socket) {
    socket.off(event, callback);
  }
};

export { connectSocket, disconnectSocket, emitEvent, addEventListener, removeEventListener };
