import React, { useState,useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import './systemtypetable.scss';
export default function Systemtypetable({ selectedIds, setSelectedIds,activityList,totalItems,setTotalItems,setActivityList }) {

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [status, setStatus] = useState(undefined);
  const [categoryName, setCategoryName] = useState("");
  const pageSize = 10; // Items per page


  useEffect(() => {
    // Fetch data when page or pageSize changes
    getData(currentPage, pageSize);
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
                const pagination = {
                  page: page,
                  limit: pageSize,
                }
      const response = await fetchData('systemcategory',requestBody, pagination);
      if (response?.data) {
        // setProductList(response.data || []);  // API returns current page data in 'items'
        setActivityList(response.data || [])
        setTotalItems(response?.totalDocuments || 0);  // API returns total count of items in 'totalItems'
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredActivityList = activityList.filter((item) => {
    const searchCategoryName = item.categoryName?.toLowerCase().includes(categoryName?.toLowerCase());
    // const searchStatus = (item.status?.toString() || '').includes(status?.toLowerCase());
    const searchStatus = status === undefined || item.status === status;

   
    return (
      (!categoryName || searchCategoryName) &&
      searchStatus
   
    );
  });

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div className='page-px-24'>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div></div></th>
              
              <th><div></div></th>

              <th><div><input
                type="text"
                placeholder="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="w-100 border-0"

              /></div></th>

              {/* <th><div> <input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="w-100 border-0"

              /></div></th> */}

<th>

<div>
  <select
    value={status === undefined ? "" : status.toString()} 
    onChange={(e) => {
      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
      setStatus(selectedStatus); 
    }}
    className="w-100 border-0"
  >
    <option value="">All</option>
    <option value="true">Active</option> 
    <option value="false">Inactive</option>
  </select>
</div>





</th>
              

            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              <th>Action</th>
              <th>Category Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              (filteredActivityList ?? []).map((item) => {
                return (
                  <tr key={item?.id}>
                    <td> <input type='checkbox' checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} /></td>
                    <td>
                      <Link to={{ pathname: `addEditSystemTypeMaster/${item?.id}` }}> 
                      <EditIcon/>
                      </Link>
                    </td>
                    <td>{item?.categoryName}</td>
                    <td>
                      <button className={item?.status ? "Active" : "Inactive"}>{item?.status ? "Active" : "Inactive"}</button>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
