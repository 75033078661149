import React,{useState} from 'react'
import './userrole.scss';
import { deleteData } from '../../services/apiService';
import Button from '../../shared/components/button';
import Userroletable from './userroletable';
import { Link } from 'react-router-dom';
import Breadcumbs from '../../shared/components/breadcumbs';
import GlobalButton from '../../shared/globalButton';
import AccountTableHeader from '../account/accountTableHeader';
import { checkAccess } from '../../services/checkFeature';
export default function Userrole() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`userrole/${id}`)));
      setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div className='page-24'>
      {/* <div className='page-header'>
        <span>User Role  </span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
        {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        {checkAccess('add_user_role')&&<Link to='/user-role/adduserrole'><Button text='Add User Role'/></Link>}
      </div>
      </div> */}
      <div className="customer-header-alignment">
          <div>
            <h2>User Role</h2>
            <Breadcumbs activePath="Dashboard" pageName="User Role" />
          </div>
          <div className="right-contnet-alignment">
            {checkAccess('add_user_role')&&<Link to="/user-role/adduserrole">
              <GlobalButton text="Add User Role"  />
            </Link>}
            {selectedIds.length > 0 &&
            <div onClick={handleDelete} >
           <GlobalButton text="Export" lightbutton  />
              </div>
}
          </div>
        </div>
        <AccountTableHeader />
      <Userroletable 
       selectedIds={selectedIds} 
       setSelectedIds={setSelectedIds} 
       activityList={activityList}
       setActivityList={setActivityList}
/>
    </div>
  )
}
