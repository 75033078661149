import React from 'react'
import './statusBox.scss';
import SelectBox from '../../../shared/components/select';
export default function StatusBox() {
  return (
    <div className='status-box-design'>
        <div className='box-header-alignment'>
            <h3>
            Status
            </h3>
            <button>
            Draft
            </button>
        </div>
        <SelectBox
            label='Customer Status'
            name='priceGroup'
            placeholder='Select. . .'
            required
          />
    </div>
  )
}
