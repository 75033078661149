import React from 'react'
import { Link } from 'react-router-dom';
import Button from '../../shared/components/button';
import './requestdata.scss';
import Requestdatatable from './requestdatatable';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
export default function Requestdata() {
  return (
    <div className='page-24'>
      {/* <div className='page-header'>
        <span>Request log</span>
        <Link to="addrequestdata"><Button text='Add requestdata'/></Link>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-3">
          <div>
            <h2>Request log</h2>
            <Breadcumbs activePath="Request log" pageName="Request log" />
          </div>
          <div className="right-contnet-alignment">
            <Link to="addrequestdata">
              <GlobalButton text="Add Request log" Icon={PlusIcon} />
            </Link>
            {/* <GlobalButton text="Export" lightbutton Icon={ExportIcon} /> */}
          </div>
        </div>
      </div>
      <Requestdatatable/>
    </div>
  )
}
