import React,{useState} from 'react'
import './productunit.scss';
import Button from '../../shared/components/button';
import Productunittable from './productunittable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import { deleteData } from '../../services/apiService';
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { filterAll } from '../../services/apiService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";

export default function Productcategory() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [PriceList, setpricelist] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`systemtype/${id}`)));
      setpricelist(PriceList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };

  const handleExport = () => {
    const headers = [
      ["Activity Type", "Activity Value", "Date","Assign To", "Linked To","Linked Name", "Status"]
    ];
 
    filterAll("myactivities")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.typeCategory,
        item.typeName,
        item.status,
      ]);
      exportToExcel('Myactivities',headers,datas,'myactivities')
    })
  };

  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};


  return (
    <div>
      {/* <div className='page-header'>
        <span>Product Unit</span>
       <Link to="add"><Button text='Add Product Unit'/></Link> 
      </div> */}
      <div className="customer-page-layoutalignment">
        <div className="customer-header-alignment">
          <div>
            <h2>Product Unit</h2>
            <Breadcumbs activePath="Product Unit" pageName="Product Unit" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            <Link to="add">
              <GlobalButton text="Add Product Unit" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>
      <Productunittable
       selectedIds={selectedIds}
       setSelectedIds={setSelectedIds}
       PriceList={PriceList}
       unitList={PriceList}

       setProductList={setpricelist}
       totalItems={totalItems}
       setTotalItems={setTotalItems}/>
    </div>
  )
}
