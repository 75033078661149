import React, { useState, useEffect } from "react";
import "moment/locale/it.js";
import Button from "../../../shared/components/button";
import Toggle from "../../../shared/components/switch";
import Input from "../../../shared/components/input";
import {
  fetchData,
  fetchsingleData,
  patchData,
  postData,
  uploadDocument,
} from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { createUser } from "../../../services/cognitoService";
import GlobalButton from "../../../shared/globalButton";
import UploadImage from "../../../shared/components/uploadImage";
import Breadcumbs from "../../../shared/components/breadcumbs";
import StatusBox from "../../addcustomers/statusBox";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";

export default function AddEdituser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const [leadSource, setLeadSource] = useState([]);
  const [Userrole, setUserRole] = useState([]);
  const [pricegroup, setPriceGroup] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const isViewMode = window.location.pathname.includes("/view");

  const [formData, setFormData] = useState({
    name: "",
    code: "",
    role: "",
    roleId: null,
    status: true,
    email: "",
    password: "",
    repassword: "",
    address: "",
    pincode: "",
    country: "",
    countryId: null,
    state: "",
    stateId: null,
    orgId:null,
    orgName:"",
    city: "",
    cityId: null,
    areaName: "",
    areaId: null,
    location: "",
    locationId: null,
    profileUrl: null,
    contactNo: "",
    productCategory: "",
    productCategoryId: null,
    priceGroupName: "",
    priceGroupId: null,
    expensetypeName:"",
    expensetypeId:null,
  });

  const [errors, setErrors] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [companyprofileOptions, setcompanyprofileOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [priceGroupOptions, setPriceGroupOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [userRoleOptions, setuserRoleOptions] = useState([]);
  const [Expensetype, setExpensetype] = useState([]);

  useEffect(() => {
    getData();
    fetchCountry();
    fetchState();
    fetchCity();
    fetchArea();
    fetchCategory();
    fetchGroup();
    fetchRole();
    fetchCompany();
    getexpensetype()
;  }, []);
  console.log(formData.email);

  const getexpensetype = async () => {
    try {
        const response = await fetchData(`expensetype`);
        if (response?.data) {
            setExpensetype(
                response?.data?.map((i) => {
                    return {
                      label: i?.name,
                      key: "expensetypeName",
                      value: { expensetypeId: i?.id, expensetypeName: i?.name },
                    };
                })
            );
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, country: i?.countryName },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("country", id);
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         countryId: response?.data?.countryId,
      //         country: response?.data?.country,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCompany = async () => {
    try {
      const response = await fetchData("companyprofile");

      if (response?.data) {
        setcompanyprofileOptions(
          response?.data.map((i) => {
            return {
              label: i?.name,
              key: "org",
              value: { orgId: i?.id, orgName: i?.name },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, state: i?.stateName },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("state", id);
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         state: response?.data?.state,
      //         stateId: response?.data?.stateId,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, city: i?.cityName },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("city", id);
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         city: response?.data?.city,
      //         cityId: response?.data?.cityId,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchArea = async (cityId) => {
    try {
      const response = await fetchData("area", { cityId: cityId });

      if (response?.data) {
        setAreaOptions(
          response?.data.map((i) => {
            return {
              label: i?.areaName,
              key: "area",
              value: { areaId: i?.id, areaName: i?.areaName },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("area", id);
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         areaName: response?.data?.areaName,
      //         areaId: response?.data?.areaId,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await fetchData("systemtype", {
        typeCategory: "category",
      });

      if (response?.data) {
        setcategoryOptions(
          response?.data.map((i) => {
            return {
              label: i?.typeName,
              key: "area",
              value: { productCategoryId: i?.id, productCategory: i?.typeName },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("systemtype", id, {
      //       typeCategory: "category",
      //     });
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         productCategory: response?.data?.typeName,
      //         productCategoryId: response?.data?.id,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchGroup = async () => {
    try {
      const response = await fetchData("pricegroup");

      if (response?.data) {
        setPriceGroupOptions(
          response?.data.map((i) => {
            return {
              label: i?.name,
              key: "pricegroup",
              value: { priceGroupId: i?.id, priceGroupName: i?.name },
            };
          })
        );
      }
      //   if (id) {
      //     const response = await fetchsingleData("pricegroup", id);
      //     if (response?.data)
      //       setFormData({
      //         ...formData,
      //         priceGroupName: response?.data?.name,
      //         priceGroupId: response?.data?.id,
      //       }); // Update state with fetched data
      //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchRole = async () => {
  //   try {
  //     const response = await fetchData("userrole");

  //     if (response?.data) {
  //       setuserRoleOptions(
  //         response?.data.map((i) => {
  //           return {
  //             label: i?.role,
  //             key: "userrole",
  //             value: { roleId: i?.id, role: i?.role },
  //           };
  //         })
  //       );
  //     }
  //   //   if (id) {
  //   //     const response = await fetchsingleData("userrole", id);
  //   //     if (response?.data)
  //   //       setFormData({
  //   //         ...formData,
  //   //         role: response?.data?.role,
  //   //         roleId: response?.data?.id,
  //   //       }); // Update state with fetched data
  //   //   }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fetchRole = async () => {
    try {
      const response = await fetchData("userrole");

      if (response?.data) {
        setuserRoleOptions(
          response?.data
            .filter((i) => i?.status)
            .map((i) => {
              return {
                label: i?.role,
                key: "userrole",
                value: { roleId: i?.id, role: i?.role },
              };
            })
        );
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const getData = async () => {
    try {
      // const responsee = await fetchData(`userrole`);
      // if (responsee?.documents) {
      //   const userrole = responsee.documents.map((i) => ({
      //     label: i?.status ? i?.name : null,
      //     key: "userrole",
      //     value: { name: i?.name },
      //   }));
      //   setUserRole(userrole);
      // }

      if (id) {
        const response = await fetchsingleData("user", id);
        if (response?.data) {
          const fetchedData = response.data;
          setFormData(fetchedData); // Update state with fetched data
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("formData", formData)
    if (isViewMode) return;
    console.log('formData', formData);
    if (validationCheck()) {
      try {
        if (id) {
          patchData(`user/${id}`, formData)
            .then((data) => {
              notify.success('User updated Successfully!');
              navigate(-1);              
              console.log("API response:", data);
            })
            .catch((error) => {
              notify.error('Error while saving Area');
              console.error("Error fetching data:", error);
            });
        } else {
          postData("user", formData)

            .then((data) => {
              notify.success('User saved Successfully!');
              navigate(-1);
              console.log("API response:", data);
              createUser(data);
            })
            .catch((error) => {
              notify.error('Error while saving Area');
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };

  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const handleInputChange = (e) => {
    if (isViewMode) return;
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors.isSubmit) validationCheck();
  };


  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.name) {
      validationErrors.name = "Enter Name";
    }
    if (!formData.role) {
      validationErrors.role = "Select Role ";
    }
    if (!formData.email) {
      validationErrors.email = "Enter Email";
    }
    if (!formData.password) {
      validationErrors.password = "Enter Password";
    }
    if (!formData.orgName) {
      validationErrors.orgName = "Enter Company";
    } 
    if (!formData.repassword) {
      validationErrors.repassword = "Re-enter your password";
    } else if (formData.password !== formData.repassword) {
      validationErrors.repassword = "Passwords do not match";
    }



    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return (
      Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
    );
  };

  const handleFileChange = async (e, id) => {
    if (isViewMode) return;
    const file = e;
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("name", file.name);
    try {
      const status = await uploadDocument(`document/uploadfile`, formData);
      if (status?.data?.id) {
        // if (formData?.productUrl) {
        //     await deleteDocument(formData?.productUrl);
        // }
        setFormData((prevFormData) => ({
          ...prevFormData,
          profileUrl: `${status?.data?.id}`
        }));
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  return (
    <div className="page-24">

      <div className='add-header-design'>
        <div>
          <h2>Add User</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add User" />
        </div>
        <div className='right-contnet-alignment'>
          <Link to="/user">
            <button className='light-button'>

              Discard
            </button>
          </Link>
          <button onClick={handleSubmit}>
            {id ? "Update" : "Save"}
          </button>
        </div>
      </div>

      <div className="page-main-grid">
        <div>
          <div className="new-white-box-design">
            <h2>
              Profile Info
            </h2>
            <div
              style={{
                width: '200px',
                height: '200px',
                margin: "25px",
                overflow: "hidden",
                border: "1px solid #ccc",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <UploadImage
                action={(e) => handleFileChange(e, formData.id)}
                value={formData.profileUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px"
                }}
              />
            </div>

            <div className="two-col-grid pt-3">
              <Input
                label={
                  <span>
                    Name<span className="required-star">*</span>
                  </span>}
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter Name"
                type="text"
                errors={errors.name}
                required
                disabled={isViewMode}
              />
              <Input
                label="Code"
                name="code"
                value={formData.code}
                onChange={handleInputChange}
                placeholder="Code"
                type="text"
                errors={errors.code}
                required
                disabled={isViewMode}
              />
              <SelectBox
                label={
                  <span>
                    User Role<span className="required-star">*</span>
                  </span>}
                name="role"
                value={userRoleOptions.find(
                  (i) => i.value.roleId === formData.roleId
                )} // Correct comparison
                options={userRoleOptions}
                placeholder="Select User Role"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      roleId: Number(selectedOption?.value?.roleId),
                      role: selectedOption?.value?.role, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.role}
                disabled={isViewMode}
              />
              <div className="d-flex align-items-end">
                <Toggle
                  label="Status"
                  checked={formData.status}
                  onChange={toggleSwitch}
                  disabled={isViewMode}
                />
              </div>
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>
              Settings
            </h2>
            <div className="two-col-grid">
              <SelectBox
                label="Price Group"
                name="priceGroup"
                value={priceGroupOptions.find(
                  (i) => i.value.priceGroupId === formData.priceGroupId
                )} // Correct comparison
                options={priceGroupOptions}
                placeholder="Select Price Group"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      priceGroupId: Number(selectedOption?.value?.priceGroupId), // Update countryId
                      priceGroupName: selectedOption?.value?.priceGroupName, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.countryName}
                disabled={isViewMode}
              />
              <SelectBox
                label="Product Category"
                name="productCategory"
                value={categoryOptions.find(
                  (i) =>
                    i.value.productCategoryId === formData.productCategoryId
                )} // Correct comparison
                options={categoryOptions}
                placeholder="Select Category"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      productCategoryId: Number(selectedOption?.value?.productCategoryId), // Update countryId
                      productCategory: selectedOption?.value?.productCategory, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.state}
                disabled={isViewMode}
              />
              <SelectBox
                label="Location"
                name="location"
                value={cityOptions.find(
                  (i) => i.value.cityId === formData.locationId
                )} // Correct comparison
                options={cityOptions}
                placeholder="Select Location"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      locationId: Number(selectedOption?.value?.cityId), // Update countryId
                      location: selectedOption?.value?.cityName, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.state}
                disabled={isViewMode}
              />
                  {company_setting?.extra_setting?.enableUserwiseExpenseType ==="Yes" && (
               <SelectBox
                                        label={
                                            <span>
                                              Expense Type
                                            </span> }                                   
                                        name="expensetypeName"
                                        value={Expensetype?.find(
                                            (option) => option.value.expensetypeId === formData.expensetypeId
                                        )}
                                        options={Expensetype}
                                        onChange={(selectedOption) => {
                                          if (!isViewMode) {
                                            setFormData((prevState) => ({
                                              ...prevState,
                                              expensetypeId: Number(selectedOption?.value?.expensetypeId), 
                                              expensetypeName: selectedOption?.value?.expensetypeName, 
                                            }));
                                          }
                                        }}
                                        placeholder="Expense Type"
                                        type="text"
                                        required={true}
                                        errors={errors.expensetype}
                                    />
                                      )}
                  <SelectBox
                label="Company"
                name="orgName"
                value={companyprofileOptions.find(
                  (i) => i.value.orgId === formData.orgId
                )} // Correct comparison
                options={companyprofileOptions}
                placeholder="Select Company"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      orgId: Number(selectedOption?.value?.orgId), // Update countryId
                      orgName: selectedOption?.value?.orgName, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.orgName}
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>
              Login Details
            </h2>
            <div className="tow-col-grid">
              <Input
                label={
                  <span>
                    Email<span className="required-star">*</span>
                  </span>}
                name="email"
                value={formData?.email}
                onChange={handleInputChange}
                placeholder="email@gmail.com"
                type="varchar"
                errors={errors.email}
                required
                disabled={isViewMode}
              />
              <Input
                label={
                  <span>
                    Password<span className="required-star">*</span>
                  </span>}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Password"
                type="password"
                errors={errors.password}
                required
                disabled={isViewMode}
              />
              <Input
                label={
                  <span>
                    Re-password<span className="required-star">*</span>
                  </span>}
                name="repassword"
                value={formData.repassword}
                onChange={handleInputChange}
                placeholder="Re-password"
                type="password"
                errors={errors.repassword}
                required
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className="new-white-box-design">
            <h2>
              Primary Contact Details
            </h2>
            <div>
              <label
                style={{
                  color: "#777",
                  fontsize: "12px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                Address
                
              </label>
              <textarea
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Address"
                type="text"
                errors={errors.address}
                required

              ></textarea>
            </div>
            <div className="two-col-grid pt-3">
              <Input
                label="Pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                placeholder="Pincode"
                type="text"
                errors={errors.pincode}
                disabled={isViewMode}
              />
              <SelectBox
                label="Country"
                name="countryName"
                value={countryOptions.find(
                  (i) => i.value.countryId === formData.countryId
                )} // Correct comparison
                options={countryOptions}
                placeholder="Select Country"
                onChange={(selectedOption) => {
                  fetchState(selectedOption?.value?.countryId);
                  if (!isViewMode) {
                    setFormData((prevState) => ({

                      ...prevState,
                      countryId: Number(selectedOption?.value?.countryId), // Update countryId
                      country: selectedOption?.value?.country, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.country}
                disabled={isViewMode}
              />
              <SelectBox
                label="State"
                name="state"
                value={stateOptions.find(
                  (i) => i.value.stateId === formData.stateId
                )} // Correct comparison
                options={stateOptions}
                placeholder="Select State"
                onChange={(selectedOption) => {
                  fetchCity(selectedOption?.value?.stateId);
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      stateId: Number(selectedOption?.value?.stateId), // Update countryId
                      state: selectedOption?.value?.state, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.state}
                disabled={isViewMode}
              />
              <SelectBox
                label="City"
                name="city"
                value={cityOptions.find(
                  (i) => i.value.cityId === formData.cityId
                )} // Correct comparison
                options={cityOptions}
                placeholder="Select City"
                onChange={(selectedOption) => {
                  fetchArea(selectedOption?.value?.cityId);
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      cityId: Number(selectedOption?.value?.cityId), // Update countryId
                      city: selectedOption?.value?.cityName, // Update countryName
                    }));
                  }
                }}
                required
                errors={errors.state}
                disabled={isViewMode}
              />
              <SelectBox
                label="Area"
                name="areaName"
                value={areaOptions.find(
                  (i) => i.value.areaId === formData.areaId
                )} // Correct comparison
                options={areaOptions}
                placeholder="Select Area"
                onChange={(selectedOption) => {
                  if (!isViewMode) {
                    setFormData((prevState) => ({
                      ...prevState,
                      areaId: Number(selectedOption?.value?.areaId), // Update countryId
                      areaName: selectedOption?.value?.areaName, // Update countryName
                    }));
                  }
                }}
                disabled={isViewMode}
              />
              <Input
                label="Contact No"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleInputChange}
                placeholder="Contact No"
                type="text"
                errors={errors.contactNo}
                disabled={isViewMode}
              />
            </div>

          </div>
        </div>
        <div>
          <StatusBox />
        </div>
      </div>


    </div>
  );
}

