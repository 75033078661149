import React, { useEffect, useState, useContext } from 'react'
import './news.scss';
import Button from '../../shared/components/button';
import { Link, useLocation } from 'react-router-dom';

import { fetchData, deleteData } from '../../services/apiService';
import { UserContext } from '../../contexts/UserContext';


export default function News() {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const { user, userRole } = useContext(UserContext);


  useEffect(() => {
    fetchData('news')
      .then(data => {
        setData(data.documents)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [])
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`news/${id}`)));
      setData(data.filter(item => !selectedIds.includes(item.newsId)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(data.map((item) => item.newsId));
    } else {
      setSelectedIds([]);
    }
  };



  
    return (
      <div>
        <div className='page-header'>
          <span>News</span>
            // <Link to="addnews"><Button text='Add News' /></Link>
            <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
            {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
            <Link to="addnews"><Button text='Add News'/></Link>
          </div>
        </div>
        <div className='news-table-alignment'>
          <div className='account-table-design'>
            <table>
              <thead>
                <tr>

                  <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === data.length} /></th>
                  <th>Action</th>
                  <th>Title</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((i) => {
                    return (
                      <tr key={i.newsId}>
                      <td> <input type='checkbox' checked={selectedIds.includes(i.dataId)} onChange={() => handleCheckboxChange(i.dataId)} /></td>
                        <td>Action Here</td>
                        <td>{i.newsTitle} </td>
                        <td>{i.expiry}</td>
                        <td>  
                          <button className='status'>
                            {i.status}
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

