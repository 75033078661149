import React from 'react';
import { Switch } from 'antd';
import './switch.scss';
export default function Toggle({ label, checked, onChange, ...otherProps }) {
    return (
        <div className="toggle-container">
            <label className="toggle-label">{label}</label>
            <Switch className="toggle-switch" checked={checked} onChange={onChange} {...otherProps} />
        </div>
    )
}
