import { RouterProvider, } from "react-router-dom";
import "./App.css";
import router from "./routes/routes";
import './styles/mixins/index.scss';
import ToastProvider from "./routes/toastr/Toastr";

function App() {
  

  return (
    <>
      <ToastProvider/>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
