import {
    createBrowserRouter,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Wrapper from "../shared/components/wrapper";
import Login from "./login";
import Forgotpassword from "./forgotpassword";
import Defaultwrapper from "../shared/components/defaultwrapper";
import Dashboard from "./dashboard";
import Activity from "./activity";
import News from "./news";
import Pricegroup from "./pricegroup";
import Productcategory from "./productcategory";
import Productunit from "./productunit";
import AddEditcategory from "./productcategory/addeditproductcategory";
import AddEditunit from "./productunit/addeditproductunit";
import Product from "./product";
import Priority from "./priority";
import Account from "./account";
import Order from "./order";
import Paymentcollection from "./paymentcollection";
import Expense from "./expense";
import Deal from "./deal";
import Lead from "./lead";

import Orderwisereport from "./orderwisereport";
import Productwisereport from "./productwisereport";
import Userrole from "./userrole";
import Activitytype from "./activitytype";
import Area from "./area";
import Paymenttype from "./paymenttype";
import Transport from "./transport";
import Leadsource from "./leadsource";
import LeadIndustry from "./leadIndustry";
import Dealpipeline from "./dealpipeline";
import Deallost from "./deallost";
import VisitFeedbacktype from "./visitFeedbacktype";
import Leavetype from "./leavetype";
import LocationPage from "./location";
import Requestdata from "./requestdata";
import Form from "./form";
import Addnews from "./news/addnews";
import Addcustomers from "./addcustomers";
import Addproduct from "./product/addeditproduct";
import AddEditactivitytype from "./activitytype/addactivity";
import AddEditpaymenttype from "./paymenttype/addeditpaymenttype";
import AddEditleadStatus from "./leadstatus/addeditlead";
import AddOrder from "./order/addoreder";
import AddEditleadSource from "./leadsource/addeditleadsource";
import AddEditleadindustry from "./leadIndustry/addeditleadindustry";
import Addedittrasport from "./transport/addedittrasport";
import AddEditdeallost from "./deallost/addeditdeallost";
import AddEditvisitfeedbacktype from "./visitFeedbacktype/addEditvisitfeedback";
import AddEditleaveType from "./leavetype/addeditleavetype";
import AddArea from "./area/addarea";
import AddEditdealpipeline from "./dealpipeline/adddealpipeline";
import AddLocation from "./location/addlocation";
import AddEditrequestdata from "./requestdata/addrequestdata";
import AddEditPriceData from "./pricegroup/addprice";
import AddEditpaymentcollection from "./paymentcollection/addpayment";
import AddEditviewPaymentCollection from "./paymentcollection/paymentview";
import AddEditexpense from "./expense/addexpense";
import AddEditviewexpense from "./expense/expenseview";
import AddEditviewordertable from "./order/view";
import AddEditviewdispatchorder from "./order/dispatchorder";
import AddEditdispatchorder from "./order/adddispatchorder";
import AddDeal from "./deal/adddeal";
import AddEditviewdealtable from "./deal/view";
import AddEditLead from "./lead/addlead";
import AddEditviewleadtable from "./lead/view";
import AddEdituserrole from "./userrole/adduserroal";
import User from "./user";
import AddEdituser from "./user/adduser";
import Expensetype from "./expensetype";
import AddEditexpensetype from "./expensetype/addexpense";
import Profile from "./profile";
import AddEditProfile from "./profile/editprofile";
import Subscription from'./subscription';
import AddEditStateTypeData from "./statetype/addstatetype";
import Statetype from "./statetype";
import Leadstatus from "./leadstatus";
import CountryMaster from "./countryMaster";
import  AddEditcountry  from "./countryMaster/addCountry";
import AddActivity from "./activity/addactivity";
import City from "./city";
import AddEditCityData from "./city/addcity";
import AddEditSystemTypeMaster from "./systemtypemaster/addeditsytemtypedata";
import SystemTypeMaster from './systemtypemaster';
import SystemTypeCategory from "./systemtypecategory";
import AddEditSystemTypeCategory from "./systemtypecategory/addeditsystemtypecategory";
import Quotation from "./quotation";
import Createquote from "./quotation/createquote";
import Quotationview from "./quotation/quoteview";
import CurrentLocations from './currentLocations';
import Invoice from "./invoice";
import Invoicetable from "./invoice/invoicetable";
import AddInvoice from "./invoice/addinvoice";
import AddEditviewInvoice from "./invoice/view";
import DispatchOrder from "./dispatch";
import CompanySettings from "./companysettings";
import AddEditviewCustomerTable from "./addcustomers/viewcustomer";
import ViewDispatchOrder from "./dispatch/viewdispatchorder";
import Userview from "./user/userview";
import SelsReturn from "./selsReturn";
import AddSelsreturn from "./selsReturn/addselesReturn";
import AddselesReturnView from "./selsReturn/selesReturnView";
import OrderReport from "./orderReport/orderReport";
import AccountcategoryTable from "./accountCategory/accountCategoryTable/accountCategoryTable";
import Accountcategory from "./accountCategory";
import AddaccountCategory from "./accountCategory/addaccountCategory/addaccountCategory";
import Segment from "./Segment";
import Addsegment from "./Segment/addSegment";
import attendancetable from "./attendance/attendancetable";
import Attendance from "./attendance";
import Leave from "./leave";
import AddEditleave from "./leave/addleave";
import Leaveview from "./leave/viewleave";
import AllLocations from "./alllocation";
const router = createBrowserRouter([

    // Page Routing// 
    {
        path: '',
        element:  
          <Defaultwrapper><Dashboard /></Defaultwrapper>
        
    },
    {
        path: "/login",
        element: <Wrapper><Login /></Wrapper>,
    },

    {
        path: "/forgot-password",
        element: <Wrapper><Forgotpassword /></Wrapper>,
    },

    {
        path: "/dashboard",
        element: <Defaultwrapper><Dashboard /></Defaultwrapper>,
    },
    {
        path: "/activity",
        element: <Defaultwrapper><Activity /></Defaultwrapper>,
    },
    {
        path: "/systemTypeMaster",
        element: <Defaultwrapper><SystemTypeMaster /></Defaultwrapper>,
    },
    {
        path: "/systemTypeMaster/addEditSystemTypeMaster",
        element: <Defaultwrapper><AddEditSystemTypeMaster /></Defaultwrapper>,
    },
    {
        path: "/systemTypeMaster/addEditSystemTypeMaster/:id",
        element: <Defaultwrapper><AddEditSystemTypeMaster /></Defaultwrapper>,
    },
    

    {
        path: "/systemTypeCategory",
        element: <Defaultwrapper><SystemTypeCategory /></Defaultwrapper>,
    },
    {
        path: "/systemTypeCategory/addEditSystemTypeCategory",
        element: <Defaultwrapper><AddEditSystemTypeCategory /></Defaultwrapper>,
    },
    {
        path: "/systemTypeCategory/addEditSystemTypeCategory/:id",
        element: <Defaultwrapper><AddEditSystemTypeCategory /></Defaultwrapper>,
    },
    {
        path: "/countryMaster/addEditcountry",
        element: <Defaultwrapper><AddEditcountry /></Defaultwrapper>,
    },
    {
        path: "/countryMaster/addEditcountry/:id",
        element: <Defaultwrapper><AddEditcountry /></Defaultwrapper>,
    },
    {
        path: "/order/adddispatchorder",
        element: <Defaultwrapper><AddEditdispatchorder></AddEditdispatchorder></Defaultwrapper>
    },
    {
        path:'/expensetype/addexpensetype/:id',
        element:<Defaultwrapper><AddEditexpensetype></AddEditexpensetype></Defaultwrapper>
    },
    {
        path: "/countryMaster",
        element: <Defaultwrapper><CountryMaster /></Defaultwrapper>,
    },
    {
        path:'/adddispatchorder',
        element:<Defaultwrapper><DispatchOrder></DispatchOrder></Defaultwrapper>
    },
    {
        path:'/adddispatchorder/view/:id',
        element:<Defaultwrapper><ViewDispatchOrder></ViewDispatchOrder></Defaultwrapper>
    },
    {
        path:'/invoice',
        element:<Defaultwrapper><Invoice></Invoice></Defaultwrapper>
    },
    {
        path:'/invoice/add',
        element:<Defaultwrapper><AddInvoice></AddInvoice></Defaultwrapper>
    },
    {
        path:'/invoice/add/:id',
        element:<Defaultwrapper><AddInvoice></AddInvoice></Defaultwrapper>
    },
    {
        path:'/invoice/view/:id',
        element:<Defaultwrapper><AddEditviewInvoice></AddEditviewInvoice></Defaultwrapper>
    },

    {
        path:'/salesReturn',
        element:<Defaultwrapper><SelsReturn></SelsReturn></Defaultwrapper>
    },
    {
        path:'/salesReturn/add',
        element:<Defaultwrapper><AddSelsreturn></AddSelsreturn></Defaultwrapper>
    },
    {
        path:'/salesReturn/add/:id',
        element:<Defaultwrapper><AddSelsreturn></AddSelsreturn></Defaultwrapper>
    },
    {
        path:'/salesReturn/view/:id',
        element:<Defaultwrapper><AddselesReturnView></AddselesReturnView></Defaultwrapper>
    },

    {
        path:'/accountCategory',
        element:<Defaultwrapper><Accountcategory></Accountcategory></Defaultwrapper>
    },
    {
        path:'/accountCategory/add',
        element:<Defaultwrapper><AddaccountCategory></AddaccountCategory></Defaultwrapper>
    },
    {
        path:'/accountCategory/add/:id',
        element:<Defaultwrapper><AddaccountCategory></AddaccountCategory></Defaultwrapper>
    },

    {
        path:'/segment',
        element:<Defaultwrapper><Segment></Segment></Defaultwrapper>
    },
    {
        path:'/segment/add',
        element:<Defaultwrapper><Addsegment></Addsegment></Defaultwrapper>
    },
    {
        path:'/segment/add/:id',
        element:<Defaultwrapper><Addsegment></Addsegment></Defaultwrapper>
    },


    {
        path:'current-location',
        element:<Defaultwrapper><CurrentLocations/></Defaultwrapper>
    },
    {
        path: "/Quotation",
        element: <Defaultwrapper><Quotation /></Defaultwrapper>,
    },
    {
        path: "/Quotation/add",
        element: <Defaultwrapper><Createquote /></Defaultwrapper>,
    },
    {
        path: "/Quotation/add/:id",
        element: <Defaultwrapper><Createquote /></Defaultwrapper>,
    },
    {
        path: "/Quotation/view/:id",
        element: <Defaultwrapper><Quotationview /></Defaultwrapper>,
    },
    {
        path: "/statetype",
        element: <Defaultwrapper><Statetype /></Defaultwrapper>,
    },
    {
        path: "/statetype/addstatetype",
        element: <Defaultwrapper><AddEditStateTypeData /></Defaultwrapper>,
    },
    {
        path: "/statetype/addstatetype/:id",
        element: <Defaultwrapper><AddEditStateTypeData /></Defaultwrapper>,
    },
    {
        path: "/city",
        element: <Defaultwrapper><City /></Defaultwrapper>,
    },
    {
        path: "/city/addcity",
        element: <Defaultwrapper><AddEditCityData /></Defaultwrapper>,
    },
    {
        path: "/city/addcity/:id",
        element: <Defaultwrapper><AddEditCityData /></Defaultwrapper>,
    },
    {
        path: "/activity/addActivity",
        element: <Defaultwrapper><AddActivity /></Defaultwrapper>,
    },
    {
        path: "activity/addeditActivity/:id",
        element: <Defaultwrapper><AddActivity /></Defaultwrapper>,
    },
    {
        path: "/news",
        element: <Defaultwrapper><News /></Defaultwrapper>,
    },
    {
        path: "/pricegroup",
        element: <Defaultwrapper><Pricegroup /></Defaultwrapper>,
    },
    {
        path: "product-category",
        element: <Defaultwrapper><Productcategory /></Defaultwrapper>
    },
    {
        path: "product-category/edit/:id",
        element: <Defaultwrapper><AddEditcategory /></Defaultwrapper>,
    },
    {
        path: "product-category/add",
        element: <Defaultwrapper><AddEditcategory /></Defaultwrapper>,
    },
    {
        path: "product-unit",
        element: <Defaultwrapper><Productunit /></Defaultwrapper>
    },
    {
        path: "product-unit/edit/:id",
        element: <Defaultwrapper><AddEditunit /></Defaultwrapper>,
    },
    {
        path: "product-unit/add",
        element: <Defaultwrapper><AddEditunit /></Defaultwrapper>,
    },
    {
        path: "/product",
        element: <Defaultwrapper><Product /></Defaultwrapper>
    },
    {
        path: "product/edit/:id",
        element: <Defaultwrapper><Addproduct /></Defaultwrapper>,
    },
    {
        path: "product/view/:id",
        element: <Defaultwrapper><Addproduct /></Defaultwrapper>,
    },
    {
        path: "product/add",
        element: <Defaultwrapper><Addproduct /></Defaultwrapper>,
    },
    {
        path: "/priority",
        element: <Defaultwrapper><Priority /></Defaultwrapper>,
    },
    {
        path: "/account",
        element: <Defaultwrapper><Account /></Defaultwrapper>,
    },
    {
        path: "/order",
        element: <Defaultwrapper><Order /></Defaultwrapper>,
    },
    {
        path: "/order/add",
        element: <Defaultwrapper><AddOrder /></Defaultwrapper>,
    },
    {
        path: "/order/edit/:id",
        element: <Defaultwrapper><AddOrder /></Defaultwrapper>,
    },
    {
        path: "/order/view/:id",
        element: <Defaultwrapper><AddEditviewordertable /></Defaultwrapper>,
    },
    {
        path: "/payment-collection",
        element: <Defaultwrapper><Paymentcollection /></Defaultwrapper>,
    },
    {
        path: "/expense",
        element: <Defaultwrapper><Expense /></Defaultwrapper>,
    },
    {
        path: "/deal",
        element: <Defaultwrapper><Deal /></Defaultwrapper>,
    },
    {
        path: "/lead",
        element: <Defaultwrapper><Lead /></Defaultwrapper>,
    },
    {
        path: "/orderwise-report",
        element: <Defaultwrapper><Orderwisereport /></Defaultwrapper>,
    },
    {
        path: "/productwise-report",
        element: <Defaultwrapper><Productwisereport /></Defaultwrapper>,
    },
    {
        path: "/user-role",
        element: <Defaultwrapper><Userrole /></Defaultwrapper>,
    },
    {
        path: "/orderReport",
        element: <Defaultwrapper><OrderReport /></Defaultwrapper>,
    },
    {
        path: "/user",
        element: <Defaultwrapper><User /></Defaultwrapper>,
    },
    {
        path: "/user/view/:id",
        element: <Defaultwrapper><Userview /></Defaultwrapper>,
    },
    {
        path: "/activitytype",
        element: <Defaultwrapper><Activitytype /></Defaultwrapper>,
    },
    {
        path: "/area",
        element: <Defaultwrapper><Area /></Defaultwrapper>,
    },
    {
        path: "/paymenttype",
        element: <Defaultwrapper><Paymenttype /></Defaultwrapper>,
    },
    {
        path: "/transport",
        element: <Defaultwrapper><Transport /></Defaultwrapper>,
    },
    {
        path: "/leadstatus",
        element: <Defaultwrapper><Leadstatus /></Defaultwrapper>,
    },
    {
        path: "/leadsource",
        element: <Defaultwrapper><Leadsource /></Defaultwrapper>,
    },
    {
        path: "/leadIndustry",
        element: <Defaultwrapper><LeadIndustry /></Defaultwrapper>,
    },
    {
        path: "/dealpipeline",
        element: <Defaultwrapper><Dealpipeline /></Defaultwrapper>,
    },
    {
        path: "/deallost",
        element: <Defaultwrapper><Deallost /></Defaultwrapper>,
    },
    {
        path: "/visitFeedbacktype",
        element: <Defaultwrapper><VisitFeedbacktype /></Defaultwrapper>,
    },
    {
        path: "/leavetype",
        element: <Defaultwrapper><Leavetype /></Defaultwrapper>,
    },
    {
        path: "/profile",
        element: <Defaultwrapper><Profile/></Defaultwrapper>,
    },
    {
        path: "/location",
        element: <Defaultwrapper><LocationPage /></Defaultwrapper>,
    },
    {
        path: "/requestdata",
        element: <Defaultwrapper><Requestdata /></Defaultwrapper>,
    },
    {
        path: "/form",
        element: <Defaultwrapper><Form /></Defaultwrapper>,
    },
    {
        path: "/news/addnews",
        element: <Defaultwrapper><Addnews /></Defaultwrapper>,
    },

    {
        path: "/activitytype/addEditactivitytype",
        element: <Defaultwrapper><AddEditactivitytype /></Defaultwrapper>,
    },
    {
        path: "/paymenttype/addEditpaymenttype",
        element: <Defaultwrapper><AddEditpaymenttype /></Defaultwrapper>,
    },
    {
        path: "/activitytype/addEditactivitytype/:id",
        element: <Defaultwrapper><AddEditactivitytype /></Defaultwrapper>,
    },
    {
        path: "/paymenttype/addEditpaymenttype/:id",
        element: <Defaultwrapper><AddEditpaymenttype /></Defaultwrapper>,
    },
    {
        path: "/leadstatus/addEditleasStatus",
        element: <Defaultwrapper><AddEditleadStatus /></Defaultwrapper>,
    },
    {
        path: "/leadstatus/addEditleasStatus/:id",
        element: <Defaultwrapper><AddEditleadStatus /></Defaultwrapper>,
    },
    {
        path: "/leadsource/addEditleadsource",
        element: <Defaultwrapper><AddEditleadSource /></Defaultwrapper>,
    },
    {
        path: "/leadsource/addEditleadsource/:id",
        element: <Defaultwrapper><AddEditleadSource /></Defaultwrapper>,
    },
    {
        path: "/leadIndustry/addEditleadindustry",
        element: <Defaultwrapper><AddEditleadindustry /></Defaultwrapper>,
    },
    {
        path: "/leadIndustry/addEditleadindustry/:id",
        element: <Defaultwrapper><AddEditleadindustry /></Defaultwrapper>,
    },
    {
        path: "/transport/addEdittrasport",
        element: <Defaultwrapper><Addedittrasport /></Defaultwrapper>,
    },
    {
        path: "/transport/addEdittrasport/:id",
        element: <Defaultwrapper><Addedittrasport /></Defaultwrapper>,
    },
    {
        path: "/account/addcustomer",
        element: <Defaultwrapper><Addcustomers /></Defaultwrapper>,
    },
    {
        path: "/account/addcustomer/:id",
        element: <Defaultwrapper><Addcustomers /></Defaultwrapper>,
    },

    {
        path:"/account/view/:id",
        element:<Defaultwrapper><AddEditviewCustomerTable></AddEditviewCustomerTable></Defaultwrapper>
    },

    {
        path: "/deallost/addEditdealLost",
        element: <Defaultwrapper><AddEditdeallost /></Defaultwrapper>,
    },
    {
        path: "/deallost/addEditdealLost/:id",
        element: <Defaultwrapper><AddEditdeallost /></Defaultwrapper>,
    },
    {
        path: "/visitFeedbacktype/addEditvisitfeedback",
        element: <Defaultwrapper><AddEditvisitfeedbacktype /></Defaultwrapper>,
    },
    {
        path: "/visitFeedbacktype/addEditvisitfeedback/:id",
        element: <Defaultwrapper><AddEditvisitfeedbacktype /></Defaultwrapper>,
    },
    {
        path: "/leavetype/addEditleave",
        element: <Defaultwrapper><AddEditleaveType /></Defaultwrapper>,
    },
    {
        path: "/leavetype/addEditleave/:id",
        element: <Defaultwrapper><AddEditleaveType /></Defaultwrapper>,
    },
    {
        path: "account/edit/:id",
        element: <Defaultwrapper><Addcustomers /></Defaultwrapper>,
    },

    {
        path: "/area/addarea",
        element: <Defaultwrapper><AddArea /></Defaultwrapper>,
    },
    {
        path: "/area/addarea/:id",
        element: <Defaultwrapper><AddArea /></Defaultwrapper>

    },
    {
        path: "/dealpipeline/adddealpipeline",
        element: <Defaultwrapper><AddEditdealpipeline /></Defaultwrapper>
    },
    {
        path: "dealpipeline/adddealpipeline/:id",
        element: <Defaultwrapper><AddEditdealpipeline /></Defaultwrapper>
    },
    // {
    //     path:"/location/addlocation",
    //     elememt:<Defaultwrapper><AddLocation /></Defaultwrapper>
    // },

    {
        path: "/location/addlocation",
        element: <Defaultwrapper><AddLocation /></Defaultwrapper>,
    },
    {
        path: "/location/addlocation/:id",
        element: <Defaultwrapper><AddLocation /></Defaultwrapper>,
    },
    {
        path: "/requestdata/addrequestdata",
        element: <Defaultwrapper><AddEditrequestdata /></Defaultwrapper>,
    },
    {
        path: "/pricegroup/addprice",
        element: <Defaultwrapper><AddEditPriceData /></Defaultwrapper>,
    },
    {
        path: "/pricegroup/addprice/:id",
        element: <Defaultwrapper><AddEditPriceData /></Defaultwrapper>,
    },
    {
        path: "/payment-collection",
        element: <Defaultwrapper><Paymentcollection /></Defaultwrapper>,
    },
    {
        path: "/payment-collection/addpaymentcollection",
        element: <Defaultwrapper><AddEditpaymentcollection /></Defaultwrapper>
    },
    {
        path: "/payment-collection/addpaymentcollection/:id",
        element: <Defaultwrapper><AddEditpaymentcollection /></Defaultwrapper>
    },
    {
        path: "/payment-collection/view/:id",
        element: <Defaultwrapper><AddEditviewPaymentCollection /></Defaultwrapper>
    },
    {
        path: "/expense/addexpense",
        element: <Defaultwrapper><AddEditexpense /></Defaultwrapper>
    },
    {
        path: "/expense/expenseview/:id",
        element: <Defaultwrapper><AddEditviewexpense /></Defaultwrapper>
    },
    {
        path: "/expense/addexpense/:id",
        element: <Defaultwrapper><AddEditexpense /></Defaultwrapper>

    },
    {
        path: "/order/dispatchorder/:id",
        element: <Defaultwrapper><AddEditviewdispatchorder /></Defaultwrapper>
    },
    {
        path: "/order/adddispatchorder/:id",
        element: <Defaultwrapper><AddEditdispatchorder></AddEditdispatchorder></Defaultwrapper>
    },
    {
        path: "/deal/adddeal",
        element: <Defaultwrapper><AddDeal></AddDeal></Defaultwrapper>

    },
    {
        path: "/deal/adddeal/:id",
        element: <Defaultwrapper><AddDeal></AddDeal></Defaultwrapper>

    },
    {
        path: "/deal/view/:id",
        element: <Defaultwrapper><AddEditviewdealtable></AddEditviewdealtable></Defaultwrapper>

    },
    {   
        path:"/lead/addlead",
        element:<Defaultwrapper><AddEditLead></AddEditLead></Defaultwrapper>
    },
    {
        path:"/lead/addlead/:id",
        element:<Defaultwrapper><AddEditLead></AddEditLead></Defaultwrapper>
    },
    {
        path:"/lead/view/:id",
        element:<Defaultwrapper><AddEditviewleadtable></AddEditviewleadtable></Defaultwrapper>
    },
    {
        path:'/user-role/adduserrole',
        element:<Defaultwrapper><AddEdituserrole></AddEdituserrole></Defaultwrapper>
    },
    {
        path:'/user-role/adduserrole/view/:id',
        element:<Defaultwrapper><AddEdituserrole></AddEdituserrole></Defaultwrapper>
    },
    {
        path:'/user/adduser',
        element:<Defaultwrapper><AddEdituser></AddEdituser></Defaultwrapper>
    },
    {
        path:'/user/adduser/view/:id',
        element:<Defaultwrapper><AddEdituser></AddEdituser></Defaultwrapper>
    },
    {
        path:'/user/adduser/:id',
        element:<Defaultwrapper><AddEdituser></AddEdituser></Defaultwrapper>
    },
    {
        path:'/user-role/adduserrole/:id',
        element:<Defaultwrapper><AddEdituserrole></AddEdituserrole></Defaultwrapper>
    },
    {
        path:'/order/add/:id',
        element:<Defaultwrapper><AddOrder></AddOrder></Defaultwrapper>
    },
    {
        path:'/expensetype',
        element:<Defaultwrapper><Expensetype></Expensetype></Defaultwrapper>
    },
    {
        path:'/expensetype/addexpensetype',
        element:<Defaultwrapper><AddEditexpensetype></AddEditexpensetype></Defaultwrapper>
    },
    {
        path:'/profile/add/:id',
        element:<Defaultwrapper><AddEditProfile></AddEditProfile></Defaultwrapper>
    },
    {
        path:'/companysetting',
        element:<Defaultwrapper><CompanySettings></CompanySettings></Defaultwrapper>
    },
    {
        path:'/adddispatchorder',
        element:<Defaultwrapper><DispatchOrder></DispatchOrder></Defaultwrapper>
    },
    {
        path:'/subscription',
        element:<Defaultwrapper><Subscription/></Defaultwrapper>
    },
    {
        path: "account/addcustomer/:id",
        element: <Defaultwrapper><Addcustomers /></Defaultwrapper>,
    },
    {
        path:"attendance",
        element:<Defaultwrapper><Attendance></Attendance></Defaultwrapper>
    },
    {
        path:"leave",
        element:<Defaultwrapper><Leave></Leave></Defaultwrapper>
    },
    {
        path:"leave/addleave",
        element:<Defaultwrapper><AddEditleave></AddEditleave></Defaultwrapper>
    },
    {
        path:"leave/addleave/:id",
        element:<Defaultwrapper><AddEditleave></AddEditleave></Defaultwrapper>
    },
    {
        path:"leave/view/:id",
        element:<Defaultwrapper><Leaveview></Leaveview></Defaultwrapper>
    },
    {
        path:"all-location",
        element:<Defaultwrapper><AllLocations></AllLocations></Defaultwrapper>
    },
    



]);

export default router