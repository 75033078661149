import React, { useEffect, useState } from "react";
import "./activeOrder.scss";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { fetchData } from "../../../services/apiService";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "../../../shared/components/pagination";
import { useSelector } from 'react-redux';

export default function ActiveOrder() {
  const [orderlist, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6; // Number of rows per page

  useEffect(() => {
    getData();

  }, []);

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));



  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const getData = async () => {
    try {
      const response = await fetchData("order");
      if (response?.data) {
        setOrderList(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateProgress = (order) => {
    if (order.orderStatus === "Pending") return 0;
    if (order.orderStatus === "Close") return 100;

    if (order.orderStatus === "Open" && order.productDetails?.length > 0) {
      const totalProducts = order.productDetails.length;
      const dispatchedCount = order.productDetails.filter(
        (product) => product.productStatus === "Dispatched"
      ).length;

      return Math.round((dispatchedCount / totalProducts) * 100);
    }

    return 0;
  };

  // Paginate orders
  const paginateOrders = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return orderlist.slice(startIndex, endIndex);
  };
  const openOrders = useSelector((state) => state.auth.openorder);
  const pendingOrders = useSelector((state) => state.auth.pendingorder);
  const cancelOrders = useSelector((state) => state.auth.cancelorder);
  const closeOrders = useSelector((state) => state.auth.closeorder);
  const totalorder = useSelector((state) => state.auth.totalorder);


  return (
    <div className="active-order-grid">
      <div className="active-order-grid-items">
        <div className="card-header-alignment">
          <h2>Order List</h2>
          <span>Export Report</span>
        </div>
        <div className="table-design-change">
          <table>
            <thead>
              <tr>
                <th>Order No</th>
                <th>Customer Name</th>
                <th>Order Date</th>
                <th>Progress</th>
                <th>Total Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {paginateOrders().map((order, index) => {
                const progress = calculateProgress(order);
                return (
                  <tr key={index}>
                    <td>
                      <span>{order?.orderNo}</span>
                    </td>
                    <td>
                      <span>{order?.customerName}</span>
                    </td>
                    <td>
                      <span>{formatDate(order?.orderDate)}</span>
                    </td>
                    <td>
                      <ProgressBar now={progress} label={`${progress}%`} />
                    </td>
                    <td>
                      <span>₹{order?.totalAmount}</span>
                    </td>
                    <td>
                      <button
                        style={{
                          backgroundColor: order?.orderStatus === 'Pending' ? '#C00900' :
                            order?.orderStatus === 'Open' ? '#0D99FF' :
                              order?.orderStatus === 'Completed' ? 'green' : 'gray',
                          color: 'white', // Set the text color to white for better contrast
                          padding: '5px 10px',
                          border: 'none',
                          borderRadius: '5px',
                        }}
                      >
                        {order?.orderStatus}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={orderlist.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <div className="active-order-grid-items">
        <div className="card-header-alignment">
          <h2>Order Overview</h2>
        </div>
        <PieChart
          series={[
            {
              data: [
                { value: pendingOrders, label: "Pending Orders" },
                { value: openOrders, label: "Open Order" },
                { value: cancelOrders, label: "Cancelled Order" },
                { value: closeOrders, label: "Closed Order" },
              ],
              innerRadius: 90,
              label: true,
              labelPosition: 'outside',

            },
          ]}
          width={435}
          height={350}
        >
          <PieCenterLabel>{totalorder}</PieCenterLabel>
        </PieChart>
       
      </div>
    </div>
  );
}
