import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SelectBox from '../../../shared/components/select';
import GlobalButton from '../../../shared/globalButton';
import { notify } from '../../toastr/Toastr';


export default function AddEditStateTypeData() {
    const navigate = useNavigate();
    const { id } = useParams();



    useEffect(() => {
        // Fetch data when the component mounts if there is an id
        if (id) {
            getData();
        }
        fetchCountry();
    }, []);

    const [formData, setFormData] = useState({
        stateName: '',
        stateShortname: '',
        countryName: '',
        countryId: '',
        status: true,
    });
    const [countryOptions, setCountryOptions] = useState([])
    const [errors, setErrors] = useState({});
    const fetchCountry = async () => {
        try {
            const response = await fetchData("country");

            if (response?.data) {
                setCountryOptions(response?.data.map(i => {
                    return {
                        label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
                    }
                }));
            }
            if (id) {
                const response = await fetchsingleData("country", id);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitting form...");

        if (validationCheck(true)) {
            try {
                if (id) {
                    patchData(`state/${id}`, formData).then(data => {
                        notify.success('State updated Successfully!');
                        navigate(-1);
                    }).catch(error => {
                        console.error('Error updating data:', error);
                    });
                } else {
                    postData('state', formData).then(data => {
                        notify.success('State saved Successfully!');
                        navigate(-1);
                    }).catch(error => {
                        console.error('Error posting data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        } else {
            console.log("Form validation failed.");
        }
    };


    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.stateName?.trim()) {
            validationErrors.stateName = 'Please Enter State Name.';
        }
       
        if (!formData?.countryId) {
            validationErrors.countryName = 'Please Select a Country.';
        }

        if (isSubmit || errors.isSubmit) {
            validationErrors.isSubmit = true;
        }

        setErrors(validationErrors);
        console.log("Validation Errors:", validationErrors); // Debug validation errors
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };


    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit) {
            validationCheck();
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData('state', id);
            if (response?.data) {
                setFormData(response.data); // Update state with fetched data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    console.log("Selected Country ID:", formData.countryId);
    console.log("Country Options:", countryOptions);

    return (
        <div>
            <div className='page-header add-news-alignment'>
                <span>State Master {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/stateType"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div>
            <div>
                <div className='add-news-form-design'>
                    <form onSubmit={handleSubmit}>
                        <div className='grid'>
                            <div className='bottom-alignment'>
                                <SelectBox
                                    label={
                                        <span>
                                            Country<span className="required-star">*</span>
                                        </span>}

                                    name="countryName"
                                    value={countryOptions.find(i => i.value.countryId === formData.countryId)}  // Correct comparison
                                    options={countryOptions}
                                    placeholder="Select Country"
                                    onChange={(selectedOption) => {
                                        console.log("Selected Country:", selectedOption);
                                        setFormData(prevState => ({
                                            ...prevState,
                                            countryId: selectedOption?.value?.countryId, // Update countryId
                                            countryName: selectedOption?.value?.countryName // Update countryName
                                        }));
                                    }}
                                    required
                                    errors={errors.countryName}
                                />



                            </div>
                            <div className='bottom-alignment'>
                                <Input
                                    label={
                                        <span>
                                            State Name<span className="required-star">*</span>
                                        </span>}

                                    name='stateName'
                                    value={formData.stateName}
                                    onChange={handleInputChange}
                                    placeholder='Enter State Name..'
                                    type='text'
                                    required
                                    errors={errors.stateName}
                                />
                            </div>
                            <div className='bottom-alignment'>
                                <Input
                                    label='State Short Name'
                                    name='stateShortname'
                                    value={formData.stateShortname}
                                    onChange={handleInputChange}
                                    placeholder='Enter State Name..'
                                    type='text'
                                    required
                                    
                                />
                            </div>


                            {/* <div className='bottom-alignment'>
                                <Input
                                    label='Price'
                                    name='price'
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    placeholder='Enter Price..'
                                    type='text'
                                    required
                                    errors={errors.price}
                                />
                            </div>
                            <div className='bottom-alignment'>
                                <Input
                                    label='Discount'
                                    name='discount'
                                    value={formData.discount}
                                    onChange={handleInputChange}
                                    placeholder='Enter Discount..'
                                    type='text'
                                    required
                                    errors={errors.discount}
                                />
                            </div>
                            <div className='bottom-alignment'>
                                <Input
                                    label='Offer Discount'
                                    name='offerdiscount'
                                    value={formData.offerdiscount}
                                    onChange={handleInputChange}
                                    placeholder='Enter Offer Discount..'
                                    type='text'
                                    required
                                    errors={errors.offerdiscount}
                                />
                            </div> */}
                            <div className='bottom-alignment'>
                                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
